import React, { useState, useEffect } from "react";

import ThowColumLayoutLogin from "../../layouts/ThowColumLayoutLogin";
import companyIcon from "../../images/svg/companyIcon.svg";
import registerIcon from "../../images/svg/registerIcon.svg";
import checkOff from "../../images/svg/checkOff.svg";
import checkOn from "../../images/svg/checkOn.svg";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { useNavigate } from "react-router-dom";
import InputSelectFilterManager from "../../components/InputSelectFilterManager";
import TermsFooter from "../../components/TermsFooter";
import { cnpj } from "cpf-cnpj-validator";
import { toast } from "react-toastify";
import axios from "axios";
import api from "../../services/api";

const Register = () => {
  const [checkTerms, setCheckTerms] = useState(false);

  const navigate = useNavigate();

  const [name, setName] = useState();
  const [picture, setPicture] = useState();
  const [corporateName, setCorporateName] = useState("");
  const [documentType, setDocumentType] = useState("99.999.999/9999-99:CNPJ");

  const [cnpjValue, setCnpjValue] = useState();
  const [type_buniness, setType_buniness] = useState();
  const [mail, setMail] = useState();

  const [cnaes, setCnaes] = useState("");
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [whatsapp, setWhatsapp] = useState();

  /* BTN STATE */

  const [btnState, setBtnState] = useState(false);

  const onSubmitCreate = async () => {
    if (
      (!corporateName,
      !cnpjValue,
      !cnaes,
      !type_buniness,
      !mail,
      !password,
      !whatsapp)
    ) {
      return toast.error("Preencha todos os campos", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (password !== confirmPassword) {
      return toast.error("Senhas não conferem", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (!checkTerms) {
      return toast.error("Aceite os termos de uso", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const data = {
      name: name,
      corporate_name: corporateName,
      cnpj: cnpjValue,
      cnaes: cnaes,
      type_buniness: type_buniness,
      email: mail,
      password: password,
      password_hash: password,
      whatsapp: whatsapp,
      who_created: "client",
    };

    setBtnState(true);
    try {
      await api.post("/user", data);

      toast.success("Seu cadastro foi sucesso", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setBtnState(false);

      navigate("/");
    } catch (error) {
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const verifyCnpj = async () => {
    const verifyLocation = documentType.split(":")[1] === "CNPJ";
    const validate = cnpj.isValid(cnpjValue);
    const cpfUnformatted = cnpj.strip(cnpjValue);

    if (!validate && verifyLocation) {
      return toast.error("CNPJ inválido", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (verifyLocation) {
      const cnaesVerify = await axios.get(
        `https://api-publica.speedio.com.br/buscarcnpj?cnpj=${cpfUnformatted}`
      );

      const nameCompany = cnaesVerify.data["RAZAO SOCIAL"];
      const number = cnaesVerify.data["CNAE PRINCIPAL CODIGO"];
      const description = cnaesVerify.data["CNAE PRINCIPAL DESCRICAO"];

      setCorporateName(nameCompany);
      setCnaes(`${number} - ${description}`);
    } else {
      setCorporateName("");
      setCnaes("");
    }
  };

  const container = {
    display: "flex",
    flexDirection: "column",
    maxWidth: "600px",
  };

  const boxFooterButton = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "70px",
    marginTop: "19px",
    width: "100%",
  };

  const footerLogin = {
    display: "flex",
    alignItems: "center",
    gap: "70px",
  };

  const footerButton = {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    marginBottom: "55px",
  };

  const containerInputs = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  };

  const boxInputs = {
    display: "flex",
    gap: "20px",
  };

  const image = {
    height: "100vh",
    position: "fixed",
  };

  const containerColum2 = {
    paddingTop: "7.5rem",
  };

  return (
    <ThowColumLayoutLogin
      page={"Register"}
      colum1={
        <div>
          <img src={registerIcon} style={image} />
        </div>
      }
      colum2={
        <div style={containerColum2}>
          <img src={companyIcon} />

          <div style={container}>
            <h2 className="textPrimaryRegister">Cadastro</h2>

            <div style={containerInputs}>
              <div style={boxInputs}>
                <InputSelectFilterManager
                  onChange={(e) => setDocumentType(e.target.value)}
                  color="var(--grey-1)"
                  name="Tipo de documento"
                  width={"100%"}
                  top="50px"
                  custom="false"
                  type={"documentType"}
                />
                <Input
                  value={cnpjValue}
                  mask={documentType.split(":")[0]}
                  name={documentType.split(":")[1]}
                  width="100%"
                  onBlur={verifyCnpj}
                  onChange={(e) => setCnpjValue(e.target.value)}
                  placeholder={`Insira o seu ${documentType.split(":")[1]}`}
                  type={"text"}
                />
              </div>
              {documentType.split(":")[1] !== "CNPJ" ? (
                <></>
              ) : (
                <div style={boxInputs}>
                  <Input
                    value={corporateName}
                    name="Razão social"
                    width="100%"
                    type={"text"}
                  />
                  <Input
                    value={cnaes}
                    name="CNAEs"
                    width="100%"
                    type={"text"}
                  />
                  {/* <Input
                  name="Insira seu tipo de negocio"
                  onChange={(e) => setType_buniness(e.target.value)}
                  width="100%"
                  type={"text"}
                /> */}
                </div>
              )}
              <div style={boxInputs}>
                <InputSelectFilterManager
                  onChange={(e) => setType_buniness(e.target.value)}
                  color="var(--grey-1)"
                  name="Insira seu tipo de negocio"
                  width={"100%"}
                  top="50px"
                  custom="false"
                  type={"register"}
                />
              </div>
              <div style={boxInputs}>
                <Input
                  value={name}
                  name="Nome completo"
                  width="100%"
                  onChange={(e) => setName(e.target.value)}
                  type={"text"}
                />
              </div>
              <div style={boxInputs}>
                <Input
                  name="Email corporativo"
                  width="100%"
                  onChange={(e) => setMail(e.target.value)}
                  placeholder="Insira um e-mail"
                  type={"email"}
                />
                <Input
                  mask={"(99) 99999-9999"}
                  name="Whatsapp"
                  width="100%"
                  onChange={(e) => setWhatsapp(e.target.value)}
                  placeholder="Insira um número"
                  type={"text"}
                />
              </div>
              <div style={boxInputs}>
                <Input
                  name="Senha"
                  width="100%"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Insira uma senha"
                  type={"password"}
                />
                <Input
                  name="Confirme sua senha "
                  width="100%"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirma senha"
                  type={"password"}
                />
              </div>

              <div style={boxFooterButton}>
                <TermsFooter
                  checkTerms={checkTerms}
                  setCheckTerms={setCheckTerms}
                />

                <div style={footerButton}>
                  <Button
                    width="267px"
                    height="45px"
                    background="#123164"
                    borderRadius="60px"
                    marginTop="0"
                    name="Cadastrar"
                    border="none"
                    disabled={btnState}
                    onClick={() => onSubmitCreate()}
                  />

                  <div style={footerLogin}>
                    <p className="textFooterRegisterTitle">Já tem uma conta?</p>
                    <p
                      onClick={() => navigate("/")}
                      style={{ cursor: "pointer" }}
                      className="textFooterRegisterContent"
                    >
                      Login
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    ></ThowColumLayoutLogin>
  );
};

export default Register;
