import { useContext, useState } from "react";
import LetterLimit from "../utils/LimitCharacters";
import ModalContainer from "./ModalContainer";
import ModalProposal from "./ModalProposal";
import { ProposalContext } from "../context/Dashboard/ProposalContext";

const ProposalLine = ({ proposal, key, client }) => {
  const { getProposalClient } = useContext(ProposalContext);
  const [modal, setModal] = useState(false);

  const styleColum1 = {
    maxWidth: "1000px",
    border: "1px solid #D7D7D7",
    height: "55px",
    borderRadius: "10px",
    background: "white",
    display: "flex",
    alignItems: "center",
    position: "relative",
    backgroundColor:
      parseFloat(proposal.advance_discount) > 0 && !client
        ? "#4935fa49"
        : proposal.visible_to_customer === 0
        ? "#f60e5030"
        : "#fff",
  };

  const newCardStyle = {
    position: "absolute",
    top: -5,
    left: -6,
    backgroundColor: "var(--color-primary)",
    color: "#fff",
    fontSize: 11,
    borderRadius: "4px",
    padding: "4px 6px",
  };

  const closeButton = () => {
    setModal(false);

    if (!proposal.viewed_by_user && client) getProposalClient(1);
  };

  const checkStatusForClass = (status, advanceDiscount) => {
    const discount = parseFloat(advanceDiscount);

    if (discount > 0 && !client) return "advanceDiscount";
    if (status.includes("Recusado")) return "refusedProposal";
    if (status.includes("Recusado")) return "refusedProposal";
    if (status.includes("Aprovada com condições pelo administrador"))
      return "ApprovedWithConditions";
    if (status.includes("Aprovado pelo administrador"))
      return "approvedProposalAdmin";
    if (status.includes("Aprovado")) return "approvedProposal";
    if (status.includes("Aguardando")) return "waitingProposal";
  };

  return (
    <>
      <div onClick={() => setModal(true)} key={key} className="containerBox">
        <div style={styleColum1}>
          {proposal.visible_to_customer === 0 ? (
            <div style={{ ...newCardStyle, background: "#9e0017" }}>
              Finalizar cotação
            </div>
          ) : (
            !proposal.viewed_by_user && <div style={newCardStyle}>Novo</div>
          )}

          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div>
              <LetterLimit
                text={proposal.company}
                limit={10}
                threePoints={true}
                className="textNameTableListUser transformY5"
                id="company"
              />
            </div>
            <p className="textNameTableListUser transformY5">
              {proposal.proposal_number}
            </p>

            <LetterLimit
              text={proposal.type}
              limit={20}
              threePoints={true}
              className="textNameTableListUser transformY5"
              id="type"
            />

            <p className="textNameTableListUser transformY5">
              {proposal.value}
            </p>
            <p className="textNameTableListUser transformY5">
              {proposal.viewed_by_user || "Não visualizado"}
            </p>

            <LetterLimit
              text={
                parseFloat(proposal.advance_discount) > 0 && !client
                  ? "Desconto pendente"
                  : proposal.status
              }
              limit={10}
              threePoints={true}
              width={110}
              widthContainer={170}
              className={`textNameTableListUser transformY5 ${checkStatusForClass(
                proposal.status,
                proposal.advance_discount
              )}`}
              id="status"
            />
          </div>
        </div>
      </div>

      <ModalContainer isActive={modal} closeModal={() => closeButton()}>
        <ModalProposal
          client={client}
          setModal={setModal}
          proposal={proposal}
          viewed={proposal.viewed_by_user}
          closeModal={() => closeButton()}
          checkStatusForClass={checkStatusForClass}
        />
      </ModalContainer>
    </>
  );
};

export default ProposalLine;
