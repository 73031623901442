import { useEffect } from "react";

const ModalContainer = ({
  children,
  isActive,
  closeModal,
  notBackground,
  zIndex,
  idClass,
  opacity,
}) => {
  const container = {
    position: "fixed",
    zIndex: zIndex ? zIndex : 2,
    backgroundColor: notBackground
      ? ""
      : opacity
      ? `#E5E5E5${opacity}`
      : "#E5E5E520",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const onCloseModal = (e) => {
    if (idClass) {
      if (e.target.id === idClass) closeModal();
    } else if (e.target.id === "modalContainer") closeModal();
  };

  useEffect(() => {
    if (isActive) {
      // Bloqueia o scroll do body
      document.body.style.overflow = "hidden";
    } else {
      // Restaura o scroll do body
      document.body.style.overflow = "";
    }

    // Cleanup quando o modal fechar
    return () => {
      document.body.style.overflow = "";
    };
  }, [isActive]);

  return (
    isActive && (
      <div
        id={idClass ? idClass : "modalContainer"}
        onClick={onCloseModal}
        style={container}
      >
        {children}
      </div>
    )
  );
};

export default ModalContainer;
