import React from "react";

const ModalTerms = ({ closeModal }) => {
  // Definindo variáveis de estilo
  const containerStyle = {
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    width: "850px",
    margin: "0 auto",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxHeight: "80vh",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  };

  const containerContentStyle = {
    padding: "20px",
    overflowY: "scroll",
    flex: 1, // Fazendo o conteúdo crescer para preencher o container
  };

  const titleStyle = {
    textAlign: "center",
    color: "#333",
    fontSize: "28px",
  };

  const sectionTitleStyle = {
    marginTop: "20px",
    color: "#333",
    fontSize: "23px",
  };

  const paragraphStyle = {
    lineHeight: "1.7",
    color: "#555",
    marginTop: "20px",
    fontSize: "13px",
  };

  const contactStyle = {
    lineHeight: "1.8",
    color: "#555",
    marginTop: "20px",
  };

  const buttonContainerStyle = {
    textAlign: "center",
    marginTop: "30px",
  };

  const buttonStyle = {
    padding: "10px 80px",
    backgroundColor: "var(--color-primary)",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  };

  return (
    <div style={containerStyle}>
      <div style={containerContentStyle}>
        <h2 style={titleStyle}>Termos de Uso da Plataforma Maxitrans Group</h2>
        <p style={paragraphStyle}>
          Bem-vindo à plataforma Maxitrans Group. Ao utilizar nossos serviços de
          cotações de fretes internacionais, nacionais, aéreos e marítimos, você
          concorda com os seguintes termos e condições. Leia atentamente antes
          de se cadastrar e utilizar nossos serviços.
        </p>

        <h3 style={sectionTitleStyle}>1. Coleta e Uso de Dados</h3>
        <p style={paragraphStyle}>
          1.1. Durante o processo de cadastro e uso da plataforma, coletamos os
          seguintes dados pessoais: nome, e-mail, WhatsApp e CNPJ.
          <br />
          1.2. Esses dados são utilizados exclusivamente pela equipe da
          Maxitrans Group para entrar em contato com os clientes, enviar
          cotações e formalizar propostas.
          <br />
          1.3. A Maxitrans Group adota medidas de segurança para proteger os
          dados pessoais dos usuários contra acessos não autorizados, divulgação
          ou destruição.
          <br />
          1.4. A Maxitrans Group não compartilha os dados dos usuários com
          terceiros, garantindo que essas informações fiquem restritas à nossa
          equipe.
          <br />
          1.5. O usuário tem o direito de solicitar a exclusão de seus dados
          pessoais da plataforma a qualquer momento, conforme as leis de
          proteção de dados aplicáveis.
        </p>

        <h3 style={sectionTitleStyle}>2. Responsabilidades dos Usuários</h3>
        <p style={paragraphStyle}>
          2.1. Ao fornecer informações para cotações, o usuário deve garantir a
          veracidade e precisão dos dados, incluindo local, endereço de entrega
          e coleta, e detalhes sobre a mercadoria a ser transportada.
          <br />
          2.2. O usuário concorda que a Maxitrans Group pode oferecer ajustes
          nos valores cotados, como descontos, caso o preço cotado inicialmente
          não atenda às expectativas.
          <br />
          2.3. O usuário é responsável por manter suas informações de cadastro
          atualizadas, garantindo que a comunicação e os serviços oferecidos
          sejam eficazes.
          <br />
          2.4. O usuário deve utilizar a plataforma de forma ética e respeitosa,
          não realizando ações que possam comprometer a integridade dos serviços
          ou da equipe Maxitrans Group.
        </p>

        <h3 style={sectionTitleStyle}>3. Proibições de Uso</h3>
        <p style={paragraphStyle}>
          3.1. É proibido utilizar a plataforma Maxitrans Group para fins
          fraudulentos ou para coletar dados sobre os preços praticados pela
          empresa sem a intenção genuína de contratar os serviços.
          <br />
          3.2. O uso de scripts automatizados ou outras técnicas para extrair
          dados da plataforma sem autorização expressa da Maxitrans Group é
          estritamente proibido.
          <br />
          3.3. Se detectado uso indevido, a conta do usuário será bloqueada até
          que a equipe da Maxitrans Group reavalie o caso e decida se o acesso
          será restabelecido.
        </p>

        <h3 style={sectionTitleStyle}>4. Suspensão e Encerramento de Contas</h3>
        <p style={paragraphStyle}>
          4.1. A Maxitrans Group reserva-se o direito de suspender ou encerrar a
          conta de usuários que utilizem a plataforma para práticas fraudulentas
          ou que não cumpram os termos de uso.
          <br />
          4.2. Em caso de suspensão, o acesso à plataforma ficará restrito até
          que a equipe da Maxitrans Group decida se o usuário poderá retornar a
          utilizar os serviços.
          <br />
          4.3. O usuário pode solicitar o encerramento de sua conta a qualquer
          momento. A Maxitrans Group processará a solicitação e confirmará o
          encerramento dentro de um prazo razoável.
        </p>

        <h3 style={sectionTitleStyle}>5. Serviços Oferecidos</h3>
        <p style={paragraphStyle}>
          5.1. A Maxitrans Group oferece tanto cotações quanto serviços de
          transporte, garantindo o cumprimento das propostas apresentadas.
          <br />
          5.2. Não há limitação nos serviços oferecidos, exceto conforme
          disposto nestes termos de uso.
          <br />
          5.3. A Maxitrans Group se reserva o direito de alterar, suspender ou
          descontinuar qualquer serviço ou funcionalidade da plataforma a
          qualquer momento, sem aviso prévio.
        </p>

        <h3 style={sectionTitleStyle}>
          6. Uso Adequado da Plataforma e Segurança Cibernética
        </h3>
        <p style={paragraphStyle}>
          6.1. A plataforma Maxitrans Group deve ser utilizada exclusivamente
          para fins legítimos relacionados à solicitação de cotações de fretes e
          contratação de serviços de transporte. Qualquer uso fora desses
          propósitos é estritamente proibido.
          <br />
          6.2. Os usuários são responsáveis por manter a confidencialidade de
          suas credenciais de acesso (nome de usuário e senha) e por todas as
          atividades que ocorram sob suas contas. Recomenda-se o uso de senhas
          fortes e a alteração periódica das mesmas.
          <br />
          6.3. A Maxitrans Group implementa medidas de segurança cibernética
          para proteger a integridade e a confidencialidade dos dados
          armazenados na plataforma. No entanto, o usuário é responsável por
          garantir que os dispositivos utilizados para acessar a plataforma
          estejam protegidos contra malware, vírus e outras ameaças
          cibernéticas.
          <br />
          6.4. É proibido ao usuário:
          <br />
          6.4.1. Tentar acessar áreas restritas da plataforma sem a devida
          autorização.
          <br />
          6.4.2. Realizar qualquer atividade que possa comprometer a segurança
          da plataforma, incluindo, mas não se limitando a, ataques de negação
          de serviço (DDoS), injeção de SQL, ou distribuição de software
          malicioso.
          <br />
          6.4.3. Utilizar a plataforma para distribuir ou transmitir materiais
          que contenham vírus, trojans, worms, ou qualquer outro código
          malicioso.
          <br />
          6.5. A Maxitrans Group monitorará atividades suspeitas na plataforma e
          se reserva o direito de bloquear contas e reportar incidentes de
          segurança cibernética às autoridades competentes.
          <br />
          6.6. Em caso de violação de segurança que comprometa os dados dos
          usuários, a Maxitrans Group notificará os afetados em conformidade com
          as leis de proteção de dados aplicáveis.
          <br />
          6.7. O usuário deve relatar imediatamente à Maxitrans Group qualquer
          uso não autorizado de sua conta ou qualquer outra violação de
          segurança, por meio dos canais de contato disponibilizados.
        </p>

        <h3 style={sectionTitleStyle}>7. Disposições Finais</h3>
        <p style={paragraphStyle}>
          7.1. A utilização da plataforma Maxitrans Group está sujeita à
          legislação vigente no Brasil e exterior.
          <br />
          7.2. Estes termos de uso podem ser atualizados a qualquer momento pela
          Maxitrans Group, sendo de responsabilidade do usuário manter-se
          informado sobre as alterações.
          <br />
          7.3. Em caso de dúvidas ou necessidade de suporte, o usuário pode
          entrar em contato através dos seguintes canais:
        </p>
        <p style={contactStyle}>
          <strong>E-mail:</strong> maxitrans@maxitransGroup.com <br />
          <strong>Telefone:</strong> +55 11 3685-2786 <br />
          <strong>Endereço:</strong> AV. Hilário Pereira de Souza 406/492. T-2
          CJ. 1710/ 1711, Centro de Osasco/SP, CEP 06010170.
        </p>

        <div style={buttonContainerStyle}>
          <button style={buttonStyle} onClick={closeModal}>
            Fechar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalTerms;
