import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import api from "../../services/api";
import moment from "moment";
import axios from "axios";

export const ProposalContext = createContext({});

export const ProposalValidation = ({ children }) => {
  // PROPOSTAS

  // ADMINISTRATIVO
  const [proposalAdm, setProposalAdm] = useState([]);
  const [totalPagesAdm, setTotalPagesAdm] = useState(0);
  const [searchProposal, setSearchProposal] = useState("");

  // CLIENTE
  const [proposalClient, setProposalClient] = useState([]);
  const [totalPagesClient, setTotalPagesClient] = useState(0);
  const [searchProposalClient, setSearchProposalClient] = useState("");
  const [countProposalUnviewed, setCountProposalUnviewed] = useState(0);

  // FRETE NACIONAL FRACIONADO
  const [btnStateNationalFractioned, setBtnStateNationalFractioned] =
    useState(false);
  const [btnStateNationalFractionedTwo, setBtnStateNationalFractionedTwo] =
    useState(false);

  // FRETE NACIONAL DEDICADO
  const [btnStateNationalDedicated, setBtnStateNationalDedicated] =
    useState(false);
  const [btnStateNationalDedicatedTwo, setBtnStateNationalDedicatedTwo] =
    useState(false);

  // FRETE INTERNACIONAL LTL
  const [btnStateInternationLTL, setBtnStateInternationLTL] = useState(false);

  // FRETE INTERNACIONAL FTL
  const [btnStateInternationFTL, setBtnStateInternationFTL] = useState(false);
  const [companys, setCompanys] = useState([]);
  const [filterProposal, setFilterProposal] = useState({});

  // NOTICAÇÕES
  const notifications = (type, message) => {
    return toast[type](message, {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  // ROTAS ADMINISTRATIVAS
  const getProposalAdm = async (page) => {
    try {
      console.log(filterProposal);
      const { data } = await api.get(
        `proposal/adm/all?page=${page}&filter=${JSON.stringify(filterProposal)}`
      );
      setTotalPagesAdm(data.totalPages);
      setProposalAdm(data.proposals);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getProposalAdmSearch = async (page) => {
    try {
      const { data } = await api.get(
        `proposal/adm/all?page=${page}&details=${searchProposal}`
      );

      setTotalPagesAdm(data.totalPages);
      setProposalAdm(data.proposals);
    } catch (error) {
      console.log(error.message);
    }
  };

  const applyDiscount = async (id, close, closeAll, data) => {
    const loadingToast = notifications("loading", "Aplicando desconto");
    try {
      await api.patch(`/proposal/discount/${id}`, data);
      toast.dismiss(loadingToast);

      notifications("success", "Desconto aplicado!");
      closeAll();
      await getProposalAdm(1);
    } catch (error) {
      toast.dismiss(loadingToast);

      notifications("error", error.response.data.message);
      close();
    }
  };

  const applyAdditional = async (id, close, closeAll, data) => {
    const loadingToast = notifications(
      "loading",
      "Adicionando valores adicionais"
    );
    try {
      await api.patch(`/proposal/additional/${id}`, data);
      toast.dismiss(loadingToast);

      notifications("success", "Adicionado com sucesso");
      closeAll();
      await getProposalAdm(1);
    } catch (error) {
      toast.dismiss(loadingToast);

      notifications("error", error.response.data.message);
      close();
    }
  };

  const getAdditional = async (id) => {
    try {
      const { data } = await api.get(`/proposal/additional/${id}`);

      return data;
    } catch (error) {
      notifications("error", error.response.data.message);
    }
  };

  const requestAdvanceDiscount = async (id, close, closeAll, data) => {
    try {
      const response = await api.patch(
        `/proposal/discount/advance/${id}`,
        data
      );

      notifications("success", response.data.message);
      closeAll();
      await getProposalAdm(1);
    } catch (error) {
      notifications("error", error.response.data.message);
      close();
    }
  };

  const applyAdvanceDiscount = async (id, close, closeAll) => {
    const loadingToast = notifications("loading", "Aplicando desconto");
    try {
      const { data } = await api.patch(
        `/proposal/approval/discount/advance/${id}`
      );
      toast.dismiss(loadingToast);

      console.log(data);

      notifications("success", data.message);
      closeAll();
      await getProposalAdm(1);
    } catch (error) {
      toast.dismiss(loadingToast);
      console.log(error.response.data);
      notifications("error", error.response.data.error);
      close();
    }
  };

  const refuseAdvanceDiscount = async (id, close, closeAll) => {
    try {
      const { data } = await api.patch(
        `/proposal/refuse/discount/advance/${id}`
      );

      notifications("success", data.message);
      closeAll();
      await getProposalAdm(1);
    } catch (error) {
      notifications("error", error.response.data.error);
      close();
    }
  };

  const approvalProposal = async (id, closeModal) => {
    try {
      await api.patch(`proposal/approval/adm/${id}`);
      notifications("success", "Proposta aprovada com sucesso!");
      await getProposalAdm(1);
      closeModal();
    } catch (error) {
      notifications("warning", error.response.data.message);
    }
  };

  const approvalWithConditionsProposal = async (id, closeModal) => {
    try {
      await api.patch(`proposal/approval/conditions/adm/${id}`);
      notifications("warn", "Proposta aprovada com condições!");
      await getProposalAdm(1);
      closeModal();
    } catch (error) {
      notifications("warning", error.response.data.message);
    }
  };

  const refuseProposal = async (id, closeModal) => {
    try {
      await api.patch(`proposal/refuse/adm/${id}`);
      notifications("info", "Proposta recusada com sucesso!");
      await getProposalAdm(1);
      closeModal();
    } catch (error) {
      notifications("error", error.response.data.message);
    }
  };

  const searchCompanys = async () => {
    try {
      if (!companys.length) {
        const response = await api.get("/user/proposal");
        setCompanys(response.data);
      }
    } catch (error) {}
  };

  // ---------------------------------------------------------

  // ROTAS CLIENTES

  const getProposalClient = async (page) => {
    try {
      const { data } = await api.get(
        `proposal?page=${page}&filter=${JSON.stringify(filterProposal)} `
      );
      setTotalPagesClient(data.totalPages);
      setProposalClient(data.proposals);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getProposalClientSearch = async (page) => {
    try {
      const { data } = await api.get(
        `proposal?page=${page}&details=${searchProposalClient}`
      );

      console.log(data);

      setTotalPagesClient(data.totalPages);
      setProposalClient(data.proposals);
    } catch (error) {
      console.log(error.message);
    }
  };

  const approvalProposalClient = async (id, closeModal) => {
    try {
      await api.patch(`proposal/approval/client/${id}`);
      notifications("success", "Proposta aprovada com sucesso!");
      await getProposalClient(1);
      closeModal();
    } catch (error) {
      notifications("error", error.response.data.message);
    }
  };

  const refuseProposalClient = async (id, closeModal, reason, details) => {
    try {
      await api.patch(`proposal/refuse/client/${id}`, {
        reason: `${reason} ${String(details).length ? ":  " + details : ""}`,
      });
      notifications("info", "Proposta recusada com sucesso!");
      await getProposalClient(1);
      closeModal();
    } catch (error) {
      notifications("error", error.response.data.message);
    }
  };

  const viewedProposalClient = async (id) => {
    try {
      await api.patch(`/proposal/viewed/${id}`);
    } catch (error) {
      notifications("error", error.response.data.message);
    }
  };

  const newProposalCounter = async () => {
    try {
      const { data } = await api.get("/proposal/unviewed");

      console.log(data);
      setCountProposalUnviewed(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(countProposalUnviewed);
  }, [countProposalUnviewed]);

  return (
    <>
      <ProposalContext.Provider
        value={{
          btnStateNationalFractioned,
          setBtnStateNationalFractioned,
          btnStateNationalFractionedTwo,
          setBtnStateNationalFractionedTwo,
          btnStateNationalDedicated,
          setBtnStateNationalDedicated,
          btnStateNationalDedicatedTwo,
          setBtnStateNationalDedicatedTwo,
          getProposalAdm,
          proposalAdm,
          totalPagesAdm,
          getProposalAdmSearch,
          searchProposal,
          setSearchProposal,
          applyDiscount,
          approvalProposal,
          refuseProposal,
          proposalClient,
          setProposalClient,
          totalPagesClient,
          setTotalPagesClient,
          searchProposalClient,
          setSearchProposalClient,
          getProposalClient,
          getProposalClientSearch,
          approvalProposalClient,
          refuseProposalClient,
          btnStateInternationLTL,
          setBtnStateInternationLTL,
          notifications,
          btnStateInternationFTL,
          setBtnStateInternationFTL,
          viewedProposalClient,
          newProposalCounter,
          countProposalUnviewed,
          approvalWithConditionsProposal,
          searchCompanys,
          companys,
          setFilterProposal,
          filterProposal,
          applyAdvanceDiscount,
          refuseAdvanceDiscount,
          requestAdvanceDiscount,
          applyAdditional,
          getAdditional,
        }}
      >
        {children}
      </ProposalContext.Provider>
    </>
  );
};
