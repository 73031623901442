import { useEffect, useState } from "react";
import Input from "./Input";

const InputsInternationalFtl = ({
  setData,
  data,
  numberIndex,
  update,
  length,
}) => {
  //console.log(data[numberIndex])

  /* INTERNATIONAL STATES*/
  const [adValoremFcaUsd, setAdValoremFcaUsd] = useState("");
  const [argentinaClearanceFees, setArgentinaClearanceFees] = useState("");
  const [cargaImo, setCargaImo] = useState("");

  const [country, setCountry] = useState("");
  const [country1, setCountry1] = useState("");
  const [crtAdmFee, setCrtAdmFee] = useState("");

  const [cubagem, setCubagem] = useState("");
  const [customsFeesArg, setCustomsFeesArg] = useState("");
  const [destinoCityState, setDestinoCityState] = useState("");

  const [exwFees, setExwFees] = useState("");
  const [freightCostPerRoute, setFreightCostPerRoute] = useState("");
  const [freightSale, setFreightSale] = useState("");

  const [grisFcaUsd, setGrisFcaUsd] = useState("");
  const [originCityState, setOriginCityState] = useState("");
  const [palletQuantityPbr100x120x120cm, setPalletQuantityPbr100x120x120cm] =
    useState("");

  const [pickUpSp101to150Km, setPickUpSp101to150Km] = useState("");
  const [pickUpSp151to200Km, setPickUpSp151to200Km] = useState("");
  const [pickUpSp51to100Km, setPickUpSp51to100Km] = useState("");

  const [pesoRangesKg, setPesoRangesKg] = useState("");
  const [txEadi, setTxEadi] = useState("");
  const [txInspectionSenasaPerCrt, setTxInspectionSenasaPerCrt] = useState("");

  const [typeofvehicles, setTypeOfVehicles] = useState("");

  const [renderAll, setRenderAll] = useState(false);

  const boxInput = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    width: "100%",
    marginBottom: "10px",
  };

  useEffect(() => {
    const handleValue = (value, length, percent) => {
      if (!isNaN(value) && percent) {
        return parseFloat(value * 100).toFixed(length || 2);
      } else if (!isNaN(value) && !percent) {
        return parseFloat(value).toFixed(length || 2);
      }
      return value;
    };

    if (data[numberIndex]) {
      if (numberIndex) {
        setAdValoremFcaUsd(data[numberIndex].ad_valorem_fca_usd);
        setArgentinaClearanceFees(data[numberIndex].argentina_clearance_fees);
        setCargaImo(data[numberIndex].carga_imo);
        setCubagem(data[numberIndex].cubagem);

        setCountry(data[numberIndex].country);
        setCountry1(data[numberIndex].country1);
        setCrtAdmFee(data[numberIndex].crt_adm_fee);

        // setCustomsFeesArg(data[numberIndex].)
        setDestinoCityState(data[numberIndex].destino_city_state);

        setExwFees(data[numberIndex].exw_fees);
        setFreightCostPerRoute(data[numberIndex].freight_cost_per_route);
        setFreightSale(data[numberIndex].freight_sale);

        setGrisFcaUsd(data[numberIndex].gris_fca_usd);
        setOriginCityState(data[numberIndex].origin_city_state);
        setPalletQuantityPbr100x120x120cm(
          data[numberIndex].pallet_quantity_pbr_100x120x120cm
        );
        setPickUpSp101to150Km(data[numberIndex].pick_up_sp_101_to_150_km);
        setPickUpSp151to200Km(data[numberIndex].pick_up_sp_151_to_200_km);
        setPickUpSp51to100Km(data[numberIndex].pick_up_sp_51_to_100_km);
        setPesoRangesKg(data[numberIndex].peso_ranges_kg);
        setTxEadi(data[numberIndex].tx_eadi);
        setTxInspectionSenasaPerCrt(
          data[numberIndex].tx_inspection_senasa_per_crt
        );
        setTypeOfVehicles(data[numberIndex].type_of_vehicles);
      }

      if (numberIndex === 0) {
        setAdValoremFcaUsd(data[0].ad_valorem_fca_usd);
        setArgentinaClearanceFees(data[0].argentina_clearance_fees);
        setCargaImo(data[0].carga_imo);
        setCubagem(data[0].cubagem);

        setCountry(data[0].country);
        setCountry1(data[0].country1);
        setCrtAdmFee(data[0].crt_adm_fee);

        // setCustomsFeesArg(data[0].)
        setDestinoCityState(data[0].destino_city_state);

        setExwFees(data[0].exw_fees);
        setFreightCostPerRoute(data[0].freight_cost_per_route);
        setFreightSale(data[0].freight_sale);

        setGrisFcaUsd(data[0].gris_fca_usd);
        setOriginCityState(data[0].origin_city_state);
        setPalletQuantityPbr100x120x120cm(
          data[0].pallet_quantity_pbr_100x120x120cm
        );
        setPickUpSp101to150Km(data[0].pick_up_sp_101_to_150_km);
        setPickUpSp151to200Km(data[0].pick_up_sp_151_to_200_km);
        setPickUpSp51to100Km(data[0].pick_up_sp_51_to_100_km);
        setPesoRangesKg(data[0].peso_ranges_kg);
        setTxEadi(data[0].tx_eadi);
        setTxInspectionSenasaPerCrt(data[0].tx_inspection_senasa_per_crt);
        setTypeOfVehicles(data[0].type_of_vehicles);
      }
    } else if (data) {
    }

    setTimeout(() => {
      setRenderAll(true);
    }, 2000);
  }, [data]);

  useEffect(() => {
    if (update && renderAll === true) {
      let dataUpdate = data;

      dataUpdate[numberIndex] = {
        ad_valorem_fca_usd: adValoremFcaUsd,
        argentina_clearance_fees: argentinaClearanceFees,
        carga_imo: cargaImo,
        cubagem: cubagem,
        country: country,
        country1: country1,
        crt_adm_fee: crtAdmFee,
        destino_city_state: destinoCityState,
        exw_fees: exwFees,
        freight_cost_per_route: freightCostPerRoute,
        freight_sale: freightSale,
        gris_fca_usd: grisFcaUsd,
        origin_city_state: originCityState,
        pallet_quantity_pbr_100x120x120cm: palletQuantityPbr100x120x120cm,
        pick_up_sp_101_to_150_km: pickUpSp101to150Km,
        pick_up_sp_151_to_200_km: pickUpSp151to200Km,
        pick_up_sp_51_to_100_km: pickUpSp51to100Km,
        peso_ranges_kg: pesoRangesKg,
        tx_eadi: txEadi,
        tx_inspection_senasa_per_crt: txInspectionSenasaPerCrt,
        type_of_vehicles: typeofvehicles,
      };

      setData(dataUpdate);
    }
  }, [
    adValoremFcaUsd,
    argentinaClearanceFees,
    cargaImo,
    country,
    country1,
    crtAdmFee,
    cubagem,
    destinoCityState,
    exwFees,
    freightCostPerRoute,
    freightSale,
    grisFcaUsd,
    originCityState,
    palletQuantityPbr100x120x120cm,
    pickUpSp101to150Km,
    pickUpSp151to200Km,
    pickUpSp51to100Km,
    pesoRangesKg,
    txEadi,
    txInspectionSenasaPerCrt,
    typeofvehicles,
    update,
    renderAll,
    data,
    numberIndex,
    setData,
  ]);

  return (
    <div style={boxInput}>
      <Input
        value={pesoRangesKg}
        onChange={(e) => setPesoRangesKg(e.target.value)}
        name="Faixas de Peso ( kg )"
        width="228px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />

      <Input
        value={argentinaClearanceFees}
        onChange={(e) => setArgentinaClearanceFees(e.target.value)}
        name="Taxas de Liberação na Argentina"
        width="228px"
        placeholder=""
        type="text"
        marginBottom="10px"
      />

      <Input
        value={cargaImo}
        onChange={(e) => setCargaImo(e.target.value)}
        name="Carga IMO"
        width="228px"
        placeholder=""
        type="text"
        marginBottom="10px"
      />

      <Input
        value={country}
        onChange={(e) => setCountry(e.target.value)}
        name="País"
        width="228px"
        placeholder=""
        type="text"
        marginBottom="10px"
      />

      <Input
        value={country1}
        onChange={(e) => setCountry1(e.target.value)}
        name="País 1"
        width="228px"
        placeholder=""
        type="text"
        marginBottom="10px"
      />

      <Input
        value={crtAdmFee}
        onChange={(e) => setCrtAdmFee(e.target.value)}
        name="Taxa de Administração CRT"
        width="228px"
        placeholder=""
        type="text"
        marginBottom="10px"
      />

      <Input
        value={cubagem}
        onChange={(e) => setCubagem(e.target.value)}
        name="Cubagem"
        width="228px"
        placeholder=""
        type="text"
        marginBottom="10px"
      />

      <Input
        value={destinoCityState}
        onChange={(e) => setDestinoCityState(e.target.value)}
        name="Cidade/Estado de Destino"
        width="228px"
        placeholder=""
        type="text"
        marginBottom="10px"
      />

      <Input
        value={exwFees}
        onChange={(e) => setExwFees(e.target.value)}
        name="Taxas EXW"
        width="228px"
        placeholder=""
        type="text"
        marginBottom="10px"
      />

      <Input
        value={freightCostPerRoute}
        onChange={(e) => setFreightCostPerRoute(e.target.value)}
        name="Custo de Frete por Rota"
        width="228px"
        placeholder=""
        type="text"
        marginBottom="10px"
      />

      <Input
        value={freightSale}
        onChange={(e) => setFreightSale(e.target.value)}
        name="Venda de Frete"
        width="228px"
        placeholder=""
        type="text"
        marginBottom="10px"
      />

      <Input
        value={grisFcaUsd}
        onChange={(e) => setGrisFcaUsd(e.target.value)}
        name="GRIS FCA USD"
        width="228px"
        placeholder=""
        type="text"
        marginBottom="10px"
      />
    </div>
  );
};

export default InputsInternationalFtl;
