import React, { useState, useEffect } from "react";

import ThowColumLayoutLogin from "../../layouts/ThowColumLayoutLogin";
import landingIcon from "../../images/svg/landingIcon.svg";
import companyIcon from "../../images/svg/companyIcon.svg";
import Button from "../../components/Button";
import api from "../../services/api";
import Input from "../../components/Input";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Landing = () => {
  const [mail, setMail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  /* MESSAGE ERROS */

  const [mailError, setMailError] = useState();
  const [paswordError, setPasswordError] = useState();

  /* BTN STATE */

  const [btnState, setBtnState] = useState();

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onSubmitSession = async () => {
    if ((!mail, !password)) {
      return toast.error("Preencha os campos de e-mail e senha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (!validateEmail(mail)) {
      return toast.error("Email inválido", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const data = {
      email: mail,
      password: password,
    };

    try {
      const response = await api.post("/session/client", data);

      const { token } = response.data;

      localStorage.setItem("@MAXITRANS_TOKEN_CLIENT:", token);

      navigate("/dashboard", { replace: true });
    } catch (error) {
      if (!error?.response?.status) {
        toast.warn(
          `Desculpe, parece que estamos enfrentando problemas técnicos no momento. 
          Por favor, entre em contato com nossa equipe de suporte para obter assistência. 
          Agradecemos sua compreensão.`,
          {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else if (error.response.status === 403) {
        toast.info(error.response.data.error, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(error.response.data.error, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  useEffect(() => {
    // localStorage.clear();
  }, []);

  const container = {
    width: "418px",
  };

  const boxFooterButton = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const image = {
    height: "100vh",
  };

  const containerColum2 = {
    paddingTop: "7.5rem",
  };

  return (
    <ThowColumLayoutLogin
      page={"Landing"}
      colum1={
        <div>
          <img src={landingIcon} style={image} />
        </div>
      }
      colum2={
        <div style={containerColum2}>
          <img src={companyIcon} />

          <div style={container}>
            <h2 className="textPrimaryLanding">Dados de acesso</h2>

            <Input
              name="E-mail"
              error={mailError}
              onChange={(e) => setMail(e.target.value)}
              placeholder="Insira seu e-mail"
              type={"text"}
              onKeyBoard={() => onSubmitSession()}
            />
            <Input
              name="Senha"
              error={paswordError}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Insira sua senha"
              type={"password"}
              onKeyBoard={() => onSubmitSession()}
            />

            <div style={boxFooterButton}>
              <Button
                width="185px"
                height="48px"
                background="#123164"
                borderRadius="60px"
                marginTop="0"
                name="Entrar"
                border="none"
                disabled={btnState}
                onClick={() => onSubmitSession()}
              />

              <div>
                <p
                  onClick={() => navigate("/forgot/password")}
                  style={{
                    cursor: "pointer",
                  }}
                  className="textForgotPassword"
                >
                  Esqueceu a senha?
                </p>
              </div>
            </div>
            <div
              style={{
                marginTop: "80px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <p className="textTitleFooterLogin">Não tem uma conta?</p>
              <p
                onClick={() => navigate("/register")}
                className="textContentFooterLogin"
              >
                Cadastre-se
              </p>
            </div>
          </div>
        </div>
      }
    ></ThowColumLayoutLogin>
  );
};

export default Landing;
