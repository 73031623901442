

const CardInput = ({state, onClick}) => {

    return (
        <div style={{
            backgroundColor: "#E4EBFF",
            padding: "2px 10px",
            borderRadius: "10px",
            display: "flex",
            gap: "10px",
        }}>
            <p style={{whiteSpace: 'nowrap'}}>{state}</p>

            <div onClick={onClick} style={{ cursor: "pointer" }}>
                <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.54849 4.49999L7.5891 1.45604C7.77784 1.26707 7.77784 0.960688 7.5891 0.77173C7.40036 0.582758 7.09434 0.582758 6.90561 0.77173L3.86532 3.816L0.825045 0.77173C0.636301 0.582758 0.330287 0.582758 0.141558 0.77173C-0.047171 0.960703 -0.0471861 1.26709 0.141558 1.45604L3.18215 4.49999L0.141558 7.54396C-0.0471861 7.73293 -0.0471861 8.03931 0.141558 8.22827C0.330303 8.41723 0.636316 8.41724 0.825045 8.22827L3.86532 5.18399L6.9056 8.22827C7.09434 8.41724 7.40036 8.41724 7.58909 8.22827C7.77783 8.0393 7.77783 7.73292 7.58909 7.54396L4.54849 4.49999Z" fill="black" />
                </svg>
            </div>
        </div>
    )
}

export default CardInput