import React, { useState, useEffect } from 'react'

import ThowColumLayoutLogin from '../../layouts/ThowColumLayoutLogin'
import forgotPasswordBackgrond from '../../images/svg/forgotPasswordBackgrond.svg'
import companyIcon from '../../images/svg/companyIcon.svg'
import mailIcon from '../../images/svg/mail.svg'
import Button from '../../components/Button'
import api from '../../services/api'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'
import { toast } from 'react-toastify'


const SendMail = () => {

    const [mail, setMail] = useState()
    const [password, setPassword] = useState()
    const [mailStorage, setMailStorage] = useState(localStorage.getItem("@MAXITRANS_MAIL_CLIENT:"))

    /* MESSAGE ERROS */

    const [mailError, setMailError] = useState()
    const [paswordError, setPasswordError] = useState()

    /* BTN STATE */

    const [btnState, setBtnState] = useState()

    const navigate = useNavigate()

    const resendMail = async () => {
        try {
            const data = {
                email: mailStorage
            }

            const response = await api.post('/user/send/mail', data)

            toast.info('E-mail reenviado, verifique sua caixa de entrada', {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } catch (error) {
            toast.error(error.response.data.error, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const container = {
        width: "418px"
    }

    const boxFooterButton = {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px"
    }

    const boxIconMail = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "3.25rem",
    }

    const containerLogo = {
        width: "418px",
        display: "flex",
        justifyContent: "center"
    }

    const containerFigure = {
        height: "100vh",
        padding: "90px 80px"
    }

    const image = {
        height: "100%",
    }

    const containerColum2 = {
        paddingTop: "3rem",
    }

    return (

        <ThowColumLayoutLogin
            page={"SendMail"}
            colum1={
                <div style={containerFigure}>
                    <img src={forgotPasswordBackgrond} style={image} />
                </div>
            }

            colum2={

                <div style={containerColum2}>

                    <div style={containerLogo}>
                        <img src={companyIcon} />
                    </div>

                    <div style={container}>
                        <h2 className="textPrimarySendMail">E-mail enviado!</h2>

                        <p style={{ maxWidth: "415px" }} className="textDescriptionSendMail">Um link de recuperação de senha foi enviado para o e-mail {" "} <span style={{ fontWeight: 600 }}>{mailStorage}</span></p>

                        <div style={boxIconMail}>
                            <img src={mailIcon} />
                        </div>

                        <div style={boxFooterButton}>
                            <Button width="202px" height="48px" background="#123164"
                                borderRadius="60px" marginTop="0" name="Recuperar senha"
                                border="none" disabled={btnState} onClick={() => navigate('/', { replace: true })} />

                            <Button width="202px" height="48px" background="#fff" color="var(--color-primary)"
                                borderRadius="60px" marginTop="0" name="Reenviar e-mail"
                                border="1px solid var(--color-primary)" disabled={btnState} onClick={() => resendMail()} />
                        </div>
                    </div>

                </div>

            }


        >

        </ThowColumLayoutLogin>

    )

}

export default SendMail