import CardInput from "./CardInput"


const InputBox = ({ onClick, name, list, removeCard}) => {

    const styleLabel = {
        marginBottom: name == "Peso cubico" ? "18.43px" : "14.43px",
        fontSize: "12.8px"
    }


    return (
        <div>
            <label className="textPrimaryLabelInput" style={styleLabel}>{name}</label>
            <div style={{
                width: "100%",
                height: "45px",
                border: "1px solid #d7d7d7",
                borderRadius: "8px",
                padding: "5px 10px",
                marginBottom: "1.9rem",
            }}>

                <div style={{
                    marginBottom: "10px",
                    overflowX: "scroll",
                    display: "flex",
                    gap: "10px"
                }}>
                    {
                        list.length > 0 && list.map((item, i) => {
                            return (
                                <CardInput onClick={()=> removeCard(i)} state={item} />
                            )
                        })
                    }

                    <div onClick={onClick} style={{ minWidth: "100%", minHeight: "30px", cursor: "text"}}/>
                </div>

            </div>
        </div>
    )
}

export default InputBox