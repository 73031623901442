import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-toastify";

const Verify = () => {
  const navigate = useNavigate();
  const verifyEmail = () => {
    try {
      const url = window.location.href;
      const match = url.split("/verify")[1].split("/");
      console.log(match);
      if (match) {
        const id = match[1];
        api.patch(`/user/${id}`, { verify: true });

        toast.success("Conta verificado com sucesso", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        try {
        } catch (error) {
          navigate("/");
        }

        return id;
      } else {
        throw new Error("URL inválida para verificação de e-mail");
      }
    } catch (error) {
      // Trate o erro aqui se necessário
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(verifyEmail());
  }, []);
  return <></>;
};

export default Verify;
