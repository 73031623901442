import React, { createContext, useContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { DashboardContext } from "../Dashboard/DashboardContext";
export const UserContext = createContext({});

export const UserValidation = ({ children }) => {
  const { getProfileAdminContext } = useContext(DashboardContext);

  const [permissionVisibility, setPermissionVisibility] = useState(false);

  const [limitDiscount, setLimitDiscount] = useState(0);

  const [typePermission, setTypePermission] = useState("");

  const [requestMade, setRequestMade] = useState(false);

  const getProfile = async () => {
    try {
      const token = localStorage.getItem("@MAXITRANS_TOKEN_ADMIN:");
      const decoded = jwt_decode(token);
      const { id } = decoded;
      const response = await getProfileAdminContext(id);

      setTypePermission(response?.permission);
      if (response?.permission === "administrative") {
        setPermissionVisibility(true);
        setRequestMade(true);
        setLimitDiscount(100);
        return true;
        // navigate("/dashboard/manage/admin");
      } else if (response?.permission === "employee") {
        setLimitDiscount(Number(response.limits_discount));
        setPermissionVisibility(false);
        setRequestMade(true);
        return false;
      } else {
        setPermissionVisibility(false);
        setRequestMade(true);
        setLimitDiscount(100);
        return false;
      }
    } catch (error) {}
  };

  return (
    <>
      <UserContext.Provider
        value={{
          permissionVisibility,
          getProfile,
          requestMade,
          setPermissionVisibility,
          typePermission,
          limitDiscount,
        }}
      >
        {children}
      </UserContext.Provider>
    </>
  );
};
