import firebase from "firebase/compat/app"
import 'firebase/compat/storage'

const firebaseConfig = {
  apiKey: "AIzaSyB6dbJ1JdtR1yZyExbOoZ_l_L_oW2xX0cw",
  authDomain: "maxitrans-6cf65.firebaseapp.com",
  projectId: "maxitrans-6cf65",
  storageBucket: "maxitrans-6cf65.appspot.com",
  messagingSenderId: "108037645779",
  appId: "1:108037645779:web:1d3e0961c2499c38de7b26",
  measurementId: "G-SSWPEW2PDJ"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);


export default app