import { useState } from "react";
import checkOff from "../images/svg/checkOff.svg";
import checkOn from "../images/svg/checkOn.svg";
import ModalContainer from "./ModalContainer";
import ModalTerms from "./ModalTerms";

const TermsFooter = ({ checkTerms, setCheckTerms }) => {
  const [modalActive, setModalActive] = useState(false);
  const containerTerms = {
    display: "flex",
    gap: "21px",
    flexDirection: "column",
  };

  const boxTerms = {
    display: "flex",
    gap: "15px",
    alignItems: "flex-start",
  };

  const boxDescriptionTerms = {
    display: "flex",
    gap: "10px",
    flexDirection: "column",
  };

  return (
    <div style={containerTerms}>
      <h3 className="textTitleTermsRegister">Termos de uso e privacidade</h3>
      <div style={boxTerms} onClick={() => setCheckTerms(!checkTerms)}>
        <div style={{ cursor: "pointer" }}>
          <img src={checkTerms ? checkOn : checkOff} />
        </div>

        <div style={boxDescriptionTerms}>
          <p className="textDescriptionTermsRegister">
            Ao clicar neste botão, eu concordo com os termos de uso e
            privacidade da nossa empresa.
          </p>
          <p
            className="textTermsModalRegister"
            onClick={() => setModalActive(true)}
          >
            Termos de uso e privacidade
          </p>
        </div>
      </div>

      <ModalContainer
        isActive={modalActive}
        opacity={90}
        closeModal={() => setModalActive(false)}
      >
        <ModalTerms closeModal={() => setModalActive(false)} />
      </ModalContainer>
    </div>
  );
};

export default TermsFooter;
