

const StoragePdf = () => {

    return (
        <div className="container3">
            <div className="img3">
                <img src="https://firebasestorage.googleapis.com/v0/b/maxitrans-6cf65.appspot.com/o/profile%2Flogo.png?alt=media&token=626ee25a-c6da-4ec6-ba88-e3f38142bd3f" />
            </div>

            <div className="headerTitle3">
                <p className="proposal3">Proposta Comercial - ARMAZENAGEM</p>
            </div>

            <div className="contentTitle3">
                <div className="boxContentTitle3">
                    <p>DATA:</p>
                    <p>19/06/2023</p>
                </div>

                <div className="boxContentTitle3">
                    <p>VALIDADE:</p>
                    <p>19/07/2023</p>
                </div>

                <div className="boxContentTitle3">
                    <p>COTAÇÃO Nº:</p>
                    <p>NC2023_114</p>
                </div>
            </div>

            <div className="contentBox3">
                <h3 className="textTitleCotation3">Informações da cotação</h3>

                <div className="containerInput3">

                    <div className="boxContainer3">
                        <p className="titleInput3">Qual melhor localização?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">Quais tipo de produto?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">Qual o peso dos paletes?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>
                </div>

                <div className="containerInput3">

                    <div className="boxContainer3">
                        <p className="titleInput3">Quais as dimensões dos paletes?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">Quantas unidades por palete?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">Qual o peso da embalagem?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>
                </div>

                <div className="containerInput3">

                    <div className="boxContainer3">
                        <p className="titleInput3">Quais as dimensões das embalagens?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">Quantos volumes por veículo?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">Qual o volume em estoque?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>
                </div>

                <div className="containerInput3">

                    <div className="boxContainer3">
                        <p className="titleInput3">Qual o valor agregado do estoque?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">Qual o giro da mercadoria?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">É carga regular ou spot?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>
                </div>

                <div className="containerInput3">

                    <div className="boxContainer3">
                        <p className="titleInput3">Quantos sku's?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">Será necessário retirar amostras?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">Será necessário retirar amostras?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>
                </div>

                <div className="containerInput3">

                    <div className="boxContainer3">
                        <p className="titleInput3">Será necessário picking?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">Picking, quantas UNID por embalagem?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">Montagem de kits?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>
                </div>

                <div className="containerInput3">

                    <div className="boxContainer3">
                        <p className="titleInput3">Média de saídas (diária|semanal|mês)</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">A carga sai fracionada ou paletizada?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">Fracionada volume estimado</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>
                </div>

                <div className="containerInput3">

                    <div className="boxContainer3">
                        <p className="titleInput3">Paletizado volume estimado</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">E necessário um padrão de expedição?</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer3">
                        <p className="titleInput3">Alguma licença específica (se sim quais)</p>
                        <div className="boxInput3">
                            <p className="valueInput3">Value</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StoragePdf