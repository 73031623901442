import { useContext } from "react"
import Input from "./Input"
import InputSelectFilterManager from "./InputSelectFilterManager"
import Textarea from "./Textarea"
import UploadImg from "./UploadImg"
import { DashboardContext } from "../context/Dashboard/DashboardContext"

const InsertInformation = () => {
    const {
        setProductType,
        setPalletWeight,
        setPalletDimensions,
        setUnitsPerPallet,
        setPackageWeight,
        setPackageDimensions,
        setVolumesPerVehicle,
        setStockVolume,
        setAddedValue,
        setInventoryTurnover,
        setRegularOrSpotCargo,
        setSkus,
        setSamplesRequired,
        setLabelingRequired,
        setPickingRequired,
        setUnitsPerPackage,
        setKitAssembly,
        setAverageOutputs,
        setFractionatedOrPalletizedCargo,
        setEstimatedFractionatedVolume,
        setEstimatedPalletizedVolume,
        setExpeditionPatternRequired,
        setSpecificLicense,
        setAdditionalObservations,
    } = useContext(DashboardContext)

    const container = {
        display: "flex",
        flexDirection: "column",
        gap: "20px"
    }

    const containerContent = {
        backgroundColor: "#fff",
        maxWidth: "1079px",
        padding: "32px",
        border: "1px solid #D7D7D7",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
    }

    const boxInputs = {
        display: "flex",
        gap: "20px",
    }

    const boxInputsTwo = {
        display: "flex",
        gap: "20px",
        width: "66%"
    }

    return (
        <div className="containerBox" style={container}>

            <div style={containerContent}>
                <div>
                    <h3 className="textTitleTypeDispatch">2° Insira as informações</h3>
                </div>

                <div
                    style={{ ...boxInputs, marginTop: "20px" }}
                >

                    <Input onChange={(e) => setProductType(e.target.value)} name="Quais tipo de produto?" width="100%" placeholder="Insira" type={"text"} />
                    <Input onChange={(e) => setPalletWeight(e.target.value)} name="Qual o peso dos paletes?" placeholder="Insira" width="100%" type={"text"} />
                    <Input onChange={(e) => setPalletDimensions(e.target.value)} name="Quais as dimensões dos paletes?" placeholder="Insira" width="100%" type={"text"} />
                </div>

                <div
                    style={boxInputs}
                >
                    <Input onChange={(e) => setUnitsPerPallet(e.target.value)} name="Quantas unidades por palete?" placeholder="Insira" width="100%" type={"text"} />
                    <Input onChange={(e) => setPackageWeight(e.target.value)} name="Qual o peso da embalagem?" placeholder="Insira" width="100%" type={"text"} />
                    <Input onChange={(e) => setPackageDimensions(e.target.value)} name="Quais as dimensões das embalagens?" placeholder="Insira" width="100%" type={"text"} />
                </div>

                <div
                    style={boxInputs}
                >
                    <Input onChange={(e) => setVolumesPerVehicle(e.target.value)} name="Quantos volumes por veículo?" placeholder="Insira" width="100%" type={"text"} />
                    <Input onChange={(e) => setStockVolume(e.target.value)} name="Qual o volume em estoque?" placeholder="Insira" width="100%" type={"text"} />
                    <Input onChange={(e) => setAddedValue(e.target.value)} name="Qual o valor agregado do estoque?" placeholder="Insira" width="100%" type={"text"} />
                </div>

                <div
                    style={boxInputs}
                >
                    <Input onChange={(e) => setInventoryTurnover(e.target.value)} name="Qual o giro da mercadoria?" placeholder="Insira" width="100%" type={"text"} />
                    <Input onChange={(e) => setRegularOrSpotCargo(e.target.value)} name="É carga regular ou spot?" placeholder="Insira" width="100%" type={"text"} />
                    <Input onChange={(e) => setSkus(e.target.value)} name="Quantos sku's?" placeholder="Insira" width="100%" type={"text"} />
                </div>

                <div
                    style={boxInputs}
                >
                    <Input onChange={(e) => setSamplesRequired(e.target.value)} name="Será necessário retirar amostras?" placeholder="Insira" width="100%" type={"text"} />
                    <Input onChange={(e) => setLabelingRequired(e.target.value)} name="Será necessário etiquetagem?" placeholder="Insira" width="100%" type={"text"} />
                    <Input onChange={(e) => setPickingRequired(e.target.value)} name="Será necessário picking?" placeholder="Insira" width="100%" type={"text"} />
                </div>

                <div
                    style={boxInputs}
                >
                    <Input onChange={(e) => setUnitsPerPackage(e.target.value)} name="Picking, quantas UNID por embalagem?" placeholder="Insira" width="100%" type={"text"} />
                    <Input onChange={(e) => setKitAssembly(e.target.value)} name="Montagem de kits?" placeholder="Insira" width="100%" type={"text"} />
                    <Input onChange={(e) => setAverageOutputs(e.target.value)} name="Média de saídas (diária|semanal|mês)" placeholder="Insira" width="100%" type={"text"} />
                </div>

                <div
                    style={boxInputs}
                >
                    <Input onChange={(e) => setFractionatedOrPalletizedCargo(e.target.value)} name="A carga sai fracionada ou paletizada?" placeholder="Insira" width="100%" type={"text"} />
                    <Input onChange={(e) => setEstimatedFractionatedVolume(e.target.value)} name="Fracionada volume estimado" placeholder="Insira" width="100%" type={"text"} />
                    <Input onChange={(e) => setEstimatedPalletizedVolume(e.target.value)} name="Paletizado volume estimado" placeholder="Insira" width="100%" type={"text"} />
                </div>

                <div
                    style={boxInputsTwo}
                >
                    <Input onChange={(e) => setExpeditionPatternRequired(e.target.value)} name="E necessário um padrão de expedição?" placeholder="Insira" width="100%" type={"text"} />
                    <Input onChange={(e) => setSpecificLicense(e.target.value)} name="Alguma licença específica (se sim quais)" placeholder="Insira" width="100%" type={"text"} />
                </div>
            </div>

            <div style={containerContent}>
                <div>
                    <h3 className="textTitleTypeDispatch">3° Detalhes</h3>
                </div>
                <div>
                    <Textarea onChange={(e) => setAdditionalObservations(e.target.value)} name="Observações adicionais" height="173px" width="100%" placeholder="Forneça os outros detalhes..." />
                </div>
                <UploadImg />
            </div>
        </div>
    )
}

export default InsertInformation