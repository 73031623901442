import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import api from "../../services/api";
import moment from "moment";
import axios from "axios";

export const DashboardContext = createContext({});

export const DashboardValidation = ({ children }) => {
  const [user, setUser] = useState();
  const [userId, setUserId] = useState();

  const [update, setUpdate] = useState(true);
  const [updateRemove, setUpdateRemove] = useState(true);
  const [commodityPrice, setCommodityPrice] = useState("");
  const [selectInputDestination, setSelectInputDestination] = useState([]);
  const [imagesBase64Global, setImagesBase64Global] = useState([]);

  /* CHECK FRACTIONAL */
  const [indexCheckFractional, setIndexCheckFractional] = useState([]);
  const [dataCheckFractional, setDataCheckFractional] = useState([]);
  const [originFraction, setOriginFraction] = useState("");
  const [destinationFraction, setDestinationFraction] = useState("");

  const [merchandiseFraction, setMerchandiseFraction] = useState("");
  const [cubageCheckFractional, setCubageCheckFractional] = useState("");

  const [cubageTotal, setCubageTotal] = useState("");
  const [grossWeightTotal, setGrossWeightTotal] = useState("");
  const [tollTotal, setTollTotal] = useState("");

  const [transitTimeTotal, setTransitTimeTotal] = useState(0);
  const [freigthTotal, setFreigthTotal] = useState(0);
  const [dispatchTotal, setDispatchTotal] = useState(0);

  const [sefazTotal, setSefazTotal] = useState(0);
  const [sumTotalUser, setSumTotalUser] = useState(0);
  const [fileStates, setFileStates] = useState([]);

  /* CHECK DISTRIBUTION NATIONAL */
  const [dataStates, setDataStates] = useState([]);
  const [comments, setComments] = useState("");
  const [typeVehicle, setTypeVehicle] = useState("");

  const [dataPriceSummary, setDataPriceSummary] = useState({});

  /* CHECK DEDICATED FREIGHT */
  const [dataFractionDedicated, setDataFractionDedicated] = useState([]);

  /* CHECK STORAGE */
  const [dataStorage, setDataStorage] = useState([]);
  const [location, setLocation] = useState("");

  const [productType, setProductType] = useState("");
  const [palletWeight, setPalletWeight] = useState("");
  const [palletDimensions, setPalletDimensions] = useState("");

  const [unitsPerPallet, setUnitsPerPallet] = useState("");
  const [packageWeight, setPackageWeight] = useState("");
  const [packageDimensions, setPackageDimensions] = useState("");

  const [volumesPerVehicle, setVolumesPerVehicle] = useState("");
  const [stockVolume, setStockVolume] = useState("");
  const [addedValue, setAddedValue] = useState("");

  const [inventoryTurnover, setInventoryTurnover] = useState("");
  const [regularOrSpotCargo, setRegularOrSpotCargo] = useState("");
  const [skus, setSkus] = useState("");

  const [samplesRequired, setSamplesRequired] = useState("");
  const [labelingRequired, setLabelingRequired] = useState("");
  const [pickingRequired, setPickingRequired] = useState("");

  const [unitsPerPackage, setUnitsPerPackage] = useState("");
  const [kitAssembly, setKitAssembly] = useState("");
  const [averageOutputs, setAverageOutputs] = useState("");

  const [fractionatedOrPalletizedCargo, setFractionatedOrPalletizedCargo] =
    useState("");
  const [estimatedFractionatedVolume, setEstimatedFractionatedVolume] =
    useState("");
  const [estimatedPalletizedVolume, setEstimatedPalletizedVolume] =
    useState("");

  const [expeditionPatternRequired, setExpeditionPatternRequired] =
    useState("");
  const [specificLicense, setSpecificLicense] = useState("");
  const [additionalObservations, setAdditionalObservations] = useState("");

  const [rowsLTL, setRowsLTL] = useState([
    { id: Date.now(), cubage: 0, weightCubed: 0 },
  ]);

  /* MARITIME TYPES */
  const [dataMaritime, setDataMaritime] = useState({});

  /* AIR TYPES */
  const [dataAir, setDataAir] = useState({});

  // NOTICAÇÕES
  const notifications = (type, message) => {
    return toast[type](message, {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const getProfileContext = async (id) => {
    try {
      const response = await api.get(`/user/${id}`);

      setUser(response.data);

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getProfileAdminContext = async (id) => {
    try {
      const response = await api.get(`/admin/${id}`);

      setUser(response.data);

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const updateUser = async (data) => {
    try {
      const response = await api.patch(`/user/${userId}`, data);

      setUser(response.data);

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getOrigin = async (origin, index) => {
    console.log(origin, 455454);
    const selectOrigem = await api.get(`/discounts/quotation/origin/${origin}`);

    const newArr = selectInputDestination;

    selectInputDestination[index] = selectOrigem.data;

    setSelectInputDestination(newArr);

    return selectOrigem.data;
  };

  const getOriginUf = async (origin, uf, index) => {
    const selectOrigem = await api.get(
      `/discounts/quotation/origin/uf/${origin}/${uf}`
    );

    return selectOrigem.data;
  };

  const getDestination = async (id, index) => {
    const selectOrigem = await api.get(`/discounts/quotation/id/${id}`);

    const newArr = selectInputDestination;

    selectInputDestination[index] = selectOrigem.data;

    setSelectInputDestination(newArr);

    return selectOrigem.data;
  };

  const formatTime = (hours) => {
    const time = moment.duration(hours, "hours");

    const days = Math.floor(time.asDays());
    const remainingHours = Math.floor(time.asHours()) % 24;

    if (days <= 0) {
      return `${remainingHours} hora${
        remainingHours === 0 || remainingHours > 1 ? "s" : ""
      }`;
    }

    let formattedTime;
    if (remainingHours >= 15) {
      formattedTime = `${days + 1} dia${
        days === 0 || days > 1 ? "s" : ""
      } e ${remainingHours} hora${
        remainingHours === 0 || remainingHours > 1 ? "s" : ""
      }`;
    } else {
      formattedTime = `${days} dia${
        days === 0 || days > 1 ? "s" : ""
      } e ${remainingHours} hora${
        remainingHours === 0 || remainingHours > 1 ? "s" : ""
      }`;
    }

    return formattedTime;
  };

  const addPercentageHours = (hour) => {
    // Given time in the format "HH:mm"
    const hoursFormact = hour.split(":").splice(0, 2).join(":");

    // Step 1: Convert the time to minutes
    const [hours, minutes] = hoursFormact.split(":");
    const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);

    // Step 2: Calculate 15% of the total minutes
    const percentage = 60;
    const percentageInMinutes = (percentage / 100) * totalMinutes;

    // Step 3: Add the result from Step 2 to the original time in minutes
    const totalMinutesWithPercentage = totalMinutes + percentageInMinutes;

    // Step 4: Convert the final minutes back to hours and minutes
    const finalHours = Math.floor(totalMinutesWithPercentage / 60);
    const finalMinutes = Math.round(totalMinutesWithPercentage % 60);

    // Format the result as HH:mm
    const result = `${finalHours.toString().padStart(2, "0")}:${finalMinutes
      .toString()
      .padStart(2, "0")}`;

    return result;
  };

  const onSumary = async (origin, destination, type, commodity) => {
    if (!origin?.length || !destination?.length || !typeVehicle?.length)
      return null;
    try {
      const data = {
        origin,
        destination,
        typeVehicle,
        type,
        commodity,
      };

      const response = await api.post("/calculate/route-summary", data);

      // Resultado final do cálculo
      console.log(response.data);

      return response.data;
    } catch (error) {
      console.log(error);
      notifications("error", error.response.data.message);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:");

    if (token) {
      const decoded = jwt_decode(token);
      setUserId(decoded.id);
      getProfileContext(decoded.id);
    }
  }, []);

  useEffect(() => {
    const data = {
      location: location,
      productType: productType,
      palletWeight: palletWeight,
      palletDimensions: palletDimensions,
      unitsPerPallet: unitsPerPallet,
      packageWeight: packageWeight,
      packageDimensions: packageDimensions,
      volumesPerVehicle: volumesPerVehicle,
      stockVolume: stockVolume,
      addedValue: addedValue,
      inventoryTurnover: inventoryTurnover,
      regularOrSpotCargo: regularOrSpotCargo,
      skus: skus,
      samplesRequired: samplesRequired,
      labelingRequired: labelingRequired,
      pickingRequired: pickingRequired,
      unitsPerPackage: unitsPerPackage,
      kitAssembly: kitAssembly,
      averageOutputs: averageOutputs,
      fractionatedOrPalletizedCargo: fractionatedOrPalletizedCargo,
      estimatedFractionatedVolume: estimatedFractionatedVolume,
      estimatedPalletizedVolume: estimatedPalletizedVolume,
      expeditionPatternRequired: expeditionPatternRequired,
      specificLicense: specificLicense,
      additionalObservations: additionalObservations,
    };

    setDataStorage(data);
  }, [
    location,
    productType,
    palletWeight,
    palletDimensions,
    unitsPerPallet,
    packageWeight,
    packageDimensions,
    volumesPerVehicle,
    stockVolume,
    addedValue,
    inventoryTurnover,
    regularOrSpotCargo,
    skus,
    samplesRequired,
    labelingRequired,
    pickingRequired,
    unitsPerPackage,
    kitAssembly,
    averageOutputs,
    fractionatedOrPalletizedCargo,
    estimatedFractionatedVolume,
    estimatedPalletizedVolume,
    expeditionPatternRequired,
    specificLicense,
    additionalObservations,
  ]);

  return (
    <>
      <DashboardContext.Provider
        value={{
          user,
          setUser,
          getProfileContext,
          getProfileAdminContext,
          updateUser,
          userId,
          update,
          setUpdate,
          updateRemove,
          setUpdateRemove,
          getOrigin,
          getOriginUf,
          getDestination,
          selectInputDestination,
          setSelectInputDestination,

          /* CHECK FRACTIONAL */
          indexCheckFractional,
          setIndexCheckFractional,
          dataCheckFractional,
          setDataCheckFractional,
          originFraction,
          setOriginFraction,
          merchandiseFraction,
          cubageCheckFractional,
          commodityPrice,
          setCommodityPrice,
          setCubageCheckFractional,
          setMerchandiseFraction,
          destinationFraction,
          setDestinationFraction,
          cubageTotal,
          setCubageTotal,
          grossWeightTotal,
          setGrossWeightTotal,
          tollTotal,
          setTollTotal,
          transitTimeTotal,
          setTransitTimeTotal,
          freigthTotal,
          setFreigthTotal,
          dispatchTotal,
          setDispatchTotal,
          sefazTotal,
          setSefazTotal,
          sumTotalUser,
          setSumTotalUser,

          /* CHECK DISTRIBUTION NATIONAL */
          dataStates,
          setDataStates,
          comments,
          setComments,
          fileStates,
          setFileStates,

          /* CHECK DEDICATED FREIGHT */
          dataFractionDedicated,
          setDataFractionDedicated,
          typeVehicle,
          setTypeVehicle,
          onSumary,
          dataPriceSummary,
          imagesBase64Global,
          setImagesBase64Global,

          /* CHECK STORAGE */
          dataStorage,
          setDataStorage,
          location,
          setLocation,
          productType,
          setProductType,
          palletWeight,
          setPalletWeight,
          palletDimensions,
          setPalletDimensions,
          unitsPerPallet,
          setUnitsPerPallet,
          packageWeight,
          setPackageWeight,
          packageDimensions,
          setPackageDimensions,
          volumesPerVehicle,
          setVolumesPerVehicle,
          stockVolume,
          setStockVolume,
          addedValue,
          setAddedValue,
          inventoryTurnover,
          setInventoryTurnover,
          regularOrSpotCargo,
          setRegularOrSpotCargo,
          skus,
          setSkus,
          samplesRequired,
          setSamplesRequired,
          labelingRequired,
          setLabelingRequired,
          pickingRequired,
          setPickingRequired,
          unitsPerPackage,
          setUnitsPerPackage,
          kitAssembly,
          setKitAssembly,
          averageOutputs,
          setAverageOutputs,
          fractionatedOrPalletizedCargo,
          setFractionatedOrPalletizedCargo,
          estimatedFractionatedVolume,
          setEstimatedFractionatedVolume,
          estimatedPalletizedVolume,
          setEstimatedPalletizedVolume,
          expeditionPatternRequired,
          setExpeditionPatternRequired,
          specificLicense,
          setSpecificLicense,
          additionalObservations,
          setAdditionalObservations,

          /* MARITIME TYPES */
          dataMaritime,
          setDataMaritime,

          /*AIR TYPE*/
          dataAir,
          setDataAir,

          rowsLTL,
          setRowsLTL,
        }}
      >
        {children}
      </DashboardContext.Provider>
    </>
  );
};
