import { useEffect, useState } from "react";
import Button from "./Button";
import InputSearch from "./InputSearch";
import AddExcel from "./AddExcel";
import api from "../services/api";
import { toast } from "react-toastify";

const ContainerInputSearchAndButton = ({
  widthInput,
  heightInput,
  onClick,
  table,
}) => {
  const [stock, setStock] = useState([]);

  const getKmRoud = async () => {
    const promises = stock.map((item) => {
      return {
        vehicle: item["VEÍCULO"],
        km_max: item["KM/(MAXIMO)"],
        output: item["SAÍDA"],
        km_loaded_above_maximum: item["R$ KM/CARREGADO (ACIMA DO KM MAXIMO)"],
        weight_capacity: item["CAP.PESO"],
        cubage: item["CUBAGEM"],
        axes: item["EIXOS"],
        vehicle_type: item["TIPO DE VEICULO"],
        calculation_qualp: item["CALCULO DO QUALP"],
        profit_margin_output: item["MARGEM DE LUCRO | SAIDA"],
        profit_margin_above_maximum_km:
          item["MARGEM DE LUCRO | ACIMA DO KM MAXIMO"],
      };
    });

    try {
      await api.post("/type/vehicle", promises);
      toast.info(
        "A tabela de cotação por veículos foi atualizada com sucesso!",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } catch (error) {
      toast.error(
        "Houve um erro ao atualizar a tabela de cotação por veículos.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  useEffect(() => {
    if (stock.length > 0) {
      getKmRoud();
    }
  }, [stock]);

  const container = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  return (
    <div className="containerBox" style={container}>
      <div style={{ display: "flex", gap: "10px" }}>
        <InputSearch table={table} width={widthInput} height={heightInput} />
        <AddExcel stock={stock} setStock={setStock} />
      </div>
      <Button
        width="195px"
        height="45px"
        background={"var(--color-primary)"}
        borderRadius="60px"
        marginTop="0"
        name="Novo Tabela"
        color={"var(--white)"}
        onClick={onClick}
        border="none"
      />
    </div>
  );
};

export default ContainerInputSearchAndButton;
