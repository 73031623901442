import { useContext, useEffect } from "react";
import { useState } from "react";
import Input from "./Input";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import api from "../services/api";


const InputsFreightQuote = ({ sumResume, setSumResume, index, isActive }) => {

    const { update, setUpdate, cubageCheckFractional, setCubageCheckFractional, setDataCheckFractional } = useContext(DashboardContext)

    const [quantityCheckFractional, setQuantityCheckFractional] = useState('');
    const [grossWeightCheckFractional, setGrossWeightCheckFractional] = useState('');
    const [heightCheckFractional, setHeightCheckFractional] = useState('');
    const [widthCheckFractional, setWidthCheckFractional] = useState('');
    const [lengthCheckFractional, setLengthCheckFractional] = useState('');
    const [cubedWeight, setCubedWeight] = useState('');

    const calculateCubage = async (length, width, height) => {
        const cubage = (Number(length) * Number(width) * Number(height)) / 1000000;
        const result = cubage * quantityCheckFractional * 1000000;
        const formattedResult = result.toLocaleString("pt-BR");

        const sum = {
            cubageTotal: Number(result.toFixed(2)),
            m3: cubageCheckFractional,
            grossWeight: String(grossWeightCheckFractional).length > 3 ?
                Number(grossWeightCheckFractional?.replace(".", "").replaceAll("kg", "").trim())
                :
                Number(String(grossWeightCheckFractional)),
            quantity: Number(quantityCheckFractional),
            grossWeight2: grossWeightCheckFractional,
            weightCubed2: cubedWeight,
        }

        if (sum.cubageTotal > 0) {
            const resultSum = await api.post("/discounts/quotation/cubed", { cubageTotal: sum.cubageTotal })

            sum.cubageWeight = Number(String(resultSum.data.result).replaceAll(".", "").split(" ")[0])
            sum.total = Number(
                String(grossWeightCheckFractional).length > 3 ? grossWeightCheckFractional?.replace(".", "").replaceAll("kg", "").trim()
                    : String(grossWeightCheckFractional)) > Number(String(resultSum.data.result).replaceAll(".", "").split(" ")[0]) ?
                Number(String(grossWeightCheckFractional).length > 3 ? grossWeightCheckFractional?.replace(".", "").replaceAll("kg", "").trim()
                    : String(grossWeightCheckFractional))
                :
                Number(String(resultSum.data.result).replaceAll(".", "").split(" ")[0])

            sum.weightCubed2 = resultSum.data.result
            setCubedWeight(resultSum.data.result)
        } else {
            setCubedWeight(0)
        }


        setSumResume(sum)

        setUpdate(!update)

        setCubageCheckFractional(`${formattedResult} m³`);
        return cubage.toFixed(2);
    };

    useEffect(() => {
        calculateCubage(lengthCheckFractional, widthCheckFractional, heightCheckFractional)
    }, [
        quantityCheckFractional,
        grossWeightCheckFractional,
        heightCheckFractional,
        widthCheckFractional,
        lengthCheckFractional,
    ])


    return (
        <>
            <Input isActive={isActive} value={quantityCheckFractional} onChange={(e) => setQuantityCheckFractional(e.target.value)} name="Quantidade" width="100%" placeholder="" type={"text"} />
            <Input isActive={isActive} onClick={() => setGrossWeightCheckFractional(String(grossWeightCheckFractional).replaceAll(".", "").replaceAll("kg", "").trim())} onBlur={() => setGrossWeightCheckFractional(`${Number(grossWeightCheckFractional).toLocaleString("pt-BR")} kg`)} value={grossWeightCheckFractional.replace(/,/g, ".")} onChange={(e) => setGrossWeightCheckFractional(e.target.value)} name="Peso bruto (KG)" width="100%" placeholder="" type={"text"} />
            <Input isActive={isActive} value={heightCheckFractional.replace(/,/g, ".")} onChange={(e) => setHeightCheckFractional(e.target.value)} name="Altura (M)" placeholder="Ex: 1" width="100%" type={"text"} />
            <Input isActive={isActive} value={widthCheckFractional.replace(/,/g, ".")} onChange={(e) => setWidthCheckFractional(e.target.value)} name="Largura (M)" placeholder="Ex: 1,50" width="100%" type={"text"} />
            <Input isActive={isActive} value={lengthCheckFractional.replace(/,/g, ".")} onChange={(e) => setLengthCheckFractional(e.target.value)} name="Comprimento (M)" placeholder="Ex: 2" width="100%" type={"text"} />
            <Input isActive={isActive} value={cubageCheckFractional} onChange={(e) => setCubageCheckFractional(e.target.value)} name="Cubagem M³" placeholder="" width="100%" type={"text"} />
            <Input isActive={isActive} value={cubedWeight} onChange={(e) => setCubedWeight(e.target.value)} name="Peso cubado" placeholder="" width="100%" type={"text"} />
        </>
    )
}

export default InputsFreightQuote