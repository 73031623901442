import { Tooltip as ReactTooltip } from "react-tooltip";

const LetterLimit = ({
  text,
  limit,
  threePoints,
  className,
  id,
  width,
  widthContainer,
}) => {
  let description = [];

  if (threePoints) {
    if (String(text).length > 1) {
      text?.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 1) {
          description = `${description.join("")}...`;
        }
      });
    }
  } else {
    if (String(text).length > 1) {
      text?.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 2) {
          description = `${description.join("")}...`;
        }
      });
    }
  }

  return (
    <div
      style={{
        width: widthContainer,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <p
        data-tooltip-place="top"
        data-tooltip-position-strategy="fixed"
        data-tooltip-id={id}
        data-tooltip-content={text}
        className={className}
        style={{ cursor: "pointer", width }}
      >
        {description}
      </p>
      <ReactTooltip id={id} />
    </div>
  );
};

export default LetterLimit;
