import { useContext, useEffect, useState } from "react";
import closeImg from "../images/svg/closeIcon.svg";
import Button from "./Button";
import { ProposalContext } from "../context/Dashboard/ProposalContext";

const ModalFilterProposal = ({ closeModal, client }) => {
  const {
    searchCompanys,
    companys,
    setFilterProposal,
    getProposalAdm,
    filterProposal,

    getProposalClient,
  } = useContext(ProposalContext);
  const [selectCompany, setSelectCompany] = useState("");
  const [status, setStatus] = useState("");
  const [onFilter, setOnFilter] = useState(false);

  const modalContainerStyles = {
    width: "370px",
    backgroundColor: "#fff",
    position: "absolute",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
  };

  const headerStyles = {
    display: "flex",
    gap: "15px",
    borderBottom: "1px solid #d7d7d7",
    padding: "15px 20px 10px",
  };

  const closeIconStyles = {
    width: "12px",
    cursor: "pointer",
  };

  const contentStyles = {
    padding: "15px 20px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  };

  const selectContainerStyles = {
    display: "flex",
    gap: "10px",
  };

  const onSearchFilter = () => {
    const date = {
      client: selectCompany,
      status,
    };

    setFilterProposal(date);

    setOnFilter(true);
  };

  useEffect(() => {
    searchCompanys();
  }, []);

  useEffect(() => {
    if (onFilter) {
      if (client) getProposalClient(1);
      else getProposalAdm(1);
      closeModal();
    }
  }, [filterProposal, client]);

  return (
    <div style={modalContainerStyles}>
      <div style={headerStyles}>
        <img src={closeImg} style={closeIconStyles} onClick={closeModal} />
        <p className="textTitleJustificationDescription">Filtro de busca</p>
      </div>

      <div style={contentStyles}>
        {!client && (
          <div style={{ ...selectContainerStyles, gap: "64px" }}>
            <label
              htmlFor="typeTime"
              className="textLabelJustificationDescription"
            >
              Cliente:
            </label>
            <select
              defaultValue={companys}
              id="typeTime"
              className="textSelectOptionsJustificationDescription"
              onChange={(e) => setSelectCompany(e.target.value)}
              style={{
                outline: "none",
                border: "none",
                borderBottom: "1px solid #d7d7d7",
                width: "150px",
              }}
            >
              <option value="default">Selecione</option>
              <option value="">Todos</option>
              {companys.length > 0 &&
                companys.map((elem) => {
                  return (
                    <>
                      <option value={elem.corporate_name}>
                        {elem.corporate_name}
                      </option>
                    </>
                  );
                })}
            </select>
          </div>
        )}

        <div style={{ ...selectContainerStyles, gap: "68px" }}>
          <label
            htmlFor="typeTime"
            className="textLabelJustificationDescription"
          >
            Status:
          </label>
          <select
            defaultValue={status}
            id="typeTime"
            className="textSelectOptionsJustificationDescription"
            onChange={(e) => setStatus(e.target.value)}
            style={{
              outline: "none",
              border: "none",
              borderBottom: "1px solid #d7d7d7",
              width: "150px",
            }}
          >
            <option value="default">Selecione</option>
            <option value="all">Todos</option>
            <option value="inProgress">Em andamento</option>
            <option value="approved">Aprovados</option>
            <option value="refused">Recusados</option>
            {!client ? <option value="analysis">Em análize</option> : <></>}
          </select>
        </div>

        <Button
          padding={"0 20px"}
          height="35px"
          background={"var(--color-primary)"}
          borderRadius="8px"
          marginTop="0"
          name={"Filtro"}
          color={"#fff"}
          border="none"
          onClick={onSearchFilter}
        />
      </div>
    </div>
  );
};

export default ModalFilterProposal;
