import React, { useState, useEffect } from "react";
import api from "../services/api";
import jwt_decode from "jwt-decode";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import { useContext } from "react";
import axios from "axios";

const InputSelectFilterManager = ({
  name,
  width,
  top,
  custom,
  color,
  marginLeft,
  height,
  type,
  onChange,
  marginRight,
  value,
  marginBottom,
  index,
  isActive,
  onBlur,
  active,
  onState,
  cityList,
  selectState,
  stateSelectUser,
  statesLists,
  originProps,
  options,
  countryOne,
  isExw,
}) => {
  const { getProfileContext, getDestination, selectInputDestination } =
    useContext(DashboardContext);

  const [routeUser, setRouteUser] = useState([]);
  const [statesList, setStatesList] = useState([]);

  const [selectOption, setSelectOption] = useState([]);
  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  const [originState, setOriginState] = useState("");
  const [originSelectUser, setOriginSelectUser] = useState("");
  const [stateSelectUserValue, setStateSelectUserValue] = useState("");

  const [counties, setCounties] = useState([]);
  const [ports, setPorts] = useState([]);

  const getUsers = async () => {
    try {
      const response = await api.get("user");
      setUsers(response.data);
    } catch (error) {}
  };

  const getType = async () => {
    const token = localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:");
    const decoded = jwt_decode(token);
    const { id } = decoded;
    const response = await getProfileContext(id);

    try {
      const results = await api.get(`/discounts/quotation/${response.email}`);

      console.log(results.data, 5000);

      setRouteUser(results.data.national);
    } catch (error) {}
  };

  const getVehicles = async () => {
    try {
      const vehiclesAll = await api.get("/type/vehicle");

      setVehicles(vehiclesAll.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLocationOriginCountry = async () => {
    try {
      // const { data: response } = await api.get("/maritime/countries");
      // setCounties(response);
    } catch (error) {}
  };

  // const getLocationOriginPorts = async () => {
  //   setPorts([]);
  //   try {
  //     const { data: response } = await api.get(`/maritime/ports/${countryOne}`);
  //     setPorts(response);
  //   } catch (error) {}
  // };

  // useEffect(() => {
  //   if (countryOne?.length > 0) {
  //     console.log(countryOne);
  //     getLocationOriginPorts();
  //   }
  // }, [countryOne]);

  useEffect(() => {
    getLocationOriginCountry();
  }, []);

  useEffect(() => {
    setStateSelectUserValue(stateSelectUser);
  }, [stateSelectUser, type]);

  useEffect(() => {
    setOriginSelectUser(stateSelectUser);
  }, [stateSelectUser]);

  useEffect(() => {
    setOriginState(originProps);
  }, [originProps]);

  useEffect(() => {
    if (type === "checkFractional") {
      getType();
    }
  }, []);

  useEffect(() => {
    setSelectOption(value);
  }, [value]);

  useEffect(() => {
    setStatesList(statesLists);
  }, [statesLists]);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (type === "typeVehicle") {
      getVehicles();
    }
  }, [type]);

  const styleSelect = {
    width: width != undefined ? width : "100%",
    height: height != undefined ? height : "45px",
    color: "#000",
    borderRadius: "10px",
    background: isActive === false ? "#d7d7d730" : "white",
    paddingLeft: "12px",
    border: "1px solid #D7D7D7",
    outline: "none",
    marginBottom: marginBottom ? marginBottom : "1.9rem",
    appearance: "none",
  };

  return (
    <div
      style={{
        position: "relative",
        width: `${width != undefined ? width : "100%"}`,
        display: "flex",
        flexDirection: "column",
        marginRight: `${marginRight != undefined ? marginRight : "0"}`,
        marginLeft: marginLeft,
      }}
    >
      {custom === true ? (
        <label
          style={{ marginBottom: "14px" }}
          className="textPrimaryLabelInput"
        >
          {name}
        </label>
      ) : (
        <label className="textLabelInputSelectFilterManager">{name}</label>
      )}

      <select
        defaultValue={value}
        onBlur={onBlur}
        disabled={isActive === false ? true : false}
        onChange={(e) => {
          type !== "listTypeVehicles" && setSelectOption(e.target.value);
          onChange && onChange(e);
        }}
        className="textSelectFilterManager"
        style={styleSelect}
      >
        {(type === "register" && (
          <>
            <option value="">Selecione</option>
            <option value="Agente de cargas">Agente de cargas</option>
            <option value="Agência de despacho aduaneiro">
              Agência de despacho aduaneiro
            </option>
            <option value="Exportador">Exportador</option>
            <option value="Importador">Importador</option>
            <option value="Indústria">Indústria</option>
            <option value="Comércio">Comércio</option>
            <option value="Transportadora">Transportadora</option>
            <option value="Operador logístico">Operador logístico</option>
            <option value="Intermediador logístico">
              Intermediador logístico
            </option>
            <option value="Outros">Outros</option>
          </>
        )) ||
          (type === "documentType" && (
            <>
              <option value="99.999.999/9999-99:CNPJ">CNPJ - Brasil</option>
              <option value="99-99999999-9:CUIT">CUIT - Argentina</option>
              <option value="99999999999:NIT">NIT - Bolivia</option>
              <option value="99999999-9:RUT">RUT - Chile</option>
              <option value="99999999-9:RUT">RUT - Uruguay</option>
              <option value="99999999-9:RUC">RUC - Paraguay</option>
              <option value="99999999999:RUC">RUC - Peru</option>
              <option value=":Outros">NIF</option>
            </>
          )) ||
          (type === "quantity" && (
            <>
              {[...Array(30)].map((_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </>
          )) ||
          (type === "countryOrigin" &&
            counties?.map((item) => {
              return (
                <>
                  <option value={item.code}>{item.country}</option>
                </>
              );
            })) ||
          (type === "portsOrigin" &&
            ports?.map((item) => {
              return (
                <>
                  <option value={`${item.location} | ${item.city}`}>
                    {`${item.location} - ${item.city}`}
                  </option>
                </>
              );
            })) ||
          (type === "countryDestination" &&
            counties?.map((item) => {
              return (
                <>
                  <option value={item.code}>{item.country}</option>
                </>
              );
            })) ||
          (type === "portsDestination" &&
            ports?.map((item) => {
              return (
                <>
                  <option value={`${item.location} | ${item.city}`}>
                    {`${item.location} - ${item.city}`}
                  </option>
                </>
              );
            })) ||
          (type === "typeFreight" && (
            <>
              {/* <option value={selectOption}>{selectOption}</option> */}
              <option value="Rodoviário nacional">
                Rodoviário nacional fracionado
              </option>
              <option value="Rodoviário dedicado">
                Rodoviário nacional dedicado
              </option>
              <option value="Rodoviário internacional LTL">
                Rodoviário internacional LTL
              </option>
              <option value="Rodoviário internacional FTL">
                Rodoviário internacional FTL
              </option>
              {/* {selectOption === "Rodoviário internacional LTL" ? (
                <>
                  <option value="Rodoviário nacional">
                    Rodoviário nacional
                  </option>
                  <option value="Rodoviário internacional FTL">
                    Rodoviário internacional FTL
                  </option>
                </>
              ) : selectOption === "Rodoviário internacional FTL" ? (
                <>
                  <option value="Rodoviário nacional">
                    Rodoviário nacional
                  </option>
                  <option value="Rodoviário internacional LTL">
                    Rodoviário internacional LTL
                  </option>
                </>
              ) : (
                <>
                  <option value="Rodoviário internacional FTL">
                    Rodoviário internacional FTL
                  </option>
                  <option value="Rodoviário internacional LTL">
                    Rodoviário internacional LTL
                  </option>
                </>
              )} */}
            </>
          )) ||
          (type === "adm" && (
            <>
              <option value="default">Selecione</option>
              <option value="administrative">Administrativo</option>
              <option value="financial">Financeiro</option>
              <option value="employee">SDR</option>
            </>
          )) ||
          (type === "typeFtlOrLtl" && (
            <>
              <option value="FTL">FTL</option>
              <option value="LTL">LTL</option>
            </>
          )) ||
          (type === "typeMoeda" && (
            <>
              <option value="default">Selecione</option>
              <option value="BRL">Real</option>
              <option value="USD">Dolar</option>
              <option value="EUR">Euro</option>
              {/* <option value="Outros">Outros</option> */}
            </>
          )) ||
          (type === "originInternational" && value?.length > 0 && (
            <>
              <option value="default">Selecione</option>
              {value.map((item) => {
                return <option value={item.origin}>{item.origin}</option>;
              })}
              <option value="Outros">Outros</option>
            </>
          )) ||
          (type === "originInternationalftl" && value?.length > 0 && (
            <>
              <option value="default">Selecione</option>
              {value.map((item) => {
                return (
                  <option value={item.origin_city_state}>
                    {item.origin_city_state}
                  </option>
                );
              })}
            </>
          )) ||
          (type === "destinationInternational" && options?.length > 0 && (
            <>
              <option value="default">Selecione</option>
              {originState === "Outros" ? (
                <option value="buenos aries">Buenos Aries</option>
              ) : (
                options.map((item) => {
                  if (item.origin.includes(originState)) {
                    return <option value={item.destiny}>{item.destiny}</option>;
                  }
                })
              )}
            </>
          )) ||
          (type === "destinationInternationalftl" && options?.length > 0 && (
            <>
              <option value="default">Selecione</option>
              {options.map((item) => {
                if (item.origin_city_state === originState) {
                  return item.data.map((elem) => {
                    return (
                      <option value={elem.destino_city_state}>
                        {elem.destino_city_state}
                      </option>
                    );
                  });
                }
              })}
            </>
          )) ||
          (type === "state" &&
            statesList?.length > 0 &&
            statesList?.map((item, i) => {
              if (i === 0) {
                return (
                  <>
                    <option value="default">Selecione</option>
                    <option value={item.sigla}>{item.nome}</option>
                  </>
                );
              }
              if (originSelectUser === item.sigla) {
                return (
                  <>
                    <option selected value={item.sigla}>
                      {item.nome}
                    </option>
                  </>
                );
              }
              return (
                <>
                  <option value={item.sigla}>{item.nome}</option>
                </>
              );
            })) ||
          (type === "citys" && cityList?.length > 0 && (
            <>
              <option selected value="default">
                Selecione
              </option>
              {cityList?.map((item, i) => {
                return (
                  <>
                    <option value={item.nome}>{item.nome}</option>
                  </>
                );
              })}
            </>
          )) ||
          (type === "routDesti" && (
            <>
              <option value="">Santo André/SP - EADI / Wilson Sons</option>
            </>
          )) ||
          (type === "rout" && options?.length > 0 && (
            <>
              {originProps && isExw ? (
                <>
                  <option selected value="default">
                    Selecione
                  </option>
                  {options.map((item) => {
                    if (item.origin.includes(originState)) {
                      return (
                        <option key={item.storage} value={item.storage}>
                          {item.storage}
                        </option>
                      );
                    }
                    return null; // Necessário para evitar avisos de renderização
                  })}
                </>
              ) : (
                <>
                  <option value="default">Selecione</option>
                </>
              )}
              <option value="Coletar | Pick-up">Coletar | Pick-up</option>
            </>
          )) ||
          (type === "routBuenos" && options?.length > 0 && (
            <>
              {originProps ? (
                <>
                  <option selected value="default">
                    Selecione
                  </option>
                  {options.map((item) => {
                    if (item.origin.includes(originState)) {
                      return (
                        <option key={item.storage} value={item.storage}>
                          {item.storage}
                        </option>
                      );
                    }
                    return null; // Necessário para evitar avisos de renderização
                  })}
                  <option value="Coletar">Coletar</option>
                </>
              ) : (
                <>
                  <option value="default">Selecione</option>
                </>
              )}
            </>
          )) ||
          (type === "inconterm" && (
            <>
              <option value="default">Selecione</option>
              <option value="EXW">EXW - Ex works</option>
              <option value="FCA">FCA - Free carrier</option>
              <option value="FAS">FAS - Free A longsi de Ship</option>
              <option value="FOB">FOB - Free On Board</option>
              <option value="CPT">CPT - Carriage Paid to</option>
              <option value="CIP">CIP - Carriage And Insurance Paid to</option>
              <option value="CFR">CFR - Cost And Freight</option>
              <option value="CIF">CIF - Cost Insurance And Freight</option>
              <option value="DAP">DAP - Delivery At Place</option>
              <option value="DPU">DPU - Delivery At Unloaded</option>
              {/* <option value="DDP">DDP - Delivered Duty Paid</option> */}
            </>
          )) ||
          (type === "kindOfPacking" && (
            <>
              <option value="default">Selecione</option>
              <option value="Amarrados">Amarrados</option>
              <option value="Big bag">Big bag</option>
              <option value="Bobina">Bobina</option>
              <option value="Caixas de madeira">Caixas de madeira</option>
              <option value="Caixas de papelão">Caixas de papelão</option>
              <option value="Cilindros">Cilindros</option>
              <option value="Container">Container</option>
              <option value="Engradado">Engradado</option>
              <option value="Engradado de madeira">Engradado de madeira</option>
              <option value="Estrados">Estrados</option>
              <option value="Estrados de madeira">Estrados de madeira</option>
              <option value="Fardos">Fardos</option>
              <option value="IBC">IBC</option>
              <option value="Pallet de plástico">Pallets de plástico</option>
              <option value="Pallets de madeira">Pallets de madeira</option>
              <option value="Rolos">Rolos</option>
              <option value="Sacos">Sacos</option>
              <option value="Tambores de plástico">Tambores de plástico</option>
              <option value="Tambores de ferro">Tambores de ferro</option>
              <option value="Outros">Outros</option>
            </>
          )) ||
          (type === "typeService" && (
            <>
              <option value="default">Selecione</option>
              <option value="Caixa com LCL">LCL</option>
              <option value="FCL">FCL</option>
              <option value="CABOTAGEM">CABOTAGEM</option>
            </>
          )) ||
          (type === "typeContainer" && (
            <>
              <option value="default">Selecione</option>
              <option value="20 pés">20 pés</option>
              <option value="40 pés">40 pés</option>
            </>
          )) ||
          (type === "modelContainer" && (
            <>
              <option value="default">Selecione</option>
              <option value="Container Dry">Container Dry</option>
              <option value="Plataforma_Flat Pack">Plataforma_Flat Pack</option>
              <option value="Container Tanque">Container Tanque</option>
              <option value="Container Refrigerados">
                Container Refrigerados
              </option>
            </>
          )) ||
          (type === "boolean" && (
            <>
              <option value="default">Selecione</option>
              <option value="Sim">Sim</option>
              <option value="Não">Não</option>
            </>
          )) ||
          (type === "imo" && (
            <>
              <option value="default">Selecione</option>
              <option value="Sim">Sim</option>
              <option value="Não">Não</option>
            </>
          )) ||
          (type === "typeVehicle" &&
            vehicles.length > 0 &&
            vehicles.map((item, i) => {
              if (i === 0) {
                return (
                  <>
                    <option value="default">Selecione</option>
                    <option value={item.id}>{item.vehicle}</option>
                  </>
                );
              } else {
                return <option value={item.id}>{item.vehicle}</option>;
              }
            })) ||
          (type === "listTypeVehicles" &&
            selectOption.length > 0 &&
            selectOption?.map((item, i) => {
              if (i === 0) {
                return (
                  <>
                    <option value="default">Selecione</option>
                    <option value={item.id}>{item.type_of_vehicles}</option>
                  </>
                );
              } else {
                return <option value={item.id}>{item.type_of_vehicles}</option>;
              }
            })) ||
          (type === "checkFractional" &&
            name === "Rota comercial de origem" &&
            routeUser.map((item, i) => {
              if (i === 0) {
                return (
                  <>
                    <option value="default">Selecione</option>
                    <option value={item.origin_trade_route}>
                      {item.origin_trade_route}
                    </option>
                  </>
                );
              }
              return (
                <>
                  <option value={item.origin_trade_route}>
                    {item.origin_trade_route}
                  </option>
                </>
              );
            })) ||
          (type === "checkFractional" &&
            name === "Rota comercial de destino" &&
            value &&
            value?.option?.length > 0 &&
            value.option.map((item, i) => {
              console.log(item, 888);
              if (i === 0) {
                return (
                  <>
                    <option value="default">Selecione</option>
                    <option value={item?.id}>
                      {item?.destination_trade_route}
                    </option>
                  </>
                );
              }
              return (
                <>
                  <option value={item?.id}>
                    {item?.destination_trade_route}
                  </option>
                </>
              );
            })) ||
          (type === "checkFractional" &&
            name === "UF" &&
            value &&
            value?.option?.length > 0 &&
            value.option.map((item, i) => {
              if (i === 0) {
                return (
                  <>
                    <option value="default">Selecione</option>
                    <option value={item?.uf}>{item?.uf}</option>
                  </>
                );
              }
              return (
                <>
                  <option value={item?.uf}>{item?.uf}</option>
                </>
              );
            })) ||
          (type === "addUser" && (
            <>
              <option value="default">Selecione</option>
              <option value="admin">Administrativo</option>
              <option value="client">Cliente</option>
            </>
          ))}
      </select>

      <div
        style={{
          position: "absolute",
          right: 0,
          top: `${top != undefined ? top : "19px"}`,
          right: "19px",
        }}
      >
        <svg
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.60677 6.61C5.15396 6.61 4.70115 6.40242 4.35831 5.99494L0.140695 0.982178C-0.0468983 0.759218 -0.0468983 0.39018 0.140695 0.16722C0.328288 -0.05574 0.638787 -0.05574 0.826381 0.16722L5.04399 5.17998C5.35449 5.54902 5.85905 5.54902 6.16955 5.17998L10.3872 0.16722C10.5748 -0.05574 10.8853 -0.05574 11.0729 0.16722C11.2604 0.39018 11.2604 0.759218 11.0729 0.982178L6.85524 5.99494C6.5124 6.40242 6.05959 6.61 5.60677 6.61Z"
            fill="#888888"
          />
        </svg>
      </div>
    </div>
  );
};

export default InputSelectFilterManager;
