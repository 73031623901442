import { useEffect, useState } from "react";
import optionsIcon from "../images/svg/optionsIcon.svg";
import Button from "./Button";
import { Tooltip as ReactTooltip } from "react-tooltip";

const ShippingCards = ({ openModal, onClick, title, visibility, type }) => {
  const [visibilityList, setVisibilityList] = useState();

  const container = {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    width: "319px",
    padding: "20px",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    backgroundColor: "#fff",
  };

  const boxLineCard = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const formactVisibility = () => {
    if (typeof visibility != "string" && visibility.length > 0) {
      const newVisibility = visibility?.map((item) => item.corporate_name);

      setVisibilityList(newVisibility?.join(", "));
    } else if (typeof visibility == "string") {
      setVisibilityList("Todos");
    }
  };

  useEffect(() => {
    formactVisibility();
  }, []);

  return (
    <div style={container}>
      <div style={boxLineCard}>
        <p
          className="textTitleShippingCard"
          data-tooltip-place="top"
          data-tooltip-position-strategy="fixed"
          data-tooltip-id="title"
          data-tooltip-content={title}
        >
          {title}
        </p>
        <img
          onClick={openModal}
          style={{ cursor: "pointer" }}
          src={optionsIcon}
        />
        <ReactTooltip id="title" />
      </div>
      <div
        style={{
          ...boxLineCard,
          borderTop: "1px solid #d7d7d7",
          paddingTop: "20px",
        }}
      >
        <p className="textTypeShippingCard">Tipo</p>
        <p className="textTypeValueShippingCard">{type}</p>
      </div>
      <div style={{ ...boxLineCard }}>
        <p className="textTypeShippingCard">Visibilidade</p>
        <div style={{ width: "143px", overflow: "hidden" }}>
          <p
            className="textTypeValueShippingCardPoints"
            data-tooltip-place="top"
            data-tooltip-position-strategy="fixed"
            data-tooltip-id="title"
            data-tooltip-content={visibilityList}
          >
            {visibilityList}
          </p>
          <ReactTooltip id="visibility" />
        </div>
      </div>
      <Button
        width="100%"
        height="37px"
        background={"var(--color-primary)"}
        borderRadius="60px"
        marginTop="0"
        name="Ver Detalhes"
        color={"var(--white)"}
        onClick={onClick}
        border="none"
      />
    </div>
  );
};

export default ShippingCards;
