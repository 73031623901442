

const AirBoxPdf = () => {

    return (
        <div className="container4">
            <div className="img4">
                <img src="https://firebasestorage.googleapis.com/v0/b/maxitrans-6cf65.appspot.com/o/profile%2Flogo.png?alt=media&token=626ee25a-c6da-4ec6-ba88-e3f38142bd3f" />
            </div>

            <div className="headerTitle4">
                <p className="proposal4">Proposta Comercial - AÉREO (CAIXA)</p>
            </div>

            <div className="contentTitle4">
                <div className="boxContentTitle4">
                    <p>DATA:</p>
                    <p>19/06/2023</p>
                </div>

                <div className="boxContentTitle4">
                    <p>VALIDADE:</p>
                    <p>19/07/2023</p>
                </div>

                <div className="boxContentTitle4">
                    <p>COTAÇÃO Nº:</p>
                    <p>NC2023_114</p>
                </div>
            </div>

            <div className="contentBox4">
                <h3 className="textTitleCotation4">Informações da cotação</h3>

                <div className="containerInputTwoInputs">

                    <div className="boxContainer4">
                        <p className="titleInput4">Rota comercial de origem</p>
                        <div className="boxInput4">
                            <p className="valueInput4">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer4">
                        <p className="titleInput4">Rota comercial de destino</p>
                        <div className="boxInput4">
                            <p className="valueInput4">Value</p>
                        </div>
                    </div>
                </div>

                <div className="containerInput4">

                    <div className="boxContainer4">
                        <p className="titleInput4">Tipo de mercadoria</p>
                        <div className="boxInput4">
                            <p className="valueInput4">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer4">
                        <p className="titleInput4">Tipo de embalagem</p>
                        <div className="boxInput4">
                            <p className="valueInput4">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer4">
                        <p className="titleInput4">A carga pode ser empilhada?</p>
                        <div className="boxInput4">
                            <p className="valueInput4">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer4">
                        <p className="titleInput4">Valor da mercadoria</p>
                        <div className="boxInput4">
                            <p className="valueInput4">Value</p>
                        </div>
                    </div>
                </div>

                <div className="containerInput4">

                    <div className="boxContainer4">
                        <p className="titleInput4">Quantidade</p>
                        <div className="boxInput4">
                            <p className="valueInput4">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer4">
                        <p className="titleInput4">Peso bruto (KG)</p>
                        <div className="boxInput4">
                            <p className="valueInput4">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer4">
                        <p className="titleInput4">Altura (M)</p>
                        <div className="boxInput4">
                            <p className="valueInput4">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer4">
                        <p className="titleInput4">Largura (M)</p>
                        <div className="boxInput4">
                            <p className="valueInput4">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer4">
                        <p className="titleInput4">Comprimento (M)</p>
                        <div className="boxInput4">
                            <p className="valueInput4">Value</p>
                        </div>
                    </div>

                    <div className="boxContainer4">
                        <p className="titleInput4">Cubagem M³</p>
                        <div className="boxInput4">
                            <p className="valueInput4">Value</p>
                        </div>
                    </div>
                </div>

                <div className="boxContainer4">
                    <p className="titleInput4">Observações adicionais</p>
                    <div className="boxTextarea4">
                        <p className="valueInput4">lorem</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AirBoxPdf