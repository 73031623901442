import { useState } from "react";
import Input from "./Input";
import { useEffect } from "react";

const InputsNational = ({ setData, data, numberIndex, getInfo, index, id }) => {
  /* NATIONAL STATES*/
  const [originTradeRoute, setOriginTradeRoute] = useState(
    data[index]?.origin_trade_route
  );
  const [uf, setUf] = useState(data[index]?.uf);
  const [destinationTradeRoute, setDestinationTradeRoute] = useState(
    data[index]?.destination_trade_route
  );
  const [transitTime, setTransitTime] = useState(data[index]?.transit_time);

  const [upTo10kg, setUpTo10kg] = useState(data[index]?.up_to_10kg);
  const [upTo20kg, setUpTo20kg] = useState(data[index]?.up_to_20kg);
  const [upTo30kg, setUpTo30kg] = useState(data[index]?.up_to_30kg);

  const [upTo50kg, setUpTo50kg] = useState(data[index]?.up_to_50kg);
  const [upTo70kg, setUpTo70kg] = useState(data[index]?.up_to_70kg);
  const [upTo100kg, setUpTo100kg] = useState(data[index]?.up_to_100kg);

  const [upTo150kg, setUpTo150kg] = useState(data[index]?.up_to_150kg);
  const [upTo200kg, setUpTo200kg] = useState(data[index]?.up_to_200kg);
  const [upTo250kg, setUpTo250kg] = useState(data[index]?.up_to_250kg);

  const [excessKg, setExcessKg] = useState(data[index]?.excess_kg);
  const [cteDispatch, setCteDispatch] = useState(data[index]?.cte_dispatch);
  const [sefazTax, setSefazTax] = useState(data[index]?.sefaz_tax);

  const [shippingValue, setShippingValue] = useState(
    data[index]?.shipping_value
  );
  const [gris, setGris] = useState(data[index]?.gris);
  const [icms, setIcms] = useState(data[index]?.icms);

  const [toll, setToll] = useState(data[index]?.toll);

  const convertToBRL = async (amount, setAmount) => {
    const price = Number(amount).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    setAmount(price);
  };

  const convertValueInput = (value, setValue) => {
    if (value != 0) {
      setValue(String(value).split("R$")[1]?.replaceAll(".", "").split(",")[0]);
    }
  };

  const getUpdate = () => {
    if (id) {
      if (getInfo) {
        setOriginTradeRoute(getInfo.origin_trade_route);
        setUf(getInfo.uf);
        setDestinationTradeRoute(getInfo.destination_trade_route);
        setTransitTime(getInfo.transit_time);
        setUpTo10kg(getInfo.up_to_10kg);
        setUpTo20kg(getInfo.up_to_20kg);
        setUpTo30kg(getInfo.up_to_30kg);
        setUpTo50kg(getInfo.up_to_50kg);
        setUpTo70kg(getInfo.up_to_70kg);
        setUpTo100kg(getInfo.up_to_100kg);
        setUpTo150kg(getInfo.up_to_150kg);
        setUpTo200kg(getInfo.up_to_200kg);
        setUpTo250kg(getInfo.up_to_250kg);
        setExcessKg(getInfo.excess_kg);
        setCteDispatch(getInfo.cte_dispatch);
        setSefazTax(getInfo.sefaz_tax);
        setShippingValue(getInfo.shipping_value);
        setGris(getInfo.gris);
        setIcms(getInfo.icms);
        setToll(getInfo.toll);
      }
    }
  };

  const getCreate = () => {
    const dataInput = {
      origin_trade_route: originTradeRoute,
      uf: uf,
      destination_trade_route: destinationTradeRoute,
      transit_time: transitTime,
      up_to_10kg: String(upTo10kg).replaceAll(".", "").split(",").join(""),
      up_to_20kg: String(upTo20kg).replaceAll(".", "").split(",").join(""),
      up_to_30kg: String(upTo30kg).replaceAll(".", "").split(",").join(""),
      up_to_50kg: String(upTo50kg).replaceAll(".", "").split(",").join(""),
      up_to_70kg: String(upTo70kg).replaceAll(".", "").split(",").join(""),
      up_to_100kg: String(upTo100kg).replaceAll(".", "").split(",").join(""),
      up_to_150kg: String(upTo150kg).replaceAll(".", "").split(",").join(""),
      up_to_200kg: String(upTo200kg).replaceAll(".", "").split(",").join(""),
      up_to_250kg: String(upTo250kg).replaceAll(".", "").split(",").join(""),
      excess_kg: String(excessKg).replaceAll(".", "").split(",").join(""),
      cte_dispatch: cteDispatch,
      sefaz_tax: sefazTax,
      shipping_value: shippingValue,
      gris: gris,
      icms: icms,
      toll: String(toll).replaceAll(".", "").split(",").join(""),
    };

    const length = numberIndex[numberIndex.length - 1] - 1;

    if (data.length === 0) {
      console.log(dataInput);
      setData([dataInput]);
    } else {
      const newData = [...data];
      newData[length] = dataInput;
      setData(newData);
    }
  };

  const getUpdateChange = () => {
    if (getInfo) {
      getInfo.origin_trade_route =
        originTradeRoute || getInfo.origin_trade_route;
      getInfo.uf = uf || getInfo.uf;
      getInfo.destination_trade_route =
        destinationTradeRoute || getInfo.destination_trade_route;
      getInfo.transit_time = transitTime || getInfo.transit_time;
      getInfo.up_to_10kg =
        String(upTo10kg).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
        getInfo.up_to_10kg;
      getInfo.up_to_20kg =
        String(upTo20kg).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
        getInfo.up_to_20kg;
      getInfo.up_to_30kg =
        String(upTo30kg).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
        getInfo.up_to_30kg;
      getInfo.up_to_50kg =
        String(upTo50kg).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
        getInfo.up_to_50kg;
      getInfo.up_to_70kg =
        String(upTo70kg).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
        getInfo.up_to_70kg;
      getInfo.up_to_100kg =
        String(upTo100kg).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
        getInfo.up_to_100kg;
      getInfo.up_to_150kg = upTo150kg || getInfo.up_to_150kg;
      getInfo.up_to_200kg = upTo200kg || getInfo.up_to_200kg;
      getInfo.up_to_250kg = upTo250kg || getInfo.up_to_250kg;
      getInfo.excess_kg =
        String(excessKg).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
        getInfo.excess_kg;
      getInfo.cte_dispatch = cteDispatch || getInfo.cte_dispatch;
      getInfo.sefaz_tax = sefazTax || getInfo.sefaz_tax;
      getInfo.shipping_value = shippingValue || getInfo.shipping_value;
      getInfo.gris = gris || getInfo.gris;
      getInfo.icms = icms || getInfo.icms;
      getInfo.toll =
        String(toll).split("R$")[1]?.replaceAll(".", "").split(",")[0] ||
        getInfo.toll;
    }
  };

  useEffect(() => {
    // console.log(data[index].uf);
  }, [data]);

  useEffect(() => {
    getUpdate();
  }, [getInfo]);

  useEffect(() => {
    if (!id) {
      getCreate();
    } else {
      getUpdateChange();
    }
  }, [
    originTradeRoute,
    uf,
    destinationTradeRoute,
    transitTime,
    upTo10kg,
    upTo20kg,
    upTo30kg,
    upTo50kg,
    upTo70kg,
    upTo100kg,
    upTo150kg,
    upTo200kg,
    upTo250kg,
    excessKg,
    cteDispatch,
    sefazTax,
    shippingValue,
    gris,
    icms,
    toll,
  ]);

  const boxInput = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    width: "100%",
    marginBottom: "10px",
  };

  return (
    <div style={boxInput}>
      <Input
        value={originTradeRoute}
        onChange={(e) => setOriginTradeRoute(e.target.value)}
        name="Rota comercial origem:"
        width="228px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={uf}
        onChange={(e) => setUf(e.target.value)}
        name="UF:"
        width="68px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={destinationTradeRoute}
        onChange={(e) => setDestinationTradeRoute(e.target.value)}
        name="Rota comercial Destino:"
        width="230px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={transitTime}
        onChange={(e) => setTransitTime(e.target.value)}
        name="Transit-time (dias uteis ):"
        width="172px"
        placeholder=""
        type={"number"}
        marginBottom="10px"
      />
      <Input
        onClick={() => convertValueInput(upTo10kg, setUpTo10kg)}
        onBlur={() => convertToBRL(upTo10kg, setUpTo10kg)}
        value={upTo10kg}
        onChange={(e) => setUpTo10kg(e.target.value)}
        name="Até 10kg:"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        onClick={() => convertValueInput(upTo20kg, setUpTo20kg)}
        onBlur={() => convertToBRL(upTo20kg, setUpTo20kg)}
        value={upTo20kg}
        onChange={(e) => setUpTo20kg(e.target.value)}
        name="Até 20kg:"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        onClick={() => convertValueInput(upTo30kg, setUpTo30kg)}
        onBlur={() => convertToBRL(upTo30kg, setUpTo30kg)}
        value={upTo30kg}
        onChange={(e) => setUpTo30kg(e.target.value)}
        name="Até 30kg:"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        onClick={() => convertValueInput(upTo50kg, setUpTo50kg)}
        onBlur={() => convertToBRL(upTo50kg, setUpTo50kg)}
        value={upTo50kg}
        onChange={(e) => setUpTo50kg(e.target.value)}
        name="Até 50kg:"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        onClick={() => convertValueInput(upTo70kg, setUpTo70kg)}
        onBlur={() => convertToBRL(upTo70kg, setUpTo70kg)}
        value={upTo70kg}
        onChange={(e) => setUpTo70kg(e.target.value)}
        name="Até 70kg:"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        onClick={() => convertValueInput(upTo100kg, setUpTo100kg)}
        onBlur={() => convertToBRL(upTo100kg, setUpTo100kg)}
        value={upTo100kg}
        onChange={(e) => setUpTo100kg(e.target.value)}
        name="Até 100kg:"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        onClick={() => convertValueInput(upTo150kg, setUpTo150kg)}
        onBlur={() => convertToBRL(upTo150kg, setUpTo150kg)}
        value={upTo150kg}
        onChange={(e) => setUpTo150kg(e.target.value)}
        name="Até 150kg:"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        onClick={() => convertValueInput(upTo200kg, setUpTo200kg)}
        onBlur={() => convertToBRL(upTo200kg, setUpTo200kg)}
        value={upTo200kg}
        onChange={(e) => setUpTo200kg(e.target.value)}
        name="Até 200kg:"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        onClick={() => convertValueInput(upTo250kg, setUpTo250kg)}
        onBlur={() => convertToBRL(upTo250kg, setUpTo250kg)}
        value={upTo250kg}
        onChange={(e) => setUpTo250kg(e.target.value)}
        name="Até 250kg:"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        onClick={() => convertValueInput(excessKg, setExcessKg)}
        onBlur={() => convertToBRL(excessKg, setExcessKg)}
        value={excessKg}
        onChange={(e) => setExcessKg(e.target.value)}
        name="KG Excedente:"
        width="95px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        onClick={() => convertValueInput(cteDispatch, setCteDispatch)}
        onBlur={() => convertToBRL(cteDispatch, setCteDispatch)}
        value={cteDispatch}
        onChange={(e) => setCteDispatch(e.target.value)}
        name="Despacho CTE:"
        width="140px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        onClick={() => convertValueInput(sefazTax, setSefazTax)}
        onBlur={() => convertToBRL(sefazTax, setSefazTax)}
        value={sefazTax}
        onChange={(e) => setSefazTax(e.target.value)}
        name="Taxa sefaz:"
        width="110px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={shippingValue}
        onChange={(e) => setShippingValue(e.target.value)}
        name="Frete:"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={gris}
        onChange={(e) => setGris(e.target.value)}
        name="GRIS %"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={icms}
        onChange={(e) => setIcms(e.target.value)}
        name="icms:"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        onClick={() => convertValueInput(toll, setToll)}
        onBlur={() => convertToBRL(toll, setToll)}
        value={toll}
        onChange={(e) => setToll(e.target.value)}
        name="Pedágio:"
        width="200px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
    </div>
  );
};

export default InputsNational;
