import React, { useContext, useState, useEffect } from "react";
import { ModalContext } from "../context/Modal";
import { UserContext } from "../context/User";
import api from "../services/api";
const FilterUsers = ({ setTypeAcess }) => {
  const { setStateModalGlobal, deleteItems } = useContext(ModalContext);

  const { typePermission } = useContext(UserContext);
  const [userName, setUserName] = useState();
  const [type, setType] = useState("client");

  const [visibleBtn, setVisibleBtn] = useState(true);

  const storage = () => {
    setStateModalGlobal(true);
  };

  const verifyVisible = () => {
    const options = {
      financial: {
        client: true,
        admin: false,
      },
      employee: {
        client: true,
        admin: false,
      },
      administrative: {
        client: true,
        admin: true,
      },
    };
    setVisibleBtn(options[typePermission || "employee"][type || "client"]);
  };

  const onChange = (e) => {
    setTypeAcess(e.target.value);
    setType(e.target.value);
  };

  useEffect(() => {
    verifyVisible();
  }, [typePermission, type]);

  const inputPrimary = {
    border: "1px solid #D7D7D7",
    width: "290px",
    height: "45px",
    borderRadius: "60px",
    outline: "none",
    paddingLeft: "50px",
  };
  const btnStyle = {
    border: "none",
    outline: "none",
    background: "#123164",
    borderRadius: "60px",
    width: "176px",
    height: "45px",
  };
  const btnStyleRemove = {
    border: "none",
    outline: "none",
    background: "#ffe1e1",
    borderRadius: "60px",
    width: "176px",
    height: "45px",
  };

  return (
    <div
      className="containerBox"
      style={{ maxWidth: "1079px", marginBottom: "43px" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "220px", display: "flex" }}>
          <div style={{ position: "relative" }}>
            <input
              onChange={(e) => setUserName(e.target.value)}
              className="textInputPrimaryFilter"
              placeholder="Pesquisar usuário"
              style={inputPrimary}
            />

            <div
              style={{
                position: "absolute",
                top: "7px",
                left: "13px",
              }}
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 0C13.968 0 18 4.032 18 9C18 13.968 13.968 18 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0ZM9 16C12.867 16 16 12.867 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16ZM17.485 16.071L20.314 18.899L18.899 20.314L16.071 17.485L17.485 16.071Z"
                  fill="#123164"
                />
              </svg>
            </div>
          </div>

          <div
            style={{
              borderRadius: "60px",
              padding: "0 20px",
              border: "1px solid #d7d7d7",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              marginLeft: "20px",
              backgroundColor: "#fff",
            }}
          >
            <p className="textSelectTypeAcess">Tipo de acesso:</p>
            <select
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "#fff",
                fontSize: "13px",
              }}
              onChange={onChange}
            >
              <option value="client">Clientes</option>
              <option value="admin">Administrativo</option>
            </select>
          </div>
        </div>

        {visibleBtn && (
          <button
            className="textBtnFilterManager"
            onClick={() => storage()}
            style={btnStyle}
          >
            Novo usuário
          </button>
        )}
      </div>
    </div>
  );
};

export default FilterUsers;
