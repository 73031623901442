// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.modalEdit {
  animation-name: modalEditAnimation;
  animation-duration: 0.8s;
}

@keyframes modalEditAnimation {
  0% {
    transform: translateY(100vh);
  }
}

.cardQuotationInformation {
  animation-name: cardQuotationInformationAnimation;
  animation-duration: 0.8s;
}

@keyframes cardQuotationInformationAnimation {
  0% {
    opacity: 0;
  }
}

.cardQuotationInformationRemove {
  animation-name: cardQuotationInformationAnimationRemove;
  animation-duration: 0.6s;
}

@keyframes cardQuotationInformationAnimationRemove {
  100% {
    opacity: 0;
  }
}

`, "",{"version":3,"sources":["webpack://./src/styles/animations.css"],"names":[],"mappings":";AACA;EACE,kCAAkC;EAClC,wBAAwB;AAC1B;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE,iDAAiD;EACjD,wBAAwB;AAC1B;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE,uDAAuD;EACvD,wBAAwB;AAC1B;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":["\n.modalEdit {\n  animation-name: modalEditAnimation;\n  animation-duration: 0.8s;\n}\n\n@keyframes modalEditAnimation {\n  0% {\n    transform: translateY(100vh);\n  }\n}\n\n.cardQuotationInformation {\n  animation-name: cardQuotationInformationAnimation;\n  animation-duration: 0.8s;\n}\n\n@keyframes cardQuotationInformationAnimation {\n  0% {\n    opacity: 0;\n  }\n}\n\n.cardQuotationInformationRemove {\n  animation-name: cardQuotationInformationAnimationRemove;\n  animation-duration: 0.6s;\n}\n\n@keyframes cardQuotationInformationAnimationRemove {\n  100% {\n    opacity: 0;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
