import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";

export const AuthContext = createContext({});

export const AuthValidation = ({ children }) => {
  const [check, setCheck] = useState(false);
  const [email, setEmail] = useState("email@mail.com");
  const navigate = useNavigate();

  const onSubmitFormLogin = async (data) => {
    try {
      const response = await api.post("/login", data);

      const { token } = response.data;
      localStorage.setItem("@Startpn:", token);

      navigate("/dashboard", { replace: true });
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const checkTerms = () => {
    check ? setCheck(false) : setCheck(true);
  };

  const onSubmitFormRegister = (data) => {
    const { password, confirm_password } = data;

    if (password !== confirm_password) {
      toast.error("Senhas diferentes!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!check) {
      toast.error("Aceite os termos para continuar", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      api.post("/user", data)
        .then((res) => {
          res.data &&
            toast.success("Conta criada com sucesso", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          setTimeout(() => {
            navigate("/login");
          }, 1500);
        })
        .catch((err) => {
          const message = err.response.data.message;
          err &&
            toast.error(`${message}`, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        });
    }
  };

  const onSubmitFormRecovery = async (data) => {
    try {
      await api.post("/send", data);
      setEmail(data.email);

      navigate("/sending");
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onSubmitFormRecoverySend = async (data) => {
    try {
      await api.post("/send", { email: email });

      toast.success("Email enviado", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onSubmitFormResetPassword = async (data) => {
    const { new_password, confirm_password, idParam } = data;
    if (new_password !== confirm_password) {
      toast.error("Senhas diferentes!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      try {
        await api.patch(`/user/${idParam}`, { password: new_password });
        toast.success("Senhas atualizada", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigate("/login");
        }, 1500);
      } catch (err) {
        const message = err.response.data.message;
        toast.error(`${message}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <>
      <AuthContext.Provider
        value={{
          onSubmitFormLogin,
          onSubmitFormRegister,
          onSubmitFormRecovery,
          onSubmitFormRecoverySend,
          onSubmitFormResetPassword,
          checkTerms,
          check,
          email,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};
