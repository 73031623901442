import Input from "./Input"
import trashIcon from "../images/svg/trashIcon.svg"
import { useState } from "react"
import { toast } from "react-toastify"

const ContentBlockModal = ({
    block,
    id,
    number,
    create,
    update,
    destroy,
    destroyVirtual,
    trash
}) => {
    const [blockName, setBlockName] = useState(block)

    const createOrUpdateBlock = async () => {
        if (!trash && !id) {
            try {
                const response = await create(blockName)

            } catch (error) {

                toast.error(error.response.data.error, {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

            }
        }

        if(number == 1 && id){
            try {
                const response = await update(blockName, id)

            } catch (error) {

                toast.error(error.response.data.error, {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

            }
        }

        if(number > 1 && id){
            try {
                const response = await update(blockName, id)

            } catch (error) {

                toast.error(error.response.data.error, {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

            }
        }

        if(number > 1 && !id){
            try {
                const response = await create(blockName)

            } catch (error) {

                toast.error(error.response.data.error, {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

            }
        }
    }

    const destroyBlock = async() => {

        if(!id){
            destroyVirtual()
        }

        if(id){
            destroy(id)
        }
        
    } 

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "21px",
            width: "100%"
        }}>
            <p style={{
                backgroundColor: "var(--color-primary)",
                color: "#fff",
                width: "34px",
                height: "34px",
                borderRadius: "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "21px"
            }}>{number}</p>

            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "14px"
            }}>
                <Input value={blockName} onChange={(e) => setBlockName(e.target.value)} onBlur={createOrUpdateBlock} height={"45px"} name="Bloqueio" width={number == 1 ? "265px" : "100%"} placeholder="Insira o cnae" type={"text"} />

                {trash && number > 1 && <img style={{ cursor: "pointer" }} onClick={destroyBlock} src={trashIcon} />}
            </div>
        </div>
    )
}

export default ContentBlockModal