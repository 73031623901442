import { useEffect, useState } from "react";
import Input from "./Input";

const InputsInternational = ({
  setData,
  data,
  numberIndex,
  update,
  length,
}) => {
  //console.log(data[numberIndex])

  /* INTERNATIONAL STATES*/
  const [initialWeightRange, setInitialWeightRange] = useState("");
  const [finalWeightRange, setFinalWeightRange] = useState("");
  const [initialCubing, setInitialCubing] = useState("");

  const [finalCubing, setFinalCubing] = useState("");
  const [standardTransitTime, setStandardTransitTime] = useState("");
  const [premiumTransitTime, setPremiumTransitTime] = useState("");

  const [adminFeePerCrt, setAdminFeePerCrt] = useState("");
  const [senasaInspectionFeePerCrt, setSenasaInspectionFeePerCrt] =
    useState("");
  const [consolidationFeePerPallete, setConsolidationFeePerPallete] =
    useState("");

  const [grisFcaUsd, setGrisFcaUsd] = useState("");
  const [adValoremFcaUsd, setAdValoremFcaUsd] = useState("");
  const [adValoremGrisEadiFca, setAdValoremGrisEadiFca] = useState("");

  const [eadiFee, setEadiFee] = useState("");
  const [pickUpUpTo50kmSp, setPickUpUpTo50kmSp] = useState("");
  const [pickUp51To100kmSp, setPickUp51To100kmSp] = useState("");

  const [pickUp101To150kmSp, setPickUp101To150kmSp] = useState("");
  const [pickUp151To200kmSp, setPickUp151To200kmSp] = useState("");
  const [deliveryUpTo30kmBsAires, setDeliveryUpTo30kmBsAires] = useState("");

  const [delivery31To60kmBsAires, setDelivery31To60kmBsAires] = useState("");
  const [delivery61To90kmBsAires, setDelivery61To90kmBsAires] = useState("");
  const [
    additionalInsurancePercentageArgentina,
    setAdditionalInsurancePercentageArgentina,
  ] = useState("");

  const [exwFees, setExwFees] = useState("");
  const [customsFeesArgentina, setCustomsFeesArgentina] = useState("");
  const [argentinaClearanceFees, setArgentinaClearanceFees] = useState("");

  const [dueClearanceRequired, setDueClearanceRequired] = useState("");
  const [coCertificateOfOriginRequired, setCoCertificateOfOriginRequired] =
    useState("");
  const [
    additionalCoCertificateOfOriginRequired,
    setAdditionalCoCertificateOfOriginRequired,
  ] = useState("");

  const [helperFee, setHelperFee] = useState("");
  const [extraCost, setExtraCost] = useState("");
  const [hazardousCargo, setHazardousCargo] = useState("");

  const [stackingFactor, setStackingFactor] = useState("");
  const [bankExchangeRate, setBankExchangeRate] = useState("");
  const [finalFreightValueUsd, setFinalFreightValueUsd] = useState("");

  const [renderAll, setRenderAll] = useState(false);

  const boxInput = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    width: "100%",
    marginBottom: "10px",
  };

  useEffect(() => {
    const handleValue = (value, length, percent) => {
      if (!isNaN(value) && percent) {
        return parseFloat(value * 100).toFixed(length || 2);
      } else if (!isNaN(value) && !percent) {
        return parseFloat(value).toFixed(length || 2);
      }
      return value;
    };

    if (data[numberIndex]) {
      if (numberIndex) {
        setInitialWeightRange(data[numberIndex].initial_weight_range);
        setFinalWeightRange(data[numberIndex].final_weight_range);
        setInitialCubing(handleValue(data[numberIndex].initial_cubing, 2));
        setFinalCubing(handleValue(data[numberIndex].final_cubing, 3));
        setStandardTransitTime(
          handleValue(data[numberIndex].standard_transit_time)
        );
        setPremiumTransitTime(
          handleValue(data[numberIndex].premium_transit_time)
        );
        setAdminFeePerCrt(handleValue(data[numberIndex].admin_fee_per_crt));
        setSenasaInspectionFeePerCrt(
          handleValue(data[numberIndex].senasa_inspection_fee_per_crt)
        );
        setConsolidationFeePerPallete(
          handleValue(data[numberIndex].consolidation_fee_per_pallete)
        );
        setGrisFcaUsd(handleValue(data[numberIndex].gris_fca_usd, 2, true));
        setAdValoremFcaUsd(
          handleValue(data[numberIndex].ad_valorem_fca_usd, 2, true)
        );
        setAdValoremGrisEadiFca(
          handleValue(data[numberIndex].ad_valorem_gris_eadi_fca, 2, true)
        );
        setEadiFee(handleValue(data[numberIndex].eadi_fee));
        setPickUpUpTo50kmSp(
          handleValue(data[numberIndex].pick_up_up_to_50km_sp)
        );
        setPickUp51To100kmSp(
          handleValue(data[numberIndex].pick_up_51_to_100km_sp)
        );
        setPickUp101To150kmSp(
          handleValue(data[numberIndex].pick_up_101_to_150km_sp)
        );
        setPickUp151To200kmSp(
          handleValue(data[numberIndex].pick_up_151_to_200km_sp)
        );
        setDeliveryUpTo30kmBsAires(
          handleValue(data[numberIndex].delivery_up_to_30km_bs_aires)
        );
        setDelivery31To60kmBsAires(
          handleValue(data[numberIndex].delivery_31_to_60km_bs_aires)
        );
        setDelivery61To90kmBsAires(
          handleValue(data[numberIndex].delivery_61_to_90km_bs_aires)
        );
        setAdditionalInsurancePercentageArgentina(
          handleValue(
            data[numberIndex].additional_insurance_percentage_argentina,
            2,
            true
          )
        );
        setExwFees(handleValue(data[numberIndex].exw_fees));
        setCustomsFeesArgentina(
          handleValue(data[numberIndex].customs_fees_argentina)
        );
        setArgentinaClearanceFees(
          handleValue(data[numberIndex].argentina_clearance_fees)
        );
        setDueClearanceRequired(
          handleValue(data[numberIndex].due_clearance_required)
        );
        setCoCertificateOfOriginRequired(
          handleValue(data[numberIndex].co_certificate_of_origin_required)
        );
        setAdditionalCoCertificateOfOriginRequired(
          handleValue(
            data[numberIndex].additional_co_certificate_of_origin_required
          )
        );

        setHelperFee(handleValue(data[numberIndex].helper_fee));
        setExtraCost(handleValue(data[numberIndex].extra_cost));
        setHazardousCargo(handleValue(data[numberIndex].hazardous_cargo));
        setStackingFactor(handleValue(data[numberIndex].stacking_factor));
        setBankExchangeRate(handleValue(data[numberIndex].bank_exchange_rate));
        setFinalFreightValueUsd(
          handleValue(data[numberIndex].final_freight_value_usd)
        );
      }
      if (numberIndex == 0) {
        setInitialWeightRange(data[0].initial_weight_range);
        setFinalWeightRange(data[0].final_weight_range);
        setInitialCubing(handleValue(data[0].initial_cubing, 2));
        setFinalCubing(handleValue(data[0].final_cubing, 3));
        setStandardTransitTime(handleValue(data[0].standard_transit_time));
        setPremiumTransitTime(handleValue(data[0].premium_transit_time));
        setAdminFeePerCrt(handleValue(data[0].admin_fee_per_crt));
        setSenasaInspectionFeePerCrt(
          handleValue(data[0].senasa_inspection_fee_per_crt)
        );
        setConsolidationFeePerPallete(
          handleValue(data[0].consolidation_fee_per_pallete)
        );
        setGrisFcaUsd(handleValue(data[0].gris_fca_usd, 2, true));
        setAdValoremFcaUsd(handleValue(data[0].ad_valorem_fca_usd, 2, true));
        setAdValoremGrisEadiFca(
          handleValue(data[0].ad_valorem_gris_eadi_fca, 2, true)
        );
        setEadiFee(handleValue(data[0].eadi_fee));
        setPickUpUpTo50kmSp(handleValue(data[0].pick_up_up_to_50km_sp));
        setPickUp51To100kmSp(handleValue(data[0].pick_up_51_to_100km_sp));
        setPickUp101To150kmSp(handleValue(data[0].pick_up_101_to_150km_sp));
        setPickUp151To200kmSp(handleValue(data[0].pick_up_151_to_200km_sp));
        setDeliveryUpTo30kmBsAires(
          handleValue(data[0].delivery_up_to_30km_bs_aires)
        );
        setDelivery31To60kmBsAires(
          handleValue(data[0].delivery_31_to_60km_bs_aires)
        );
        setDelivery61To90kmBsAires(
          handleValue(data[0].delivery_61_to_90km_bs_aires)
        );
        setAdditionalInsurancePercentageArgentina(
          handleValue(
            data[0].additional_insurance_percentage_argentina,
            2,
            true
          )
        );
        setExwFees(handleValue(data[0].exw_fees));
        setCustomsFeesArgentina(handleValue(data[0].customs_fees_argentina));
        setArgentinaClearanceFees(
          handleValue(data[0].argentina_clearance_fees)
        );
        setDueClearanceRequired(handleValue(data[0].due_clearance_required));
        setCoCertificateOfOriginRequired(
          handleValue(data[0].co_certificate_of_origin_required)
        );
        setAdditionalCoCertificateOfOriginRequired(
          handleValue(data[0].additional_co_certificate_of_origin_required)
        );

        setHelperFee(handleValue(data[0].helper_fee));
        setExtraCost(handleValue(data[0].extra_cost));
        setHazardousCargo(handleValue(data[0].hazardous_cargo));
        setStackingFactor(handleValue(data[0].stacking_factor));
        setBankExchangeRate(handleValue(data[0].bank_exchange_rate));
        setFinalFreightValueUsd(handleValue(data[0].final_freight_value_usd));
      }
    } else if (data) {
    }

    setTimeout(() => {
      setRenderAll(true);
    }, 2000);
  }, [data]);

  useEffect(() => {
    if (update && renderAll == true) {
      let dataUpdate = data;

      dataUpdate[numberIndex] = {
        initial_weight_range: initialWeightRange,
        final_weight_range: finalWeightRange,
        initial_cubing: initialCubing,
        final_cubing: finalCubing,
        standard_transit_time: standardTransitTime,
        premium_transit_time: premiumTransitTime,
        admin_fee_per_crt: adminFeePerCrt,
        senasa_inspection_fee_per_crt: senasaInspectionFeePerCrt,
        consolidation_fee_per_pallete: consolidationFeePerPallete,
        gris_fca_usd: grisFcaUsd,
        ad_valorem_fca_usd: adValoremFcaUsd,
        ad_valorem_gris_eadi_fca: adValoremGrisEadiFca,
        eadi_fee: eadiFee,
        pick_up_up_to_50km_sp: pickUpUpTo50kmSp,
        pick_up_51_to_100km_sp: pickUp51To100kmSp,
        pick_up_101_to_150km_sp: pickUp101To150kmSp,
        pick_up_151_to_200km_sp: pickUp151To200kmSp,
        delivery_up_to_30km_bs_aires: deliveryUpTo30kmBsAires,
        delivery_31_to_60km_bs_aires: delivery31To60kmBsAires,
        delivery_61_to_90km_bs_aires: delivery61To90kmBsAires,
        additional_insurance_percentage_argentina:
          additionalInsurancePercentageArgentina,
        exw_fees: exwFees,
        customs_fees_argentina: customsFeesArgentina,
        argentina_clearance_fees: argentinaClearanceFees,
        due_clearance_required: dueClearanceRequired,
        co_certificate_of_origin_required: coCertificateOfOriginRequired,
        additional_co_certificate_of_origin_required:
          additionalCoCertificateOfOriginRequired,
        helper_fee: helperFee,
        extra_cost: extraCost,
        hazardous_cargo: hazardousCargo,
        stacking_factor: stackingFactor,
        bank_exchange_rate: bankExchangeRate,
        final_freight_value_usd: finalFreightValueUsd,
      };

      setData(dataUpdate);
    }
  }, [
    initialWeightRange,
    finalWeightRange,
    initialCubing,
    finalCubing,
    standardTransitTime,
    premiumTransitTime,
    adminFeePerCrt,
    senasaInspectionFeePerCrt,
    consolidationFeePerPallete,
    grisFcaUsd,
    adValoremFcaUsd,
    adValoremGrisEadiFca,
    eadiFee,
    pickUpUpTo50kmSp,
    pickUp51To100kmSp,
    pickUp101To150kmSp,
    pickUp151To200kmSp,
    deliveryUpTo30kmBsAires,
    delivery31To60kmBsAires,
    delivery61To90kmBsAires,
    additionalInsurancePercentageArgentina,
    exwFees,
    customsFeesArgentina,
    argentinaClearanceFees,
    dueClearanceRequired,
    coCertificateOfOriginRequired,
    additionalCoCertificateOfOriginRequired,
    helperFee,
    extraCost,
    hazardousCargo,
    stackingFactor,
    bankExchangeRate,
    finalFreightValueUsd,
  ]);

  return (
    <div style={boxInput}>
      <Input
        value={initialWeightRange}
        onChange={(e) => setInitialWeightRange(e.target.value)}
        name="Faixas de Peso Inicial ( kg )"
        width="228px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={finalWeightRange}
        onChange={(e) => setFinalWeightRange(e.target.value)}
        name="Faixas de Peso Final ( kg )"
        width="228px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={initialCubing}
        onChange={(e) => setInitialCubing(e.target.value)}
        name="Cubagem Inicial 1m³"
        width="228px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={finalCubing}
        onChange={(e) => setFinalCubing(e.target.value)}
        name="Cubagem Final 1m³"
        width="228px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={standardTransitTime}
        onChange={(e) => setStandardTransitTime(e.target.value)}
        name="Frete LTL  STANDARD Transit Time"
        width="228px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={premiumTransitTime}
        onChange={(e) => setPremiumTransitTime(e.target.value)}
        name="Frete LTL  PREMMIUN Transit Time"
        width="228px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={adminFeePerCrt}
        onChange={(e) => setAdminFeePerCrt(e.target.value)}
        name="Taxa ADM por CRT"
        width="150px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={senasaInspectionFeePerCrt}
        onChange={(e) => setSenasaInspectionFeePerCrt(e.target.value)}
        name="TX Inspeção SENASA por CRT"
        width="200px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={consolidationFeePerPallete}
        onChange={(e) => setConsolidationFeePerPallete(e.target.value)}
        name="TX Consolidação por Pallete ou Solta"
        width="248px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={grisFcaUsd}
        onChange={(e) => setGrisFcaUsd(e.target.value)}
        name="GRIS FCA-USD"
        width="100px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={adValoremFcaUsd}
        onChange={(e) => setAdValoremFcaUsd(e.target.value)}
        name="Ad valorem FCA - USD"
        width="228px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={adValoremGrisEadiFca}
        onChange={(e) => setAdValoremGrisEadiFca(e.target.value)}
        name="Ad valorem GRIS EADI - FCA"
        width="200px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={eadiFee}
        onChange={(e) => setEadiFee(e.target.value)}
        name="TX EADI"
        width="80px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={pickUpUpTo50kmSp}
        onChange={(e) => setPickUpUpTo50kmSp(e.target.value)}
        name="PICK UP SP Até 50KM"
        width="139px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={pickUp51To100kmSp}
        onChange={(e) => setPickUp51To100kmSp(e.target.value)}
        name="PICK UP SP 51 a 100 km"
        width="155px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={pickUp101To150kmSp}
        onChange={(e) => setPickUp101To150kmSp(e.target.value)}
        name="PICK UP SP 101 a 150 km"
        width="160px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={pickUp151To200kmSp}
        onChange={(e) => setPickUp151To200kmSp(e.target.value)}
        name="PICK UP SP 151 a 200 km"
        width="165px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={deliveryUpTo30kmBsAires}
        onChange={(e) => setDeliveryUpTo30kmBsAires(e.target.value)}
        name="DELIVERY Bs Aires Até 30KM"
        width="180px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={delivery31To60kmBsAires}
        onChange={(e) => setDelivery31To60kmBsAires(e.target.value)}
        name="DELIVERY Bs Aires 31 a 60km"
        width="180px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={delivery61To90kmBsAires}
        onChange={(e) => setDelivery61To90kmBsAires(e.target.value)}
        name="DELIVERY Bs Aires 61 a 90km"
        width="180px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={additionalInsurancePercentageArgentina}
        onChange={(e) =>
          setAdditionalInsurancePercentageArgentina(e.target.value)
        }
        name="adicional seguro % delivery Argentina"
        width="265px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={exwFees}
        onChange={(e) => setExwFees(e.target.value)}
        name="Taxas EXW"
        width="80px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={customsFeesArgentina}
        onChange={(e) => setCustomsFeesArgentina(e.target.value)}
        name="Taxas Aduana_Arg"
        width="150px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={argentinaClearanceFees}
        onChange={(e) => setArgentinaClearanceFees(e.target.value)}
        name="Taxas desembaraço Argentina"
        width="228px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={dueClearanceRequired}
        onChange={(e) => setDueClearanceRequired(e.target.value)}
        name="Precisa de Emissão DUE-Desembaraço ? Sim ou Não"
        width="348px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={coCertificateOfOriginRequired}
        onChange={(e) => setCoCertificateOfOriginRequired(e.target.value)}
        name="Precisa de Emissão CO_certif origem ? Sim ou Não"
        width="348px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={additionalCoCertificateOfOriginRequired}
        onChange={(e) =>
          setAdditionalCoCertificateOfOriginRequired(e.target.value)
        }
        name="Precisa de Emissão CO_certif origem adicional ? Sim ou Não"
        width="400px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={helperFee}
        onChange={(e) => setHelperFee(e.target.value)}
        name="Taxa Por ajudante"
        width="130px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={extraCost}
        onChange={(e) => setExtraCost(e.target.value)}
        name="Custo Extra"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={hazardousCargo}
        onChange={(e) => setHazardousCargo(e.target.value)}
        name="CARGA IMO"
        width="90px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={stackingFactor}
        onChange={(e) => setStackingFactor(e.target.value)}
        name="fator empilhamento _ SIM OU NÃO"
        width="228px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={bankExchangeRate}
        onChange={(e) => setBankExchangeRate(e.target.value)}
        name="Dólar BANCEN"
        width="100px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
      <Input
        value={finalFreightValueUsd}
        onChange={(e) => setFinalFreightValueUsd(e.target.value)}
        name="Valor frete em dólar USD _final"
        width="218px"
        placeholder=""
        type={"text"}
        marginBottom="10px"
      />
    </div>
  );
};

export default InputsInternational;
