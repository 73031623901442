import { useEffect } from "react";

const ModalOptionsFilterCitys = ({ list, onClick }) => {
  useEffect(() => {
    console.log(list);
  }, [list]);
  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: "#fff",
        zIndex: "1",
        top: "84px",
        border: "1px solid #d7d7d7",
        width: "300px",
        borderRadius: "8px",
        marginTop: "2px",
        maxHeight: "200px",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        overflowY: "auto",
        padding: "17px 16px",
      }}
    >
      {list?.map((item) => {
        return (
          <p onClick={() => onClick(item.nome)} style={{ cursor: "pointer" }}>
            {item.nome}
          </p>
        );
      })}
    </div>
    // <div
    //   style={{
    //     position: "absolute",
    //     border: "1px solid #d7d7d7",
    //     width: "300px",
    //     height: "50px",
    //     bottom: "-22px",
    //     backgroundColor: "#fff",
    //     overflow: "hidden",
    //   }}
    // >
    //   {/* {list?.map((item) => {
    //     return <p>{item}</p>;
    //   })} */}
    //   <p></p>
    // </div>
  );
};

export default ModalOptionsFilterCitys;
