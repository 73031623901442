const ScrollBotton = ({ width, typeRoute }) => {
  /* STYLES */
  const scrollContainerStyle = {
    position: "fixed",
    width: "990px",
    padding: "10px",
    paddingLeft: "10px",
    backgroundColor: "#fff",
    bottom: "30px",
    zIndex: 2,
    border: "1px solid #d7d7d7",
    borderBottom: "1px solid transparent",
    borderRadius: "10px",
    overflowX: "scroll",
  };

  const fillerTextStyle = {
    width:
      (typeRoute === "Rodoviário dedicado" && "2000px") ||
      (typeRoute === "Rodoviário internacional LTL" && "7200px") ||
      (typeRoute === "Rodoviário internacional FTL" && "3040px") ||
      width,
  };

  const bottomContainerStyle = {
    position: "fixed",
    width: "990px",
    padding: "20px",
    backgroundColor: "#fff",
    bottom: "15px",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    boxShadow: "0px 2px 24px -9px",
    zIndex: 1,
  };

  /* RENDER */
  return (
    <div className="boxContainerCard" style={{ margin: "0 auto" }}>
      <div id="test" className="scrollLowerUp" style={scrollContainerStyle}>
        <p style={fillerTextStyle}></p>
      </div>
      <div style={bottomContainerStyle}></div>
    </div>
  );
};

export default ScrollBotton;
