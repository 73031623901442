import React from 'react'

const CardQuotation = ({ title, description, check, setCheck, onHandleCardChange,width}) => {

    const container = {
        minWidth: "301px",
        background: "#FFFFFF",
        border: `1px solid ${check ? "#062F67" : "#D7D7D7"}`,
        borderRadius: "10px 0px",
        padding: "20px",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        width: width ? width :  "100%"
    }
    return (

        <div style={container} onClick={() => onHandleCardChange(setCheck)}>
            <div
                style={{
                    width: "100%"
                }}
            >
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <h3 className='textTitleCardQuotation'>{title}</h3>
                    <div>
                        {
                            check ?

                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12.0938" r="11.5" fill="#062F67" stroke="#062F67" />
                                    <path d="M17.3344 8.09338L10.0011 15.4267L6.66772 12.0934" fill="#062F67" />
                                    <path d="M17.3344 8.09338L10.0011 15.4267L6.66772 12.0934" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                :

                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12.0938" r="11.5" fill="white" stroke="#062F67" />
                                    <path d="M17.3344 8.09338L10.0011 15.4267L6.66772 12.0934" fill="white" />
                                    <path d="M17.3344 8.09338L10.0011 15.4267L6.66772 12.0934" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>


                        }
                    </div>
                </div>
            </div>

            <div>
                <p className='textDescriptionCardQuotation'>{description}</p>
            </div>
        </div >
    )

}

export default CardQuotation