import { useContext, useEffect, useState } from "react"
import { DashboardContext } from "../context/Dashboard/DashboardContext"
import api from "../services/api"
import numeral from "numeral"
import moment from "moment/moment"

const SectionSelectQuotationSummary = ({
    nationalRoad,
    setNationalRoad,
    internationalRoad,
    setInternationalRoad,
    storage,
    setStorage,
    airTransport,
    setAirTransport,
    maritimeTransport,
    setMaritimeTransport,

    checkFractional,
    setCheckFractional,
    checkNational,
    setCheckNational,
    checkDedicated,
    setCheckDedicated,

    checkStandardInternational,
    setCheckStandardInternational,
    checkPremiumInternational,
    setCheckPremiumInternational,
    checkDedicatedInternational,
    setCheckDedicatedInternational,

    checkNationalAir,
    setCheckNationalAir,
    checkInternationalAir,
    setCheckInternationalAir,

    cubageCheckFraction,
    resumeSum

}) => {
    const {
        update,
        selectInputDestination,

        /* CHECK FRACTIONAL */
        cubageTotal,
        setCubageTotal,
        grossWeightTotal,
        setGrossWeightTotal,
        tollTotal,
        setTollTotal,
        transitTimeTotal,
        setTransitTimeTotal,
        freigthTotal,
        setFreigthTotal,
        dispatchTotal,
        setDispatchTotal,
        sefazTotal,
        setSefazTotal,
        sumTotalUser,
        setSumTotalUser,

        dataFractionDedicated,
        dataPriceSummary
    } = useContext(DashboardContext)
    const [grisTotal, setGrisTotal] = useState(0)
    const [shippingValueTotal, setShippingValueTotal] = useState(0)

    const [freightDedicated, setFreightDedicated] = useState(0)
    const [transitTimeDedicated, setTransitTimeDedicated] = useState(0)
    const [tollDedicated, setTollDedicated] = useState(0)

    const [cubageTotalDedicated, setCubageTotalDedicated] = useState(0)


    const containerContent = {
        backgroundColor: "#fff",
        maxWidth: "1000px",
        width: "1000px",
        padding: "32px",
        border: "1px solid #D7D7D7",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "20px"
    }
    /*100 - 12 = 0.88 / 2060*/
    const getPriceIcms = async () => {
        let totalPriceIcms = 0

        resumeSum.map(item => {

            if(item.grisTotal){

                
                console.log(item.icms)
            }
            
        })
    }

    const getShippingValue = async () => {
        let totalShippingValue = 0

        resumeSum.map(item => {

            if (item.grisTotal) {
                console.log(item.shippingValue)

                let valuePriceProduct = Number(item?.grisTotal?.replace("R$", "").replaceAll(".", "").split(",")[0])
                let shippingValue = Number(item.shippingValue)

                console.log(shippingValue)
                let result = valuePriceProduct + (valuePriceProduct * (shippingValue / 100))

                totalShippingValue = totalShippingValue + result - valuePriceProduct
            }

        })

        setShippingValueTotal(totalShippingValue)
    }

    const getGris = async () => {
        let totalGris = 0

        resumeSum.map(item => {

            if (item.grisTotal) {
                console.log(item)

                let valuePriceProduct = Number(item?.grisTotal?.replace("R$", "").replaceAll(".", "").split(",")[0])
                let gris = Number(item.gris)
                console.log(gris)
                let result = valuePriceProduct + (valuePriceProduct * (gris / 100))

                totalGris = totalGris + result - valuePriceProduct

                console.log(item.grisTotal)
            }

        })

        setGrisTotal(totalGris)
    }

    const getFreigth = async () => {
        let totalFreight = 0


        await resumeSum.map(async item => {


            if (item.id) {

                const getFreightPrice = await api.post(`/quotation/freight/${item.id}`, {
                    peso: item.cubageWeight > Number(item.grossWeight.replace(/,/g, ".")) ? item.cubageWeight : Number(item.grossWeight.replace(/,/g, "."))
                })

                item.freigth = getFreightPrice.data.result
                totalFreight = getFreightPrice.data.result

                setFreigthTotal(totalFreight)
            }
        })

        /*const getFreightPrice = await api.post(`/quotation/freight/`, {
            peso: Number(cubageTotal.toFixed(2)) > grossWeightTotal ? Number(cubageTotal.toFixed(2)) : grossWeightTotal
        })
        totalFreight = Number(getFreightPrice.data.result)
        
        console.log(totalFreight)*/

    }

    const getToll = async () => {
        let totalToll = 0

        resumeSum.map(item => {
            if (grossWeightTotal > item.cubageWeight) {
                totalToll = totalToll + grossWeightTotal / 100 * Number(item.toll)
                item.tollReq = totalToll
            } else if (grossWeightTotal < item.cubageWeight) {
                totalToll = totalToll + item.cubageWeight / 100 * Number(item.toll)
                item.tollReq = totalToll
            }
        })

        setTollTotal(totalToll)
    }

    const getDispatch = async () => {
        let totalDispatch = 0

        resumeSum.map(item => {
            if (item.dispatch) {
                totalDispatch = totalDispatch + Number(item.dispatch)
            }
        })

        setDispatchTotal(totalDispatch)
    }

    const getSefaz = async () => {
        let totalSefaz = 0

        resumeSum.map(item => {
            if (item.sefaz) {
                totalSefaz = totalSefaz + Number(item.sefaz)
            }
        })

        setSefazTotal(totalSefaz)
    }

    const getTransitTime = async () => {
        let totalTransitTime = 0

        resumeSum.map(item => {
            if (item.sefaz) {
                totalTransitTime = totalTransitTime + Number(item.transitTime)
            }
        })

        setTransitTimeTotal(totalTransitTime)
    }

    const getTotalValue = async () => {
        const total = tollTotal + freigthTotal + dispatchTotal + sefazTotal

        setSumTotalUser(total)
    }

    const parseDuration = (duration) => {
        const dayRegex = /(\d+)\s*dia[s]?/i;
        const hourRegex = /(\d+)\s*hora[s]?/i;
        const dayMatch = duration?.match(dayRegex);
        const hourMatch = duration?.match(hourRegex);

        const days = dayMatch ? parseInt(dayMatch[1], 10) : 0;
        const hours = hourMatch ? parseInt(hourMatch[1], 10) : 0;

        return { days, hours };
    }

    const getTotalDedicated = () => {
        let tollTotal = 0;
        let freightTotal = 0;
        let transitTimeTotal = [];
        let cubageTotal = 0;

        if (dataFractionDedicated.length > 1) {
            dataFractionDedicated.map(item => {
                setTollDedicated(tollTotal.toFixed(2));
                console.log(item.transitTime);
                tollTotal = tollTotal + item.toll;
                freightTotal = freightTotal + item.freight;
                transitTimeTotal.push(item.transitTime);
                console.log(item)
                cubageTotal = cubageTotal + item?.cubageTotal
            });

            const total = transitTimeTotal.reduce((accumulator, currentValue) => {
                const parsedDuration = parseDuration(currentValue);
                if (parsedDuration) {
                    accumulator.days += parsedDuration.days;
                    accumulator.hours += parsedDuration.hours;
                }
                return accumulator;
            }, { days: 0, hours: 0 });

            // Ajuste para arredondar a quantidade de horas se for maior ou igual a 24
            if (total.hours >= 24) {
                total.days += Math.floor(total.hours / 24);
                total.hours %= 24;
            }

            // Ajuste para casos em que a soma é menor do que 1 dia
            if (total.days === 0 && total.hours === 0) {
                total.hours = Math.round(total.hours); // Arredonda para o número inteiro mais próximo
            }

            setTransitTimeDedicated(`${total.days != undefined ? total.days : ""} ${total.days != undefined ? "dia" : ""}${total.days != undefined ? total.days !== 1 ? 's' : '' : ''} ${total.days != undefined && total.hours > 0 ? "e" : ""} ${total.hours} hora${total.hours !== 1 ? 's' : ''}`);

            setTollDedicated(tollTotal);
            setFreightDedicated(freightTotal);
            setCubageTotalDedicated(cubageTotal)
        }
    }

    useEffect(() => {
        getTotalDedicated()
        getGris()
        getShippingValue()
    }, [dataFractionDedicated, update])

    useEffect(() => {
        getTotalValue()
    }, [
        tollTotal,
        freigthTotal,
        dispatchTotal,
        sefazTotal
    ])

    useEffect(() => {
        getFreigth()
        getToll()
        getDispatch()
        getSefaz()
        getTransitTime()
        getPriceIcms()
    }, [cubageTotal, grossWeightTotal])


    useEffect(() => {
        let totalCubage = 0
        let totalGrossWeight = 0
        let cubageWeight = 0



        resumeSum.map(item => {

            totalCubage = totalCubage + item.cubageTotal
            totalGrossWeight = totalGrossWeight + Number(item.grossWeight?.replace(".", ""))
            cubageWeight = cubageWeight + item.cubageWeight


        })

        setGrossWeightTotal(totalGrossWeight)

        setCubageTotal(Number(totalCubage))

    }, [update, selectInputDestination])

    useEffect(() => {
    }, [dataPriceSummary])
    return (
        <div style={containerContent}>
            <div>
                <h3 className="textTitleTypeDispatch">Resumo total cotação</h3>
            </div>

            {

                checkFractional && (
                    <>
                        <div style={{
                            display: "flex",
                        }}>
                            <div style={{ display: "flex", width: "150px" }}>
                                <div>
                                    <p className="textTitleSummary">Despacho CTE</p>
                                    <div>
                                        {
                                            dispatchTotal > 0 ?
                                                dispatchTotal.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })
                                                :

                                                <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                                </svg>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "250px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Taxa Sefaz</p>
                                    <div>
                                        {
                                            sefazTotal > 0 ?
                                                sefazTotal.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })
                                                :
                                                < svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                                </svg>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Frete Peso</p>
                                    <div>
                                        {
                                            freigthTotal > 0 ?

                                                freigthTotal.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })
                                                :
                                                <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                                </svg>
                                        }

                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "202px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Ad valorem</p>
                                    <div>
                                        {
                                            shippingValueTotal > 0 ?

                                            shippingValueTotal.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })
                                                :

                                                <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                                </svg>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">GRIS</p>
                                    <div>
                                        {
                                            grisTotal > 0 ?

                                                grisTotal.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })
                                                :

                                                <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                                </svg>
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div style={{
                            display: "flex",

                        }}>
                            <div style={{ display: "flex", gap: "50px", width: "210px" }}>
                                <div>
                                    <p className="textTitleSummary">Cubagem total</p>
                                    <div>
                                        {

                                            cubageTotal != 0 ?

                                                `${cubageTotal.toLocaleString("pt-BR")} m³`
                                                :

                                                <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                                </svg>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "362px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Pedágio <span style={{ fontSize: "12px" }}>(A cada 100kg)</span></p>
                                    <div>
                                        {
                                            tollTotal > 0 ?
                                                Number(tollTotal).toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })
                                                :

                                                <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                                </svg>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "288px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Transit time</p>
                                    <div>

                                        {
                                            transitTimeTotal > 0 ?
                                                `${transitTimeTotal} dias úteis`
                                                :

                                                <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                                </svg>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "44px", width: "299px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Valor sem ICMS %</p>
                                    <div>
                                        {
                                            sumTotalUser > 0 ?
                                                sumTotalUser.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })
                                                :
                                                <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                                </svg>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "42px", width: "277px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Valor com ICMS %</p>
                                    <div>
                                        {
                                            sumTotalUser > 0 ?
                                                sumTotalUser.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })
                                                :
                                                <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                                </svg>
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>
                    </>
                )
                ||
                checkDedicated && (
                    <>
                        <div style={{
                            display: "flex",
                        }}>
                            <div style={{ display: "flex", width: "200px" }}>
                                <div>
                                    <p className="textTitleSummary">Frete</p>
                                    <div>
                                        {
                                            freightDedicated > 0 ?
                                                freightDedicated.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })
                                                :

                                                <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                                </svg>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "300px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Pedágio</p>
                                    <div>
                                        {
                                            tollDedicated > 0 ?
                                                tollDedicated.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })
                                                :
                                                <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                                </svg>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Ad valorem</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">GRIS</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div style={{
                            display: "flex",

                        }}>
                            <div style={{ display: "flex", width: "200px" }}>
                                <div>
                                    <p className="textTitleSummary">Cubagem total</p>
                                    <div>
                                        {
                                            cubageTotalDedicated > 0 ?
                                                `${cubageTotalDedicated.toLocaleString("pt-BR")} m³`
                                                :

                                                <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                                </svg>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Transit time</p>
                                    <div>
                                        {
                                            String(transitTimeDedicated).length > 0 ?
                                                transitTimeDedicated
                                                :
                                                <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                                </svg>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Valor total</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </>
                )
            }

            {
                internationalRoad &&
                checkStandardInternational && (
                    <>
                        <div style={{
                            display: "flex",
                        }}>
                            <div style={{ display: "flex", width: "200px" }}>
                                <div>
                                    <p className="textTitleSummary">Frete Peso</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "300px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Ad valorem</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Valor total</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Cubagem total</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div style={{
                            display: "flex",

                        }}>
                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <p className="textTitleSummary">Transit time</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Outros</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </>
                )
                ||
                checkPremiumInternational && (
                    <>
                        <div style={{
                            display: "flex",
                        }}>
                            <div style={{ display: "flex", width: "200px" }}>
                                <div>
                                    <p className="textTitleSummary">Frete Peso</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "300px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Ad valorem</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Valor total</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Cubagem total</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div style={{
                            display: "flex",

                        }}>
                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <p className="textTitleSummary">Transit time</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Outros</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </>
                )
                ||
                checkDedicatedInternational && (
                    <>
                        <div style={{
                            display: "flex",
                        }}>
                            <div style={{ display: "flex", width: "200px" }}>
                                <div>
                                    <p className="textTitleSummary">Frete Peso</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "300px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Ad valorem</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Valor total</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Cubagem total</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div style={{
                            display: "flex",

                        }}>
                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <p className="textTitleSummary">Transit time</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                                <div>
                                    <svg width="1" height="50" viewBox="0 0 1 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="0.5" y1="49.1932" x2="0.5" y2="0.187511" stroke="#D7D7D7" />
                                    </svg>
                                </div>
                                <div>
                                    <p className="textTitleSummary">Outros</p>
                                    <div>
                                        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z" fill="#060606" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </>
                )

            }
        </div >
    )
}

export default SectionSelectQuotationSummary