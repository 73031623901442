import { useState } from "react"
import Button from "./Button"
import ContentBlockModal from "./ContentBlockModal"
import { useEffect } from "react"
import api from "../services/api"

const ModalBlackList = ({ closeModal }) => {
    const [listBlocks, setListBlocks] = useState([])

    const getBlocks = async () => {
        const response = await api.get("/block")
        setListBlocks(response.data)
    }

    const createBlocks = async (block) => {

        const data = {
            block: block
        }

        await api.post("/block", data)
        getBlocks()
    }

    const updateBlocks = async (block, id) => {

        const data = {
            block: block
        }

        const response = await api.patch(`/block/${id}`, data)
    }

    const deleteBlocks = async (id) => {

        await api.delete(`/block/${id}`)

        getBlocks()
    }

    const destroyVirtual = () => {
        setListBlocks(listBlocks.splice(0, listBlocks.length))
    }

    useEffect(() => {
        getBlocks()
    }, [])

    const container = {
        display: "flex",
        flexDirection: "column",
        gap: "17px",
        backgroundColor: "#FFFFFF",
        width: "375px",
        height: "auto",
        boxShadow: "0px 0px 13px -3px #00000033",
        border: "1px solid transparent",
        borderRadius: "20px"
    }

    const headerOption = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #D7D7D7",
        padding: "15px 18px 18px"
    }

    const headerBoxLeft = {
        display: "flex",
        alignItems: "center",
        gap: "22px",
    }

    const boxTextDelete = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderBottom: "1px solid #D7D7D7",
        paddingBottom: "18px",
        cursor: "pointer",
    }

    return (
        <div className="modalEdit" style={container}>
            <div style={headerOption}>
                <div style={headerBoxLeft}>
                    <div style={{ cursor: "pointer" }} onClick={() => {
                        closeModal();
                    }}>
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.48406 6.36766L12.4871 1.35916C12.7976 1.04823 12.7976 0.544109 12.4871 0.2332C12.1765 -0.0777335 11.673 -0.0777335 11.3624 0.2332L6.35997 5.24222L1.35752 0.2332C1.04696 -0.0777335 0.543453 -0.0777335 0.232919 0.2332C-0.0776148 0.544134 -0.0776396 1.04825 0.232919 1.35916L5.23589 6.36766L0.232919 11.3762C-0.0776396 11.6871 -0.0776396 12.1912 0.232919 12.5021C0.543478 12.8131 1.04699 12.8131 1.35752 12.5021L6.35997 7.4931L11.3624 12.5021C11.673 12.8131 12.1765 12.8131 12.487 12.5021C12.7976 12.1912 12.7976 11.6871 12.487 11.3762L7.48406 6.36766Z" fill="black" />
                        </svg>
                    </div>
                    <p className="textModalTitleOption">Ajuste lista negra</p>
                </div>
                <Button width="101px" height="36px" background="#062F67"
                    borderRadius="60px" marginTop="0" name="Salvar"
                    color="#fff" onClick={closeModal}
                    border="none" />
            </div>

            <div style={{
                padding: "5px 5px 5px 22px",
                height: "446px",
            }}>
                <div style={{
                    width: "100%",
                    height: "420px",
                    overflowY: "scroll",
                }}>
                    {
                        listBlocks.length === 0 ? (
                            <ContentBlockModal
                                number={1}
                                create={createBlocks}
                                update={updateBlocks}
                                destroy={deleteBlocks}
                                trash={false} />
                        )
                            :
                            listBlocks.map((item, i) => {

                                return (
                                    <ContentBlockModal
                                        id={item.id}
                                        index={i}
                                        number={i + 1}
                                        block={item.block}
                                        create={createBlocks}
                                        update={updateBlocks}
                                        destroy={deleteBlocks}
                                        destroyVirtual={destroyVirtual}
                                        trash={true} />
                                )
                            })
                    }
                    <div onClick={() => setListBlocks([...listBlocks, { block: "" }])} style={{ display: "flex", alignItems: "center", gap: "12px", cursor: "pointer" }}>
                        <span className="textUpAddIcon">+</span>
                        <p className="textAddBlock">Novo bloqueio</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalBlackList