import { useState } from "react"
import excel from "../images/png/arquivo-excel.png"
import { read, utils } from 'xlsx'
import { toast } from "react-toastify"

const AddCardInput = ({ onClick, stock, setStock }) => {
    const [fileCvg, setFileCvg] = useState()
    
    const handleFile = async (file) => {

        if (file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {

            toast.error("Formato de arquivo inválido!", {
                autoClose: 2000,
            })
            return false
        }

        const data = await file.arrayBuffer()

        const workbook = read(data)


        const workSheet = workbook.Sheets[workbook.SheetNames[0]]
        const worksheet = utils.sheet_to_json(workSheet);

        setFileCvg(file)
        setStock(worksheet)

        console.log(worksheet)


    }

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            gap: "20px"
        }}>

            <div onClick={onClick} style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",

                cursor: "pointer"
            }}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 10H15" stroke="#062F67" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10 15V5" stroke="#062F67" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <p className="textAddRouteCard">Adicionar outra rota comercial</p>
            </div>

            <label style={{
                cursor: "pointer",
                padding: "6px",
                width: "35px",
                height: "40px",
                border: "1px solid #d7d7d7",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <img style={{ width: "100%" }} src={excel} />
                <input type="file" id="file" onChange={(e) => handleFile(e.target.files[0])} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" name="file" style={{ display: "none" }} />
            </label>
        </div>
    )
}

export default AddCardInput