import React, { useState } from "react";
import ThowColumLayout from "../../layouts/ThowColumLayout";
import Sidebar from "../../components/Sidebar";
import HeaderDashboard from "../../components/HeaderDashboard";
import TableListAdm from "../../components/TableListAdm";
import TableListClients from "../../components/TableListClients";
import FilterUsers from "../../components/FilterUsers";

const ManageAccesses = () => {
  const [typeAcess, setTypeAcess] = useState("client");
  /* STYLES */
  const container = {
    maxWidth: "100%",
    paddingRight: "4.125rem",
    paddingTop: "2.6875rem",
    paddingLeft: "3.375rem",
    backgroundColor: "#F9F9F9",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginBottom: "20px",
  };

  return (
    <ThowColumLayout
      page={"ManageAccesses"}
      colum1={<Sidebar page="ManageAccesses" access="adm" />}
      colum2={
        <>
          <HeaderDashboard name="Gerenciar acessos" adm={true} />
          <div className="boxContainerCard" style={container}>
            <FilterUsers setTypeAcess={setTypeAcess} />
            {typeAcess === "client" ? <TableListClients /> : <TableListAdm />}
          </div>
        </>
      }
    ></ThowColumLayout>
  );
};

export default ManageAccesses;
