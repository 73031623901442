import { useContext, useState } from "react";
import Input from "./Input";
import InputSelectFilterManager from "./InputSelectFilterManager";
import InputsFreightQuote from "./InputsFreightQuote";
import Textarea from "./Textarea";
import UploadImg from "./UploadImg";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import { useEffect } from "react";
import api from "../services/api";
import { ProposalContext } from "../context/Dashboard/ProposalContext";

const CardInfoQuotation = ({
  inconterm,
  type,
  index,
  imo,
  servicesAdd,
  visibleProposal,
}) => {
  const {
    getOrigin,
    getOriginUf,
    getDestination,
    dataCheckFractional,
    setDataCheckFractional,
  } = useContext(DashboardContext);

  const { btnStateNationalFractioned, btnStateNationalFractionedTwo } =
    useContext(ProposalContext);

  /* DATA LOCALE */
  const [dataLocation, setDataLocation] = useState({});

  /* INPUTS VALUE */
  const [originFraction, setOriginFraction] = useState("");
  const [destinationFraction, setDestinationFraction] = useState("");

  const [selectDestination, setSelectDestination] = useState([]);
  const [selectUf, setSelectUf] = useState([]);
  const [destination, setDestination] = useState("");

  const [inputActive, setInputActive] = useState(false);
  const [commodityPrice, setCommodityPrice] = useState(0);
  const [merchandiseFraction, setMerchandiseFraction] = useState("");

  const [imagesBase64, setImagesBase64] = useState([]);
  const [imagesFirebase, setImagesFirebase] = useState([]);
  const [dataSummaryResume, setDataSummaryResume] = useState();

  /* QUOTATION SUMMARY */
  const [freightWeightValue, setFreightWeightValue] = useState(0);
  const [dispatchValue, setdispatchValue] = useState(0);
  const [sefazTaxValue, setSefazTaxValue] = useState(0);

  const [advaloremValue, setAdvaloremValue] = useState(0);
  const [grisValue, setGrisValue] = useState(0);
  const [tollValue, setTollValue] = useState(0);

  const [transitTime, setTransitTime] = useState("");
  const [totalIcms, setTotalIcms] = useState(0);
  const [totalNotIcms, setTotalNotIcms] = useState(0);

  const [cubageTotal, setCubageTotal] = useState(0);
  const [details, setDetails] = useState("");

  const convertToBRL = async (amount, setAmount) => {
    const price = Number(amount).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    setAmount(price);
  };

  const getSelectUf = async (e) => {
    if (e.target.value != "default") {
      const result = await getOrigin(e.target.value, index);

      setOriginFraction(e.target.value);

      setSelectUf({
        option: result.national,
      });
      // setSelectDestination({
      //   option: result.national,
      // });
    } else {
      setSelectDestination({});
    }
  };

  const getSelect = async (e) => {
    if (e.target.value != "default") {
      console.log(originFraction, e.target.value, 55);
      const result = await getOriginUf(originFraction, e.target.value, index);

      setSelectDestination({
        option: result.national,
      });
    } else {
      setSelectDestination({});
    }
  };

  const getOption = async (e) => {
    if (e.target.value != "default") {
      const result = await getDestination(e.target.value, index);

      setDestinationFraction(e.target.value);
      setDestination(e.target.value);

      setDataLocation(result.national[0]);
    }
  };

  const getTotalValues = async () => {
    if (dataSummaryResume && dataLocation?.id) {
      /* ICMS */
      if (dataLocation?.icms) {
        const sumTotalPrice =
          dispatchValue +
          sefazTaxValue +
          freightWeightValue +
          advaloremValue +
          grisValue +
          tollValue;

        const dataIcms = {
          total: sumTotalPrice,
        };

        try {
          const calculateIcms = await api.post(
            `/quotation/icms/${dataLocation?.id}`,
            dataIcms
          );

          setTotalIcms(calculateIcms.data.result);

          let dataState = dataCheckFractional;

          dataState[index] = {
            ...dataState[index],
            ...dataSummaryResume,
            origin: originFraction,
            destination: destinationFraction,
            commodityPrice: commodityPrice,
            merchandise: merchandiseFraction,
            advaloremValue: advaloremValue,
            gris: grisValue,
            freigth: freightWeightValue,
            toll: tollValue,
            transitTime: transitTime,
            grisTotal: commodityPrice,
            images: imagesFirebase,
            totalIcms: totalIcms,
            totalNotIcms: totalNotIcms,
            details: details,
            type: "LTL",
          };

          setDataCheckFractional(dataState);
        } catch (error) {
          console.log(error.es);
        }
      }

      /* NOT ICMS */
      if (true) {
        const sumTotalPrice =
          dispatchValue +
          sefazTaxValue +
          freightWeightValue +
          advaloremValue +
          grisValue +
          tollValue;
        setTotalNotIcms(sumTotalPrice);
      }
    }
  };

  const resumeList = async () => {
    if (dataSummaryResume && dataLocation?.id) {
      /* Freight Weight Price */
      if (dataSummaryResume?.grossWeight) {
        const dataFreightWeight = {
          peso:
            (dataSummaryResume.grossWeight &&
              !dataSummaryResume.cubageWeight &&
              Number(dataSummaryResume.grossWeight)) ||
            (!dataSummaryResume.grossWeight &&
              dataSummaryResume.cubageWeight &&
              Number(dataSummaryResume.cubageWeight)) ||
            dataSummaryResume.grossWeight > dataSummaryResume.cubageWeight
              ? dataSummaryResume.grossWeight
              : dataSummaryResume.cubageWeight,
        };
        try {
          const calculateFreightWeight = await api.post(
            `/quotation/freight/${dataLocation.id}`,
            dataFreightWeight
          );

          setFreightWeightValue(Number(calculateFreightWeight.data.result));
        } catch (error) {
          console.log(error);
        }
      }

      /* Dispatch Price */
      if (dataLocation?.cte_dispatch) {
        const calculateDispatch = Number(dataLocation.cte_dispatch);

        setdispatchValue(calculateDispatch);
      }

      /* Sefaz Tax Price*/
      if (dataLocation?.sefaz_tax) {
        const calculateSefazTax = Number(dataLocation.sefaz_tax);

        setSefazTaxValue(calculateSefazTax);
      }

      /* Ad Valorem Price */
      if (dataLocation?.shipping_value && commodityPrice) {
        const dataAdValorem = {
          priceProduct: Number(
            commodityPrice
              .replace("R$", "")
              .replaceAll(".", "")
              .replace(",", ".")
          ),
        };

        try {
          const calculateAdValorem = await api.post(
            `/quotation/advalorem/${dataLocation.id}`,
            dataAdValorem
          );

          setAdvaloremValue(calculateAdValorem.data.result);
        } catch (error) {
          console.log(error);
        }
      }

      /* Gris Price */
      if (dataLocation?.gris && commodityPrice) {
        const dataGris = {
          priceProduct: Number(
            commodityPrice
              .replace("R$", "")
              .replaceAll(".", "")
              .replace(",", ".")
          ),
        };
        try {
          const calculateAdValorem = await api.post(
            `/quotation/gris/${dataLocation.id}`,
            dataGris
          );

          setGrisValue(calculateAdValorem.data.result);
        } catch (error) {
          console.log(error);
        }
      }

      /* Toll Price */
      if (dataLocation?.toll && dataSummaryResume?.cubageWeight) {
        const dataToll = {
          peso:
            dataSummaryResume.cubageWeight > dataSummaryResume.grossWeight
              ? dataSummaryResume.cubageWeight
              : dataSummaryResume.grossWeight,
        };
        try {
          const calculateToll = await api.post(
            `/quotation/toll/${dataLocation?.id}`,
            dataToll
          );

          setTollValue(calculateToll.data.result);
        } catch (error) {
          console.log(error.es);
        }
      }

      /* Transit Time */
      if (dataLocation?.transit_time) {
        const message = `${dataLocation?.transit_time} dia${
          Number(dataLocation?.transit_time) > 1 ? "s" : ""
        } úteis`;
        setTransitTime(message);
      }

      if (dataSummaryResume.cubageTotal) {
        setCubageTotal(dataSummaryResume.cubageTotal);
      }
    }
  };

  useEffect(() => {
    let dataState = dataCheckFractional;

    dataState[index] = {
      ...dataState[index],
      ...dataSummaryResume,
      origin: originFraction,
      destination: destinationFraction,
      commodityPrice: commodityPrice,
      merchandise: merchandiseFraction,
      advaloremValue: advaloremValue,
      gris: grisValue,
      freigth: freightWeightValue,
      toll: tollValue,
      transitTime: transitTime,
      grisTotal: commodityPrice,
      images: imagesFirebase,
      totalIcms: totalIcms,
      totalNotIcms: totalNotIcms,
      type: "LTL",
    };
  }, [totalIcms, totalNotIcms]);

  useEffect(() => {}, [tollValue]);

  useEffect(() => {
    if (destination) {
      setInputActive(true);
    }
  }, [destinationFraction]);

  useEffect(() => {
    getTotalValues();
  }, [btnStateNationalFractioned]);

  useEffect(() => {
    resumeList();
  }, [btnStateNationalFractionedTwo]);

  const boxInputs = {
    display: "flex",
    gap: "20px",
  };

  const containerContent = {
    backgroundColor: "#fff",
    width: "100%",
    padding: "32px",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginTop: "30px",
  };

  return (
    <>
      <h3 className="textTitleTypeDispatch">Informações da cotação</h3>
      <div style={{ ...boxInputs, marginTop: "20px" }}>
        <InputSelectFilterManager
          onChange={getSelectUf}
          index={index}
          color="var(--grey-1)"
          name="Rota comercial de origem"
          width={inconterm ? "100%" : "290px"}
          top="50px"
          custom="false"
          type={type}
        />
        <InputSelectFilterManager
          onChange={getSelect}
          value={selectUf}
          index={index}
          color="var(--grey-1)"
          name="UF"
          width={inconterm ? "100%" : "290px"}
          top="50px"
          custom="false"
          type={type}
        />

        <InputSelectFilterManager
          onChange={getOption}
          value={selectDestination}
          index={index}
          color="var(--grey-1)"
          name="Rota comercial de destino"
          width={inconterm ? "100%" : "290px"}
          top="50px"
          custom="false"
          type={type}
        />

        {inconterm && (
          <>
            <InputSelectFilterManager
              color="var(--grey-1)"
              name="Inconterm"
              width={inconterm ? "100%" : "290px"}
              top="50px"
              custom="false"
              type="inconterm"
            />
          </>
        )}
        {imo && (
          <>
            <InputSelectFilterManager
              color="var(--grey-1)"
              name="Carga imo"
              width={imo ? "100%" : "290px"}
              top="50px"
              custom="false"
              type="imo"
            />
          </>
        )}
      </div>

      <div style={boxInputs}>
        <Input
          isActive={inputActive}
          onChange={(e) => {
            setMerchandiseFraction(e.target.value);
          }}
          name="Tipo de mercadoria"
          width="100%"
          placeholder=""
          type={"text"}
        />

        <InputSelectFilterManager
          isActive={inputActive}
          color="var(--grey-1)"
          name="Tipo de embalagem"
          width="100%"
          top="50px"
          custom="false"
          type="kindOfPacking"
        />

        <InputSelectFilterManager
          isActive={inputActive}
          color="var(--grey-1)"
          name="A carga pode ser empilhada?"
          width="100%"
          top="50px"
          custom="false"
          type="boolean"
        />

        <Input
          isActive={inputActive}
          onBlur={() => convertToBRL(commodityPrice, setCommodityPrice)}
          value={commodityPrice}
          onChange={(e) => {
            setCommodityPrice(e.target.value);
          }}
          name="Valor da mercadoria"
          width="100%"
          placeholder="0,00"
          type={"text"}
          marginBottom="10px"
        />
      </div>

      <div style={boxInputs}>
        {
          <InputsFreightQuote
            isActive={inputActive}
            sumResume={dataSummaryResume}
            setSumResume={setDataSummaryResume}
            index={index}
          />
        }
      </div>

      <div>
        {servicesAdd && (
          <>
            <h3 className="textTitleTypeDispatch">Serviços adicionais</h3>

            <div style={boxInputs}>
              <InputSelectFilterManager
                isActive={inputActive}
                color="var(--grey-1)"
                name="Emissão DUE-Desembaraço ? Sim ou Não"
                width="100%"
                top="50px"
                custom="false"
                type="boolean"
              />

              <InputSelectFilterManager
                isActive={inputActive}
                color="var(--grey-1)"
                name=" Precisa de Emissão CO_certif origem ? Sim ou "
                width="100%"
                top="50px"
                custom="false"
                type="boolean"
              />
            </div>
          </>
        )}
      </div>

      <div>
        <Textarea
          name="Observações adicionais"
          height="83px"
          width="100%"
          onChange={(e) => {
            setDetails(e.target.value);
          }}
        />
      </div>
      {/* 
      <UploadImg
      // imagesBase64={imagesBase64}
      // setImagesBase64={setImagesBase64}
      // imagesFirebase={imagesFirebase}
      // setImagesFirebase={setImagesFirebase}
      /> */}

      <UploadImg
        imagesFirebase={imagesFirebase}
        setImagesFirebase={setImagesFirebase}
        click={() => {}}
      />

      <div style={containerContent}>
        <div>
          <h3 className="textTitleTypeDispatch">Resumo total cotação</h3>
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ display: "flex", width: "146px" }}>
            <div>
              <p className="textTitleSummary">Despacho CTE</p>
              <div>
                {dispatchValue > 0 ? (
                  dispatchValue.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "18px", width: "195px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Taxa Sefaz</p>
              <div>
                {sefazTaxValue > 0 ? (
                  sefazTaxValue.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "50px", width: "200px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Frete Peso</p>
              <div>
                {freightWeightValue > 0 ? (
                  freightWeightValue.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "50px", width: "209px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Ad valorem</p>
              <div>
                {advaloremValue > 0 ? (
                  advaloremValue.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "50px", width: "192px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">GRIS</p>
              <div>
                {grisValue > 0 ? (
                  grisValue.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ display: "flex", gap: "50px", width: "135px" }}>
            <div>
              <p className="textTitleSummary">Cubagem total</p>
              <div>
                {cubageTotal > 0 ? (
                  `${cubageTotal.toLocaleString("pt-BR")} m³`
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "12px", width: "180px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">
                Pedágio <span style={{ fontSize: "12px" }}>(A cada 100kg)</span>
              </p>
              <div>
                {tollValue > 0 ? (
                  tollValue.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          {/* RETIRADO APÓS A CALL NO DIA 07/02/2024 */}
          {/* <div style={{ display: "flex", gap: "50px", width: "288px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Transit time</p>
              <div>
                { transitTime ? (
                  transitTime
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div> */}

          <div style={{ display: "flex", gap: "21px", width: "186px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Valor sem ICMS %</p>
              <div>
                {totalNotIcms > 0 ? (
                  totalNotIcms.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "42px", width: "277px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Valor com ICMS %</p>
              <div>
                {totalIcms > 0 ? (
                  totalIcms.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardInfoQuotation;
