import { useContext, useEffect, useState } from "react";
import Input from "./Input";
import InputSelectFilterManager from "./InputSelectFilterManager";
import Textarea from "./Textarea";
import UploadImg from "./UploadImg";
import InputsFreightQuote from "./InputsFreightQuote";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import CardInfoQuotation from "./CardInfoQuotation";
import CardInfoQuotationGeneric from "./CardInfoQuotationGeneric";
import CardInfoQuotationInternational from "./CardInfoQuotationInternational";
import CardInfoQuotationInternationalFTL from "./CardInfoQuotationInternationalFTL";
import CardInfoQuotationMaritime from "./CardInfoQuotationMaritime";
import CardInfoQuotationAir from "./CardInfoQuotationAir";

const SectionSelectQuotationInformation = ({
  inconterm,
  addCard,
  removeCard,
  buttonAddCard,
  isPrimaryCard,
  id,
  removeAnimation,
  type,
  sumResume,
  setSumResume,
  index,
  check,
  imo,
  servicesAdd,
  visibleProposal,
}) => {
  const {
    dataFractionDedicated,
    setDataFractionDedicated,
    update,
    setUpdate,
    updateRemove,
    setUpdateRemove,
    selectInputDestination,
    setSelectInputDestination,
    setDataCheckFractional,
  } = useContext(DashboardContext);

  const container = {
    backgroundColor: "#fff",
    maxWidth: "1000px",
    padding: "32px",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
  };

  const boxAddNewRoute = {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    marginTop: "20px",
    cursor: "pointer",
  };

  const removeResumePrice = () => {
    if (sumResume) {
      const removeCubage = sumResume.slice(0, index);
      const removeSumary = selectInputDestination.slice(0, index);
      setSumResume(removeCubage);
      setDataCheckFractional(removeCubage);
      setSelectInputDestination(removeSumary);
      setUpdate(!update);
    }

    const removeDataDedicated = dataFractionDedicated;

    const removeSumary = removeDataDedicated.slice(0, index + 1);

    setDataFractionDedicated(removeSumary);
    removeCard();
    setUpdate(!update);
    setUpdateRemove(!updateRemove);
  };

  useEffect(() => {
    console.log(check);
  }, [check]);

  return (
    <div
      className={
        (removeAnimation && "cardQuotationInformationRemove") ||
        (id > 1 && "cardQuotationInformation")
      }
      style={container}
    >
      {(check == "checkDedicated" && (
        <CardInfoQuotationGeneric
          inconterm={inconterm}
          index={id}
          check={check}
        />
      )) ||
        (check == "checkNational" && (
          <CardInfoQuotationGeneric
            inconterm={inconterm}
            index={id}
            check={check}
          />
        )) ||
        (check == "checkAirInternational" && (
          <CardInfoQuotationGeneric
            inconterm={inconterm}
            index={id}
            check={check}
          />
        )) ||
        (check == "checkMaritime" && (
          <CardInfoQuotationMaritime
            inconterm={inconterm}
            index={id}
            check={check}
          />
        )) ||
        (check == "checkNationalAir" && (
          <CardInfoQuotationAir
            inconterm={inconterm}
            index={id}
            check={check}
          />
        )) ||
        (check == "checkInternationalAir" && (
          <CardInfoQuotationAir
            inconterm={inconterm}
            index={id}
            check={check}
          />
        )) ||
        (check == "checkInternationalStandard" && (
          <CardInfoQuotationInternational
            inconterm={inconterm}
            servicesAdd={servicesAdd}
            imo={imo}
            type={type}
            sumResume={sumResume}
            setSumResume={setSumResume}
            index={index}
            id={id}
            check={check}
          />
        )) ||
        (check === "checkInternationalPremium" && type == "standard" && (
          <CardInfoQuotationInternational
            inconterm={inconterm}
            servicesAdd={servicesAdd}
            imo={imo}
            type={type}
            sumResume={sumResume}
            setSumResume={setSumResume}
            index={index}
            id={id}
            check={check}
          />
        )) ||
        (check === "checkInternationalPremium" && type == "premium" && (
          <CardInfoQuotationInternational
            inconterm={inconterm}
            servicesAdd={servicesAdd}
            imo={imo}
            type={type}
            sumResume={sumResume}
            setSumResume={setSumResume}
            index={index}
            id={id}
            check={check}
          />
        )) ||
        (check === "checkDedicatedInternational" && (
          <CardInfoQuotationInternationalFTL
            inconterm={inconterm}
            servicesAdd={servicesAdd}
            imo={imo}
            type={type}
            sumResume={sumResume}
            setSumResume={setSumResume}
            index={index}
            id={id}
            check={check}
          />
        )) || (
          <CardInfoQuotation
            inconterm={inconterm}
            servicesAdd={servicesAdd}
            imo={imo}
            type={type}
            sumResume={sumResume}
            setSumResume={setSumResume}
            index={index}
            id={id}
            visibleProposal={visibleProposal}
            check={check}
          />
        )}

      {buttonAddCard && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={boxAddNewRoute}
            onClick={() => {
              addCard();
            }}
          >
            <div
              style={{
                transform: "translateY(-2px)",
              }}
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 10.7812H15"
                  stroke="#062F67"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 15.7812V5.78125"
                  stroke="#062F67"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p className="textAddNewRoute">Adicionar nova rota</p>
          </div>

          {!isPrimaryCard && (
            <div style={boxAddNewRoute} onClick={removeResumePrice}>
              <div
                style={{
                  transform: "translateY(-2px)",
                }}
              >
                <svg
                  width="12"
                  height="3"
                  viewBox="0 0 12 3"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1.78125H11"
                    stroke="#EA0000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <p className="textRemoveNewRoute">Remover rota</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SectionSelectQuotationInformation;
