import { useState } from "react";
import InputSelectFilterManager from "./InputSelectFilterManager";
import Input from "./Input";
import Textarea from "./Textarea";
import UploadImg from "./UploadImg";
import { useEffect } from "react";
import { useContext } from "react";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import api from "../services/api";
import axios from "axios";
import { ProposalContext } from "../context/Dashboard/ProposalContext";
import InputPrimeReact from "./InputPrimeReact";
import Select from "react-select";
import _ from "lodash";

const CardInfoQuotationGeneric = ({ inconterm, type, index, check }) => {
  const {
    onSumary,
    dataFractionDedicated,
    setDataFractionDedicated,
    update,
    setUpdate,
    typeVehicle,
  } = useContext(DashboardContext);

  const { btnStateNationalDedicated } = useContext(ProposalContext);

  const [dataLocation, setDataLocation] = useState({});

  /* CHECK DEDICATED FREIGHT */
  const [originFractionDedicated, setOriginFractionDedicated] = useState({
    label: "São Paulo",
    value: "São Paulo",
  });
  const [destinationFractionDedicated, setDestinationFractionDedicated] =
    useState([]);

  const [merchandiseFractionDedicated, setMerchandiseFractionDedicated] =
    useState("");
  const [commodityPriceDedicated, setCommodityPriceDedicated] = useState("");
  const [typePacking, setTypePacking] = useState("");

  const [cargoStacked, setCargoStacked] = useState("");
  const [quantityDedicated, setQuantityDedicated] = useState("");
  const [grossWeightDedicated, setGrossWeightDedicated] = useState("");

  const [heightDedicated, setHeightDedicated] = useState("");
  const [widthDedicated, setWidthDedicated] = useState("");
  const [lengthDedicated, setLengthDedicated] = useState("");

  const [cubedWeight, setCubedWeight] = useState("");
  const [additionalObservationsDedicated, setAdditionalObservationsDedicated] =
    useState("");
  const [inconterState, setIncontermState] = useState("");

  const [click, setClick] = useState(false);
  const [clickBlur, setClickBlur] = useState(false);

  /* National Dedicated */
  const [freight, setFreight] = useState(0);
  const [toll, setToll] = useState(0);
  const [transitTime, setTransitTime] = useState("");

  const [file, setFile] = useState("");

  /* counties all */
  const [allStatesAndCitysToBrazil, setAllStatesAndCitysToBrazil] =
    useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [calling, setCalling] = useState(false);
  const [cityList, setCityList] = useState([]);

  const [originFractionDedicatedcollect, setOriginFractionDedicatedcollect] =
    useState([]);

  const [smallVehicleRoute, setSmallVehicleRoute] = useState(null);

  const convertToBRL = async (amount, setAmount) => {
    const price = Number(amount).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    setCommodityPriceDedicated(price);
    setAmount(price);
  };

  const resumeUpload = async () => {
    const arrData = dataFractionDedicated;

    const data = {
      ...dataLocation,
      origin: `${originFractionDedicated.label} - ${originFractionDedicatedcollect.label}`,
      destination: destinationFractionDedicated.label,
      inconterm: inconterState,
      merchandiseFractionDedicated: merchandiseFractionDedicated,
      commodityPriceDedicated: commodityPriceDedicated,
      typePacking: typePacking,
      cargoStacked: cargoStacked,
      quantityDedicated: quantityDedicated,
      grossWeightDedicated: grossWeightDedicated,
      heightDedicated: heightDedicated,
      widthDedicated: widthDedicated,
      lengthDedicated: lengthDedicated,
      cubedWeight: cubedWeight,
      comment: additionalObservationsDedicated,
      file,
      freight,
      toll,
      transitTime,
    };

    console.log(data, "<M======");

    arrData[index] = data;
    setDataFractionDedicated(arrData);
    setUpdate(!update);
    //  setClickBlur(!clickBlur)
  };

  const resumePrice = async () => {
    const response = await onSumary(
      originFractionDedicatedcollect.value,
      destinationFractionDedicated.value,
      check,
      commodityPriceDedicated
    );

    const arrData = dataFractionDedicated;

    setFreight(response?.frete || 0);
    setToll(response?.pedagio || 0);
    setTransitTime(response?.tempo);

    const data = {
      freight: response?.frete,
      toll: response?.pedagio,
      transitTime: response?.tempo,
      gris: response?.gris,
      adValorem: response?.adValorem,
    };

    setDataLocation(data);

    // setUpdate(!update)
    // setClick(!click)
  };

  const calculateCubage = async (length, width, height) => {
    const cubage = (Number(length) * Number(width) * Number(height)) / 1000000;
    const result = cubage * quantityDedicated * 1000000;
    const formattedResult = result.toLocaleString("pt-BR");

    const arrData = dataFractionDedicated;

    if (arrData.length) {
      arrData[index].cubageTotal = result;
    }

    setDataFractionDedicated(arrData);

    setCubedWeight(formattedResult);
  };

  // Função debounced para buscar nos municípios
  const handleSearch = _.debounce((searchTerm) => {
    if (searchTerm) {
      const results = allStatesAndCitysToBrazil.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredOptions(results);
    } else {
      setFilteredOptions(allStatesAndCitysToBrazil); // Mostra todas as opções se o input estiver vazio
    }
  }, 300); // 300ms de debounce

  // Função para ser chamada no onInputChange do Select
  const handleInputChange = (inputValue) => {
    handleSearch(inputValue);
  };

  const getCityInBrazil = async (state) => {
    try {
      const { data } = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios?orderBy=nome`
      );

      const citys = data.map((elem) => {
        return { label: elem.nome, value: elem.nome };
      });

      console.log(citys, state);

      setCityList(citys);
    } catch (error) {}
  };

  // Função para remover pontuação
  const removePunctuation = (text) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s]/gi, "")
      .toLowerCase();
  };

  // FUNÇÃO DEIXA PRE SETADO O ESTADO NO CAMPO 'SELECIONAR ESTADO'
  const onSelectStateOrigin = async () => {
    const nameClean = removePunctuation(originFractionDedicated.value);

    const { data: states } = await axios.get(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
    );

    const filterStateSelect = states.find(
      (state) => removePunctuation(state.nome) === nameClean
    );

    const formactState = filterStateSelect.sigla;

    if (formactState) {
      getCityInBrazil(formactState);
    }
  };

  const getPermittedVehicle = async () => {
    try {
      if (typeVehicle.length) {
        setDestinationFractionDedicated({});
        const { data: isVerify } = await api.get(
          `/permitted/vehicle?vehicle=${typeVehicle}`
        );

        if (isVerify.message.toLowerCase() == "ok") setSmallVehicleRoute(false);
        else if (isVerify.message.toLowerCase() == "nok")
          setSmallVehicleRoute(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    resumeUpload();
  }, [click, freight, toll, transitTime]);

  useEffect(() => {
    calculateCubage(lengthDedicated, widthDedicated, heightDedicated);
  }, [btnStateNationalDedicated]);

  useEffect(() => {
    if (
      originFractionDedicated?.value?.length > 0 &&
      destinationFractionDedicated?.value?.length > 0
    ) {
      console.log(originFractionDedicated?.value?.length > 0, "<====");
      resumePrice();
    }
  }, [btnStateNationalDedicated]);

  useEffect(() => {
    setClickBlur(!clickBlur);
  }, [typeVehicle]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchStates = async () => {
      try {
        const response = await axios.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/municipios",
          { cancelToken: source.token } // Passa o token para a requisição
        );
        const municipios = response?.data?.map((city) => {
          return { label: city.nome, value: city.nome };
        });

        setAllStatesAndCitysToBrazil(municipios);
        setFilteredOptions(municipios); // Inicialmente, todas as opções são exibidas
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Requisição cancelada", error.message);
        } else {
          console.error("Erro ao buscar estados:", error);
        }
      }
    };

    fetchStates();

    return () => {
      source.cancel("Componente desmontado: cancelando requisição");
    };
  }, []);

  useEffect(() => {
    allStatesAndCitysToBrazil.length && onSelectStateOrigin();
  }, [originFractionDedicated, allStatesAndCitysToBrazil]);

  useEffect(() => {
    getPermittedVehicle();
  }, [typeVehicle]);

  const boxInputs = {
    display: "flex",
    gap: "20px",
  };

  const containerContent = {
    backgroundColor: "#fff",
    width: "100%",
    padding: "32px",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    display: "flex",
    marginTop: "20px",
    flexDirection: "column",
    gap: "20px",
  };

  return (
    <>
      <h3 className="textTitleTypeDispatch">Informações da cotação</h3>
      <div style={{ ...boxInputs, marginTop: "20px" }}>
        <label
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            fontSize: 15,
          }}
        >
          Estado de origem
          <Select
            options={filteredOptions}
            // onChange={(selectedOption) =>
            //   setOriginFractionDedicated(selectedOption)
            // }
            isDisabled
            onInputChange={handleInputChange}
            placeholder="Selecione uma opção"
            value={originFractionDedicated}
            className={"selectDedicado"}
          />
        </label>

        <label
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            fontSize: 15,
          }}
        >
          Cidade de coleta origem
          <Select
            options={cityList}
            onChange={(e) => setOriginFractionDedicatedcollect(e)}
            onInputChange={handleInputChange}
            placeholder="Selecione uma opção"
            value={originFractionDedicatedcollect}
            className={"selectDedicado"}
          />
        </label>

        <label
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            fontSize: 15,
          }}
        >
          Local de destino
          <Select
            options={smallVehicleRoute ? cityList : filteredOptions}
            onChange={(selectedOption) =>
              setDestinationFractionDedicated(selectedOption)
            }
            onInputChange={handleInputChange}
            placeholder="Selecione uma opção"
            value={destinationFractionDedicated}
            className={"selectDedicado"}
          />
        </label>

        {inconterm && (
          <>
            <InputSelectFilterManager
              onChange={(e) => {
                setClick(!click);
                setIncontermState(e.target.value);
              }}
              color="var(--grey-1)"
              name="Inconterm"
              width={inconterm ? "100%" : "290px"}
              top="50px"
              custom="false"
              type="inconterm"
            />
          </>
        )}
      </div>

      <div style={{ height: "20px", width: "10px" }} />
      <div style={boxInputs}>
        <Input
          onChange={(e) => {
            setClick(!click);
            setMerchandiseFractionDedicated(e.target.value);
          }}
          name="Tipo de mercadoria"
          width="100%"
          placeholder=""
          type={"text"}
        />
        <InputSelectFilterManager
          onChange={(e) => {
            setClick(!click);
            setTypePacking(e.target.value);
          }}
          color="var(--grey-1)"
          name="Tipo de embalagem"
          width="100%"
          top="50px"
          custom="false"
          type="kindOfPacking"
        />
        <InputSelectFilterManager
          onChange={(e) => {
            setClick(!click);
            setCargoStacked(e.target.value);
          }}
          color="var(--grey-1)"
          name="A carga pode ser empilhada?"
          width="100%"
          top="50px"
          custom="false"
          type="boolean"
        />
        <InputPrimeReact
          label={"Valor da mercadoria"}
          value={commodityPriceDedicated}
          onChange={(e) => setCommodityPriceDedicated(e.value)}
          type={"currency"}
          width={"300px"}
          currencyType={"BRL"}
        />
      </div>

      <div style={boxInputs}>
        {
          <>
            <Input
              onChange={(e) => {
                setClick(!click);
                setQuantityDedicated(e.target.value);
              }}
              name="Quantidade"
              width="100%"
              placeholder=""
              type={"text"}
            />
            <Input
              onChange={(e) => {
                setClick(!click);
                setGrossWeightDedicated(e.target.value);
              }}
              onClick={() =>
                setGrossWeightDedicated(
                  String(grossWeightDedicated)
                    .replaceAll(".", "")
                    .replaceAll("kg", "")
                    .trim()
                )
              }
              onBlur={() =>
                setGrossWeightDedicated(
                  `${Number(grossWeightDedicated).toLocaleString("pt-BR")} kg`
                )
              }
              value={grossWeightDedicated.replace(/,/g, ".")}
              name="Peso bruto (KG)"
              width="100%"
              placeholder=""
              type={"text"}
            />
            <Input
              onChange={(e) => {
                setClick(!click);
                setHeightDedicated(e.target.value);
              }}
              name="Altura (M)"
              placeholder="Ex: 1"
              width="100%"
              type={"text"}
            />
            <Input
              onChange={(e) => {
                setClick(!click);
                setWidthDedicated(e.target.value);
              }}
              name="Largura (M)"
              placeholder="Ex: 1,50"
              width="100%"
              type={"text"}
            />
            <Input
              onChange={(e) => {
                setClick(!click);
                setLengthDedicated(e.target.value);
              }}
              name="Comprimento (M)"
              placeholder="Ex: 2"
              width="100%"
              type={"text"}
            />
            <Input
              onChange={(e) => {
                setClick(!click);
                setCubedWeight(e.target.value);
              }}
              value={`${cubedWeight} m³`}
              name="Cubagem M³"
              placeholder=""
              width="100%"
              type={"text"}
            />
          </>
        }
      </div>

      <div>
        <Textarea
          onChange={(e) => {
            setClick(!click);
            setAdditionalObservationsDedicated(e.target.value);
          }}
          name="Observações adicionais"
          height="83px"
          width="100%"
        />
      </div>

      <UploadImg
        imagesFirebase={file}
        index={index}
        setImagesFirebase={setFile}
        click={() => setClick(!click)}
      />

      {check == "checkDedicated" && (
        <div style={containerContent}>
          <div>
            <h3 className="textTitleTypeDispatch">Resumo total cotação</h3>
          </div>
          <>
            <div
              style={{
                display: "flex",
              }}
            >
              <div style={{ display: "flex", width: "184px" }}>
                <div>
                  <p className="textTitleSummary">Frete</p>
                  <div>
                    {freight > 0 ? (
                      freight.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                    ) : (
                      <svg
                        width="8"
                        height="3"
                        viewBox="0 0 8 3"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                          fill="#060606"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                <div>
                  <svg
                    width="1"
                    height="50"
                    viewBox="0 0 1 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="0.5"
                      y1="49.1932"
                      x2="0.5"
                      y2="0.187511"
                      stroke="#D7D7D7"
                    />
                  </svg>
                </div>
                <div>
                  <p className="textTitleSummary">Pedágio</p>
                  <div>
                    {toll > 0 ? (
                      toll.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                    ) : (
                      <svg
                        width="8"
                        height="3"
                        viewBox="0 0 8 3"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                          fill="#060606"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", gap: "50px", width: "250px" }}>
                <div>
                  <svg
                    width="1"
                    height="50"
                    viewBox="0 0 1 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="0.5"
                      y1="49.1932"
                      x2="0.5"
                      y2="0.187511"
                      stroke="#D7D7D7"
                    />
                  </svg>
                </div>

                <div>
                  <p className="textTitleSummary">Cubagem total</p>
                  <div>
                    {cubedWeight > 0 ? (
                      `${cubedWeight} m³`
                    ) : (
                      <svg
                        width="8"
                        height="3"
                        viewBox="0 0 8 3"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                          fill="#060606"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                <div>
                  <svg
                    width="1"
                    height="50"
                    viewBox="0 0 1 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="0.5"
                      y1="49.1932"
                      x2="0.5"
                      y2="0.187511"
                      stroke="#D7D7D7"
                    />
                  </svg>
                </div>
                <div>
                  <p className="textTitleSummary">Transit time</p>
                  <div>
                    {transitTime ? (
                      transitTime
                    ) : (
                      <svg
                        width="8"
                        height="3"
                        viewBox="0 0 8 3"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                          fill="#060606"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", gap: "50px", width: "200px" }}>
                <div>
                  <svg
                    width="1"
                    height="50"
                    viewBox="0 0 1 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="0.5"
                      y1="49.1932"
                      x2="0.5"
                      y2="0.187511"
                      stroke="#D7D7D7"
                    />
                  </svg>
                </div>
                <div>
                  <p className="textTitleSummary">Valor total</p>
                  <div>
                    {/* <svg
                      width="8"
                      height="3"
                      viewBox="0 0 8 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                        fill="#060606"
                      />
                    </svg> */}
                    {(freight + toll).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              style={{
                display: "flex",
              }}
            ></div> */}
          </>
        </div>
      )}
    </>
  );
};

export default CardInfoQuotationGeneric;
