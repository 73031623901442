import ContainerInputSearchAndButton from "../../../components/ContainerInputSearchAndButton";
import HeaderDashboard from "../../../components/HeaderDashboard";
import SearchProposalsContainer from "../../../components/SearchProposalsContainer";
import Sidebar from "../../../components/Sidebar";
import TableProposalContent from "../../../components/TableProposalContent";
import TableProposalHeader from "../../../components/TableProposalHeader";
import ThowColumLayout from "../../../layouts/ThowColumLayout";

const ProposalPage = () => {
  const container = {
    maxWidth: "100%",
    paddingRight: "4.125rem",
    paddingTop: "2.6875rem",
    paddingLeft: "3.375rem",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginBottom: "20px",
  };

  return (
    <ThowColumLayout
      page={"DashboardAdm"}
      colum1={<Sidebar page="Proposals" access="adm" />}
      colum2={
        <div
          style={{
            backgroundColor: "#F9F9F9",
            minHeight: "100vh",
          }}
        >
          <HeaderDashboard name="Propostas" adm={true} />

          <div className="boxContainerCard" style={container}>
            <SearchProposalsContainer />

            <TableProposalHeader />
            <TableProposalContent />
          </div>
        </div>
      }
    ></ThowColumLayout>
  );
};

export default ProposalPage;
