import { useContext, useState } from "react";
import Input from "./Input";
import Textarea from "./Textarea";
import UploadImg from "./UploadImg";
import InputBox from "./InputBox";
import { useEffect } from "react";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import axios from "axios";
import ModalOptionsFilterCitys from "./ModalOptionsFilterCitys";

const SectionSelectQuotationSummaryNational = () => {
  const { dataStates, setDataStates, setComments, fileStates, setFileStates } =
    useContext(DashboardContext);

  const [countiesArray, setCountiesArray] = useState([]);
  const [countiesArrayFilter, setCountiesArrayFilter] = useState([]);
  const [stateInput, setStateInput] = useState(false);
  const [value, setValue] = useState("");

  const removeCard = (index) => {
    const newArr = dataStates;

    const response = newArr.filter((item, i) => {
      if (i !== index) {
        return item;
      }
    });

    setDataStates(response);
  };

  const getAllCounties = async () => {
    const { data: counties } = await axios.get(
      "https://servicodados.ibge.gov.br/api/v1/localidades/municipios"
    );

    const { data: statesAll } = await axios.get(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
    );

    setCountiesArray([...statesAll, ...counties]);
  };

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const getOneCounties = async (valueChange) => {
    const filterCitys = countiesArray.filter((city) => {
      const cityNameWithoutAccents = removeAccents(city.nome.toLowerCase());
      const searchValueWithoutAccents = removeAccents(value.toLowerCase());

      return cityNameWithoutAccents.includes(searchValueWithoutAccents);
    });

    setValue(valueChange);
    setCountiesArrayFilter(filterCitys);
  };

  useEffect(() => {
    getAllCounties();
  }, []);

  useEffect(() => {
    if (dataStates == 0) {
      setStateInput(true);
    }
  }, [dataStates]);

  useEffect(() => {
    if (!stateInput) {
      if (String(value).length > 0) {
        setDataStates([...dataStates, value]);
        setValue("");
      }
    }
  }, [stateInput]);

  const container = {
    backgroundColor: "#fff",
    maxWidth: "1079px",
    padding: "32px",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  };

  return (
    <div className="containerBox" style={container}>
      <div>
        <h3 className="textTitleTypeDispatch">Informações da cotação</h3>
      </div>
      <div>
        {stateInput ? (
          <div style={{ position: "relative" }}>
            <Input
              onBlur={() => dataStates.length > 0 && setStateInput(false)}
              onChange={(e) => getOneCounties(e.target.value)}
              //   onChange={(e) => setValue(e.target.value)}
              name="Insira os estados que tem interrese para a distribuição"
              width="100%"
              states={true}
              setStateInput={setStateInput}
              placeholder="Selecione"
              type={"text"}
            />
            {value.length ? (
              <ModalOptionsFilterCitys
                list={countiesArrayFilter}
                onClick={(valueProps) => {
                  setValue(valueProps);

                  setStateInput(false);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <InputBox
            removeCard={removeCard}
            list={dataStates}
            name="Insira os estados que tem interrese para a distribuição"
            onClick={() => setStateInput(true)}
            data={dataStates}
          />
        )}
        <Textarea
          onChange={(e) => setComments(e.target.value)}
          name="Observações adicionais"
          height="83px"
          width="100%"
        />
        <UploadImg
          imagesFirebase={fileStates}
          setImagesFirebase={setFileStates}
          click={() => {}}
        />
      </div>
    </div>
  );
};

export default SectionSelectQuotationSummaryNational;
