

const RemoveCardInput = ({onClick}) => {

    return (
        <div onClick={onClick} style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
        
            cursor: "pointer"
        }}>
            <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1H11" stroke="var(--negative)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>


            <p className="textRemoveRouteCard">Remover ultima rota comercial</p>
        </div>
    )
}

export default RemoveCardInput