import { useState, useContext, useEffect } from "react";
import InputPrimeReact from "./InputPrimeReact";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import moment from "moment";

const CardInfoQuotationMaritime = ({ inconterm, type, index, check }) => {
  const { dataMaritime, setDataMaritime } = useContext(DashboardContext);

  // Porto de Origem
  const [originPort, setOriginPort] = useState("");

  // Coleta de container
  const [needContainerCollection, setNeedContainerCollection] = useState(false);

  // Local do carregamento do container
  const [containerLoadingLocation, setContainerLoadingLocation] = useState("");

  // Porto de Destino
  const [destinationPort, setDestinationPort] = useState("");

  // Precisarei da entrega do container
  const [containerDelivery, setContainerDelivery] = useState("");

  // Local do Descarregamento do Container
  const [containerUnloadingLocation, setContainerUnloadingLocation] =
    useState("");

  // Data de Embarque Desejada
  const [desiredShippingDate, setDesiredShippingDate] = useState(null);

  const [customsClearance, setCustomsClearance] = useState(null);

  // NCM da Carga
  const [cargoNCM, setCargoNCM] = useState("");

  // Opção de Carga
  const [loadOption, setLoadOption] = useState("");

  // Mercadoria
  const [merchandise, setMerchandise] = useState("");

  // Peso
  const [weight, setWeight] = useState(0);

  // Carga Perigosa
  const [hazardousCargo, setHazardousCargo] = useState(false);
  // Carga Perigosa

  // ONU
  const [onu, setOnu] = useState("");

  // Classe
  const [classType, setClassType] = useState("");

  // PG
  const [pg, setPg] = useState("");

  // Nome Técnico
  const [technicalName, setTechnicalName] = useState("");

  // Quantidade
  const [quantity, setQuantity] = useState("");

  // Tipo de Embalagem
  const [packagingType, setPackagingType] = useState("");

  // Carga Refrigerada
  const [refrigeratedCargo, setRefrigeratedCargo] = useState(false);

  // Temperatura (°C)
  const [temperature, setTemperature] = useState("");

  // Ventilação (M3/Hr)
  const [ventilation, setVentilation] = useState("");

  // Umidade (%)
  const [humidity, setHumidity] = useState("");

  // Quantidade de Containers
  const [containerQuantity, setContainerQuantity] = useState("");

  // Tipo e Tamanho de Container
  const [containerTypeSize, setContainerTypeSize] = useState("");

  // Carga Não Empilhável
  const [nonStackableCargo, setNonStackableCargo] = useState(false);

  // Dimensões
  const [dimensions, setDimensions] = useState("");

  // Quantidade de Pallets
  const [palletQuantity, setPalletQuantity] = useState("");

  // Tipo de Volumes
  const [palletType, setPalletType] = useState("");

  // Pagamento das Taxas Locais de Origem
  const [localOriginFeesPayment, setLocalOriginFeesPayment] = useState("");

  // Pagamento do THC de Destino
  const [destinationTHCPayment, setDestinationTHCPayment] = useState("");

  // Pagamento do frete
  const [seaFreight, setSeaFreight] = useState("");

  const handleOptionLoadChange = (e) => {
    setLoadOption(e.value);
  };

  // Função para capturar o preenchimento dos states
  const captureFormData = () => {
    return {
      "Porto de Origem": originPort,
      "Coleta de container": needContainerCollection,
      "Local do carregamento do container": containerLoadingLocation,
      "Porto de Destino": destinationPort,
      "Entrega do container": containerDelivery,
      "Local do Descarregamento do Container": containerUnloadingLocation,
      "Data de Embarque Desejada":
        moment(desiredShippingDate).format("DD/MM/YYYY"),
      "NCM da Carga": cargoNCM,
      "Opção de Carga": loadOption,
      Mercadoria: merchandise,
      Peso: weight,
      "Carga Perigosa": hazardousCargo,
      ONU: onu,
      Classe: classType,
      PG: pg,
      "Nome Técnico": technicalName,
      Quantidade: quantity,
      "Tipo de Embalagem": packagingType,
      "Carga Refrigerada": refrigeratedCargo,
      "Temperatura (°C)": temperature,
      "Ventilação (M³/Hr)": ventilation,
      "Umidade (%)": humidity,
      "Quantidade de Containers": containerQuantity,
      "Tipo e Tamanho de Container": containerTypeSize,
      "Carga Não Empilhável": nonStackableCargo,
      Dimensões: dimensions,
      "Quantidade de Volumes": palletQuantity,
      "Tipo de Volumes": palletType,
      "Pagamento das Taxas Locais de Origem": localOriginFeesPayment,
      "Pagamento do THC de Destino": destinationTHCPayment,
    };
  };

  useEffect(() => {
    // Captura os dados do formulário
    const formData = captureFormData();

    // Aqui você pode fazer algo com o formData, como enviá-lo para uma API
    setDataMaritime(formData);

    // Função de limpeza, se necessário
    return () => {
      console.log("Cleaning up form data...");
    };
  }, [
    originPort,
    needContainerCollection,
    containerLoadingLocation,
    destinationPort,
    containerDelivery,
    containerUnloadingLocation,
    desiredShippingDate,
    cargoNCM,
    loadOption,
    merchandise,
    weight,
    hazardousCargo,
    onu,
    classType,
    pg,
    technicalName,
    quantity,
    packagingType,
    refrigeratedCargo,
    temperature,
    ventilation,
    humidity,
    containerQuantity,
    containerTypeSize,
    nonStackableCargo,
    dimensions,
    palletQuantity,
    palletType,
    localOriginFeesPayment,
    destinationTHCPayment,
  ]);

  const containerBoxStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  };

  return (
    <div
      style={{
        width: "940px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      {/* Porto de Origem */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={containerBoxStyle}>
          <InputPrimeReact
            label="Porto de Origem (Cidade, País)"
            type="text"
            placeholder="Informe a cidade e o país"
            width="280px"
            value={originPort}
            onChange={(e) => setOriginPort(e.target.value)}
            className={"inputMaritime"}
          />

          {/* Flag para Coleta do Container */}
          <label className="labelCheckMaritime">
            <input
              type="checkbox"
              checked={needContainerCollection}
              onChange={(e) => setNeedContainerCollection(e.target.checked)}
            />
            Precisarei da coleta do container
          </label>
          {needContainerCollection && (
            <InputPrimeReact
              label="Local - Carregamento do Container"
              type="text"
              placeholder="Informe o local de carregamento"
              width="280px"
              value={containerLoadingLocation}
              onChange={(e) => setContainerLoadingLocation(e.target.value)}
              className={"inputMaritime"}
            />
          )}
        </div>

        <div style={containerBoxStyle}>
          {/* Porto de Destino */}
          <InputPrimeReact
            label="Porto de Destino (Cidade, País)"
            type="text"
            placeholder="Informe a cidade e o país"
            width="280px"
            value={destinationPort}
            onChange={(e) => setDestinationPort(e.target.value)}
            className={"inputMaritime"}
          />

          {/* Flag para Entrega do Container */}
          <label className="labelCheckMaritime">
            <input
              type="checkbox"
              checked={containerDelivery}
              onChange={(e) => setContainerDelivery(e.target.checked)}
            />
            Precisarei da entrega do container
          </label>
          {containerDelivery && (
            <InputPrimeReact
              label="Local - Descarregamento do Container"
              type="text"
              placeholder="Informe o local de descarregamento"
              width="280px"
              value={containerUnloadingLocation}
              onChange={(e) => setContainerUnloadingLocation(e.target.value)}
              className={"inputMaritime"}
            />
          )}
        </div>

        <div style={containerBoxStyle}>
          {/* Data de Embarque Desejada */}
          <InputPrimeReact
            label="Data de Embarque Desejada"
            type="calendar"
            placeholder="DD/MM/AAAA"
            value={desiredShippingDate}
            onChange={(e) => setDesiredShippingDate(e.target.value)} // Adicione o onChange handler apropriado
            className={"calendarMaritime"}
          />

          {/* Flag para Despacho Aduaneiro */}
          <label className="labelCheckMaritime">
            <input
              type="checkbox"
              checked={customsClearance}
              onChange={(e) => setCustomsClearance(e.target.checked)}
            />
            Precisarei do despacho aduaneiro
          </label>
          {customsClearance && (
            <InputPrimeReact
              label="NCM da Carga"
              type="text"
              placeholder="Informe o NCM da carga"
              width="280px"
              value={cargoNCM}
              onChange={(e) => setCargoNCM(e.target.value)}
              className={"inputMaritime"}
            />
          )}
        </div>
      </div>

      {/* Opção de Carga (FCL ou LCL) */}
      <InputPrimeReact
        label="Opção de Carga"
        options={[
          { label: "FCL", value: "FCL" },
          { label: "LCL", value: "LCL" },
        ]}
        onChange={handleOptionLoadChange}
        optionLabel="label"
        optionValue="value"
        placeholder="Selecione uma opção"
        value={loadOption}
        type="dropdown"
        className={"selecatMaritime"}
      />

      {loadOption === "FCL" && (
        <>
          {/* Mercadoria */}

          <InputPrimeReact
            label="Mercadoria"
            type="text"
            placeholder="Informe a mercadoria"
            value={merchandise}
            onChange={(e) => setMerchandise(e.target.value)} // Adicione o onChange handler apropriado
            width={"100%"}
            className={"inputMaritime"}
          />

          <InputPrimeReact
            label="Peso Bruto"
            type="number"
            placeholder="KG"
            onChange={(e) => setWeight(e.value)} // Adicione o onChange handler apropriado
            className={"inputNumberMaritime"}
            suffix=" KG"
            mode="decimal"
            // minFractionDigits={2}
          />

          {/* Carga Perigosa */}
          <label className="labelCheckMaritime">
            <input
              type="checkbox"
              checked={hazardousCargo}
              onChange={(e) => setHazardousCargo(e.target.checked)}
            />
            Carga Perigosa
          </label>
          {hazardousCargo && (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <InputPrimeReact
                  label="ONU"
                  type="text"
                  placeholder="Informe o código ONU"
                  onChange={(e) => setOnu(e.target.value)} // Adicione o onChange handler apropriado
                  width={"280px"}
                  className={"inputMaritime"}
                  value={onu}
                />
                <InputPrimeReact
                  label="Classe"
                  type="text"
                  placeholder="Informe a classe"
                  value={classType}
                  onChange={(e) => setClassType(e.target.value)} // Adicione o onChange handler apropriado
                  width={"280px"}
                  className={"inputMaritime"}
                />
                <InputPrimeReact
                  label="PG"
                  type="text"
                  placeholder="Informe o PG"
                  value={pg}
                  onChange={(e) => setPg(e.target.value)} // Adicione o onChange handler apropriado
                  width={"280px"}
                  className={"inputMaritime"}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <InputPrimeReact
                  label="Nome Técnico"
                  type="text"
                  placeholder="Informe o nome técnico da carga"
                  value={technicalName}
                  onChange={(e) => setTechnicalName(e.target.value)} // Adicione o onChange handler apropriado
                  width={"280px"}
                  className={"inputMaritime"}
                />
                <InputPrimeReact
                  label="Quantidade"
                  type="number"
                  placeholder="Informe a quantidade"
                  value={quantity}
                  onChange={(e) => setQuantity(e.value)} // Adicione o onChange handler apropriado
                  width={"280px"}
                  className={"inputNumberTwoMaritime"}
                />
                <InputPrimeReact
                  label="Tipo de Embalagem"
                  type="text"
                  placeholder="Informe o tipo de embalagem"
                  value={packagingType}
                  onChange={(e) => setPackagingType(e.target.value)} // Adicione o onChange handler apropriado
                  width={"280px"}
                  className={"inputMaritime"}
                />
              </div>
            </>
          )}

          {/* Carga Refrigerada */}
          <label className="labelCheckMaritime">
            <input
              type="checkbox"
              checked={refrigeratedCargo}
              onChange={(e) => setRefrigeratedCargo(e.target.checked)}
            />
            Carga Refrigerada
          </label>
          {refrigeratedCargo && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <InputPrimeReact
                label="Temperatura (°C)"
                type="number"
                placeholder="Informe a temperatura"
                onChange={(e) => setTemperature(e.value)} // Adicione o onChange handler apropriado
                className={"inputNumberTwoMaritime"}
                suffix={" °C"}
              />
              <InputPrimeReact
                label="Ventilação (M3/Hr)"
                type="number"
                placeholder="Informe a ventilação"
                onChange={(e) => setVentilation(e.value)} // Adicione o onChange handler apropriado
                className={"inputNumberTwoMaritime"}
                suffix={" m³/h"}
              />
              <InputPrimeReact
                label="Umidade (%)"
                type="number"
                placeholder="Informe a umidade"
                onChange={(e) => setHumidity(e.value)} // Adicione o onChange handler apropriado
                className={"inputNumberTwoMaritime"}
                suffix={" %"}
              />
            </div>
          )}

          {/* Quantidade de Containers */}
          <InputPrimeReact
            label="Quantidade de Containers"
            type="number"
            placeholder="Informe a quantidade"
            onChange={(e) => setContainerQuantity(e.value)} // Adicione o onChange handler apropriado
            className={"inputNumberMaritime"}
          />

          {/* Tipo e Tamanho de Container */}
          <InputPrimeReact
            label="Tipo e Tamanho de Container"
            type="dropdown"
            options={[
              { label: "20 DRY", value: "20 DRY" },
              { label: "40 DRY", value: "40 DRY" },
              { label: "40 DRY HC", value: "40 DRY HC" },
              { label: "20 REEF", value: "20 REEF" },
              { label: "40 REEF", value: "40 REEF" },
            ]}
            placeholder="Selecione o tipo e tamanho do container"
            value={containerTypeSize}
            onChange={(e) => setContainerTypeSize(e.value)} // Adicione o onChange handler apropriado
            className={"selecatMaritime"}
          />
        </>
      )}

      {loadOption === "LCL" && (
        <>
          {/* Carga Não Empilhável */}
          <label className="labelCheckMaritime">
            <input
              type="checkbox"
              checked={nonStackableCargo}
              onChange={(e) => setNonStackableCargo(e.target.checked)}
            />
            Carga Não Empilhável
          </label>
          {nonStackableCargo && (
            <InputPrimeReact
              label="Dimensões"
              type="text"
              width="280px"
              placeholder="Informe as dimensões da carga"
              value={dimensions}
              onChange={(e) => setDimensions(e.target.value)} // Adicione o onChange handler apropriado
              className={"inputMaritime"}
            />
          )}

          {/* Quantidade de Volumes */}

          <InputPrimeReact
            label="Peso Bruto"
            type="number"
            placeholder="KG"
            onChange={(e) => setWeight(e.value)} // Adicione o onChange handler apropriado
            className={"inputNumberMaritime"}
            suffix=" KG"
            mode="decimal"
            // minFractionDigits={2}
          />

          <InputPrimeReact
            label="Quantidade de Volumes"
            type="number"
            placeholder="Informe a quantidade"
            onChange={(e) => setPalletQuantity(e.value)} // Adicione o onChange handler apropriado
          />

          {/* Tipo de Volumes */}

          <InputPrimeReact
            label="Tipo de Volumes"
            type="text"
            width="280px"
            placeholder="Informe as dimensões da carga"
            value={palletType}
            onChange={(e) => setPalletType(e.target.value)} // Adicione o onChange handler apropriado
            className={"inputMaritime"}
          />
        </>
      )}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Pagamento das Taxas Locais de Origem */}
        <InputPrimeReact
          label="Pagamento das Taxas Locais de Origem"
          type="dropdown"
          options={[
            { label: "Brasil", value: "Brasil" },
            { label: "Exterior", value: "Exterior" },
          ]}
          value={localOriginFeesPayment}
          onChange={(e) => setLocalOriginFeesPayment(e.value)}
          placeholder="Selecione o local"
          className={"selecatMaritime"}
        />

        {/* Pagamento do THC de Destino */}
        <InputPrimeReact
          label="Pagamento do THC de Destino"
          type="dropdown"
          options={[
            { label: "Brasil", value: "Brasil" },
            { label: "Exterior", value: "Exterior" },
          ]}
          placeholder="Selecione o local"
          className={"selecatMaritime"}
          value={destinationTHCPayment}
          onChange={(e) => setDestinationTHCPayment(e.value)}
        />

        {/* Pagamento do frete */}
        <InputPrimeReact
          label="Pagamento do frete"
          type="dropdown"
          options={[
            { label: "Brasil", value: "Brasil" },
            { label: "Exterior", value: "Exterior" },
          ]}
          placeholder="Selecione o local"
          className={"selecatMaritime"}
          value={seaFreight}
          onChange={(e) => setSeaFreight(e.value)}
        />
      </div>
    </div>
  );
};

export default CardInfoQuotationMaritime;
