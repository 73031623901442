import { useContext, useState } from "react";
import Input from "./Input";
import InputSelectFilterManager from "./InputSelectFilterManager";
import InputsFreightQuote from "./InputsFreightQuote";
import Textarea from "./Textarea";
import UploadImg from "./UploadImg";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import { useEffect } from "react";
import api from "../services/api";
import axios from "axios";
import InputsFreightQuoteInternational from "./InputsFreightQuoteInternational";
import { toast } from "react-toastify";
import { ProposalContext } from "../context/Dashboard/ProposalContext";
import InputPrimeReact from "./InputPrimeReact";
// import InputSelectPrimeReact from "./InputSelectPrimeReact";

const CardInfoQuotationInternational = ({
  inconterm,
  type,
  index,
  imo,
  servicesAdd,
}) => {
  const {
    getOrigin,
    getDestination,
    dataCheckFractional,
    setDataCheckFractional,
  } = useContext(DashboardContext);

  const { btnStateInternationLTL } = useContext(ProposalContext);

  /* DATA LOCALE */
  const [dataLocation, setDataLocation] = useState({});

  /* INPUTS VALUE */
  const [originFraction, setOriginFraction] = useState("");
  const [originDestination, setOriginDestination] = useState("");
  const [originDestinationExemplo, setOriginDestinationExemplo] = useState("");

  const [selectDestination, setSelectDestination] = useState([]);
  const [destination, setDestination] = useState("");

  const [inputActive, setInputActive] = useState(false);
  const [commodityPrice, setCommodityPrice] = useState(0);
  const [merchandiseFraction, setMerchandiseFraction] = useState("");

  const [imagesBase64, setImagesBase64] = useState([]);
  const [imagesFirebase, setImagesFirebase] = useState([]);
  const [dataSummaryResume, setDataSummaryResume] = useState();

  /* QUOTATION SUMMARY */
  const [freightWeightValue, setFreightWeightValue] = useState(0);
  const [dispatchValue, setdispatchValue] = useState(0);
  const [sefazTaxValue, setSefazTaxValue] = useState(0);

  const [advaloremValue, setAdvaloremValue] = useState(0);
  const [tollValue, setTollValue] = useState(0);

  const [cubageTotal, setCubageTotal] = useState(0);

  const [updateValue, setUpdateValue] = useState(false);
  const [cityList, setCityList] = useState([]);

  const [statesList, setStatesList] = useState([]);
  const [originInternarional, setOriginInternarional] = useState("");
  const [destinationInternational, setDestinationInternational] = useState("");

  const [incontermInternational, setIncontermInternational] = useState("");
  const [imoInternational, setImoInternational] = useState("");
  const [originFractionInternational, setOriginFractionInternational] =
    useState("");

  const [stateSelectUser, setStateSelectUser] = useState("");
  const [emissionClearance, setEmissionClearance] = useState("");
  const [issuanceCertifOrigin, setIssuanceCertifOrigin] = useState("");

  const [additionalIssuanceCertifOrigin, setAdditionalIssuanceCertifOrigin] =
    useState("");
  const [helperFee, setHelperFee] = useState("");
  const [priceTotalStanded, setPriceTotalStanded] = useState("");
  const [priceTotalPremium, setPriceTotalPremium] = useState("");

  const [addressInternarional, setAddressInternarional] = useState("");
  const [addressInternarionalState, setAddressInternarionalState] =
    useState("");
  const [addressInternarionalOptional, setAddressInternarionalOptional] =
    useState("");

  const [
    addressInternarionalOptionalActive,
    setAddressInternarionalOptionalActive,
  ] = useState(false);

  const [kindOfPacking, setKindOfPacking] = useState("");
  const [palletizedLoad, setPalletizedLoad] = useState("");
  const [stackedLoad, setStackedLoad] = useState("");

  const [coinType, setCoinType] = useState("");
  const [originOption, setOriginOption] = useState([]);

  const [emissionClearancePrice, setEmissionClearancePrice] = useState("");
  const [issuanceCertifOriginPrice, setIssuanceCertifOriginPrice] =
    useState("");
  const [
    additionalIssuanceCertifOriginPrice,
    setAdditionalIssuanceCertifOriginPrice,
  ] = useState("");

  const [helperFeePrice, setHelperFeePrice] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [hasRenderedOnce, setHasRenderedOnce] = useState(false);

  const [allStatesAndCitysToBrazil, setAllStatesAndCitysToBrazil] = useState(
    []
  );
  const [disableStateSelection, setDisableStateSelection] = useState(false);

  const [formOfPayment, setFormOfPayment] = useState("");

  const [multiLineCubing, setMultiLineCubing] = useState("");
  const [isMultiLineCubing, setIsMultiLineCubing] = useState(false);
  const [dimensions, setDimensions] = useState([]);

  const [isAduanaBuenos, setIsAduanaBuenos] = useState(false);
  const [visibleOriginBuenos, setVisibleOriginBuenos] = useState(true);
  const [collectionAddress, setCollectionAddress] = useState("");

  const getSelect = async (e) => {
    if (e.target.value != "default") {
      // USAR COM PRIME REACT
      const result = await getOrigin(e.value.nome, index);

      setOriginDestination(e.value.nome);

      setOriginDestinationExemplo(e.value);

      // const result = await getOrigin(e.target.value, index);

      // setOriginDestination(e.target.value);

      // setOriginDestinationExemplo(e.target.value);

      setSelectDestination({
        option: result.national,
      });
    } else {
      setSelectDestination({});
    }
  };

  const getTotalValues = async () => {
    let dataState = dataCheckFractional;
    console.log(dataSummaryResume?.total, dataSummaryResume?.grossWeight);

    dataState[index] = {
      routeOrigin: originInternarional,
      routeDest: destinationInternational,
      typeOrigem: originFractionInternational,
      addressOptional: addressInternarionalOptional,
      merchandiseFraction: merchandiseFraction,
      incontermInternational: incontermInternational,
      typePacking: kindOfPacking,
      stackedLoad: stackedLoad,
      state: stateSelectUser,
      city: originDestination,
      coinType: coinType,
      palletizedLoad: palletizedLoad,
      emissionClearance: emissionClearance,
      commodityPrice: commodityPrice,
      quantity: dataSummaryResume?.quantity,
      peso:
        dataSummaryResume?.total > dataSummaryResume?.cubageWeight
          ? dataSummaryResume?.total
          : dataSummaryResume?.cubageWeight,
      pesoClient: dataSummaryResume?.grossWeight,
      cubage: `${dataSummaryResume?.cubageTotal} m³`,
      emissionClearance: emissionClearance,
      issuanceCertifOrigin: issuanceCertifOrigin,
      additionalIssuanceCertifOrigin: additionalIssuanceCertifOrigin,
      helperFee: helperFee,
      quantityHelperFee: quantity,
      typePacking: kindOfPacking,
      hazardousCargo: imoInternational,
      merchandise: merchandiseFraction,
      collectionAddress,
      multiLineCubing: {
        quantitySum: multiLineCubing?.quantitySum,
        peso: multiLineCubing?.weightCubedSum,
      },
      dimensions,
      formOfPayment,
      type: type,
    };

    if (isMultiLineCubing) {
      dataState[index].peso = multiLineCubing?.weightCubedSum;
      dataState[index].cubage = `${Number(multiLineCubing?.cubageSum).toFixed(
        2
      )} m³`;
      dataState[index].quantity = multiLineCubing?.quantitySum;
      dataState[index].typePacking =
        multiLineCubing?.hasWood == true ? "Caixas de madeira" : kindOfPacking;
    }

    console.log(dataState[index]);
    console.log(multiLineCubing);

    try {
      const { data: dataResponse } = await api.post(
        "/quotations/international/all",
        dataState[index]
      );

      if (dataResponse?.message == "Alguém do comercial entrara em contato") {
        return toast.info(
          "Sua cotação está fora do nosso raio de atendimento. Nosso consultor entrara em contato, após ser concluída essa cotação.",
          {
            position: "top-right",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else if (dataResponse?.exceededKmLimit || dataResponse?.argentinaEXW) {
        setdispatchValue(0);
        setSefazTaxValue(0);
        setEmissionClearancePrice("");

        setIssuanceCertifOriginPrice("");
        setAdditionalIssuanceCertifOriginPrice("");
        setHelperFeePrice();

        setCubageTotal(0);
        setPriceTotalStanded();
        setPriceTotalPremium("");
      } else {
        setdispatchValue(dataResponse?.freight);
        setSefazTaxValue(dataResponse?.adValorem);
        setEmissionClearancePrice(dataResponse?.dueClearanceRequired);

        setIssuanceCertifOriginPrice(dataResponse?.coCertificateOrigin);
        setAdditionalIssuanceCertifOriginPrice(
          dataResponse?.additionalCoCertificateOrigin
        );
        setHelperFeePrice(dataResponse?.helperFee);

        setCubageTotal(dataResponse?.cubage);
        setPriceTotalStanded(dataResponse?.totalPriceMoeda);
        setPriceTotalPremium(dataResponse?.totalPriceMoedaPremium);
      }

      dataState[index].palletizedLoadShoose =
        dataResponse?.palletizedLoadShoose;
      dataState[index].hazardousCargoShoose =
        dataResponse?.hazardousCargoShoose;
      dataState[index].stackedLoadShoose = dataResponse?.stackedLoadShoose;
      dataState[index].coinTypeShoose = dataResponse?.coinTypeShoose;
      dataState[index].emissionClearanceShoose =
        dataResponse?.emissionClearanceShoose;
      dataState[index].issuanceCertifOriginShoose =
        dataResponse?.issuanceCertifOriginShoose;
      dataState[index].additionalIssuanceCertifOriginShoose =
        dataResponse?.additionalIssuanceCertifOriginShoose;
      dataState[index].additionalIssuanceCertifOrigin =
        dataResponse?.additionalIssuanceCertifOrigin;
      dataState[index].helperFeeShoose = dataResponse?.helperFeeShoose;

      dataState[index].quantityHelperFeeShoose =
        dataResponse?.quantityHelperFeeShoose;
      dataState[index].freight = dataResponse?.freight;
      dataState[index].adValorem = dataResponse?.adValorem;
      dataState[index].dueClearanceRequired =
        dataResponse?.dueClearanceRequired;
      dataState[index].coCertificateOrigin = dataResponse?.coCertificateOrigin;
      dataState[index].additionalCoCertificateOrigin =
        dataResponse?.additionalCoCertificateOrigin;
      dataState[index].helperFee = dataResponse?.helperFee;
      dataState[index].cubage = dataResponse?.cubage;
      dataState[index].coinToday = dataResponse?.coinToday;
      dataState[index].totalPriceMoeda = dataResponse?.totalPriceMoeda;
      dataState[index].peso = dataResponse?.peso;
      dataState[index].totalPriceMoedaPremium =
        dataResponse?.totalPriceMoedaPremium;
      dataState[index].routeOrigin = dataResponse?.routeOrigin;
      dataState[index].routeDest = dataResponse?.routeDest;
      dataState[index].exceededKmLimit = dataResponse?.exceededKmLimit;
      dataState[index].distanceInKm = dataResponse?.distanceInKm;
      dataState[index].argentinaEXW = dataResponse?.argentinaEXW;
      dataState[index].inconterm = incontermInternational;
    } catch (error) {
      console.log(error);
      const message = error.response.data.message;

      return toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    setDataCheckFractional(dataState);
  };

  const getStatesInBrazil = async () => {
    try {
      const getStates = await axios.get(
        "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
      );

      setStatesList(getStates.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCityInBrazil = async (e, stateFormact) => {
    try {
      const state = e?.target?.value ?? stateFormact;
      console.log(stateFormact, 100);
      setStateSelectUser(state);
      // setOriginDestination("");
      const getCitys = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state.sigla}/municipios?orderBy=nome`
      );

      console.log(getCitys);
      setCityList(getCitys.data);
    } catch (error) {}
  };

  const getStatesByOrigin = async (e) => {
    setOriginFractionInternational(e.target.value);
    if (
      e.target.value !== "Coletar | Pick-up" &&
      e.target.value !== "Coletar" &&
      e.target.value !== "default"
    ) {
      if (e.target.value.split("–").length > 1) {
        const state = e.target.value.split("–")[1].trim();

        if (!state.toLowerCase().includes("buenos")) {
          const getCitys = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios?orderBy=nome`
          );

          console.log(state);

          setCityList(getCitys.data);
        }
      } else {
        const state = e.target.value.split("-")[1].trim();

        if (!state.toLowerCase().includes("buenos")) {
          const getCitys = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios?orderBy=nome`
          );
          setCityList(getCitys.data);
        }
      }
    }
  };

  const getOrigins = async (e) => {
    try {
      const response = await api.get("/quotations/international/origin");

      const formactValues = response?.data.map((elem) => {
        const destiny =
          elem?.destiny?.split("–")[0] ?? elem?.destiny?.split("-")[0];
        return { ...elem, destiny };
      });

      setOriginOption(formactValues);
    } catch (error) {
      console.error(error);
    }
  };

  // Função para buscar a lista de municípios do IBGE
  const fetchStates = async () => {
    try {
      const response = await axios.get(
        "https://servicodados.ibge.gov.br/api/v1/localidades/municipios"
      );
      const municipios = response.data;

      setAllStatesAndCitysToBrazil(municipios);
    } catch (error) {
      console.error("Erro ao buscar estados:", error);
    }
  };

  // Função para remover pontuação
  const removePunctuation = (text) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s]/gi, "")
      .toLowerCase();
  };

  // FUNÇÃO DEIXA PRE SETADO O ESTADO NO CAMPO 'SELECIONAR ESTADO'
  const onSelectStateOrigin = () => {
    const nameClean = removePunctuation(originInternarional);

    if (nameClean.includes("outros")) {
      setDisableStateSelection(false);
      return false;
    }

    const filterStateSelect = allStatesAndCitysToBrazil.find(
      (states) => removePunctuation(states.nome) === nameClean
    );

    const formactState = filterStateSelect?.microrregiao?.mesorregiao?.UF;

    if (formactState) {
      setStateSelectUser(formactState);
      setDisableStateSelection(true);
      getCityInBrazil("", formactState);
    } else setDisableStateSelection(false);
  };

  // FUNÇÃO DEIXA PRE SETADO O ESTADO NO CAMPO 'SELECIONAR ESTADO'
  const onSelectStateDestination = () => {
    if (originFractionInternational === "Coletar") {
      const nameClean = removePunctuation(destinationInternational?.trim());

      console.log(nameClean, "<==== DESTINO");

      if (nameClean.includes("outros")) {
        setDisableStateSelection(false);
        return false;
      }

      const filterStateSelect = allStatesAndCitysToBrazil.find(
        (states) => removePunctuation(states.nome) === nameClean
      );

      const formactState = filterStateSelect?.microrregiao?.mesorregiao?.UF;

      if (formactState) {
        setStateSelectUser(formactState);
        setDisableStateSelection(true);
        getCityInBrazil("", formactState);
      } else setDisableStateSelection(false);
    }
  };

  useEffect(() => {
    originInternarional && onSelectStateOrigin();
  }, [originInternarional]);

  useEffect(() => {
    destinationInternational && onSelectStateDestination();
  }, [destinationInternational, originFractionInternational]);

  useEffect(() => {
    if (
      originFractionInternational == "Coletar | Pick-up" ||
      originFractionInternational == "Coletar"
    ) {
      setAddressInternarionalOptionalActive(true);
    } else {
      setAddressInternarionalOptionalActive(false);
    }
  }, [originFractionInternational]);

  useEffect(() => {
    setCommodityPrice("");
  }, [coinType]);

  useEffect(() => {
    setInputActive(true);
    if (originOption.length > 0) {
      originOption.map((item) => {
        if (item.origin.includes(originInternarional)) {
          console.log(item);
          if (imoInternational === "Sim") {
            console.log(item.cityImo, 6666);
            setOriginFraction(item.cityImo);
            setAddressInternarionalState(item.finalDestination);
            setAddressInternarional(item.addressImo);
            setInputActive(true);
          } else {
            setOriginFraction(item.city);
            setAddressInternarionalState(item.finalDestination);
            setAddressInternarional(item.address);
            setInputActive(true);
          }
        }
      });
    } else {
      setInputActive(true);
      setAddressInternarional("");
      setAddressInternarionalState("");
    }

    if (
      originInternarional != "default" &&
      originInternarional != "" &&
      destinationInternational != "default" &&
      destinationInternational != "" &&
      incontermInternational != "default" &&
      incontermInternational != "" &&
      imoInternational != "default" &&
      imoInternational != "" &&
      originFractionInternational != "default" &&
      originFractionInternational != "" &&
      stateSelectUser != "default" &&
      stateSelectUser != "" &&
      originFraction
    ) {
      setInputActive(true);
    }
  }, [
    originInternarional,
    destinationInternational,
    incontermInternational,
    imoInternational,
    originFractionInternational,
    stateSelectUser,
    originFraction,
  ]);

  useEffect(() => {
    if (originOption.length > 0) {
      originOption.map((item) => {
        if (item.origin.includes(originInternarional)) {
          console.log(item.city);

          if (imoInternational === "Sim") {
            setOriginFraction(item.cityImo);
            setAddressInternarional(item.addressImo);
            setInputActive(true);
          } else {
            setOriginFraction(item.city);
            setAddressInternarional(item.address);
            setInputActive(true);
          }
        }
      });
    } else {
      setInputActive(false);
      setAddressInternarional("");
      setAddressInternarionalState("");
    }
  }, [imoInternational]);

  useEffect(() => {
    getStatesInBrazil();
    getOrigins();
    fetchStates();
  }, []);

  useEffect(() => {
    console.log(
      dataSummaryResume,
      multiLineCubing,
      originFractionInternational
    );
  }, [dataSummaryResume, multiLineCubing, originFractionInternational]);

  useEffect(() => {
    if (hasRenderedOnce) {
      getTotalValues();
    }
    setHasRenderedOnce(true);
  }, [btnStateInternationLTL]);

  useEffect(() => {
    if (originFractionInternational === "Aduana fiscal – Buenos Aries") {
      setIsAduanaBuenos(true);
      setCollectionAddress("");
    } else {
      setIsAduanaBuenos(false);
    }
  }, [originFractionInternational]);

  useEffect(() => {
    if (
      incontermInternational === "EXW" &&
      originInternarional === "Buenos Aries"
    ) {
      setVisibleOriginBuenos(false);
      setIsAduanaBuenos(false);
    } else {
      setVisibleOriginBuenos(true);
    }
  }, [incontermInternational, originInternarional]);

  const boxInputs = {
    display: "flex",
    gap: "20px",
  };

  const containerContent = {
    backgroundColor: "#fff",
    width: "100%",
    padding: "32px",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginTop: "30px",
  };

  return (
    <>
      <h3 className="textTitleTypeDispatch">Informações da cotação</h3>
      <div style={{ ...boxInputs, marginTop: "20px" }}>
        <InputSelectFilterManager
          onChange={(e) => setOriginInternarional(e.target.value)}
          index={index}
          color="var(--grey-1)"
          name="Rota comercial de origem"
          width={inconterm ? "100%" : "290px"}
          top="50px"
          custom="false"
          type="originInternational"
          value={originOption}
        />

        <InputSelectFilterManager
          onChange={(e) => setDestinationInternational(e.target.value)}
          value={selectDestination}
          index={index}
          originProps={originInternarional}
          color="var(--grey-1)"
          name="Rota comercial de destino"
          width={inconterm ? "100%" : "290px"}
          top="50px"
          custom="false"
          type="destinationInternational"
          options={originOption}
        />

        {inconterm && (
          <>
            <InputSelectFilterManager
              onChange={(e) => setIncontermInternational(e.target.value)}
              color="var(--grey-1)"
              name="Inconterm"
              width={inconterm ? "100%" : "290px"}
              top="50px"
              custom="false"
              type="inconterm"
            />
          </>
        )}
        {imo && (
          <>
            <InputSelectFilterManager
              onChange={(e) => setImoInternational(e.target.value)}
              color="var(--grey-1)"
              name="Carga imo"
              width={imo ? "100%" : "290px"}
              top="50px"
              custom="false"
              type="imo"
            />
          </>
        )}
      </div>

      <div
        style={{
          ...boxInputs,
          marginTop: "20px",
          display: visibleOriginBuenos ? "flex" : "none",
        }}
      >
        <InputSelectFilterManager
          stateSelectUser={stateSelectUser}
          onChange={getStatesByOrigin}
          index={index}
          color="var(--grey-1)"
          name="Origem"
          width={inconterm ? "100%" : "290px"}
          top="50px"
          custom="false"
          type={
            originInternarional.toLowerCase().includes("buenos")
              ? "routBuenos"
              : "rout"
          }
          isExw={incontermInternational !== "EXW"}
          options={originOption}
          originProps={originInternarional}
        />
        {originFractionInternational !== "Coletar | Pick-up" ? (
          <></>
        ) : (
          <InputPrimeReact
            label="Estado de coleta"
            options={statesList}
            onChange={getCityInBrazil}
            value={stateSelectUser}
            optionLabel="nome"
            optionValue="sigla"
            type={"dropdownInput"}
            width={"300px"}
            disabled={disableStateSelection}
            idStyle={"selectTable"}
          />
          // <InputSelectFilterManager
          //   statesLists={statesList}
          //   stateSelectUser={getCityInBrazil}
          //   selectState={originFractionInternational}
          //   onChange={getCityInBrazil}
          //   index={index}
          //   color="var(--grey-1)"
          //   name="Selecionar estado"
          //   width={inconterm ? "100%" : "290px"}
          //   top="50px"
          //   custom="false"
          //   type={"state"}
          // />
        )}

        {originFractionInternational !== "Coletar | Pick-up" ? (
          <></>
        ) : (
          <InputPrimeReact
            label="Cidade de coleta"
            options={cityList}
            onChange={getSelect}
            value={originDestinationExemplo}
            optionLabel="nome"
            type={"dropdownInput"}
            width={"300px"}
            idStyle={"selectTable"}
          />
        )}
        {originFractionInternational === "Coletar | Pick-up" ||
        originFractionInternational === "Coletar" ? (
          <></>
        ) : (
          <Input
            isActive={addressInternarionalOptionalActive}
            value={
              addressInternarionalOptionalActive
                ? addressInternarionalOptional
                : addressInternarional
            }
            name={
              addressInternarionalOptionalActive
                ? "Endereço opcional"
                : "Endereço"
            }
            width="100%"
            placeholder=""
            type={"text"}
          />
        )}
        {/* <Input
          isActive={false}
          value={addressInternarionalState}
          name="Destino final"
          width="100%"
          placeholder=""
          type={"text"}
        /> */}
      </div>

      {originInternarional === "Buenos Aries" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Input
            isActive={!isAduanaBuenos}
            onBlur={() => setUpdateValue(!updateValue)}
            onChange={(e) => {
              setCollectionAddress(e.target.value);
            }}
            name="Endereço de coleta"
            width="100%"
            placeholder=""
            type={"text"}
            value={collectionAddress}
          />

          <InputPrimeReact
            label="Estado de entrega"
            options={statesList}
            onChange={getCityInBrazil}
            value={stateSelectUser}
            optionLabel="nome"
            optionValue="sigla"
            type={"dropdownInput"}
            width={"300px"}
            disabled={disableStateSelection}
            idStyle={"selectTable"}
          />
          <InputPrimeReact
            label="Cidade de entrega"
            options={cityList}
            onChange={getSelect}
            value={originDestinationExemplo}
            optionLabel="nome"
            type={"dropdownInput"}
            width={"300px"}
            idStyle={"selectTable"}
          />
        </div>
      ) : (
        <></>
      )}

      <div style={{ ...boxInputs }}>
        {originFractionInternational !== "Coletar | Pick-up" ? (
          <></>
        ) : (
          <Input
            isActive={addressInternarionalOptionalActive}
            value={
              addressInternarionalOptionalActive
                ? addressInternarionalOptional
                : addressInternarional
            }
            name={
              addressInternarionalOptionalActive
                ? "Endereço opcional"
                : "Endereço"
            }
            onChange={(e) => setAddressInternarionalOptional(e.target.value)}
            width="100%"
            placeholder=""
            type={"text"}
          />
        )}
      </div>

      <div style={boxInputs}>
        <Input
          isActive={inputActive}
          onBlur={() => setUpdateValue(!updateValue)}
          onChange={(e) => {
            setMerchandiseFraction(e.target.value);
          }}
          name="Tipo de mercadoria"
          width="100%"
          placeholder=""
          type={"text"}
        />

        <InputSelectFilterManager
          isActive={inputActive}
          onChange={(e) => setPalletizedLoad(e.target.value)}
          color="var(--grey-1)"
          name="Carga paletizada?"
          width="100%"
          top="50px"
          custom="false"
          type="boolean"
        />

        <InputSelectFilterManager
          isActive={palletizedLoad != ""}
          onChange={(e) => setKindOfPacking(e.target.value)}
          color="var(--grey-1)"
          name="Tipo de embalagem"
          width="100%"
          top="50px"
          custom="false"
          type="kindOfPacking"
        />
      </div>

      <div style={boxInputs}>
        <InputSelectFilterManager
          isActive={inputActive}
          onChange={(e) => setStackedLoad(e.target.value)}
          color="var(--grey-1)"
          name="A carga empilhada?"
          width="100%"
          top="50px"
          custom="false"
          type="boolean"
        />

        <InputSelectFilterManager
          // isActive={inputActive}
          onChange={(e) => setCoinType(e.target.value)}
          color="var(--grey-1)"
          name="Moeda"
          width="100%"
          top="50px"
          custom="false"
          type="typeMoeda"
        />

        {/* <Input
          //isActive={inputActive}
          onBlur={() => convertToBRL(commodityPrice, setCommodityPrice)}
          onClick={() => convertToStr(commodityPrice, setCommodityPrice)}
          value={commodityPrice}
          onChange={(e) => {
            setCommodityPrice(e.target.value);
          }}
          name="Valor da mercadoria"
          width="100%"
          placeholder="0,00"
          type={"text"}
          marginBottom="10px"
        /> */}
        <InputPrimeReact
          label={"Valor da mercadoria"}
          value={commodityPrice}
          onChange={(e) => setCommodityPrice(e.value)}
          type={"currency"}
          width={"300px"}
          className={"currencyTable"}
          currencyType={coinType}
        />
      </div>

      <div style={boxInputs}>
        {
          <InputsFreightQuoteInternational
            updateCubage={() => setUpdateValue(!updateValue)}
            isActive={inputActive}
            sumResume={dataSummaryResume}
            setSumResume={setDataSummaryResume}
            index={index}
            setMultiLineCubing={setMultiLineCubing}
            setIsMultiLineCubing={setIsMultiLineCubing}
            isMultiLineCubing={isMultiLineCubing}
            setDimensions={setDimensions}
          />
        }
      </div>

      <div>
        {servicesAdd && (
          <>
            <h3 className="textTitleTypeDispatch">
              Serviços adicionais - Brasil (Opcional)
            </h3>

            <div style={{ ...boxInputs, margin: "20px 0 0 0" }}>
              <InputSelectFilterManager
                isActive={inputActive}
                onChange={(e) => setEmissionClearance(e.target.value)}
                color="var(--grey-1)"
                name={`${
                  originInternarional.toLowerCase().includes("buenos")
                    ? "Desembaraço aduaneiro export/import"
                    : "Emissão  DUE-Desembaraço"
                }?`}
                width="100%"
                top="50px"
                custom="false"
                type="boolean"
              />

              <Input
                isActive={false}
                value={emissionClearancePrice}
                name={`Valor  ${
                  incontermInternational === "EXW" ? "Incluso" : "da emissão"
                }`}
                width="150px"
                placeholder="0,00"
                type={"text"}
                marginBottom="10px"
              />

              <InputSelectFilterManager
                isActive={inputActive}
                color="var(--grey-1)"
                name="Emissão CO_certif origem ?"
                onChange={(e) => setIssuanceCertifOrigin(e.target.value)}
                width="100%"
                top="50px"
                custom="false"
                type="boolean"
              />

              <Input
                isActive={false}
                value={issuanceCertifOriginPrice}
                name={`Valor  ${
                  incontermInternational === "EXW" ? "incluso" : "da emissão"
                }`}
                width="150px"
                placeholder="0,00"
                type={"text"}
                marginBottom="10px"
              />
            </div>

            <div style={boxInputs}>
              <div style={{ display: "flex", width: "100%", gap: "20px" }}>
                <InputSelectFilterManager
                  isActive={inputActive}
                  onChange={(e) =>
                    setAdditionalIssuanceCertifOrigin(e.target.value)
                  }
                  color="var(--grey-1)"
                  name="Emissão CO_certif origem adicional ?"
                  width="100%"
                  top="50px"
                  custom="false"
                  type="boolean"
                />

                <Input
                  isActive={false}
                  value={additionalIssuanceCertifOriginPrice}
                  name={`Valor  ${
                    incontermInternational === "EXW" ? "Incluso" : "da emissão"
                  }`}
                  width="150px"
                  placeholder="0,00"
                  type={"text"}
                  marginBottom="10px"
                />
              </div>
              <div style={{ width: "100%", display: "flex", gap: "20px" }}>
                <InputSelectFilterManager
                  isActive={inputActive}
                  onChange={(e) => setHelperFee(e.target.value)}
                  color="var(--grey-1)"
                  name="Ajudantes ?"
                  width="100%"
                  top="50px"
                  custom="false"
                  type="boolean"
                />

                {helperFee === "Sim" ? (
                  <InputSelectFilterManager
                    isActive={inputActive}
                    onChange={(e) => setQuantity(e.target.value)}
                    color="var(--grey-1)"
                    name="Quantos"
                    width="100%"
                    top="50px"
                    custom="false"
                    type="quantity"
                  />
                ) : (
                  <></>
                )}

                <Input
                  isActive={false}
                  value={helperFeePrice}
                  name="Taxa por ajudante"
                  width="150px"
                  placeholder="0,00"
                  type={"text"}
                  marginBottom="10px"
                />
              </div>
            </div>
          </>
        )}
      </div>

      <div style={{ marginBottom: "20px" }}>
        <h3 className="textTitleTypeDispatch">Pagamento</h3>

        <div style={{ width: "40%" }}>
          <InputPrimeReact
            label={"Escolha a forma de pagamento"}
            value={formOfPayment}
            onChange={(e) => setFormOfPayment(e.value)}
            type={"dropdown"}
            options={[
              "Boleto bancário - BRL",
              "Crédito em conta - BRL/ARS",
              "Pix à vista com desconto 1% - BRL",
              "Parcelamento 2 vezes - BRL",
              "Swift Bancário - Remessa ao exterior - USD",
              "ALIAS à vista com desconto de 1% - ARS",
            ]}
            placeholder={"Selecione"}
            width={"100%"}
            className={"currencyTable"}
            currencyType={coinType}
          />
        </div>
      </div>

      <div>
        <Textarea name="Observações adicionais" height="83px" width="100%" />
      </div>

      <UploadImg
        imagesBase64={imagesBase64}
        setImagesBase64={setImagesBase64}
        imagesFirebase={imagesFirebase}
        setImagesFirebase={setImagesFirebase}
      />

      <div style={containerContent}>
        <div>
          <h3 className="textTitleTypeDispatch">Resumo total cotação</h3>
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ display: "flex", width: "146px" }}>
            <div>
              <p className="textTitleSummary">Frete</p>
              <div>
                {dispatchValue ? (
                  dispatchValue
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "18px", width: "195px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Ad Valorem | Gris</p>
              <div>
                {sefazTaxValue ? (
                  sefazTaxValue
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "50px", width: "200px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Outros</p>
              <div>
                {freightWeightValue > 0 ? (
                  freightWeightValue.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "50px", width: "209px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Cubagem</p>
              <div>
                {cubageTotal ? (
                  cubageTotal
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          {type === "standard" && (
            <div style={{ display: "flex", gap: "50px", width: "342px" }}>
              <div>
                <p className="textTitleSummary">
                  Valor total (Standard) | Transit time - 12 Dias
                </p>
                <div>
                  {priceTotalStanded ? (
                    priceTotalStanded
                  ) : (
                    <svg
                      width="8"
                      height="3"
                      viewBox="0 0 8 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                        fill="#060606"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
          )}

          {type === "premium" && (
            <div style={{ display: "flex", gap: "14px", width: "387px" }}>
              <div>
                <p className="textTitleSummary">
                  Valor total (Premium) | Transit time - 5 Dias
                </p>
                <span style={{ fontSize: "11px", fontWeight: "600" }}>
                  * Dead-line toda quarta feira em horário comercial
                </span>
                <div>
                  {priceTotalPremium ? (
                    priceTotalPremium
                  ) : (
                    <svg
                      width="8"
                      height="3"
                      viewBox="0 0 8 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                        fill="#060606"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CardInfoQuotationInternational;
