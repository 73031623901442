import React, { useContext, useEffect, useState } from "react";
import companyIcon from "../images/svg/companyIcon.svg";
import { useNavigate } from "react-router-dom";
import { DashboardContext } from "../context/Dashboard/DashboardContext";

import tableDashAdmOn from "../images/svg/tableDashAdmOn.svg";
import tableDashAdmOff from "../images/svg/tableDashAdmOff.svg";

import manageAccessesAdmOn from "../images/svg/ManageAccessesOn.svg";
import manageAccessesAdmOff from "../images/svg/ManageAccessesOff.svg";

import blackListAdmOn from "../images/svg/blackListOn.svg";
import blackListAdmOff from "../images/svg/blackListOff.svg";

import proposalOn from "../images/svg/proposalsOn.svg";
import proposalOff from "../images/svg/proposalsOff.svg";
import { ProposalContext } from "../context/Dashboard/ProposalContext";
import { UserContext } from "../context/User";

const Sidebar = ({ page, access }) => {
  const navigate = useNavigate();

  const { permissionVisibility, getProfile } = useContext(UserContext);
  const { countProposalUnviewed } = useContext(ProposalContext);

  const container = {
    width: "229px",
    position: "fixed",
    top: "0",
    zIndex: "2",
    height: "100vh",
    background: "white",
    border: "1px solid #D7D7D7",
    borderTopRightRadius: 17,
    borderBottomRightRadius: 17,
  };

  const containerImage = {
    display: "flex",
    justifyContent: "center",
    marginTop: "2.625rem",
  };
  const containerItem = {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    width: "205px",
    borderRadius: "8px",
    paddingTop: "2px",
    paddingBottom: "3px",
    height: "39px",
    background: "#123164",
    marginBottom: "21px",
    cursor: "pointer",
  };

  const containerItemNotChecked = {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    width: "205px",
    borderRadius: "8px",
    paddingTop: "2px",
    paddingBottom: "3px",
    height: "39px",
    background: "white",
    marginBottom: "21px",
    cursor: "pointer",
  };

  const image = {
    position: "relative",
    top: "1px",
    marginLeft: "11px",
  };

  const countProposalStyle = {
    position: "absolute",
    right: -7,
    top: -10,
    backgroundColor: "var(--color-primary)",
    padding: "3px 6px",
    borderRadius: 60,
    color: "#fff",
    fontSize: 11,
    fontWeight: "bold",
    border: "2px solid #fff",
  };

  const redirectToDashboardPage = () => {
    navigate("/dashboard");
  };

  const redirectToDashboardAdmPage = () => {
    navigate("/dashboard/admin");
  };

  const redirectToUserPage = () => {
    navigate("/dashboard/profile");
  };

  const redirectToLoginPage = () => {
    if (access == "adm") {
      return navigate("/login/admin");
    }
    return navigate("/");
  };

  const redirectToManageAccessesPage = () => {
    navigate("/dashboard/manage/admin");
  };

  const redirectToBlackListPage = () => {
    navigate("/dashboard/list/admin");
  };

  const redirectToProposalPage = () => {
    navigate("/dashboard/proposal/admin");
  };

  const redirectToProposalPageClient = () => {
    navigate("/dashboard/proposal");
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {}, [permissionVisibility]);

  return (
    <div style={container}>
      <div style={containerImage}>
        <img src={companyIcon} />
      </div>

      {access == "adm" ? (
        <div
          style={{
            paddingLeft: "12px",
            paddingRight: "12px",
            marginTop: "52px",
          }}
        >
          {/* TABELA DE FRETES */}
          {permissionVisibility ? (
            <div
              onClick={() => redirectToDashboardAdmPage()}
              style={
                page == "DashboardAdm" ? containerItem : containerItemNotChecked
              }
            >
              {/* ICON */}
              <div style={image}>
                <img
                  src={
                    page == "DashboardAdm" ? tableDashAdmOn : tableDashAdmOff
                  }
                />
              </div>

              {/* TEXT */}
              <p
                className={
                  page == "DashboardAdm"
                    ? "textCheckedSidebar"
                    : "textNotCheckedSidebar"
                }
              >
                Tabelas de fretes
              </p>
            </div>
          ) : (
            <></>
          )}

          {/* GERENCIAMENTO DE ACESSOS */}
          <div
            onClick={() => redirectToManageAccessesPage()}
            style={
              page == "ManageAccesses" ? containerItem : containerItemNotChecked
            }
          >
            {/* ICON */}
            <div style={image}>
              <img
                src={
                  page == "ManageAccesses"
                    ? manageAccessesAdmOn
                    : manageAccessesAdmOff
                }
              />
            </div>

            {/* TEXT */}
            <p
              className={
                page == "ManageAccesses"
                  ? "textCheckedSidebar"
                  : "textNotCheckedSidebar"
              }
            >
              Gerenciar acessos
            </p>
          </div>

          {/* BLACK LIST */}
          {permissionVisibility ? (
            <div
              onClick={() => redirectToBlackListPage()}
              style={
                page == "BlackList" ? containerItem : containerItemNotChecked
              }
            >
              {/* ICON */}
              <div style={image}>
                <img
                  src={page == "BlackList" ? blackListAdmOn : blackListAdmOff}
                />
              </div>

              {/* TEXT */}
              <p
                className={
                  page == "BlackList"
                    ? "textCheckedSidebar"
                    : "textNotCheckedSidebar"
                }
              >
                Lista negra
              </p>
            </div>
          ) : (
            <></>
          )}

          {/* PROPOSALS */}
          <div
            onClick={() => redirectToProposalPage()}
            style={
              page == "Proposals" ? containerItem : containerItemNotChecked
            }
          >
            {/* ICON */}
            <div style={image}>
              <img src={page == "Proposals" ? proposalOn : proposalOff} />
            </div>

            {/* TEXT */}
            <p
              style={{ marginLeft: 6 }}
              className={
                page == "Proposals"
                  ? "textCheckedSidebar"
                  : "textNotCheckedSidebar"
              }
            >
              Propostas
            </p>
          </div>

          <div
            onClick={() => redirectToLoginPage()}
            style={{
              ...containerItemNotChecked,
              marginTop: "300px",
            }}
          >
            <div style={{ ...image, transform: "translateY(-5px)" }}>
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.10243 16.5725H3.94576C3.52716 16.5725 3.12571 16.4063 2.82971 16.1103C2.53372 15.8143 2.36743 15.4128 2.36743 14.9942V3.94589C2.36743 3.52729 2.53372 3.12583 2.82971 2.82984C3.12571 2.53384 3.52716 2.36755 3.94576 2.36755H7.10243"
                  stroke="#787486"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.6267 13.4158L16.5725 9.47L12.6267 5.52417"
                  stroke="#787486"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5725 9.46997H7.10254"
                  stroke="#787486"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <p className="textNotCheckedSidebar">Sair</p>
          </div>
        </div>
      ) : (
        <div
          style={{
            paddingLeft: "12px",
            paddingRight: "12px",
            marginTop: "52px",
          }}
        >
          <div
            onClick={() => redirectToDashboardPage()}
            style={
              page == "Dashboard" ? containerItem : containerItemNotChecked
            }
          >
            <div style={image}>
              {page == "Dashboard" ? (
                <svg
                  width="23"
                  height="26"
                  viewBox="0 0 23 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.2684 6.65174C15.181 5.91888 16.1209 4.93406 16.5976 3.73597C16.8465 3.11078 16.766 2.40092 16.3833 1.83742C15.9892 1.25615 15.3358 0.909058 14.6364 0.909058H8.36364C7.66423 0.909058 7.01082 1.25615 6.61668 1.83742C6.23405 2.40092 6.15459 3.11078 6.40236 3.73597C6.87909 4.93301 7.81895 5.91888 8.73164 6.65174C3.864 8.45828 0 14.6662 0 19.7272C0 23.1866 2.81332 26 6.27273 26H16.7273C20.1867 26 23 23.1866 23 19.7272C23 14.6662 19.136 8.45828 14.2684 6.65174ZM14.6541 2.96338C14.1147 4.31933 12.465 5.46515 11.4979 6.00565C10.533 5.46933 8.8895 4.33292 8.36259 2.99997L14.6541 2.96338ZM16.7262 23.9091H6.27273C3.96645 23.9091 2.09091 22.0335 2.09091 19.7272C2.09091 14.2459 7.015 8.22724 11.5 8.22724C15.985 8.22724 20.9091 14.2459 20.9091 19.7272C20.9091 22.0335 19.0335 23.9091 16.7273 23.9091H16.7262ZM11.499 23.9091C10.9219 23.9091 10.4535 23.4407 10.4535 22.8636V21.8181H10.1733C9.05677 21.8181 8.01655 21.2181 7.45723 20.251C7.16868 19.7513 7.33909 19.1125 7.83882 18.8229C8.33645 18.5333 8.97732 18.7037 9.26691 19.2045C9.453 19.5265 9.80114 19.7272 10.1733 19.7272H12.5444C13.1205 19.7272 13.5899 19.2578 13.5899 18.6818C13.5899 18.2866 13.3065 17.9521 12.9166 17.8872L9.73632 17.3572C8.33436 17.1241 7.31609 15.9218 7.31609 14.5C7.31609 12.7708 8.72327 11.3636 10.4525 11.3636V10.3181C10.4525 9.74106 10.9208 9.27269 11.4979 9.27269C12.075 9.27269 12.5434 9.74106 12.5434 10.3181V11.3636H12.8235C13.939 11.3636 14.9803 11.9647 15.5396 12.9318C15.8282 13.4315 15.6578 14.0703 15.158 14.3599C14.6594 14.6495 14.0195 14.4791 13.73 13.9783C13.5428 13.6552 13.1957 13.4556 12.8235 13.4556H10.4525C9.87641 13.4556 9.407 13.925 9.407 14.501C9.407 14.8962 9.69032 15.2307 10.0803 15.2956L13.2605 15.8256C14.6625 16.0587 15.6808 17.261 15.6808 18.6828C15.6808 20.412 14.2736 21.8192 12.5444 21.8192V22.8646C12.5444 23.4417 12.076 23.9101 11.499 23.9101V23.9091Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  width="23"
                  height="26"
                  viewBox="0 0 23 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.2684 6.65174C15.181 5.91888 16.1209 4.93406 16.5976 3.73597C16.8465 3.11078 16.766 2.40092 16.3833 1.83742C15.9892 1.25615 15.3358 0.909058 14.6364 0.909058H8.36364C7.66423 0.909058 7.01082 1.25615 6.61668 1.83742C6.23405 2.40092 6.15459 3.11078 6.40236 3.73597C6.87909 4.93301 7.81895 5.91888 8.73164 6.65174C3.864 8.45828 0 14.6662 0 19.7272C0 23.1866 2.81332 26 6.27273 26H16.7273C20.1867 26 23 23.1866 23 19.7272C23 14.6662 19.136 8.45828 14.2684 6.65174ZM14.6541 2.96338C14.1147 4.31933 12.465 5.46515 11.4979 6.00565C10.533 5.46933 8.8895 4.33292 8.36259 2.99997L14.6541 2.96338ZM16.7262 23.9091H6.27273C3.96645 23.9091 2.09091 22.0335 2.09091 19.7272C2.09091 14.2459 7.015 8.22724 11.5 8.22724C15.985 8.22724 20.9091 14.2459 20.9091 19.7272C20.9091 22.0335 19.0335 23.9091 16.7273 23.9091H16.7262ZM11.499 23.9091C10.9219 23.9091 10.4535 23.4407 10.4535 22.8636V21.8181H10.1733C9.05677 21.8181 8.01655 21.2181 7.45723 20.251C7.16868 19.7513 7.33909 19.1125 7.83882 18.8229C8.33645 18.5333 8.97732 18.7037 9.26691 19.2045C9.453 19.5265 9.80114 19.7272 10.1733 19.7272H12.5444C13.1205 19.7272 13.5899 19.2578 13.5899 18.6818C13.5899 18.2866 13.3065 17.9521 12.9166 17.8872L9.73632 17.3572C8.33436 17.1241 7.31609 15.9218 7.31609 14.5C7.31609 12.7708 8.72327 11.3636 10.4525 11.3636V10.3181C10.4525 9.74106 10.9208 9.27269 11.4979 9.27269C12.075 9.27269 12.5434 9.74106 12.5434 10.3181V11.3636H12.8235C13.939 11.3636 14.9803 11.9647 15.5396 12.9318C15.8282 13.4315 15.6578 14.0703 15.158 14.3599C14.6594 14.6495 14.0195 14.4791 13.73 13.9783C13.5428 13.6552 13.1957 13.4556 12.8235 13.4556H10.4525C9.87641 13.4556 9.407 13.925 9.407 14.501C9.407 14.8962 9.69032 15.2307 10.0803 15.2956L13.2605 15.8256C14.6625 16.0587 15.6808 17.261 15.6808 18.6828C15.6808 20.412 14.2736 21.8192 12.5444 21.8192V22.8646C12.5444 23.4417 12.076 23.9101 11.499 23.9101V23.9091Z"
                    fill="#062F67"
                  />
                </svg>
              )}
            </div>

            {page == "Dashboard" ? (
              <p className="textCheckedSidebar">Cotação de frete</p>
            ) : (
              <p className="textNotCheckedSidebar">Cotação de frete</p>
            )}
          </div>

          {/* PROPOSALS */}
          <div
            onClick={() => redirectToProposalPageClient()}
            style={
              page === "Proposals"
                ? { ...containerItem, position: "relative" }
                : {
                    ...containerItemNotChecked,
                    position: "relative",
                  }
            }
          >
            {/* ICON */}
            <div style={image}>
              <img src={page == "Proposals" ? proposalOn : proposalOff} />
            </div>

            {/* TEXT */}
            <p
              style={{ marginLeft: 6 }}
              className={
                page == "Proposals"
                  ? "textCheckedSidebar"
                  : "textNotCheckedSidebar"
              }
            >
              Propostas
            </p>
            {countProposalUnviewed > 0 && (
              <span style={countProposalStyle}>{countProposalUnviewed}</span>
            )}
          </div>

          <div
            onClick={() => redirectToUserPage()}
            style={page == "Profile" ? containerItem : containerItemNotChecked}
          >
            <div style={image}>
              {page == "Profile" ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_107_2298)">
                    <path
                      d="M12 12C13.1867 12 14.3467 11.6481 15.3334 10.9888C16.3201 10.3295 17.0892 9.39246 17.5433 8.2961C17.9974 7.19975 18.1162 5.99335 17.8847 4.82946C17.6532 3.66558 17.0818 2.59648 16.2426 1.75736C15.4035 0.918247 14.3344 0.346802 13.1705 0.115291C12.0067 -0.11622 10.8003 0.00259972 9.7039 0.456726C8.60754 0.910851 7.67047 1.67989 7.01118 2.66658C6.35189 3.65328 6 4.81331 6 6C6.00159 7.59081 6.63424 9.11602 7.75911 10.2409C8.88399 11.3658 10.4092 11.9984 12 12ZM12 2C12.7911 2 13.5645 2.2346 14.2223 2.67412C14.8801 3.11365 15.3928 3.73836 15.6955 4.46927C15.9983 5.20017 16.0775 6.00444 15.9231 6.78036C15.7688 7.55629 15.3878 8.26902 14.8284 8.82843C14.269 9.38784 13.5563 9.7688 12.7804 9.92314C12.0044 10.0775 11.2002 9.99827 10.4693 9.69552C9.73836 9.39277 9.11365 8.88008 8.67412 8.22228C8.2346 7.56449 8 6.79113 8 6C8 4.93914 8.42143 3.92172 9.17157 3.17158C9.92172 2.42143 10.9391 2 12 2Z"
                      fill="white"
                    />
                    <path
                      d="M12 14C9.61386 14.0026 7.32622 14.9517 5.63896 16.639C3.95171 18.3262 3.00265 20.6139 3 23C3 23.2652 3.10536 23.5196 3.29289 23.7071C3.48043 23.8946 3.73478 24 4 24C4.26522 24 4.51957 23.8946 4.70711 23.7071C4.89464 23.5196 5 23.2652 5 23C5 21.1435 5.7375 19.363 7.05025 18.0503C8.36301 16.7375 10.1435 16 12 16C13.8565 16 15.637 16.7375 16.9497 18.0503C18.2625 19.363 19 21.1435 19 23C19 23.2652 19.1054 23.5196 19.2929 23.7071C19.4804 23.8946 19.7348 24 20 24C20.2652 24 20.5196 23.8946 20.7071 23.7071C20.8946 23.5196 21 23.2652 21 23C20.9974 20.6139 20.0483 18.3262 18.361 16.639C16.6738 14.9517 14.3861 14.0026 12 14Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_107_2298">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_9_617)">
                    <path
                      d="M12 12C13.1867 12 14.3467 11.6481 15.3334 10.9888C16.3201 10.3295 17.0892 9.39246 17.5433 8.2961C17.9974 7.19975 18.1162 5.99335 17.8847 4.82946C17.6532 3.66558 17.0818 2.59648 16.2426 1.75736C15.4035 0.918247 14.3344 0.346802 13.1705 0.115291C12.0067 -0.11622 10.8003 0.00259972 9.7039 0.456726C8.60754 0.910851 7.67047 1.67989 7.01118 2.66658C6.35189 3.65328 6 4.81331 6 6C6.00159 7.59081 6.63424 9.11602 7.75911 10.2409C8.88399 11.3658 10.4092 11.9984 12 12ZM12 2C12.7911 2 13.5645 2.2346 14.2223 2.67412C14.8801 3.11365 15.3928 3.73836 15.6955 4.46927C15.9983 5.20017 16.0775 6.00444 15.9231 6.78036C15.7688 7.55629 15.3878 8.26902 14.8284 8.82843C14.269 9.38784 13.5563 9.7688 12.7804 9.92314C12.0044 10.0775 11.2002 9.99827 10.4693 9.69552C9.73836 9.39277 9.11365 8.88008 8.67412 8.22228C8.2346 7.56449 8 6.79113 8 6C8 4.93914 8.42143 3.92172 9.17157 3.17158C9.92172 2.42143 10.9391 2 12 2Z"
                      fill="#062F67"
                    />
                    <path
                      d="M12 14C9.61386 14.0026 7.32622 14.9517 5.63896 16.639C3.95171 18.3262 3.00265 20.6139 3 23C3 23.2652 3.10536 23.5196 3.29289 23.7071C3.48043 23.8946 3.73478 24 4 24C4.26522 24 4.51957 23.8946 4.70711 23.7071C4.89464 23.5196 5 23.2652 5 23C5 21.1435 5.7375 19.363 7.05025 18.0503C8.36301 16.7375 10.1435 16 12 16C13.8565 16 15.637 16.7375 16.9497 18.0503C18.2625 19.363 19 21.1435 19 23C19 23.2652 19.1054 23.5196 19.2929 23.7071C19.4804 23.8946 19.7348 24 20 24C20.2652 24 20.5196 23.8946 20.7071 23.7071C20.8946 23.5196 21 23.2652 21 23C20.9974 20.6139 20.0483 18.3262 18.361 16.639C16.6738 14.9517 14.3861 14.0026 12 14Z"
                      fill="#062F67"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_9_617">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </div>

            {page == "Profile" ? (
              <p className="textCheckedSidebar">Minha conta</p>
            ) : (
              <p className="textNotCheckedSidebar">Minha conta</p>
            )}
          </div>

          <div
            onClick={() => redirectToLoginPage()}
            style={{
              ...containerItemNotChecked,
              marginTop: "300px",
            }}
          >
            <div style={{ ...image, transform: "translateY(-5px)" }}>
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.10243 16.5725H3.94576C3.52716 16.5725 3.12571 16.4063 2.82971 16.1103C2.53372 15.8143 2.36743 15.4128 2.36743 14.9942V3.94589C2.36743 3.52729 2.53372 3.12583 2.82971 2.82984C3.12571 2.53384 3.52716 2.36755 3.94576 2.36755H7.10243"
                  stroke="#787486"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.6267 13.4158L16.5725 9.47L12.6267 5.52417"
                  stroke="#787486"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5725 9.46997H7.10254"
                  stroke="#787486"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <p className="textNotCheckedSidebar">Sair</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
