export const proposalsClients = [
  {
    name: "Americanas",
    proposalId: "RONAC-2024-10",
    email: "comercial@americanas.com",
    type: "Varejo",
    value: "15.972,00",
    status: true,
  },
  {
    name: "Magazine Luiza",
    proposalId: "ML2024-20",
    email: "comercial@magazineluiza.com",
    type: "Varejo",
    value: "18.500,00",
    status: false,
  },
  {
    name: "Amazon",
    proposalId: "AMZ-2024-15",
    email: "comercial@amazon.com",
    type: "Tecnologia",
    value: "22.300,00",
    status: true,
  },
  {
    name: "Apple",
    proposalId: "APL2024-08",
    email: "comercial@apple.com",
    type: "Tecnologia",
    value: "30.800,00",
    status: true,
  },
  {
    name: "Google",
    proposalId: "GOOGL-2024-05",
    email: "comercial@google.com",
    type: "Tecnologia",
    value: "40.200,00",
    status: true,
  },
  {
    name: "Microsoft",
    proposalId: "MSFT-2024-12",
    email: "comercial@microsoft.com",
    type: "Tecnologia",
    value: "35.600,00",
    status: false,
  },
  {
    name: "Walmart",
    proposalId: "WMT2024-18",
    email: "comercial@walmart.com",
    type: "Varejo",
    value: "28.900,00",
    status: true,
  },
  {
    name: "Samsung",
    proposalId: "SSNLF-2024-09",
    email: "comercial@samsung.com",
    type: "Tecnologia",
    value: "25.400,00",
    status: false,
  },
  {
    name: "Netflix",
    proposalId: "NFLX-2024-07",
    email: "comercial@netflix.com",
    type: "Entretenimento",
    value: "32.100,00",
    status: true,
  },
  {
    name: "Facebook",
    proposalId: "FB2024-11",
    email: "comercial@facebook.com",
    type: "Tecnologia",
    value: "38.700,00",
    status: true,
  },
  {
    name: "Tesla",
    proposalId: "TSLA2024-06",
    email: "comercial@tesla.com",
    type: "Automobilística",
    value: "55.900,00",
    status: true,
  },
  {
    name: "Toyota",
    proposalId: "TOYOTA-2024-03",
    email: "comercial@toyota.com",
    type: "Automobilística",
    value: "42.500,00",
    status: true,
  },
  {
    name: "IBM",
    proposalId: "IBM2024-14",
    email: "comercial@ibm.com",
    type: "Tecnologia",
    value: "48.300,00",
    status: false,
  },
  {
    name: "Nike",
    proposalId: "NIKE-2024-17",
    email: "comercial@nike.com",
    type: "Vestuário",
    value: "29.600,00",
    status: true,
  },
  {
    name: "Uber",
    proposalId: "UBER2024-16",
    email: "comercial@uber.com",
    type: "Transporte",
    value: "36.200,00",
    status: false,
  },
  {
    name: "General Electric",
    proposalId: "GE2024-13",
    email: "comercial@ge.com",
    type: "Energia",
    value: "47.800,00",
    status: true,
  },
  {
    name: "Intel",
    proposalId: "INTC-2024-02",
    email: "comercial@intel.com",
    type: "Tecnologia",
    value: "39.400,00",
    status: false,
  },
  {
    name: "McDonald's",
    proposalId: "MCD2024-19",
    email: "comercial@mcdonalds.com",
    type: "Alimentício",
    value: "26.800,00",
    status: true,
  },
  {
    name: "Cisco",
    proposalId: "CSCO-2024-04",
    email: "comercial@cisco.com",
    type: "Tecnologia",
    value: "33.700,00",
    status: true,
  },
  {
    name: "Boeing",
    proposalId: "BA2024-01",
    email: "comercial@boeing.com",
    type: "Aeroespacial",
    value: "45.600,00",
    status: false,
  },
];
