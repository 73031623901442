import { useContext, useEffect, useState } from "react";
import Button from "./Button";
import { ProposalContext } from "../context/Dashboard/ProposalContext";
import { UserContext } from "../context/User";

const CardDiscount = ({
  isActive,
  idProposal,
  close,
  closeAll,
  advanceDiscount,
}) => {
  const {
    applyDiscount,
    applyAdvanceDiscount,
    refuseAdvanceDiscount,
    requestAdvanceDiscount,
  } = useContext(ProposalContext);
  const { limitDiscount } = useContext(UserContext);

  const [typeDiscount, setTypeDiscount] = useState("default");
  const [valueOrDiscount, setValueOrDiscount] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [dataDiscount, setDataDiscount] = useState({});

  const [optionLimit, setOptionLimit] = useState([]);
  const [application, setApplication] = useState(true);

  const container = {
    position: "absolute",
    width: "300px",
    backgroundColor: "#fff",
    zIndex: "5",
    border: "1px solid #d7d7d7",
    borderRadius: "0 0 10px 10px",
    top: "45px",
    right: "0",
    padding: "10px",
  };

  const boxSelect = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginBottom: "20px",
  };

  const selectOrInput = {
    padding: "4px",
    outline: "none",
    borderRadius: "6px",
    border: "1px solid #d7d7d7",
  };

  const verifyValuePercentage = (e) => {
    const value = e.target.value;
    if (typeDiscount.includes("discount")) {
      if (value <= 100) {
        setValueOrDiscount(e.target.value);
      }
    } else if (typeDiscount.includes("value")) {
      setValueOrDiscount(e.target.value);
    }
  };

  const verifyDisabled = () => {
    if (typeDiscount === "default") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const verifyDiscount = () => {
    if (typeDiscount.includes("discount")) {
      if (valueOrDiscount > 100) {
        setValueOrDiscount(100);
      }
    }
  };

  const percentageSelect = () => {
    const options = [];

    if (application) {
      for (let i = 1; i <= limitDiscount; i++) {
        options.push(
          <option key={i} value={i}>
            {i}%
          </option>
        );
      }
      setOptionLimit(options);
    } else {
      for (let i = 1; i <= 100; i++) {
        options.push(
          <option key={i} value={i}>
            {i}%
          </option>
        );
      }
      setOptionLimit(options);
    }
  };

  const onChangeApplicationOrRequest = (status) => {
    setApplication(status);
  };

  const onDiscount = () => {
    if (application)
      return applyDiscount(idProposal, close, closeAll, dataDiscount);
    else requestAdvanceDiscount(idProposal, close, closeAll, dataDiscount);
  };

  useEffect(() => {
    verifyDisabled();
    verifyDiscount();
  }, [typeDiscount]);

  useEffect(() => {
    setDataDiscount({ [typeDiscount]: valueOrDiscount });
  }, [typeDiscount, valueOrDiscount]);

  useEffect(() => {
    percentageSelect();
  }, [limitDiscount, application]);

  return (
    isActive && (
      <div style={container}>
        {advanceDiscount > 0 ? (
          <div style={{ width: "100%" }}>
            <div style={boxSelect}>
              <p className="titleDiscontProposal">
                Valor da proposta após desconto
              </p>
              <p className="contentDiscontProposal">
                Valor:{" "}
                <span>
                  {advanceDiscount.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>
              </p>
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Button
                padding={"5px 20px"}
                width={"100%"}
                background={"#123164"}
                borderRadius="10px"
                marginTop="0"
                name={"Aplicar"}
                color={"#fff"}
                onClick={() =>
                  applyAdvanceDiscount(idProposal, close, closeAll)
                }
                border="1px solid #FFE1E1"
              />
              <Button
                padding={"5px 20px"}
                width={"100%"}
                background={"#ec0000"}
                borderRadius="10px"
                marginTop="0"
                name={"Recusar"}
                color={"#fff"}
                onClick={() =>
                  refuseAdvanceDiscount(idProposal, close, closeAll)
                }
                border="1px solid transparent"
              />
            </div>
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "20px",
                marginBottom: "20px",
              }}
            >
              <p
                onClick={() => onChangeApplicationOrRequest(true)}
                className={`applicationOrRequestProposal ${
                  application && "selectOptionDiscount"
                }`}
              >
                Aplicação
              </p>
              <p
                onClick={() => onChangeApplicationOrRequest(false)}
                className={`applicationOrRequestProposal ${
                  !application && "selectOptionDiscount"
                }`}
              >
                Solicitação
              </p>
            </div>
            <div style={boxSelect}>
              <p>Tipo do desconto</p>
              <select
                onChange={(e) => setTypeDiscount(e.target.value)}
                style={{ ...selectOrInput, padding: "6px 4px" }}
              >
                <option value={"default"}>Selecione</option>
                <option value={"discount"}>Porcentagem</option>
                <option value={"value"}>Valor</option>
              </select>
            </div>

            <div style={boxSelect}>
              <p>Valor</p>

              {typeDiscount == "discount" ? (
                <select
                  onChange={verifyValuePercentage}
                  style={{ ...selectOrInput, padding: "6px 4px" }}
                >
                  {optionLimit}
                </select>
              ) : (
                <input
                  disabled={disabled}
                  value={valueOrDiscount}
                  onChange={verifyValuePercentage}
                  style={selectOrInput}
                  type="number"
                />
              )}
            </div>

            <Button
              padding={"5px 20px"}
              width={"100%"}
              background={"#123164"}
              borderRadius="10px"
              marginTop="0"
              name={"Aplicar desconto"}
              color={"#fff"}
              onClick={onDiscount}
              border="1px solid #FFE1E1"
            />
          </div>
        )}
      </div>
    )
  );
};

export default CardDiscount;
