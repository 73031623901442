import { BigHead } from "@bigheads/core";

const CardUserList = ({ image, name, onClick, cnpj }) => {
  const letterLimit = (text, limit, threePoints) => {
    let description = [];

    if (threePoints) {
      text.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 1) {
          description = `${description.join("")}...`;
        }
      });
    } else {
      text.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 2) {
          description = `${description.join("")}...`;
        }
      });
    }

    return description;
  };
  return (
    <div
      style={{
        backgroundColor: "#E4EBFF",
        minWidth: "240px",
        height: !image && "28px",
        borderRadius: "60px",
        display: "flex",
        alignItems: "center",
        padding: "3px",
        gap: "10px",
        position: "relative",
        marginBottom: "3px",
      }}
    >
      {image ? (
        <img src={image} />
      ) : (
        <div
          style={{
            width: "30px",
            height: "30px",
            position: "absolute",
            bottom: "0",
          }}
        >
          <BigHead
            accessory="shades"
            body="chest"
            circleColor="blue"
            clothing="tankTop"
            clothingColor="black"
            eyebrows="angry"
            eyes="wink"
            facialHair="mediumBeard"
            graphic="vue"
            hair="short"
            hairColor="black"
            hat="none"
            hatColor="green"
            lashes="false"
            lipColor="purple"
            mask="true"
            faceMask="true"
            mouth="open"
            skinTone="brown"
          />
        </div>
      )}

      <p
        style={{ marginLeft: !image && "40px" }}
        className="textNameTableListManager"
      >
        {letterLimit(name, 20)}
        {/* {` - ${cnpj}`} */}
      </p>

      <div
        onClick={onClick}
        style={{ position: "absolute", right: "10px", cursor: "pointer" }}
      >
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.54849 3.99988L7.5891 0.955925C7.77784 0.766952 7.77784 0.460569 7.5891 0.271612C7.40036 0.0826397 7.09434 0.0826397 6.90561 0.271612L3.86532 3.31588L0.825045 0.271612C0.636301 0.0826397 0.330287 0.0826397 0.141558 0.271612C-0.047171 0.460584 -0.0471861 0.766967 0.141558 0.955925L3.18215 3.99988L0.141558 7.04384C-0.0471861 7.23281 -0.0471861 7.5392 0.141558 7.72815C0.330303 7.91711 0.636316 7.91713 0.825045 7.72815L3.86532 4.68387L6.9056 7.72815C7.09434 7.91713 7.40036 7.91713 7.58909 7.72815C7.77783 7.53918 7.77783 7.2328 7.58909 7.04384L4.54849 3.99988Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
  );
};

export default CardUserList;
