import React, { useState, useContext, useEffect } from "react";
import CardTableUsers from "./CardTableUsers";
import uploadUserIcon from "../images/svg/uploadUserIcon.svg";
import Input from "./Input";
import InputSelectFilterManager from "./InputSelectFilterManager";
import { ModalContext } from "../context/Modal";
import { UserContext } from "../context/User";
import Modal from "react-bootstrap/Modal";
import api from "../services/api";
import app from "../firebase/database";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { cnpj } from "cpf-cnpj-validator";

const TableListAdm = ({ typeAcess }) => {
  const [stateCheckPrimary, setStateCheckPrimary] = useState(false);
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [imageFirebase, setImageFirebase] = useState();

  /* MODAL STATES */

  const { modalGlobal, setStateModalGlobal } = useContext(ModalContext);
  const { data, permissionVisibility, getProfile } = useContext(UserContext);

  const [modalActions, setModalActions] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);

  const [users, setUsers] = useState([]);

  const [name, setName] = useState();
  const [whatsapp, setWhatsapp] = useState();
  const [mail, setMail] = useState();
  const [password, setPassword] = useState();
  const [limitDiscount, setLimitDiscount] = useState(0);

  const [permission, setPermission] = useState("");
  const [corporateName, setCorporateName] = useState("");
  const [documentType, setDocumentType] = useState("99.999.999/9999-99:CNPJ");
  const [cnpjValue, setCnpjValue] = useState("");
  const [cnaes, setCnaes] = useState("");
  const [type_buniness, setType_buniness] = useState();
  const [typeBuniness, setTypeBuniness] = useState("");
  const [visibilityPermission, setVisibilityPermission] = useState(false);

  /* ERROR MESSAGES */

  const [nameError, setNameError] = useState();
  const [mailError, setMailError] = useState();
  const [passwordError, setPasswordError] = useState();

  const [permissionError, setPermissionError] = useState();

  /* ID */
  const [id, setId] = useState();

  const styleColum1 = {
    maxWidth: "1079px",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    background: "white",
  };
  const btnStyleModal = {
    border: "none",
    outline: "none",
    background: "#123164",
    borderRadius: "50px",
    width: "101px",
    height: "36px",
  };
  const btnStyleRemove = {
    width: "82px",
    height: "37px",
    borderRadius: "50px",
    border: "none",
    background: "#FFE1E1",
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const loadImage = (file) => {
    setImage(file);
    setImageFirebase(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      setImagePreview(reader.result);
    };
  };

  const closeModal = (value) => {
    setName();
    setWhatsapp();
    setMail();
    setPassword();
    setPermission();
    setImagePreview();
    setCnpjValue();
    setCorporateName();
    setCnaes();
    setLimitDiscount();

    setNameError();
    setMailError();
    setPasswordError();
    setPermissionError();

    setStateModalGlobal(value);
  };

  const closeModalEdit = () => {
    setName();
    setMail();
    setPassword();
    setPermission();
    setImagePreview();
    setLimitDiscount();

    setNameError();
    setMailError();
    setPasswordError();
    setPermissionError();
    setModalEdit(false);
  };

  const getUsers = async () => {
    try {
      const response = await api.get("/admin");

      setUsers(response.data);
    } catch (error) {
      setUsers([]);
    }
  };

  const storage = async () => {
    try {
      if (!name || !mail || !password) {
        return toast.error("Verifique se todos os campos foram preechidos", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      const payload = {
        name: name,
        email: mail,
        permission,
        password: password,
        password_hash: password,
        limits_discount: limitDiscount,
      };
      await api.post("/admin", payload);
      closeModal(false);
      getUsers();
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const update = async () => {
    try {
      if (image != undefined) {
        toast.success("alterando cliente....", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        app
          .storage()
          .ref(`/users/${image.name}`)
          .put(image)
          .then(() => {
            app
              .storage()
              .ref(`/users/${image.name}`)
              .getDownloadURL()
              .then(async (url) => {
                // await api.patch(`user/${id}`, {
                //   name: name,
                //   mail: mail,
                //   permission: permission,
                //   password_hash: password,
                //   picture: url,
                // });

                await api.patch(`admin/${id}`, {
                  picture: url,
                  name: name,
                  mail: mail,
                  permission,
                  email: mail,
                  limits_discount: limitDiscount,
                });

                toast.success("Cliente alterado com sucesso!", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });

                setTimeout(() => {
                  window.location.href = "/dashboard/users";
                }, [3000]);
              });
          });

        return false;
      }

      const response = await api.patch(`admin/${id}`, {
        name: name,
        mail: mail,
        permission,
        email: mail,
        limits_discount: limitDiscount,
      });

      if (password) {
        await api.patch(`admin/${id}`, {
          password: password,
          password_hash: password,
        });
      }

      if (response.status == 200) {
        toast.success("Administrativo alterado com sucesso!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setTimeout(() => {
          window.location.href = "/dashboard/manage/admin";
        }, [2000]);
      }
    } catch (error) {
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const getModalActions = async (id) => {
    setId(id);
    setModalActions(true);
    const response = await api.get(`admin/${id}`);
    setName(response.data?.name);
  };

  const getModalConfirmation = () => {
    setModalActions(false);
    setModalConfirmation(true);
  };

  const remove = async (mail) => {
    try {
      await api.delete(`/admin/${id}`);
      setModalConfirmation(false);
      getUsers();
    } catch (error) {}
  };

  const edit = async () => {
    setModalActions(false);
    setModalEdit(true);
    const response = await api.get(`/admin/${id}`);
    setName(response.data?.name);
    setMail(response.data?.email);
    setPermission(response.data?.permission);
    setImagePreview(response.data?.picture);
    setLimitDiscount(response.data?.limits_discount);
  };

  const levelManager = (level) => {
    const options = {
      financial: "Financeiro",
      employee: "SDR",
      administrative: "Administrativo",
    };

    return options[level];
  };

  const verifyValuePercentage = (e) => {
    const value = e.target.value;
    if (value <= 100) {
      setLimitDiscount(e.target.value);
    }
  };

  useEffect(() => {
    if (name != undefined) {
      setNameError();
    }
    if (mail != undefined) {
      setMailError();
    }
    if (password != undefined) {
      setPasswordError();
    }
    if (permission != undefined) {
      setPermissionError();
    }

    if (mail != undefined) {
      const mailIsValid = validateEmail(mail);

      if (mailIsValid == false) {
        setMailError("email inválido");
      }
    }

    if (String(password).length <= 7) {
      setPasswordError("senha deve conter no minimo 8 caracteres");
    }
  }, [name, mail, password, permission]);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (String(data).length > 0) {
      setUsers(data);
    }
  }, [data]);

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    setVisibilityPermission(permissionVisibility);
  }, [permissionVisibility]);
  return (
    <div className="containerBox" style={{ height: "100vh" }}>
      <ToastContainer />

      <div style={styleColum1}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            height: "56px",
            alignItems: "center",
          }}
        >
          <div>
            <p className="textNameTableListUserTwo">Nome do usuário</p>
          </div>

          <div>
            <p className="textNameTableListUserTwo">E-mail</p>
          </div>

          <div>
            <p className="textNameTableListUserTwo">Permissão</p>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: "20px",
          marginTop: "20px",
          overflowY: "auto",
          height: "calc(100vh - 350px)",
          paddingRight: "20px",
        }}
      >
        {users?.map((item) => {
          return (
            <CardTableUsers
              onClick={() => getModalActions(item.id, item.mail)}
              picture={item?.picture}
              name={item?.name}
              mail={item?.email}
              status={false}
              level={levelManager(item?.permission)}
              checkNotVisible={stateCheckPrimary}
            />
          );
        })}
      </div>
      <Modal
        dialogClassName="modal-filter-table-list-user"
        show={modalGlobal}
        onHide={() => closeModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div style={{ display: "flex", height: "30px" }}>
            <div
              onClick={() => closeModal(false)}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_0_2887)">
                  <path
                    d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_0_2887">
                    <rect
                      width="12.72"
                      height="12.7354"
                      fill="white"
                      transform="translate(0.0143433)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <p className="textPrimaryModalFilterManager">Novo usuário</p>
          </div>

          <button
            className="textBtnFilterManagerModal"
            onClick={() => storage()}
            style={btnStyleModal}
          >
            Criar
          </button>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "scroll", maxHeight: 450 }}>
          <input
            onChange={(e) => loadImage(e.target.files[0])}
            accept="image/png"
            name="file"
            id="file"
            type="file"
            style={{ display: "none" }}
          />

          {imagePreview != undefined ? (
            <label
              htmlFor="file"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "3.93rem",
                marginTop: "2.12rem",
              }}
            >
              <img
                src={"data:image/;base64;" + imagePreview}
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "100%",
                }}
              />
            </label>
          ) : (
            <label
              htmlFor="file"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "3.93rem",
              }}
            >
              <img src={uploadUserIcon} />
            </label>
          )}

          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div style={{ position: "relative" }}>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                color="black"
                width="265px"
                height="45px"
                name="Nome do usuário"
              />
              <p className="errorMessage">
                {nameError != undefined ? nameError : <></>}
              </p>
            </div>

            <div style={{ position: "relative" }}>
              <Input
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                color="black"
                width="265px"
                height="45px"
                name="E-mail"
              />
              <p className="errorMessage">
                {mailError != undefined ? mailError : <></>}
              </p>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div style={{ position: "relative" }}>
              <InputSelectFilterManager
                onChange={(e) => setPermission(e.target.value)}
                type="adm"
                marginRight="0"
                top="44px"
                width="265px"
                height="45px"
                name="Permissão"
                custom={true}
              />
              <p className="errorMessage">
                {permissionError != undefined ? permissionError : <></>}
              </p>
            </div>

            <div style={{ position: "relative" }}>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                topEye="46px"
                rightEye="10px"
                color="black"
                width="265px"
                height="45px"
                name="Senha"
              />
              <p className="errorMessage">
                {passwordError != undefined ? passwordError : <></>}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="modal-filter-table-list-user-edit"
        show={modalActions}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            style={{
              display: "flex",
              height: "30px",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p
              className="textPrimaryModalFilterManager"
              style={{ position: "relative", right: "17px" }}
            >
              Ações
            </p>

            <div
              onClick={() => setModalActions(false)}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_0_2887)">
                  <path
                    d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_0_2887">
                    <rect
                      width="12.72"
                      height="12.7354"
                      fill="white"
                      transform="translate(0.0143433)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => edit()}
              style={{
                height: "55px",
                width: "100%",
                borderBottom: "1px solid #D7D7D7",
                textAlign: "center",
                paddingTop: "0.9rem",
                cursor: "pointer",
              }}
            >
              <p className="textModalTableListUserEditPrimary">Editar acesso</p>
            </div>

            <div
              onClick={() => getModalConfirmation()}
              style={{
                height: "55px",
                width: "100%",
                borderBottom: "1px solid #D7D7D7",
                textAlign: "center",
                paddingTop: "0.9rem",
                cursor: "pointer",
              }}
            >
              <p className="textModalTableListUserEditSecondary">
                Excluir acesso
              </p>
            </div>

            <div style={{ height: "70px" }}></div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="modal-filter-table-list-user"
        show={modalEdit}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div style={{ display: "flex", height: "30px" }}>
            <div
              onClick={() => closeModalEdit(false)}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_0_2887)">
                  <path
                    d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_0_2887">
                    <rect
                      width="12.72"
                      height="12.7354"
                      fill="white"
                      transform="translate(0.0143433)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <p className="textPrimaryModalFilterManager">Editar usuário</p>
          </div>

          <button
            className="textBtnFilterManagerModal"
            onClick={() => update()}
            style={btnStyleModal}
          >
            Editar
          </button>
        </Modal.Header>
        <Modal.Body>
          <input
            onChange={(e) => loadImage(e.target.files[0])}
            accept="image/png"
            name="file"
            id="file"
            type="file"
            style={{ display: "none" }}
          />

          {imagePreview != undefined ? (
            <label
              htmlFor="file"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "3.93rem",
                marginTop: "2.12rem",
              }}
            >
              <img
                src={imagePreview}
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "100%",
                }}
              />
            </label>
          ) : (
            <label
              htmlFor="file"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "3.93rem",
              }}
            >
              <img src={uploadUserIcon} />
            </label>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <div style={{ position: "relative", width: "100%" }}>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                color="black"
                width="100%"
                height="45px"
                name="Nome do usuário"
              />
              <p className="errorMessage">
                {nameError != undefined ? nameError : <></>}
              </p>
            </div>

            <div style={{ position: "relative", width: "100%" }}>
              <Input
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                width="100%"
                height="45px"
                name="E-mail"
              />
              <p className="errorMessage">
                {mailError != undefined ? mailError : <></>}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "space-between",
            }}
          >
            {permissionVisibility ? (
              <div style={{ position: "relative", width: "100%" }}>
                <InputSelectFilterManager
                  onChange={(e) => setPermission(e.target.value)}
                  type="adm"
                  marginRight="0"
                  top="44px"
                  width="100%"
                  height="45px"
                  name="Permissão"
                  custom={true}
                />
                <p className="errorMessage">
                  {permissionError != undefined ? permissionError : <></>}
                </p>
              </div>
            ) : (
              <></>
            )}

            <div style={{ position: "relative", width: "100%" }}>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                topEye="46px"
                rightEye="10px"
                color="black"
                width={"100%"}
                height="45px"
                name="Senha"
              />
              <p className="errorMessage">
                {passwordError != undefined ? passwordError : <></>}
              </p>
            </div>
          </div>
          {permissionVisibility ? (
            <div style={{ position: "relative", width: "100%" }}>
              <Input
                value={limitDiscount}
                onChange={verifyValuePercentage}
                color="black"
                width="100%"
                height="45px"
                type={"number"}
                name="Limite de desconto"
              />
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="modal-filter-table-list-user-edit"
        show={modalConfirmation}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            style={{
              display: "flex",
              height: "30px",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                onClick={() => setModalConfirmation(false)}
                style={{ cursor: "pointer" }}
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_0_2887)">
                    <path
                      d="M7.49864 6.3679L12.5016 1.35941C12.8122 1.04847 12.8122 0.544354 12.5016 0.233445C12.1911 -0.0774893 11.6876 -0.0774893 11.377 0.233445L6.37456 5.24246L1.37211 0.233445C1.06155 -0.0774893 0.55804 -0.0774893 0.247506 0.233445C-0.0630274 0.544378 -0.0630522 1.0485 0.247506 1.35941L5.25048 6.3679L0.247506 11.3764C-0.0630522 11.6874 -0.0630522 12.1915 0.247506 12.5024C0.558065 12.8133 1.06158 12.8133 1.37211 12.5024L6.37456 7.49334L11.377 12.5024C11.6876 12.8133 12.1911 12.8133 12.5016 12.5024C12.8122 12.1915 12.8122 11.6873 12.5016 11.3764L7.49864 6.3679Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_0_2887">
                      <rect
                        width="12.72"
                        height="12.7354"
                        fill="white"
                        transform="translate(0.0143433)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <p
                className="textPrimaryModalFilterManager"
                style={{ left: "4px", top: "10px" }}
              >
                Excluir usuário
              </p>
            </div>

            <button
              className="textBtnRemove"
              onClick={() => remove()}
              style={btnStyleRemove}
            >
              Excluir
            </button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: "17px" }}>
          <p className="textConfirmationRemove">
            Tem certeza que deseja excluir o usuário <b>{name}</b> ?
          </p>
          <div style={{ height: "40px" }}></div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TableListAdm;
