import { useState } from "react";
import Button from "./Button";
import CardQuotation from "./CardQuotation";
import Input from "./Input";
import InputSelectFilterManager from "./InputSelectFilterManager";
import { useContext } from "react";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import api from "../services/api";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";

const SectionSelectShippingType = ({
  nationalRoad,
  setNationalRoad,
  internationalRoad,
  setInternationalRoad,
  storage,
  setStorage,
  airTransport,
  setAirTransport,
  maritimeTransport,
  setMaritimeTransport,
  checkFractional,
  setCheckFractional,
  checkNational,
  setCheckNational,
  checkDedicated,
  setCheckDedicated,
  checkStandardInternational,
  setCheckStandardInternational,
  checkPremiumInternational,
  setCheckPremiumInternational,
  checkDedicatedInternational,
  setCheckDedicatedInternational,
  checkNationalAir,
  setCheckNationalAir,
  checkInternationalAir,
  setCheckInternationalAir,
  setDataCheckFractional,
}) => {
  const {
    setTypeService,
    setTypeContainer,
    setModelContainer,
    typeVehicle,
    setTypeVehicle,
    onSumary,
    temperature,
    setTemperature,
  } = useContext(DashboardContext);

  const [typeBuniness, setTypeBuniness] = useState("");

  /* FUNCTION LISTINGS BY TYPES */
  const handleStateChange = (stateSetter) => {
    setNationalRoad(false);
    setInternationalRoad(false);
    setStorage(false);
    setAirTransport(false);
    setMaritimeTransport(false);
    stateSetter(true);
    setDataCheckFractional([]);

    if (stateSetter === setNationalRoad) {
      setCheckFractional(true);
      setCheckNational(false);
      setCheckDedicated(false);

      setCheckStandardInternational(false);
      setCheckPremiumInternational(false);
      setCheckDedicatedInternational(false);
    }

    if (stateSetter === setInternationalRoad) {
      setCheckStandardInternational(true);
      setCheckPremiumInternational(false);
      setCheckDedicatedInternational(false);

      setCheckFractional(false);
      setCheckNational(false);
      setCheckDedicated(false);
    }

    if (stateSetter === setStorage) {
      setCheckNationalAir(false);
      setCheckInternationalAir(false);

      setCheckFractional(false);
      setCheckNational(false);
      setCheckDedicated(false);

      setCheckStandardInternational(false);
      setCheckPremiumInternational(false);
      setCheckDedicatedInternational(false);
    }

    if (stateSetter === setAirTransport) {
      setCheckNationalAir(true);
      setCheckInternationalAir(false);

      setCheckFractional(false);
      setCheckNational(false);
      setCheckDedicated(false);

      setCheckStandardInternational(false);
      setCheckPremiumInternational(false);
      setCheckDedicatedInternational(false);
    }

    if (stateSetter === setMaritimeTransport) {
      setCheckNationalAir(false);
      setCheckInternationalAir(false);

      setCheckFractional(false);
      setCheckNational(false);
      setCheckDedicated(false);

      setCheckStandardInternational(false);
      setCheckPremiumInternational(false);
      setCheckDedicatedInternational(false);
    }
  };

  /* FUNCTION LISTINGS BY CARDS */
  const handleCardChange = (stateSetter) => {
    setCheckNationalAir(false);
    setCheckInternationalAir(false);

    setCheckFractional(false);
    setCheckNational(false);
    setCheckDedicated(false);

    setCheckStandardInternational(false);
    setCheckPremiumInternational(false);
    setCheckDedicatedInternational(false);
    stateSetter(true);
    setDataCheckFractional([]);
  };

  const limitLength = (e) => {
    if (String(e.target.value).length <= 2) {
      setTemperature(e.target.value);
    }
  };

  const getProfileUser = async () => {
    try {
      const decoded = jwt_decode(
        localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:")
      );
      const { id } = decoded;
      const response = await api.get(`/user/${id}`);
      setTypeBuniness(response.data.type_buniness);
    } catch (error) {}
  };

  useEffect(() => {
    getProfileUser();
  }, []);

  /* STYLES */
  const containerContent = {
    backgroundColor: "#fff",
    width: "100%",
    padding: "32px",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
  };

  const selectServices = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  };

  return (
    <div className="containerCard" style={containerContent}>
      <div style={selectServices}>
        <h3 className="textTitleTypeDispatch">
          1° Selecione o perfil do seu envio
        </h3>
        <p className="textDescriptionTypeDispatch">Categoria do serviço</p>

        <div
          onScrollCapture={(e) => {
            //console.log(e)
          }}
          style={{
            width: "100%",
            // overflowX: "auto"
          }}
        >
          <div
            style={{
              borderBottom: "1px solid #d7d7d7",
              display: "flex",
              flexDirection: "row",
              gap: "2px",
              paddingBottom: "13px",
              justifyContent: "space-between",
            }}
          >
            {typeBuniness !== "Transportadora" && (
              <Button
                width="auto"
                height="35px"
                background={
                  nationalRoad ? "var(--color-primary)" : "var(--white)"
                }
                borderRadius="60px"
                padding="0 25px"
                marginTop="0"
                name="Rodoviário nacional"
                color={nationalRoad ? "var(--white)" : "var(--color-primary)"}
                border="none"
                onClick={() => handleStateChange(setNationalRoad)}
              />
            )}
            {typeBuniness !== "Transportadora" && (
              <Button
                width="auto"
                height="35px"
                background={
                  internationalRoad ? "var(--color-primary)" : "var(--white)"
                }
                borderRadius="60px"
                padding="0 25px"
                marginTop="0"
                name="Rodoviário internacional"
                color={
                  internationalRoad ? "var(--white)" : "var(--color-primary)"
                }
                border="none"
                onClick={() => handleStateChange(setInternationalRoad)}
              />
            )}
            <Button
              width="auto"
              height="35px"
              background={storage ? "var(--color-primary)" : "var(--white)"}
              borderRadius="60px"
              padding="0 25px"
              marginTop="0"
              name="Armazenagem"
              color={storage ? "var(--white)" : "var(--color-primary)"}
              border="none"
              onClick={() => handleStateChange(setStorage)}
            />

            {typeBuniness !== "Agente de cargas" &&
              typeBuniness !== "Operador logístico" &&
              typeBuniness !== "Outros" && (
                <Button
                  width="auto"
                  height="35px"
                  background={
                    airTransport ? "var(--color-primary)" : "var(--white)"
                  }
                  borderRadius="60px"
                  padding="0 25px"
                  marginTop="0"
                  name="Aéreo"
                  color={airTransport ? "var(--white)" : "var(--color-primary)"}
                  border="none"
                  onClick={() => handleStateChange(setAirTransport)}
                />
              )}

            {typeBuniness !== "Agente de cargas" &&
              typeBuniness !== "Operador logístico" &&
              typeBuniness !== "Outros" && (
                <Button
                  width="auto"
                  height="35px"
                  background={
                    maritimeTransport ? "var(--color-primary)" : "var(--white)"
                  }
                  borderRadius="60px"
                  padding="0 25px"
                  marginTop="0"
                  name="Marítimo"
                  color={
                    maritimeTransport ? "var(--white)" : "var(--color-primary)"
                  }
                  border="none"
                  onClick={() => handleStateChange(setMaritimeTransport)}
                />
              )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
            paddingBottom: !checkDedicated ? "25px" : "",
          }}
        >
          {nationalRoad && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "space-between",
                  paddingBottom: "20px",
                }}
              >
                <CardQuotation
                  // title="Frete fracionado (LTL)"
                  title="Frete fracionado"
                  check={checkFractional}
                  description="Para mercadorias gerais que compartilham espaço com outros envios."
                  setCheck={setCheckFractional}
                  onHandleCardChange={() =>
                    handleCardChange(setCheckFractional)
                  }
                />

                <CardQuotation
                  // title="Distribuição nacional (LTL)"
                  title="Distribuição nacional"
                  check={checkNational}
                  description="Para projetos de distribuição nacional por estado."
                  setCheck={setCheckNational}
                  onHandleCardChange={() => handleCardChange(setCheckNational)}
                />

                <CardQuotation
                  // title="Frete dedicado (FTL)"
                  title="Frete dedicado"
                  check={checkDedicated}
                  description="Frete exclusivo para mercadorias gerais e quimicas."
                  setCheck={setCheckDedicated}
                  onHandleCardChange={() => handleCardChange(setCheckDedicated)}
                />
              </div>
              {checkDedicated && (
                <InputSelectFilterManager
                  onBlur={onSumary}
                  onChange={(e) => setTypeVehicle(e.target.value)}
                  color="var(--grey-1)"
                  name="Tipo de veículo"
                  width="301px"
                  top="50px"
                  custom="false"
                  type="typeVehicle"
                />
              )}
            </div>
          )}

          {internationalRoad && (
            <>
              <CardQuotation
                title="Frete LTL Standard"
                check={checkStandardInternational}
                description="Na entrega LTL standard o Transit Time e de até 12 dias."
                setCheck={setCheckStandardInternational}
                onHandleCardChange={() =>
                  handleCardChange(setCheckStandardInternational)
                }
              />

              <CardQuotation
                title="Frete LTL Premium"
                check={checkPremiumInternational}
                description="Na entrega LTL premium o Transit Time e de até 5 dias."
                setCheck={setCheckPremiumInternational}
                onHandleCardChange={() =>
                  handleCardChange(setCheckPremiumInternational)
                }
              />

              <CardQuotation
                title="Frete dedicado (FTL)"
                check={checkDedicatedInternational}
                description="Frete exclusivo para mercadorias gerais."
                setCheck={setCheckDedicatedInternational}
                onHandleCardChange={() =>
                  handleCardChange(setCheckDedicatedInternational)
                }
              />
            </>
          )}

          {airTransport && (
            <div style={{ display: "flex", gap: "20px", overflowX: "auto" }}>
              <CardQuotation
                width={"301px"}
                title="Aéreo nacional"
                check={checkNationalAir}
                description="Para mercadorias que tem como destino cidades e estados do brasil."
                setCheck={setCheckNationalAir}
                onHandleCardChange={() => handleCardChange(setCheckNationalAir)}
              />

              <CardQuotation
                width={"301px"}
                title="Aéreo internacional"
                check={checkInternationalAir}
                description="Para mercadorias que tem como destino outros países"
                setCheck={setCheckInternationalAir}
                onHandleCardChange={() =>
                  handleCardChange(setCheckInternationalAir)
                }
              />
            </div>
          )}

          {/* {maritimeTransport && (
            <div
              style={{
                display: "flex",
                gap: "20px",
                width: "100%",
                borderTop: "1px solid #d7d7d7",
                paddingTop: "17px",
              }}
            >
              <InputSelectFilterManager
                onChange={(e) => setTypeService(e.target.value)}
                color="var(--grey-1)"
                name="Tipo de serviço"
                width="100%"
                top="50px"
                custom="false"
                type="typeService"
              />
              <InputSelectFilterManager
                onChange={(e) => setTypeContainer(e.target.value)}
                color="var(--grey-1)"
                name="Tipo de container"
                width="100%"
                top="50px"
                custom="false"
                type="typeContainer"
              />
              <InputSelectFilterManager
                onChange={(e) => setModelContainer(e.target.value)}
                color="var(--grey-1)"
                name="Modelo do container"
                width="100%"
                top="50px"
                custom="false"
                type="modelContainer"
              />
              <Input
                value={temperature}
                onChange={limitLength}
                color="#000"
                name="Temperatura"
                width="100%"
                top="50px"
                custom="false"
                type="number"
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default SectionSelectShippingType;
