import { BigHead } from "@bigheads/core"


const CardUserInput = ({ image, name, onClick }) => {

    return (
        <div onClick={onClick} style={{
            backgroundColor: "#E4EBFF",
            width: "90%",
            borderRadius: "60px",
            display: "flex",
            alignItems: "center",
            padding: "3px",
            gap: "10px",
            cursor: "pointer"
        }}>
            {
                image ? (
                    <img src={image} />
                )
                    :
                    (
                        <div style={{
                            width: "30px",
                            height: "30px"
                        }}>
                            <BigHead
                                accessory="shades"
                                body="chest"
                                circleColor="blue"
                                clothing="tankTop"
                                clothingColor="black"
                                eyebrows="angry"
                                eyes="wink"
                                facialHair="mediumBeard"
                                graphic="vue"
                                hair="short"
                                hairColor="black"
                                hat="none"
                                hatColor="green"
                                lashes="false"
                                lipColor="purple"
                                mask="true"
                                faceMask="true"
                                mouth="open"
                                skinTone="brown"
                            />
                        </div>
                    )
            }

            <p className="textNameTableListManager">{name}</p>
        </div>
    )
}

export default CardUserInput