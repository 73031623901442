import React, { useEffect, useState } from "react";
import closeEyeIcon from "../images/svg/closeEyeIcon.svg";
import openEyeIcon from "../images/svg/openEyeIcon.svg";
import InputMask from "react-input-mask";
const Input = ({
  states,
  name,
  type,
  placeholder,
  onChange,
  width,
  error,
  height,
  marginLeft,
  color,
  onBlur,
  value,
  rightEye,
  topEye,
  mask,
  marginBottom,
  onClick,
  setStateInput,
  isActive,
  modal,
  onKeyBoard,
  disabled,
}) => {
  const [stateEye, setStateEye] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && onKeyBoard) {
      // Realize a ação desejada aqui, por exemplo, chame uma função onClick
      onKeyBoard();
    }
  };

  const containerInput = {
    display: "flex",
    flexDirection: "column",
    marginLeft: marginLeft,
    width: width,
  };

  const styleInput = {
    width: width != undefined ? width : "418px",
    color: color != undefined ? color : "",
    height: height != undefined ? height : "45px",
    borderRadius: "10px",
    border: "1px solid #D7D7D7",
    marginBottom: marginBottom ? marginBottom : "1.9rem",
    outline: "none",
    backgroundColor:
      name == "Cubagem M³" || name == "Peso cubado" || isActive == false
        ? "#d7d7d745"
        : "#fff",
  };

  const styleLabel = {
    marginBottom: name == "Peso cubico" ? "18.43px" : "14.43px",
    fontSize: "12.8px",
  };

  const containerInputMail = {
    width: width != undefined ? width : "418px",

    display: "flex",
    flexDirection: "column",
    position: "relative",
  };

  const containerInputPassowrd = {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: width != undefined ? width : "418px",
  };

  const image = {
    position: "absolute",
    right: 0,
    top: topEye != undefined ? topEye : "50px",
    right: rightEye != undefined ? rightEye : "10px",
    cursor: "pointer",
  };

  return (
    <div style={containerInput}>
      {type == "password" ? (
        <div style={containerInputPassowrd}>
          <label className="textPrimaryLabelInput" style={styleLabel}>
            {name}
          </label>
          <input
            onBlur={onBlur}
            className="textPrimaryInput"
            onChange={onChange}
            placeholder={placeholder}
            type={stateEye == true ? "text" : type}
            style={styleInput}
            onKeyDown={handleKeyDown}
          />

          {stateEye == false ? (
            <img
              src={closeEyeIcon}
              onClick={() => setStateEye(!stateEye)}
              style={image}
            />
          ) : (
            <img
              src={openEyeIcon}
              onClick={() => setStateEye(!stateEye)}
              style={image}
            />
          )}

          {error != undefined ? (
            <p className="textErrorInput">{error}</p>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div style={containerInputMail}>
          <label className="textPrimaryLabelInput" style={styleLabel}>
            {name}
          </label>
          {states ? (
            <>
              <InputMask
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    setStateInput(false);
                  }
                }}
                id="#input"
                onClick={onClick}
                onBlur={onBlur}
                disabled={
                  (name == "Cubagem M³" && true) ||
                  (name == "Peso cubado" && true) ||
                  disabled ||
                  false
                }
                maskChar=""
                mask={mask}
                className="textPrimaryInput"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                type={type}
                style={styleInput}
                onKeyDownCapture={handleKeyDown}
              />
            </>
          ) : (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputMask
                  onClick={onClick}
                  onBlur={onBlur}
                  disabled={
                    (name == "Cubagem M³" && true) ||
                    (name == "Peso cubado" && true) ||
                    (isActive == false && true) ||
                    false
                  }
                  maskChar=""
                  mask={mask}
                  className="textPrimaryInput"
                  value={value}
                  onChange={onChange}
                  placeholder={placeholder}
                  type={type}
                  style={styleInput}
                  onKeyDownCapture={handleKeyDown}
                />
                {modal?.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      backgroundColor: "#fff",
                      zIndex: "1",
                      top: "84px",
                      border: "1px solid #d7d7d7",
                      width: "100%",
                      borderRadius: "8px",
                      marginTop: "2px",
                      maxHeight: "200px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      overflowY: "auto",
                      padding: "17px 16px",
                    }}
                  >
                    {modal.map((item) => {
                      return (
                        <p onClick={onClick} style={{ cursor: "pointer" }}>
                          {item.nome}
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>
              {error != undefined ? (
                <p className="textErrorInput">{error}</p>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Input;
