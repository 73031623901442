/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import uploadIcon from "../images/svg/uploadIcon.svg";
import { toast } from "react-toastify";
import deleteImageIcon from "../images/svg/deleteImageIcon.svg";
import app from "../firebase/database";
import attachment from "../images/png/link-file.png";

const UploadImg = ({
  imagesFirebase,
  setImagesFirebase,
  index,
  fileName,
  click,
}) => {
  const [imagesBase64, setImagesBase64] = useState([]);
  const [arryBase64, setArryBase64] = useState([]);

  /* IMAGE DELETION FUNCTION */
  const deleteFile = (file) => {
    const filterImgs = imagesBase64.filter((img) => img !== file);
    setImagesBase64(filterImgs);
    setImagesFirebase(filterImgs);
    click();
  };

  /* FUNCTION TO ADD IMAGES */
  const getImages = async (file) => {
    if (imagesBase64.length >= 5) {
      toast.error("Limite de imagens atingido!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return false;
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        // O resultado é armazenado em event.target.result
        const base64String = event.target.result;

        const array = [...imagesBase64];
        const base = [...arryBase64];

        array.push({ filename: file.name, content: base64String });
        base.push({ filename: file.name, content: base64String });

        setImagesBase64(array);

        setArryBase64(base);
        setImagesFirebase(base);
      };
      reader.readAsDataURL(file);
    }
    click();
    // const reader = new FileReader()

    // reader.readAsDataURL(file)

    // reader.onload = () => {

    //     const array = [...imagesBase64]

    //     array.push({ image: reader.result })

    //     setImagesBase64(array)
    // }

    // await app.storage().ref(`/profile/${file.name}`).put(file).then(() => {
    //     app.storage().ref(`/profile/${file.name}`).getDownloadURL().then(async (url) => {
    //         const images = [...imagesFirebase]
    //         images.push(url)
    //         setImagesFirebase(images)
    //     })
    // })
  };

  /* STYLES COMPONENTS */
  const container = {
    marginTop: "20px",
    border: "2px dashed #D7D7D7",
    borderRadius: "60px",
    width: "100%",
  };

  const boxContent = {
    display: "flex",
    justifyContent: "center",
    padding: "8px",
  };

  const boxIcon = {
    display: "flex",
    alignItems: "center",
    gap: "13px",
    cursor: "pointer",
  };

  const boxImgAndIcon = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <>
      <div style={container}>
        <div style={boxContent}>
          <div style={boxImgAndIcon}>
            <label htmlFor="file" style={boxIcon}>
              <img src={uploadIcon} />
              <p
                style={{ transform: "translateY(3px)" }}
                className="textUploadImg"
              >
                Upload
              </p>
            </label>
            <div style={{ display: "flex" }}>
              {imagesBase64?.map((item) => {
                console.log(item.filename);
                return (
                  <div
                    style={{
                      position: "relative",
                      marginRight: "3rem",
                      marginLeft: "0.7rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        // src={item.content}
                        src={attachment}
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "3px",
                          marginTop: "32px",
                        }}
                      />
                      <p style={{ fontSize: "13px" }}>{item.filename}</p>
                    </div>

                    <img
                      onClick={() => deleteFile(item)}
                      src={deleteImageIcon}
                      style={{
                        position: "absolute",
                        top: "25px",
                        right: "0",
                        background: "white",
                        zIndex: "1px",
                        borderRadius: "30px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <input
        onChange={(e) => getImages(e.target.files[0])}
        // accept="image/png"
        name="file"
        id="file"
        type="file"
        style={{ display: "none" }}
      />
    </>
  );
};

export default UploadImg;
