import { useEffect, useState } from "react";
import CardBackList from "../../components/CardBackList";
import HeaderDashboard from "../../components/HeaderDashboard";
import InputSearch from "../../components/InputSearch";
import ModalContainer from "../../components/ModalContainer";
import Sidebar from "../../components/Sidebar";
import ThowColumLayout from "../../layouts/ThowColumLayout";
import ModalOptions from "../../components/ModalOptions";
import ModalBlackList from "../../components/ModalBlackList";
import api from "../../services/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import jwt_decode from "jwt-decode";
import { DashboardContext } from "../../context/Dashboard/DashboardContext";
import { UserContext } from "../../context/User";

const BlackList = () => {
  const {
    permissionVisibility,
    getProfile,
    requestMade,
    setPermissionVisibility,
  } = useContext(UserContext);

  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [listUsers, setListUsers] = useState([]);
  const [update, setUpdate] = useState(false);

  const getUsersNotApproved = async () => {
    const response = await api.get("/user/not/approved");

    console.log(response.data);

    setListUsers(response.data);
  };

  const approvedUser = async (id) => {
    try {
      const response = await api.patch(`/approved/${id}`);

      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setUpdate(!update);
    } catch (error) {
      return toast.error(error.data.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const controlPermission = async () => {
    // Verifica se já foi feito essa requisição
    if (requestMade) {
      if (!permissionVisibility)
        navigate("/dashboard/manage/admin");
    } else {
      await getProfile();
    }
  };

  useEffect(() => {
    controlPermission();
  }, [permissionVisibility, requestMade]);

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    getUsersNotApproved();
  }, [update]);

  /* STYLES */
  const container = {
    maxWidth: "100%",
    paddingRight: "4.125rem",
    paddingTop: "2.6875rem",
    paddingLeft: "3.375rem",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginBottom: "20px",
  };

  const boxCards = {
    maxWidth: "1000px",
    display: "flex",
    gap: "21px",
    flexWrap: "wrap",
    marginTop: "36px",
  };

  return permissionVisibility ? (
    <ThowColumLayout
      page={"DashboardAdm"}
      colum1={<Sidebar page="BlackList" access="adm" />}
      colum2={
        <div
          style={{
            minHeight: "100vh",
            backgroundColor: "#F9F9F9",
          }}
        >
          <HeaderDashboard name="Lista negra" adm={true} />

          <div className="boxContainerCard" style={container}>
            <div className="containerBox">
              <InputSearch
                table={true}
                onClick={() => setModal(true)}
              />

              <ModalContainer
                isActive={modal}
                closeModal={() => setModal(false)}
              >
                <ModalBlackList
                  isActive={modal}
                  type="delete"
                  closeModal={() => setModal(!modal)}
                />
              </ModalContainer>
            </div>

            <div style={boxCards}>
              {listUsers.map((item) => {
                return (
                  <CardBackList
                    id={item.id}
                    name={item.name}
                    corporate={item.corporate_name}
                    cnpj={item.cnpj}
                    cnaes={item.cnaes}
                    email={item.email}
                    whatsapp={item.whatsapp}
                    approved={approvedUser}
                    typeBuniness={item.type_buniness}
                  />
                );
              })}
            </div>
          </div>
        </div>
      }
    ></ThowColumLayout>
  ) : (
    <></>
  );
};

export default BlackList;
