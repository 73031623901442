import { useContext, useEffect, useState } from "react";
import { proposalsClients } from "../pages/Proposal/mocks";
import ModalContainer from "./ModalContainer";
import ModalProposal from "./ModalProposal";
import api from "../services/api";
import { ProposalContext } from "../context/Dashboard/ProposalContext";
import { Tooltip as ReactTooltip } from "react-tooltip";
import LetterLimit from "../utils/LimitCharacters";
import ProposalLine from "./LineProposal";
import Pagination from "./Pagination";

const TableProposalContent = ({ client }) => {
  const {
    getProposalAdm,
    proposalAdm,
    totalPagesAdm,
    searchProposal,
    getProposalAdmSearch,
    proposalClient,
    totalPagesClient,
    searchProposalClient,
    getProposalClient,
    getProposalClientSearch,
  } = useContext(ProposalContext);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (client) {
      if (String(searchProposalClient).length > 0) {
        console.log(searchProposalClient);
        getProposalClientSearch(currentPage);
      } else {
        getProposalClient(currentPage);
      }
    } else {
      if (String(searchProposal).length > 0) {
        getProposalAdmSearch(currentPage);
      } else {
        getProposalAdm(currentPage);
      }
    }
  }, [currentPage, searchProposal, searchProposalClient, client]);

  return client ? (
    <>
      {proposalClient?.map((elem, i) => {
        return (
          <>
            <ProposalLine proposal={elem} key={i} client={client} />
          </>
        );
      })}
      <Pagination
        totalPages={totalPagesClient}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  ) : (
    <>
      {proposalAdm?.map((elem, i) => {
        return (
          <>
            <ProposalLine proposal={elem} key={i} />
          </>
        );
      })}
      <Pagination
        totalPages={totalPagesAdm}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default TableProposalContent;
