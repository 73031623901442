import { useEffect, useState } from "react";
import camPicture from "../images/svg/camPicture.svg";
import Input from "./Input";
import Button from "./Button";
import jwt_decode from "jwt-decode";
import { useContext } from "react";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import { BigHead } from "@bigheads/core";

import api from "../services/api";
import app from "../firebase/database";
import axios from "axios";
import { cnpj } from "cpf-cnpj-validator";
import { toast } from "react-toastify";
import { set } from "react-hook-form";

const ProfileCard = () => {
  const { user, getProfileContext, updateUser } = useContext(DashboardContext);

  const [companyName, setCompanyName] = useState(user?.corporate_name);
  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);

  const [cnpjData, setCnpjData] = useState();
  const [cnaesData, setCnaesData] = useState(user?.cnaes);

  const [password, setPassword] = useState();
  const [typeBuniness, setTypeBuniness] = useState(user?.type_buniness);

  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState(user?.picture);
  const [imageFirebase, setImageFirebase] = useState("");

  useEffect(() => {
    console.log(user?.picture);
  }, [user]);

  const loadImage = async (file) => {
    setImage(file);
    setImageFirebase(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async () => {
      setImagePreview(reader.result);
    };

    await app
      .storage()
      .ref(`/profile/${file.name}`)
      .put(file)
      .then(() => {
        app
          .storage()
          .ref(`/profile/${file.name}`)
          .getDownloadURL()
          .then(async (url) => {
            const data = {
              picture: url,
            };

            setImagePreview(url);
            const response = await api.patch(`/user/${user.id}`, data);
            setImageFirebase("");
          });
      });
  };

  const getProfile = async (token) => {
    const decoded = jwt_decode(token);
    const { id } = decoded;
    const response = await getProfileContext(id);

    setName(response.name);
    setEmail(response.email);
    setCompanyName(response.corporate_name);
    setCnpjData(response.cnpj);
    setTypeBuniness(response.type_buniness);
    setCnaesData(response.cnaes);
    setImagePreview(response.picture);
  };

  const verifyCnpj = async () => {
    const validate = cnpj.isValid(cnpjData);

    if (!validate) {
      return toast.error("CNPJ inválido", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const data = {
      cnpj: cnpjData,
    };

    //const response = await updateUser(data)
    //setCompanyName(response.corporate_name)
    //setCnpjData(response.cnpj)
    //setCnaesData(response.cnaes)
  };

  const updatePassword = async () => {
    const data = {
      password: password,
    };

    if (String(password).length < 8) {
      return toast.warn("Senha fraca, defina no mínimo 8 caracteres", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    try {
      const response = await api.patch(`user/${user.id}`, data);

      console.log(response);

      return "update";
    } catch (error) {
      return toast.warn(error.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const upadateProfile = async () => {
    const validate = cnpj.isValid(cnpjData);
    const formactCnpj = cnpjData.replace(/[^0-9]/g, "");

    if (!validate) {
      return toast.error("CNPJ inválido", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    try {
      const data = {
        cnpj: formactCnpj,
        type_buniness: typeBuniness,
        name: name,
        email: email,
      };

      if (password) {
        const updatePass = await updatePassword();

        if (updatePass !== "update") {
          return null;
        }
      }

      const response = await api.patch(`/user/${user.id}`, data);

      setCompanyName(response.data.corporate_name);
      setCnpjData(response.data.cnpj);
      setCnaesData(response.data.cnaes);

      toast.success("Dados atualizados!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {}
  };

  useEffect(() => {
    const token = localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:");

    if (token) {
      getProfile(token);
    }
  }, []);

  const boxLineInput = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  };

  return (
    <div
      style={{
        width: "713px",
        background: "#FFFFFF",
        border: "1px solid #D7D7D7",
        borderRadius: "15px",
        padding: "37px",
        margin: "0 auto",
      }}
    >
      <div>
        <input
          onChange={(e) => loadImage(e.target.files[0])}
          accept="image/png"
          name="file"
          id="file"
          type="file"
          style={{ display: "none" }}
        />

        <label
          htmlFor="file"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto 3.93rem",
            width: "115px",
            height: "115px",
            position: "relative",
          }}
        >
          {imagePreview ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                border: "1px solid #d7d7d7d7",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={imagePreview}
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "100%",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                border: "1px solid #d7d7d7d7",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  minWidth: "150px",
                  height: "150px",
                  transform: "translateY(-20px)",
                }}
              >
                <BigHead
                  accessory="shades"
                  body="chest"
                  circleColor="blue"
                  clothing="tankTop"
                  clothingColor="black"
                  eyebrows="angry"
                  eyes="wink"
                  facialHair="mediumBeard"
                  graphic="vue"
                  hair="short"
                  hairColor="black"
                  hat="none"
                  hatColor="green"
                  lashes="false"
                  lipColor="purple"
                  mask="true"
                  faceMask="true"
                  mouth="open"
                  skinTone="brown"
                />
              </div>
            </div>
          )}
          <img
            src={camPicture}
            style={{
              position: "absolute",
              bottom: 4.5,
              width: "33px",
              right: "5px",
            }}
          />
        </label>
      </div>
      <div style={boxLineInput}>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={verifyCnpj}
          name="Nome"
          width="100%"
          placeholder="Seu nome completo"
          type={"text"}
        />
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          name="Email"
          width="100%"
          placeholder="Seu email"
          type={"text"}
        />
      </div>
      <div style={boxLineInput}>
        <Input
          mask="99.999.999/9999-99"
          value={cnpjData}
          onChange={(e) => setCnpjData(e.target.value)}
          onBlur={verifyCnpj}
          name="CNPJ"
          width="100%"
          placeholder="Digite apenas números"
          type={"text"}
        />
        <Input
          value={companyName}
          name="Razão social"
          width="100%"
          placeholder="Nome da razão social"
          type={"text"}
        />
      </div>
      <div style={boxLineInput}>
        <Input value={cnaesData} name="CNAEs" width={"100%"} />
        <Input
          value={typeBuniness}
          name="Insira seu tipo de negocio"
          width="100%"
          placeholder="Tecnologia"
          type={"text"}
        />
      </div>
      <div style={boxLineInput}>
        <Input
          name="Senha"
          width="308px"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Insira uma senha"
          type={"password"}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "47px",
        }}
      >
        <Button
          width="331px"
          height="43px"
          background={"var(--color-primary)"}
          borderRadius="60px"
          marginTop="0"
          name="Salvar"
          color={"var(--white)"}
          border="none"
          onClick={() => upadateProfile()}
        />
      </div>
    </div>
  );
};

export default ProfileCard;
