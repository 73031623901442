import { useContext, useEffect, useState } from "react";
import Button from "./Button";
import InputSearch from "./InputSearch";
import AddExcel from "./AddExcel";
import api from "../services/api";
import { toast } from "react-toastify";
import { ProposalContext } from "../context/Dashboard/ProposalContext";
import FilterPage from "./FilterPage";
import ModalContainer from "./ModalContainer";
import ModalFilterProposal from "./ModalFilterProposal";

const SearchProposalsContainer = ({ widthInput, heightInput, client }) => {
  const { setSearchProposal, setSearchProposalClient } =
    useContext(ProposalContext);
  const [isActive, setIsActive] = useState(false);

  const container = {
    display: "flex",
    gap: "20px",
    alignItems: "center",
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      console.log(event.key);
      if (client) {
        setSearchProposalClient(event.target.value);
      } else {
        setSearchProposal(event.target.value);
      }
    }
  };

  return (
    <div className="containerBox" style={container}>
      <InputSearch
        width={widthInput}
        height={heightInput}
        onBlur={(text) => setSearchProposalClient(text.target.value)}
        onKeyDown={handleKeyPress}
      />

      <FilterPage onClick={() => setIsActive(true)} />

      <ModalContainer isActive={isActive} closeModal={() => setIsActive(true)}>
        <ModalFilterProposal
          closeModal={() => setIsActive(false)}
          client={client}
        />
      </ModalContainer>
    </div>
  );
};

export default SearchProposalsContainer;
