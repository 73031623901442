import React, { useState, useEffect } from 'react'

import ThowColumLayoutLogin from '../../layouts/ThowColumLayoutLogin'
import forgotPasswordBackgrond from '../../images/svg/forgotPasswordBackgrond.svg'
import companyIcon from '../../images/svg/companyIcon.svg'
import Button from '../../components/Button'
import api from '../../services/api'
import Input from '../../components/Input'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const ForgotPassword = () => {

    const [mail, setMail] = useState()
    const [password, setPassword] = useState()
    const navigate = useNavigate();

    /* MESSAGE ERROS */

    const [mailError, setMailError] = useState()
    const [paswordError, setPasswordError] = useState()

    /* BTN STATE */

    const [btnState, setBtnState] = useState()


    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const onSubmitSendMail = async () => {

        if (!mail) {
            return toast.error('Preencha o campo de e-mail', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        if (!validateEmail(mail)) {
            return toast.error('Email inválido', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        const data = {
            email: mail,
        }

        try {
            const response = await api.post('/user/send/mail', data)

            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            localStorage.setItem('@MAXITRANS_MAIL_CLIENT:', mail)

            setTimeout(() => {
                navigate("/send/mail")
            }, 1300);

        } catch (error) {
            toast.error(error.response.data.error, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const container = {
        width: "418px"
    }

    const boxFooterButton = {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between'
    }

    const containerFigure = {
        height: "100vh",
        padding: "126px 80px"
    }

    const image = {
        height: "100%",
    }
    
    const containerColum2 = {
        paddingTop: "7.5rem",
    }

    return (

        <ThowColumLayoutLogin
            page={"ForgotPassword"}
            colum1={
                <div style={containerFigure}>
                    <img src={forgotPasswordBackgrond} style={image} />
                </div>
            }

            colum2={

                <div style={containerColum2}>

                    <img src={companyIcon} />

                    <div style={container}>
                        <h2 className="textPrimaryForgotPassword">Esqueceu a senha ?</h2>

                        <p className="textDescriptionForgotPassword">Não se preocupe! Digite seu e-mail para receber instruções de recuperação de senha</p>

                        <Input name="E-mail" error={mailError} onChange={(e) => setMail(e.target.value)} placeholder="Insira seu e-mail" type={"text"} />

                        <div style={boxFooterButton}>
                            <Button width="202px" height="48px" background="#123164"
                                borderRadius="60px" marginTop="0" name="Recuperar senha"
                                border="none" disabled={btnState} onClick={() => onSubmitSendMail()} />

                            <div>
                                <p
                                    onClick={() => navigate("/")}
                                    style={{
                                        cursor: "pointer"
                                    }} className='textForgotPassword'>Lembrou da senha?</p>
                            </div>
                        </div>
                    </div>

                </div>

            }


        >

        </ThowColumLayoutLogin>

    )

}

export default ForgotPassword