import { Routes, Route, Navigate } from "react-router-dom";
import { PrivateRoute } from "../pages/PrivateRoute";
import Landing from "../pages/Landing/index";
import ForgotPassword from "../pages/ForgotPassword";
import SendMail from "../pages/SendMail";
import ResetPassword from "../pages/ResetPassword";
import Register from "../pages/Register";
import Dashboard from "../pages/Dashboard";
import Profile from "../pages/Profile";
import LoginAdm from "../pages/LoginAdm";
import DashboardAdm from "../pages/DashboardAdm";
import ManageAccesses from "../pages/ManageAccesses";
import BlackList from "../pages/BlackList";
import Pdf from "../pages/Pdf";
import NationalDistributorPdf from "../pages/NationalDistributorPdf";
import StoragePdf from "../pages/StoragePdf";
import AirBoxPdf from "../pages/AirBoxPdf";
import PdfIternational from "../pages/PdfInternational";
import Verify from "../pages/Verify";
import ProposalPage from "../pages/Proposal/Admin";
import ProposalPageClient from "../pages/Proposal/Client/Index";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import useOnlineStatus from "../hooks/useOnlineStatus";

export const RoutesUrl = () => {
  useOnlineStatus();
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot/password" element={<ForgotPassword />} />
      <Route path="/reset/password/:id" element={<ResetPassword />} />
      <Route path="/send/mail" element={<SendMail />} />

      <Route element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/profile" element={<Profile />} />
        <Route path="/dashboard/proposal" element={<ProposalPageClient />} />
      </Route>

      <Route path="/login/admin" element={<LoginAdm />} />
      <Route path="/dashboard/admin" element={<DashboardAdm />} />
      <Route path="/dashboard/manage/admin" element={<ManageAccesses />} />
      <Route path="/dashboard/list/admin" element={<BlackList />} />
      <Route path="/dashboard/proposal/admin" element={<ProposalPage />} />

      {/* PDF */}
      <Route path="/verify/:id" element={<Verify />} />
      <Route path="/dashboard/pdf" element={<Pdf />} />
      <Route
        path="/dashboard/pdf/international"
        element={<PdfIternational />}
      />
      <Route
        path="/dashboard/pdf/national/distributor"
        element={<NationalDistributorPdf />}
      />
      <Route path="/dashboard/pdf/storage" element={<StoragePdf />} />
      <Route path="/dashboard/pdf/storage" element={<StoragePdf />} />
      <Route path="/dashboard/pdf/air/box" element={<AirBoxPdf />} />
    </Routes>
  );
};
