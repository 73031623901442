import React, { useEffect, useContext } from "react";
import { BigHead } from "@bigheads/core";
import { ModalContext } from "../context/Modal";
import LetterLimit from "../utils/LimitCharacters";

const CardTableUsers = ({
  checkNotVisible,
  name,
  mail,
  level,
  picture,
  onClick,
  status,
}) => {
  const { removeAllItems } = useContext(ModalContext);

  const styleColum2 = {
    border: "1px solid #D7D7D7",
    height: "55px",
    borderRadius: "10px",
    background: "white",
  };

  const changeTypeBtn = () => {
    removeAllItems(checkNotVisible);
  };

  useEffect(() => {
    changeTypeBtn();
  }, [checkNotVisible]);

  return (
    <div style={styleColum2}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          transform: "translateY(20px)",
          height: "80px",
        }}
      >
        <div style={{ display: "flex" }}>
          {!picture ? (
            <div
              style={{
                width: "45px",
                height: "35px",
                position: "relative",
                top: "-24px",
                left: "17px",
                borderRadius: "100%",
              }}
            >
              <BigHead
                accessory="shades"
                body="chest"
                circleColor="blue"
                clothing="tankTop"
                clothingColor="black"
                eyebrows="angry"
                eyes="wink"
                facialHair="mediumBeard"
                graphic="vue"
                hair="short"
                hairColor="black"
                hat="none"
                hatColor="green"
                lashes="false"
                lipColor="purple"
                mask="true"
                faceMask="true"
                mouth="open"
                skinTone="brown"
              />
            </div>
          ) : (
            <img
              src={picture}
              style={{
                width: "35px",
                height: "35px",
                position: "relative",
                top: "-14px",
                left: "17px",
                borderRadius: "100px",
              }}
            />
          )}

          <LetterLimit
            text={name}
            limit={13}
            threePoints={true}
            className="textNameTableListUser transformY5"
            id="type"
          />
        </div>

        <div>
          <LetterLimit
            text={mail}
            limit={20}
            threePoints={true}
            className="textNameTableListUser transformY5"
            id="type"
          />
        </div>

        {status !== false && (
          <div>
            <p
              className={`textNameTableListUser ${
                status == "unblocked" ? "approvedProposal" : "refusedProposal"
              }`}
              style={{ transform: "translateX(-10px)" }}
            >
              {status === "unblocked" ? "Ativo" : "Inativo"}
            </p>
          </div>
        )}

        <div style={{ display: "flex" }}>
          <p className="textNameTableListUser">{level}</p>

          <div
            style={{
              position: "relative",
              top: "-3px",
              left: "10px",
              cursor: "pointer",
            }}
            onClick={onClick}
          >
            <div
              onClick={onClick}
              style={{
                cursor: "pointer",
                width: "25px",
                height: "50px",
                position: "absolute",
                top: -20,
              }}
            ></div>
            <div
              style={{
                width: "4px",
                height: "4px",
                background: "black",
                borderRadius: "100%",
              }}
            ></div>
            <div
              style={{
                width: "4px",
                height: "4px",
                background: "black",
                borderRadius: "100%",
                marginTop: "2px",
              }}
            ></div>
            <div
              style={{
                width: "4px",
                height: "4px",
                background: "black",
                borderRadius: "100%",
                marginTop: "2px",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardTableUsers;
