import React, { useContext, useEffect, useState } from "react";
import { ProposalContext } from "../context/Dashboard/ProposalContext";

const AdditionalValues = ({ onAdd, id }) => {
  const { getAdditional } = useContext(ProposalContext);
  const [additionalValues, setAdditionalValues] = useState([]);
  const [description, setDescription] = useState("");
  const [value, setValue] = useState("");
  const [showInProposal, setShowInProposal] = useState(false);

  const handleAdd = () => {
    const newValue = {
      description,
      value,
      showInProposal,
    };

    if (description.length && value > 0) {
      setAdditionalValues([...additionalValues, newValue]);

      const filterExistingValues = [...additionalValues, newValue].filter(
        (elem) => elem?.description && elem?.value
      );
      setDescription("");
      setValue("");
      setShowInProposal(false);
    }
  };

  const handleDelete = (index) => {
    const updatedValues = additionalValues.filter((_, i) => i !== index);
    setAdditionalValues(updatedValues);
  };

  const listAdditional = async () => {
    const response = await getAdditional(id);

    if (response?.length) setAdditionalValues(response);
  };

  // Variáveis de estilo
  const containerStyle = {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "20px",
    maxWidth: "500px",
    margin: "20px auto",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const titleStyle = {
    fontSize: "18px",
    color: "#333",
    margin: "20px 0 15px",
  };

  const labelStyle = {
    fontSize: "14px",
    color: "#555",
    display: "block",
    marginBottom: "5px",
  };

  const inputStyle = {
    width: "100%",
    padding: "10px",
    fontSize: "14px",
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    outline: "none",
  };

  const checkboxStyle = {
    marginRight: "10px",
  };

  const buttonStyle = {
    padding: "10px 20px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
    marginTop: "10px",
    marginBottom: "20px",
  };

  const listStyle = {
    listStyle: "none",
    paddingLeft: "0",
    marginTop: "20px",
  };

  const listItemStyle = {
    backgroundColor: "#f0f0f0",
    padding: "10px",
    borderRadius: "4px",
    marginBottom: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const deleteButtonStyle = {
    backgroundColor: "#FF6347",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    padding: "5px 10px",
  };

  useEffect(() => {
    listAdditional();
  }, []);

  useEffect(() => {
    onAdd(additionalValues);
  }, [additionalValues]);

  return (
    <div style={containerStyle}>
      <h5 style={titleStyle}>Adicionar Valores Adicionais</h5>
      <div>
        <label style={labelStyle}>Descrição:</label>
        <input
          style={inputStyle}
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div>
        <label style={labelStyle}>Valor:</label>
        <input
          style={inputStyle}
          type="number"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <div>
        <label style={labelStyle}>
          <input
            style={checkboxStyle}
            type="checkbox"
            checked={showInProposal}
            onChange={(e) => setShowInProposal(e.target.checked)}
          />
          Exibir na Proposta
        </label>
      </div>
      <button style={buttonStyle} onClick={handleAdd}>
        Adicionar
      </button>

      {additionalValues.length > 0 && (
        <div style={{ borderTop: "1px solid #d7d7d7" }}>
          <h6 style={titleStyle}>Valores Adicionados</h6>
          <ul style={listStyle}>
            {additionalValues.map((item, index) => (
              <li key={index} style={listItemStyle}>
                <span>
                  {item.description} - {item.value}{" "}
                  {item.showInProposal && "(Exibir na proposta)"}
                </span>
                <button
                  style={deleteButtonStyle}
                  onClick={() => handleDelete(index)}
                >
                  Deletar
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AdditionalValues;
