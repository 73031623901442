import Button from "./Button"

const CardBackList = ({
    id,
    corporate,
    cnpj,
    cnaes,
    email,
    whatsapp,
    typeBuniness,
    approved
}) => {

    const container = {
        width: "318px",
        padding: "20px",
        border: "1px solid #D7D7D7",
        borderRadius: "10px",
        backgroundColor: "#fff"
    }

    const header = {
        paddingBottom: "16px",
        borderBottom: "1px solid #E4E4EF",
        marginBottom: "16px"
    }

    const boxLine = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "16px"
    }

    const footerBtn = {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        marginTop: "10px"
    }

    return (
        <div style={container}>
            <div style={header}>
                <h3 className="textTitleCardBlackList">{corporate}</h3>
            </div>
            <div style={boxLine}>
                <p className="textTypeCardBlackList">CNPJ</p>
                <p className="textContentCardBlackList">{cnpj}</p>
            </div>
            <div style={boxLine}>
                <p className="textTypeCardBlackList"style={{width: "200px",  overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>CNAEs</p>
                <p className="textContentCardBlackList">{cnaes}</p>
            </div>
            <div style={boxLine}>
                <p className="textTypeCardBlackList">Tipo de negocio</p>
                <p className="textContentCardBlackList" style={{width: "150px", overflow: "hidden", textOverflow: "ellipsis"}}>{typeBuniness}</p>
            </div>
            <div style={boxLine}>
                <p className="textTypeCardBlackList">E-mail</p>
                <p className="textContentCardBlackList" style={{width: "200px", overflow: "hidden", textOverflow: "ellipsis"}}>{email}</p>
            </div>
            <div style={boxLine}>
                <p className="textTypeCardBlackList">Whatsapp</p>
                <p className="textContentCardBlackList">{whatsapp}</p>
            </div>
            <div style={footerBtn}>
                <Button width="100%" height="40px" background={"#FFFFFF"}
                    borderRadius="60px" marginTop="0" name="Recusar"
                    color={"#EA0000"}
                    border="1px solid #EA0000" />

                <Button width="100%" height="40px" background={"#FFFFFF"}
                    borderRadius="60px" marginTop="0" name="Aprovar"
                    color={"#062F67"} onClick={()=> approved(id)}
                    border="1px solid #062F67" />
            </div>
        </div>
    )
}

export default CardBackList