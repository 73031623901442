import teste from "../../images/png/logoMaxitrans.png";

const Pdf = () => {
  return (
    <div className="containerPdf">
      <div className="containerBoxLogo">
        <img src={teste} />
      </div>
      <div className="headerTitle">
        <p className="proposal">Proposta Comercial - NACIONAL</p>
      </div>

      <div className="contentTitle">
        <div className="boxContentTitle">
          <p>DATA:</p>
          <p>19/06/2023</p>
        </div>

        <div className="boxContentTitle">
          <p>VALIDADE:</p>
          <p>19/07/2023</p>
        </div>

        <div className="boxContentTitle">
          <p>COTAÇÃO Nº:</p>
          <p>NC2023_114</p>
        </div>
      </div>

      <div className="contentValue">
        <p className="textTitleContent">DESTINATÁRIO</p>
        <div className="contentValueBox">
          <div className="contentBoxValueAddressee">
            <div>
              <p className="cardTitleAddressee">Nome do cliente:</p>
              <p className="cardContetAddressee">Enterworld</p>
            </div>
            <div className="beam"></div>
            <div>
              <p className="cardTitleAddressee">Contato Comercial:</p>
              <p className="cardContetAddressee">Ewerton</p>
            </div>
          </div>

          <div className="contentBoxValueAddressee translateAddress">
            <div>
              <p className="cardTitleAddressee">CNPJ do Cliente:</p>
              <p className="cardContetAddressee">.</p>
            </div>

            <div>
              <p className="cardTitleAddressee">Fone de contato:</p>
              <p className="cardContetAddressee">.</p>
            </div>

            <div className="emailBoxAddressee">
              <p className="cardTitleAddressee">E-mail de contato:</p>
              <p className="cardContetAddressee">.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="contentTitleCargoData">
        <div>
          <p>DADOS DA CARGA</p>
        </div>
      </div>

      <div className="containerCargoData">
        <div className="contentBoxValueCargoData">
          <div>
            <p className="cardTitleCargoData">Mercadoria:</p>
            <p className="cardContetCargoData">Maquina automatica</p>
          </div>

          <div>
            <p className="cardTitleCargoData">Peso bruto:</p>
            <p className="cardContetCargoDataNotColor">320 kg</p>
          </div>

          <div>
            <p className="cardTitleCargoData">Dimensões (CxLxA):</p>
            <p className="cardContetCargoDataNotColor">320 kg</p>
          </div>
        </div>

        <div className="contentBoxValueCargoData">
          <div>
            <p className="cardTitleCargoData">Volumes:</p>
            <p className="cardContetCargoDataNotColor">1</p>
          </div>

          <div>
            <p className="cardTitleCargoData">Valor da Mercadoria:</p>
            <p className="cardContetCargoDataNotColor">R$ 40.000,00</p>
          </div>

          <div>
            <p className="cardTitleCargoData">Carga Quimica:</p>
            <p className="cardContetCargoDataYesColor">Não</p>
          </div>
        </div>
      </div>

      <div className="contentTitleCargoData">
        <div>
          <p>TARIFAS RELACIONADAS AO EMBARQUE - Obrigatórias</p>
        </div>
      </div>

      <div>
        <table>
          <tr>
            <th className="thTable th1">Origem</th>
            <th className="thTable th2">Destino</th>
            <th className="thTable th3">Mercadoria</th>
            <th className="thTable th4">Frete (R$/viag)</th>
            <th className="thTable th5">Pedágio (R$/viag)</th>
            <th className="thTable th6">Ad Valorem (%NF)</th>
            <th className="thTable th7">Gris (%NF)</th>
            <th className="thTable th8">Tipo</th>
            <th className="thTable th9">Transit time</th>
          </tr>
          <tr>
            <td className="tdTable">Santos SP</td>
            <td className="tdTable">Aracaju SE</td>
            <td className="tdTable">Maquina automatica</td>
            <td className="tdTable">R$5.715,00</td>
            <td className="tdTable">R$0,00</td>
            <td className="tdTable">0,15%</td>
            <td className="tdTable">0,10%</td>
            <td className="tdTable">LTL</td>
            <td className="tdTable">14 dias Uteis</td>
          </tr>
        </table>
      </div>
      <div className="priceTotalContainer">
        <div className="boxPrice">
          <p>Valor total:</p>
          <p>R$ 200,00</p>
        </div>
      </div>

      <div className="contentTitleContract">
        <div>
          <p>CONDIÇÕES COMERCIAIS</p>
        </div>
      </div>

      <div className="containerContract">
        <p className="textGrid">
          Pedágio não incluso no Frete. ICMS não incluso, cobrado conforme
          legislação Ad Valorem/Gris: não incluso, calcular sob valor da nf.
        </p>

        <p className="textBold">
          Ajudante (caso necessário): R$ 250,00 por pessoa; por pessoa
        </p>

        <p className="textContent">
          Estadia: até 5 horas de espera para carga ou descarga, após isso será
          cobrado R$ 60,00 por ton/hora. Caso o carregamento não seja cumprido
          por falta de não liberação da mercadoria ou por informação equivocada,
          haverá reprogramação de coleta e implicará em custo adicional de
          deslocamento do veículo; Caso o contratante tenha algum tipo de
          restrição nos locais de carga/descarga ou até mesmo documental esta
          informação deverá ser repassada antes do fechamento da proposta; Caso
          as dimensões da(as) mercadoria(as) não estejam corretas o contratante
          deverá nos comunicar para reavaliar o preço ofertado; Caso o
          transporte da mercadoria tiver troca de nota fiscal os valores
          ofertados sofrerão alterações; Taxa de urgência (coletas/entregas no
          mesmo dia, fins de semana e feriados): taxado em 0% sobre frete.
          Nossas apólice de seguros não têm cobertura em caso de avarias,
          somente roubo, quando as mercadorias forem equipamentos usados.
          Valores de mercadorias acima de R$ 100.000,00 deverão ser analisadas
          pelo nosso Departamento de Risco/Seguros antes da elaboração da
          proposta. Despesas ou multas decorrentes de demora na nacionalização
          das mercadorias, ou seja, não inerentes aos serviços prestados, não
          serão pagas pelas nossas empresas. Coleta: 48 horas após a aprovação
          ou conforme disponibilidade
        </p>

        <p className="textBold">Validade da proposta:</p>

        <p className="textContent">
          As condições desta proposta, poderão sofrer alterações a curto prazo,
          dependendo do esclarecimento e entendimento definitivo, dos órgãos
          oficiais e do mercado, sobre as diversas variáveis da aplicação da
          Resolução ANTT 5.867/20.
        </p>

        <div className="boxText">
          <p className="textBold">Cubagem:</p>
          <p className="textContent">
            (quando aplicável) : 300 kg/m3 coleta ou entrega
          </p>
        </div>

        <div className="boxText">
          <p className="textBold">Horário de Expediente:</p>
          <p className="textContent">
            favor solicitar os serviços com antecedência mínima de 48 horas:
            horário de funcionamento de 2ª a 6ª das 08:00 ás 18:00 h.f
          </p>
        </div>

        <div className="boxText">
          <p className="textBold">Carga e descarga:</p>
          <p className="textContent">
            Não estão inclusas nos preços apresentados e deverão ser negociadas
            a parte, caso sejam necessárias.
          </p>
        </div>

        <p className="textBold">Agendamento (exclusivo):</p>
        <p className="textContent">
          Horários específicos de coletas e entregas devem ser previamente
          informados pelo cliente para avaliação e programação junto a operação.
        </p>

        <div className="boxText">
          <p className="textBold">Devolução:</p>
          <p className="textContent">100% do frete original.</p>
        </div>

        <div className="boxText">
          <p className="textBold">Armazenamento em depósito:</p>
          <p className="textContent">
            Não aplicável. Caso esse serviço seja necessário, os valores devem
            ser consultados previamente ao embarque.
          </p>
        </div>

        <p className="textBold">Gerenciamento de Risco:</p>

        <p className="textContent">
          A Maxitrans deve ser previamente avisada para que possa avaliar as
          condições de gerenciamento de risco do embarque e passar ao cliente
          possíveis custos adicionais com seguro e procedimentos de reforço e
          apoio, pois é obrigatório segundo o Plano de Gerenciamento de Risco da
          Maxitrans. O cliente precisa passar essa informação a Maxitrans com no
          mínimo 48h de antecedência ao embarque. O valor de escolta não está
          incluso nos valores apresentados e será negociado junto ao cliente. Em
          alguns casos, pode haver a cobrança de prêmio adicional de seguro.
        </p>

        <p className="textBold">Mercadorias Específicas:</p>

        <p className="textContent">
          Caso o cliente necessitar transporte das mercadorias listadas abaixo,
          a Maxitrans deve ser previamente informada para verificar as condições
          de de Gerenciamento de Risco e equipamentos adequados e, se
          necessário, passar ao cliente eventuais custos extras ao embarque.
          Esses custos não estão inclusos na proposta e serão negociados com o
          cliente. Produtos Controlados pelo Exército, Defensivos agrícolas,
          equipamentos de tecnologia (hightech), cosméticos e fármacos ou outras
          mercadorias consideradas visadas.
        </p>

        <p className="textBold">Seguro próprio do cliente:</p>

        <p className="textContent">
          É necessária a apresentação prévia ao embarque da carta DDR e PGR do
          cliente para que elas sejam avaliadas e homologadas pela seguradora
          Maxitrans. Ressaltando que a DDR não desobriga a contratação do seguro
          obrigatório de RCTR-C e RCTR-VI. Caberá exclusivamente ao Cliente a
          responsabilidade pela abrangência, coberturas e extensão dos seguros e
          limites de indenização, dentre outras condições constantes das
          apólices que venham a ser contratadas, assumindo, por sua exclusiva
          conta, eventuais perdas decorrentes de insuficiência de coberturas ou
          verbas seguradas. O seguro previsto neste item cobrirá também as
          empresas subcontratadas pela Maxitrans para prestação dos ora serviços
          contratados, equiparando-os como prepostos do seguro, não cabendo,
          portanto, ação regressiva contra os mesmos ou contra a Maxitrans.
        </p>

        <p className="textBold">Carga Química:</p>

        <p className="textContent">
          A carga química classificada como perigosa deverá estar acompanhada
          obrigatoriamente da devida documentação conforme legislação e órgãos
          anuentes (Ficha de Emergência, FISPQ, Envelope, Certificado de
          Embalagens Homologadas, etc.). O cliente deve enviar a FISPQ 24 horas
          antes do embarque para análise de nosso Departamento de Qualidade.
        </p>

        <p className="textBold">Extraordinários:</p>

        <p className="textContent">
          Em caso de cancelamento do embarque, necessário fazê-lo com mínimo de
          um turno de antecedência. Cancelamentos no ato, após a chegada do
          caminhão no local da coleta, poderá incorrer em multa de 100% do valor
          original do frete. Em casos de Notas fiscais SEM indicador do pagador
          do frete (CIF – frete PAGO) ou (FOB – frete a PAGAR), não poderemos
          proceder com o embarque.
        </p>

        <p>
          <span className="textBold">
            Taxa de alteração/anulação/reversão do CTE pós-emissão:
          </span>{" "}
          <span className="textContent">
            R$ 25,00 (em caso de erro do cliente). Para anulação de conhecimento
            após a emissão, faz-se necessária emissão de nota de anulação por
            parte do pagador do frete.
          </span>
        </p>

        <p>
          <span className="textBold">
            Taxa de emissão e reenvio de 2ª via cobrança de fatura:
          </span>{" "}
          <span className="textContent">
            R$ 10,00 por emissão em caso de comprovada a entrega da 1ª via. Será
            cobrado R$ 10,00 extra para cada via adicional solicitada.
          </span>
        </p>

        <div className="boxText2">
          <p className="textBold1">Reajustes:</p>
          <p className="textContent">
            {`
                            Vigência do frete valida por 30 dias
                            Índice padrão: INCT-L e INCT-F
                            Gatilho de combustível: 5% acumulado (+/-)
                            Participação do combustível: 30% em raios de até 150km e 40% em raios acima de 150km
                            Quando o acumulado dos reajustes no valor do diesel da região de carregamento pela média informada no site da ANP for maior ou igual a 5%, haverá aplicação
                            automática de reajuste.
                            Este acumulado vale tanto para percentual positivo (aumento) quanto para negativo (desconto).
                            Exemplo: Valor do Frete: R$100,00 > Aumento Acumulado: 5,4% - Representação 5,4% x 30% = 1,62% > Nova Tarifa: R$100,00 + 1,62% = R$101,62
                            `}
          </p>
        </div>

        <div className="boxText2">
          <p className="textBold2">SLA:</p>
          <p className="textContent">
            {`
                            Programação: Analisar conforme KMs
                            Coletas: 95%
                            Entregas: 95% (On time delivery)
                            `}
          </p>
        </div>

        <div className="boxText2">
          <p className="textBold3">Faturamento:</p>
          <p className="textContent">
            {`
                            Comprovante de entrega (POD): Digital
                            Emissão da Fatura: Contra data do CTE
                            Prazo de Pagamento: 15 dias corridos                            
                            `}
          </p>
        </div>

        <div className="boxText2">
          <p className="textBold4">Impostos:</p>
          <p className="textContent">
            {`
                            Repasse conforme alteração prevista por lei                       
                            `}
          </p>
        </div>

        <p className="textContent2">
          Agradecemos o interesse e nos colocamos à sua disposição para qualquer
          esclarecimento. Cordialmente,
        </p>

        <p className="textBold5">Maxitrans Logistica Internacional</p>
      </div>
    </div>
  );
};

export default Pdf;
