/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from "react";
import Input from "./Input";
import InputSelectFilterManager from "./InputSelectFilterManager";
import InputsFreightQuote from "./InputsFreightQuote";
import Textarea from "./Textarea";
import UploadImg from "./UploadImg";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import { useEffect } from "react";
import api from "../services/api";
import axios from "axios";
import InputsFreightQuoteInternational from "./InputsFreightQuoteInternational";
import { toast } from "react-toastify";
import { ProposalContext } from "../context/Dashboard/ProposalContext";
import jwt_decode from "jwt-decode";
import InputPrimeReact from "./InputPrimeReact";

const CardInfoQuotationInternationalFTL = ({
  inconterm,
  type,
  index,
  imo,
  servicesAdd,
}) => {
  const {
    getOrigin,
    getDestination,
    getProfileContext,
    dataCheckFractional,
    setDataCheckFractional,
  } = useContext(DashboardContext);

  const { btnStateInternationFTL } = useContext(ProposalContext);

  /* DATA LOCALE */
  const [dataLocation, setDataLocation] = useState({});

  /* INPUTS VALUE */
  const [originFraction, setOriginFraction] = useState("");

  const [selectDestination, setSelectDestination] = useState([]);
  const [destination, setDestination] = useState("");

  const [inputActive, setInputActive] = useState(false);
  const [commodityPrice, setCommodityPrice] = useState(0);
  const [merchandiseFraction, setMerchandiseFraction] = useState("");

  const [imagesBase64, setImagesBase64] = useState([]);
  const [imagesFirebase, setImagesFirebase] = useState([]);
  const [dataSummaryResume, setDataSummaryResume] = useState();

  /* BLUE INPUTS */
  const [originBlur, setOriginBlur] = useState("");
  const [destinationBlur, setDestinationBlur] = useState("");
  const [incontermBlur, setIncontermBlur] = useState("");
  const [imoBlur, setImoBlur] = useState("");

  const [typeVehiclesBlur, setTypeVehiclesBlur] = useState("");
  const [cityStateBlur, setCityStateBlur] = useState("");
  const [cityBlur, setCityBlur] = useState("");

  const [palletizedLoadBlur, setPalletizedLoadBlur] = useState("");
  const [kindOfPackingBlur, setKindOfPackingBlur] = useState("");
  const [stackedLoadBlur, setStackedLoadBlur] = useState("");

  const [coinTypeBlur, setCoinTypeBlur] = useState("");
  const [merchandiseBlur, setMerchandiseBlur] = useState("");
  const [commodityPriceBlur, setCommodityPriceBlur] = useState("");

  /* QUOTATION SUMMARY */
  const [freightWeightValue, setFreightWeightValue] = useState(0);
  const [dispatchValue, setdispatchValue] = useState(0);
  const [sefazTaxValue, setSefazTaxValue] = useState(0);

  const [advaloremValue, setAdvaloremValue] = useState(0);
  const [tollValue, setTollValue] = useState(0);

  const [cubageTotal, setCubageTotal] = useState(0);
  const [listTypeVehicles, setListTypeVehicles] = useState([]);
  const [typeVehiclesSelect, setTypeVehiclesSelect] = useState("");
  const [originAdress, setOriginAdress] = useState("");
  const [destinationAdress, setDestinationAdress] = useState("");

  const [updateValue, setUpdateValue] = useState(false);
  const [cityList, setCityList] = useState([]);

  const [statesList, setStatesList] = useState([]);
  const [originInternarional, setOriginInternarional] = useState("");
  const [destinationInternational, setDestinationInternational] = useState("");

  const [incontermInternational, setIncontermInternational] = useState("");
  const [imoInternational, setImoInternational] = useState("");
  const [originFractionInternational, setOriginFractionInternational] =
    useState("");

  const [stateSelectUser, setStateSelectUser] = useState("");
  const [emissionClearance, setEmissionClearance] = useState("");
  const [issuanceCertifOrigin, setIssuanceCertifOrigin] = useState("");

  const [additionalIssuanceCertifOrigin, setAdditionalIssuanceCertifOrigin] =
    useState("");
  const [helperFee, setHelperFee] = useState("");
  const [priceTotalStanded, setPriceTotalStanded] = useState("");
  const [priceTotalPremium, setPriceTotalPremium] = useState("");

  const [addressInternarional, setAddressInternarional] = useState("");
  const [addressInternarionalState, setAddressInternarionalState] =
    useState("");
  const [addressInternarionalOptional, setAddressInternarionalOptional] =
    useState("");

  const [
    addressInternarionalOptionalActive,
    setAddressInternarionalOptionalActive,
  ] = useState(false);

  const [kindOfPacking, setKindOfPacking] = useState("");
  const [palletizedLoad, setPalletizedLoad] = useState("");
  const [stackedLoad, setStackedLoad] = useState("");

  const [coinType, setCoinType] = useState("");
  const [originOption, setOriginOption] = useState([]);

  const [emissionClearancePrice, setEmissionClearancePrice] = useState("");
  const [issuanceCertifOriginPrice, setIssuanceCertifOriginPrice] =
    useState("");
  const [
    additionalIssuanceCertifOriginPrice,
    setAdditionalIssuanceCertifOriginPrice,
  ] = useState("");

  const [helperFeePrice, setHelperFeePrice] = useState("");
  const [hasRenderedOnce, setHasRenderedOnce] = useState(false);
  const [formOfPayment, setFormOfPayment] = useState("");
  const [allStatesAndCitysToBrazil, setAllStatesAndCitysToBrazil] = useState(
    []
  );

  const [disableStateSelection, setDisableStateSelection] = useState(false);
  const [originDestinationExemplo, setOriginDestinationExemplo] = useState("");
  const [originOrDestine, setOriginOrDestine] = useState("");

  const [multiLineCubing, setMultiLineCubing] = useState("");
  const [isMultiLineCubing, setIsMultiLineCubing] = useState(false);
  const [dimensions, setDimensions] = useState([]);

  const getSelect = async (e) => {
    if (e.target.value != "default") {
      // USAR COM PRIME REACT
      const result = await getOrigin(e.value.nome, index);

      setOriginDestinationExemplo(e.value);

      // const result = await getOrigin(e.target.value, index);

      // setOriginDestination(e.target.value);

      // setOriginDestinationExemplo(e.target.value);

      setSelectDestination({
        option: result.national,
      });
    } else {
      setSelectDestination({});
    }
  };

  const getStatesInBrazil = async () => {
    try {
      const getStates = await axios.get(
        "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
      );

      setStatesList(getStates.data);
    } catch (error) {}
  };

  const getCityInBrazil = async (e, stateFormact) => {
    try {
      const state = e?.target?.value ?? stateFormact;
      console.log(stateFormact, 100);
      setStateSelectUser(state);
      // setOriginDestination("");
      const getCitys = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state.sigla}/municipios?orderBy=nome`
      );

      console.log(getCitys);
      setCityList(getCitys.data);
    } catch (error) {}
  };

  const getQuotationDetails = async () => {
    try {
      let dataState = dataCheckFractional;
      dataState[index] = {
        id_international: typeVehiclesBlur,
        origin: originInternarional,
        dest: destinationInternational,
        originOrDestine,
        inconterm: incontermBlur,
        imo_load: imoBlur,
        city_state: stateSelectUser,
        city: originDestinationExemplo,
        merchandise: merchandiseBlur,
        pesoClient: dataSummaryResume?.grossWeight,
        palletized_cargo: palletizedLoadBlur,
        kind_packing: kindOfPackingBlur,
        piled_load: stackedLoadBlur,
        coin_type: coinTypeBlur,
        price_merchandise: commodityPrice,
        cubage: dataSummaryResume?.m3,
        cubed_weight: dataSummaryResume?.grossWeight,
        gross_weight: dataSummaryResume?.cubageWeight,
        quantity: dataSummaryResume?.quantity,
        formOfPayment,
        dimensions,
      };

      if (isMultiLineCubing) {
        dataState[index].peso = multiLineCubing?.weightCubedSum;

        dataState[index].cubage = `${Number(multiLineCubing?.cubageSum).toFixed(
          2
        )} m³`;

        dataState[index].cubageNumber = multiLineCubing?.cubageSum;
        dataState[index].quantity = multiLineCubing?.quantitySum;
        dataState[index].typePacking =
          multiLineCubing?.hasWood == true
            ? "Caixas de madeira"
            : kindOfPacking;
        console.log(dataState, 2000);
      }

      console.log(originOrDestine);

      console.log(dataSummaryResume);
      const response = await api.post(
        `/quotations/international/ftl/all`,
        dataState[index]
      );

      if (response?.data?.message) {
        toast.info(`${response?.data?.message}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        const {
          freight,
          adValoremGris,
          total,
          peso,
          coinToday,
          formOfPayment,
        } = response.data;
        dataState[index].freight = freight;
        dataState[index].adValoremGris = adValoremGris;
        dataState[index].totalPriceMoeda = total;
        dataState[index].peso = peso;
        dataState[index].coinToday = coinToday;
        dataState[index].formOfPayment = formOfPayment;

        setdispatchValue(freight);
        setSefazTaxValue(adValoremGris);
        setFreightWeightValue(total);
        setDataCheckFractional(dataState);
        setCubageTotal();
      }
      console.log(response.data);
    } catch (error) {}
  };

  const getTypeVehicles = async () => {
    try {
      const response = await api.post(
        "/quotations/international/type/vehicles/ftl",
        {
          origin: originInternarional,
          destiny: destinationInternational,
        }
      );

      console.log({
        origin: originInternarional,
        destiny: destinationInternational,
      });

      console.log(response.data);
      setListTypeVehicles(response.data);
    } catch (error) {}
  };

  const getOrigins = async (e) => {
    try {
      const token = localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:");
      const decoded = jwt_decode(token);
      const { id } = decoded;
      const client = await getProfileContext(id);

      const response = await api.get(
        `/quotations/international/origin/ftl/${client.email}`
      );

      setOriginOption(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Função para buscar a lista de municípios do IBGE
  const fetchStates = async () => {
    try {
      const response = await axios.get(
        "https://servicodados.ibge.gov.br/api/v1/localidades/municipios"
      );
      const municipios = response.data;

      setAllStatesAndCitysToBrazil(municipios);
    } catch (error) {
      console.error("Erro ao buscar estados:", error);
    }
  };

  // Função para remover pontuação
  const removePunctuation = (text) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s]/gi, "")
      .toLowerCase();
  };

  // FUNÇÃO DEIXA PRE SETADO O ESTADO NO CAMPO 'SELECIONAR ESTADO'
  const onSelectStateOrigin = () => {
    const nameCleanOrigin = removePunctuation(originInternarional).trim();
    const nameCleanDestine = removePunctuation(destinationInternational).trim();

    const filterStateSelectOrigin = allStatesAndCitysToBrazil.find(
      (states) => removePunctuation(states.nome) === nameCleanOrigin
    );
    const filterStateSelectDestine = allStatesAndCitysToBrazil.find(
      (states) => removePunctuation(states.nome) === nameCleanDestine
    );

    const formactStateOrigin =
      filterStateSelectOrigin?.microrregiao?.mesorregiao?.UF;
    const formactStateDestine =
      filterStateSelectDestine?.microrregiao?.mesorregiao?.UF;

    console.log(formactStateOrigin, formactStateDestine);

    if (formactStateDestine) {
      setStateSelectUser(formactStateDestine);
      setDisableStateSelection(true);
      getCityInBrazil("", formactStateDestine);
      setOriginOrDestine("destine");
      return true;
    } else if (formactStateOrigin) {
      setStateSelectUser(formactStateOrigin);
      setDisableStateSelection(true);
      getCityInBrazil("", formactStateOrigin);
      setOriginOrDestine("origin");
      return true;
    } else setDisableStateSelection(false);
  };

  useEffect(() => {
    if (originInternarional || destinationInternational) onSelectStateOrigin();
    setTypeVehiclesSelect("");
    setListTypeVehicles([]);
  }, [originInternarional, destinationInternational]);

  useEffect(() => {
    console.log(originFractionInternational);
    if (originFractionInternational == "Coletar | Pick-up") {
      setAddressInternarionalOptionalActive(true);
    } else {
      setAddressInternarionalOptionalActive(false);
    }
  }, [originFractionInternational]);

  useEffect(() => {
    setCommodityPrice("");
  }, [coinType]);

  useEffect(() => {
    console.log(originInternarional);

    if (originOption.length > 0) {
      //   originOption.map((item) => {
      //     if (item.origin.includes(originInternarional)) {
      //       setAddressInternarionalState(item.finalDestination);
      //       setAddressInternarional(item.address);
      //       setInputActive(true);
      //     }
      //   });
    } else {
      setInputActive(false);
      setAddressInternarional("");
      setAddressInternarionalState("");
    }

    if (
      originInternarional != "default" &&
      originInternarional != "" &&
      destinationInternational != "default" &&
      destinationInternational != "" &&
      incontermInternational != "default" &&
      incontermInternational != "" &&
      imoInternational != "default" &&
      imoInternational != "" &&
      originFractionInternational != "default" &&
      originFractionInternational != "" &&
      stateSelectUser != "default" &&
      stateSelectUser != "" &&
      originFraction
    ) {
      setInputActive(true);
    }
  }, [
    originInternarional,
    destinationInternational,
    incontermInternational,
    imoInternational,
    originFractionInternational,
    stateSelectUser,
    originFraction,
  ]);

  useEffect(() => {
    getStatesInBrazil();
    getOrigins();
    fetchStates();
  }, []);

  useEffect(() => {
    setCubageTotal(dataSummaryResume?.m3);
  }, [dataSummaryResume]);

  useEffect(() => {
    if (hasRenderedOnce) {
      getQuotationDetails();
    }
    setHasRenderedOnce(true);
  }, [btnStateInternationFTL]);

  useEffect(() => {
    if (originInternarional && destinationInternational) {
      getTypeVehicles();
    }
  }, [originInternarional, destinationInternational]);

  useEffect(() => {
    if (typeVehiclesSelect.length > 0) {
      const filterList = listTypeVehicles.find(
        (elem) => elem.id === typeVehiclesSelect
      );

      console.log(filterList);

      setOriginAdress(filterList.origin_address);
      setDestinationAdress(filterList.destination_address);
    }
  }, [typeVehiclesSelect]);

  const boxInputs = {
    display: "flex",
    gap: "20px",
  };

  const containerContent = {
    backgroundColor: "#fff",
    width: "100%",
    padding: "32px",
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginTop: "30px",
  };

  return (
    <>
      <h3 className="textTitleTypeDispatch">Informações da cotação</h3>
      <div style={{ ...boxInputs, marginTop: "20px" }}>
        <InputSelectFilterManager
          onBlur={(e) => setOriginBlur(e.target.value)}
          onChange={(e) => setOriginInternarional(e.target.value)}
          index={index}
          color="var(--grey-1)"
          name="Rota comercial de origem"
          width={inconterm ? "100%" : "290px"}
          top="50px"
          custom="false"
          type="originInternationalftl"
          value={originOption}
        />
        <InputSelectFilterManager
          onBlur={(e) => setDestinationBlur(e.target.value)}
          onChange={(e) => setDestinationInternational(e.target.value)}
          value={selectDestination}
          index={index}
          originProps={originInternarional}
          color="var(--grey-1)"
          name="Rota comercial de destino"
          width={inconterm ? "100%" : "290px"}
          top="50px"
          custom="false"
          type="destinationInternationalftl"
          options={originOption}
        />

        {inconterm && (
          <>
            <InputSelectFilterManager
              onBlur={(e) => setIncontermBlur(e.target.value)}
              onChange={(e) => setIncontermInternational(e.target.value)}
              color="var(--grey-1)"
              name="Inconterm"
              width={inconterm ? "100%" : "290px"}
              top="50px"
              custom="false"
              type="inconterm"
            />
          </>
        )}
        {imo && (
          <>
            <InputSelectFilterManager
              onBlur={(e) => setImoBlur(e.target.value)}
              onChange={(e) => setImoInternational(e.target.value)}
              color="var(--grey-1)"
              name="Carga imo"
              width={imo ? "100%" : "290px"}
              top="50px"
              custom="false"
              type="imo"
            />
          </>
        )}
      </div>

      <div style={{ ...boxInputs }}>
        {
          <InputSelectFilterManager
            onBlur={(e) => setTypeVehiclesBlur(e.target.value)}
            onChange={(e) => setTypeVehiclesSelect(e.target.value)}
            color="var(--grey-1)"
            name="Tipo de veiculos"
            width={"100%"}
            top="50px"
            custom="false"
            type="listTypeVehicles"
            value={listTypeVehicles}
          />
        }
        {
          // <InputSelectFilterManager
          //   onBlur={(e) => setCityStateBlur(e.target.value)}
          //   statesLists={statesList}
          //   stateSelectUser={stateSelectUser}
          //   selectState={originFractionInternational}
          //   onChange={getCityInBrazil}
          //   index={index}
          //   color="var(--grey-1)"
          //   name="Selecionar estado"
          //   width={inconterm ? "100%" : "290px"}
          //   top="50px"
          //   custom="false"
          //   type={"state"}
          // />

          <InputPrimeReact
            label="Selecionar estado"
            options={statesList}
            onChange={getCityInBrazil}
            value={stateSelectUser}
            optionLabel="nome"
            optionValue="sigla"
            type={"dropdownInput"}
            width={"300px"}
            disabled={disableStateSelection}
            idStyle={"selectTable"}
          />
        }

        {
          // <InputSelectFilterManager
          //   onBlur={(e) => setCityBlur(e.target.value)}
          //   onChange={getSelect}
          //   cityList={cityList}
          //   index={index}
          //   color="var(--grey-1)"
          //   name="Selecionar cidade"
          //   width={inconterm ? "100%" : "290px"}
          //   top="50px"
          //   custom="false"
          //   type={"citys"}
          // />

          <InputPrimeReact
            label="Selecionar cidade"
            options={cityList}
            onChange={getSelect}
            value={originDestinationExemplo}
            optionLabel="nome"
            type={"dropdownInput"}
            width={"300px"}
            idStyle={"selectTable"}
          />
        }
        {/* <Input
          isActive={false}
          value={addressInternarionalState}
          name="Destino final"
          width="100%"
          placeholder=""
          type={"text"}
        /> */}
      </div>

      {/* NÃO APAGAR VVV - SOLITADO PELO JOSEVALDO PARA DEIXAR ESSE CAMPO OCULTADO  */}
      {/* <div style={{ ...boxInputs }}>
        <Input
          isActive={false}
          value={originAdress}
          name="Endereço de origem"
          width="100%"
          placeholder=""
          type={"text"}
        />
        <Input
          isActive={false}
          value={destinationAdress}
          name="Endereço de destino"
          width="100%"
          placeholder=""
          type={"text"}
        />
      </div> */}

      {/* <div style={{ ...boxInputs }}>
        <Input
          isActive={addressInternarionalOptionalActive}
          value={
            addressInternarionalOptionalActive
              ? addressInternarionalOptional
              : addressInternarional
          }
          name={
            addressInternarionalOptionalActive
              ? "Endereço opcional"
              : "Endereço"
          }
          width="100%"
          placeholder=""
          type={"text"}
        />
      </div> */}

      <div style={boxInputs}>
        <Input
          isActive={true}
          onBlur={(e) => {
            setMerchandiseBlur(e.target.value);
            setUpdateValue(!updateValue);
          }}
          onChange={(e) => {
            setMerchandiseFraction(e.target.value);
          }}
          name="Tipo de mercadoria"
          width="100%"
          placeholder=""
          type={"text"}
        />

        <InputSelectFilterManager
          onBlur={(e) => setPalletizedLoadBlur(e.target.value)}
          isActive={true}
          onChange={(e) => setPalletizedLoad(e.target.value)}
          color="var(--grey-1)"
          name="Carga paletizada?"
          width="100%"
          top="50px"
          custom="false"
          type="boolean"
        />

        <InputSelectFilterManager
          onBlur={(e) => setKindOfPackingBlur(e.target.value)}
          isActive={palletizedLoad != ""}
          onChange={(e) => setKindOfPacking(e.target.value)}
          color="var(--grey-1)"
          name="Tipo de embalagem"
          width="100%"
          top="50px"
          custom="false"
          type="kindOfPacking"
        />
      </div>

      <div style={boxInputs}>
        <InputSelectFilterManager
          onBlur={(e) => setStackedLoadBlur(e.target.value)}
          isActive={true}
          onChange={(e) => setStackedLoad(e.target.value)}
          color="var(--grey-1)"
          name="A carga empilhada?"
          width="100%"
          top="50px"
          custom="false"
          type="boolean"
        />

        <InputSelectFilterManager
          // isActive={inputActive}
          onBlur={(e) => setCoinTypeBlur(e.target.value)}
          onChange={(e) => {
            setCommodityPriceBlur(0);
            setCoinType(e.target.value);
          }}
          color="var(--grey-1)"
          name="Moeda"
          width="100%"
          top="50px"
          custom="false"
          type="typeMoeda"
        />

        {/* <Input
          //isActive={inputActive}
          onBlur={(e) => {
            setCommodityPriceBlur(e.target.value);
            convertToBRL(commodityPrice, setCommodityPrice);
          }}
          onClick={() => convertToStr(commodityPrice, setCommodityPrice)}
          value={commodityPrice}
          onChange={(e) => {
            setCommodityPrice(e.target.value);
          }}
          name="Valor da mercadoria"
          width="100%"
          placeholder="0,00"
          type={"text"}
          marginBottom="10px"
        /> */}

        <InputPrimeReact
          label={"Valor da mercadoria"}
          value={commodityPrice}
          onChange={(e) => setCommodityPrice(e.value)}
          type={"currency"}
          width={"300px"}
          className={"currencyTable"}
          currencyType={coinType}
        />
      </div>

      <div style={boxInputs}>
        {
          <InputsFreightQuoteInternational
            isActive={true}
            updateCubage={() => setUpdateValue(!updateValue)}
            sumResume={dataSummaryResume}
            setSumResume={setDataSummaryResume}
            index={index}
            setMultiLineCubing={setMultiLineCubing}
            setIsMultiLineCubing={setIsMultiLineCubing}
            isMultiLineCubing={isMultiLineCubing}
            setDimensions={setDimensions}
          />
        }
      </div>

      <div style={{ marginBottom: "20px" }}>
        <h3 className="textTitleTypeDispatch">Pagamento</h3>

        <div style={{ width: "40%" }}>
          <InputPrimeReact
            label={"Escolha a forma de pagamento"}
            value={formOfPayment}
            onChange={(e) => setFormOfPayment(e.value)}
            type={"dropdown"}
            options={[
              "Boleto bancário - BRL",
              "Crédito em conta - BRL/ARS",
              "Pix à vista com desconto 1% - BRL",
              "Parcelamento 2 vezes - BRL",
              "Swift Bancário - Remessa ao exterior - USD",
              "ALIAS à vista com desconto de 1% - ARS",
            ]}
            placeholder={"Selecione"}
            width={"100%"}
            className={"currencyTable"}
            currencyType={coinType}
          />
        </div>
      </div>

      <div>
        <Textarea name="Observações adicionais" height="83px" width="100%" />
      </div>

      <UploadImg
        imagesBase64={imagesBase64}
        setImagesBase64={setImagesBase64}
        imagesFirebase={imagesFirebase}
        setImagesFirebase={setImagesFirebase}
      />

      <div style={containerContent}>
        <div>
          <h3 className="textTitleTypeDispatch">Resumo total cotação</h3>
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ display: "flex", width: "146px" }}>
            <div>
              <p className="textTitleSummary">Frete</p>
              <div>
                {dispatchValue ? (
                  dispatchValue
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "18px", width: "195px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Ad Valorem | Gris</p>
              <div>
                {sefazTaxValue ? (
                  sefazTaxValue
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "50px", width: "200px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Total</p>
              <div>
                {freightWeightValue ? (
                  freightWeightValue
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "50px", width: "209px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">Cubagem</p>
              <div>
                {cubageTotal ? (
                  cubageTotal
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ display: "flex", gap: "50px", width: "342px" }}>
            <div>
              <p className="textTitleSummary">
                Valor total (Standard) | Transit time - 102 Dias
              </p>
              <div>
                {priceTotalStanded ? (
                  priceTotalStanded
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "14px", width: "387px" }}>
            <div>
              <svg
                width="1"
                height="50"
                viewBox="0 0 1 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="49.1932"
                  x2="0.5"
                  y2="0.187511"
                  stroke="#D7D7D7"
                />
              </svg>
            </div>
            <div>
              <p className="textTitleSummary">
                Valor total (Premium) | Transit time - 5 Dias
              </p>
              <div>
                {priceTotalPremium ? (
                  priceTotalPremium
                ) : (
                  <svg
                    width="8"
                    height="3"
                    viewBox="0 0 8 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.964 0.90125V2.62925H0.566V0.90125H7.964Z"
                      fill="#060606"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default CardInfoQuotationInternationalFTL;
