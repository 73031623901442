import { useState } from "react"
import HeaderDashboard from "../../components/HeaderDashboard"
import Sidebar from "../../components/Sidebar"
import ThowColumLayout from "../../layouts/ThowColumLayout"
import ProfileCard from "../../components/ProfileCard"

const Profile = () => {
    const [image, setImage] = useState()
    const [imagePreview, setImagePreview] = useState()
    const [imageFirebase, setImageFirebase] = useState()

    const container = {
        maxWidth: "100%",
        paddingRight: "4.125rem",
        paddingTop: "2.6875rem",
        paddingLeft: "3.375rem",
        backgroundColor: "#F9F9F9",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        marginBottom: "20px"
    }

    return (
        <ThowColumLayout
            page={"Dashboard"}
            colum1={
                <Sidebar page="Profile" />
            }

            colum2={
                <>
                    <HeaderDashboard name="Minha Conta" />

                    <div style={container}>
                        <ProfileCard />
                    </div>
                </>
            }


        >

        </ThowColumLayout>
    )
}

export default Profile