import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useWebSocket } from "../context/Dashboard/WebSocketContext";
import jwt_decode from "jwt-decode";

const useOnlineStatus = () => {
  const { socket } = useWebSocket();
  const location = useLocation();

  // Lista das rotas onde o usuário deve ser considerado online
  const onlineRoutes = [
    "/dashboard",
    "/dashboard/profile",
    "/dashboard/proposal",
  ];

  useEffect(() => {
    const isOnlineRoute = onlineRoutes.includes(location.pathname);
    const token = localStorage.getItem("@MAXITRANS_TOKEN_CLIENT:");

    let id = "Não encontrado";

    if (token) {
      const decoded = jwt_decode(token);
      id = decoded.id;
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && isOnlineRoute) {
        // Emite evento de usuário online quando a aba está visível e em rota válida
        socket.emit("userOnline", id); // Substitua 'userId' pelo ID real do usuário
      } else if (document.visibilityState === "hidden") {
        // Emite evento de usuário offline quando a aba é ocultada
        socket.emit("userOffline", id); // Substitua 'userId' pelo ID real do usuário
      }
    };

    const handleBeforeUnload = () => {
      // Emite evento de usuário offline quando a página está sendo fechada ou recarregada
      socket.emit("userOffline", id); // Substitua 'userId' pelo ID real do usuário
    };

    // Listener para mudanças de visibilidade da aba
    document.addEventListener("visibilitychange", handleVisibilityChange);
    // Listener para fechar a aba ou recarregar a página
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Verifica o estado da visibilidade ao carregar o componente
    if (document.visibilityState === "visible" && isOnlineRoute) {
      socket.emit("userOnline", id); // Substitua 'userId' pelo ID real do usuário
    }

    // Limpa os listeners quando o componente é desmontado
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);

      // Marca o usuário como offline ao desmontar o componente
      if (isOnlineRoute) {
        socket.emit("userOffline", id); // Substitua 'userId' pelo ID real do usuário
      }
    };
  }, [socket, location.pathname]);

  return null;
};

export default useOnlineStatus;
