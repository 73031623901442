

const NationalDistributorPdf = () => {


    return (
        <div className="container2">
            <div className="img2">
                <img src="https://firebasestorage.googleapis.com/v0/b/maxitrans-6cf65.appspot.com/o/profile%2Flogo.png?alt=media&token=626ee25a-c6da-4ec6-ba88-e3f38142bd3f" />
            </div>

            <div className="headerTitle2">
                <p className="proposal2">Proposta Comercial - NACIONAL</p>
            </div>

            <div className="contentTitle2">
                <div className="boxContentTitle2">
                    <p>DATA:</p>
                    <p>19/06/2023</p>
                </div>

                <div className="boxContentTitle2">
                    <p>VALIDADE:</p>
                    <p>19/07/2023</p>
                </div>

                <div className="boxContentTitle2">
                    <p>COTAÇÃO Nº:</p>
                    <p>NC2023_114</p>
                </div>
            </div>

            <div className="contentBox2">
                <h3 className="textTitleCotation2">Informações da cotação</h3>

                <div className="boxContainer2">
                    <p className="titleInput2">Insira os estados que tem interrese para a distribuição</p>
                    <div className="boxInput2">
                        <p className="valueInput2">Value</p>
                    </div>
                </div>

                <div className="boxContainer2">
                    <p className="titleInput2">Observações adicionais</p>
                    <div className="boxTextarea2">
                        <p className="valueInput2">lorem</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default NationalDistributorPdf