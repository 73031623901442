import { useState, useContext, useEffect } from "react";
import InputPrimeReact from "./InputPrimeReact";
import { DashboardContext } from "../context/Dashboard/DashboardContext";
import moment from "moment";

const CardInfoQuotationAir = ({ inconterm, type, index, check }) => {
  const { dataAir, setDataAir } = useContext(DashboardContext);

  // Porto de Origem
  const [originPort, setOriginPort] = useState("");

  // Coleta de container
  const [needContainerCollection, setNeedContainerCollection] = useState(false);

  // Local do carregamento do container
  const [containerLoadingLocation, setContainerLoadingLocation] = useState("");

  // Porto de Destino
  const [destinationPort, setDestinationPort] = useState("");

  // Precisarei da entrega do container
  const [containerDelivery, setContainerDelivery] = useState("");

  // Local do Descarregamento do Container
  const [containerUnloadingLocation, setContainerUnloadingLocation] =
    useState("");

  // Data de Embarque Desejada
  const [desiredShippingDate, setDesiredShippingDate] = useState(null);

  const [customsClearance, setCustomsClearance] = useState(null);

  // NCM da Carga
  const [cargoNCM, setCargoNCM] = useState("");

  // Opção de Carga
  const [loadOption, setLoadOption] = useState("");

  // Peso
  const [weight, setWeight] = useState(0);

  // Carga Não Empilhável
  const [nonStackableCargo, setNonStackableCargo] = useState(false);

  // Dimensões
  const [dimensions, setDimensions] = useState("");

  // Quantidade de Pallets
  const [palletQuantity, setPalletQuantity] = useState("");

  // Tipo de Volumes
  const [palletType, setPalletType] = useState("");

  // Pagamento das Taxas Locais de Origem
  const [localOriginFeesPayment, setLocalOriginFeesPayment] = useState("");

  // Pagamento do THC de Destino
  const [destinationTHCPayment, setDestinationTHCPayment] = useState("");

  // Pagamento do frete
  const [seaFreight, setSeaFreight] = useState("");

  // Função para capturar o preenchimento dos states
  const captureFormData = () => {
    return {
      "Tipo de cotação":
        check == "checkNationalAir" ? "Aéreo nacional" : "Aéreo internacional",
      "Porto de Origem": originPort,
      "Coleta de container": needContainerCollection,
      "Local do carregamento do container": containerLoadingLocation,
      "Porto de Destino": destinationPort,
      "Entrega do container": containerDelivery,
      "Local do Descarregamento do Container": containerUnloadingLocation,
      "Data de Embarque Desejada":
        moment(desiredShippingDate).format("DD/MM/YYYY"),
      "NCM da Carga": cargoNCM,
      "Opção de Carga": loadOption,
      Peso: weight,
      "Carga Não Empilhável": nonStackableCargo,
      Dimensões: dimensions,
      "Quantidade de Volumes": palletQuantity,
      "Tipo de Volumes": palletType,
      "Pagamento das Taxas Locais de Origem": localOriginFeesPayment,
      "Pagamento do THC de Destino": destinationTHCPayment,
    };
  };

  useEffect(() => {
    // Captura os dados do formulário
    const formData = captureFormData();

    // Aqui você pode fazer algo com o formData, como enviá-lo para uma API
    setDataAir(formData);

    // Função de limpeza, se necessário
    return () => {
      console.log("Cleaning up form data...");
    };
  }, [
    originPort,
    needContainerCollection,
    containerLoadingLocation,
    destinationPort,
    containerDelivery,
    containerUnloadingLocation,
    desiredShippingDate,
    cargoNCM,
    loadOption,

    weight,
    nonStackableCargo,
    dimensions,
    palletQuantity,
    palletType,
    localOriginFeesPayment,
    destinationTHCPayment,
  ]);

  const containerBoxStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  };

  return (
    <div
      style={{
        width: "940px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      {/* Porto de Origem */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={containerBoxStyle}>
          <InputPrimeReact
            label="Porto de Origem (Cidade, País)"
            type="text"
            placeholder="Informe a cidade e o país"
            width="280px"
            value={originPort}
            onChange={(e) => setOriginPort(e.target.value)}
            className={"inputMaritime"}
          />

          {/* Flag para Coleta do Container */}
          <label className="labelCheckMaritime">
            <input
              type="checkbox"
              checked={needContainerCollection}
              onChange={(e) => setNeedContainerCollection(e.target.checked)}
            />
            Precisarei da coleta do container
          </label>
          {needContainerCollection && (
            <InputPrimeReact
              label="Local - Carregamento do Container"
              type="text"
              placeholder="Informe o local de carregamento"
              width="280px"
              value={containerLoadingLocation}
              onChange={(e) => setContainerLoadingLocation(e.target.value)}
              className={"inputMaritime"}
            />
          )}
        </div>

        <div style={containerBoxStyle}>
          {/* Porto de Destino */}
          <InputPrimeReact
            label="Porto de Destino (Cidade, País)"
            type="text"
            placeholder="Informe a cidade e o país"
            width="280px"
            value={destinationPort}
            onChange={(e) => setDestinationPort(e.target.value)}
            className={"inputMaritime"}
          />

          {/* Flag para Entrega do Container */}
          <label className="labelCheckMaritime">
            <input
              type="checkbox"
              checked={containerDelivery}
              onChange={(e) => setContainerDelivery(e.target.checked)}
            />
            Precisarei da entrega do container
          </label>
          {containerDelivery && (
            <InputPrimeReact
              label="Local - Descarregamento do Container"
              type="text"
              placeholder="Informe o local de descarregamento"
              width="280px"
              value={containerUnloadingLocation}
              onChange={(e) => setContainerUnloadingLocation(e.target.value)}
              className={"inputMaritime"}
            />
          )}
        </div>

        <div style={containerBoxStyle}>
          {/* Data de Embarque Desejada */}
          <InputPrimeReact
            label="Data de Embarque Desejada"
            type="calendar"
            placeholder="DD/MM/AAAA"
            value={desiredShippingDate}
            onChange={(e) => setDesiredShippingDate(e.target.value)} // Adicione o onChange handler apropriado
            className={"calendarMaritime"}
          />

          {/* Flag para Despacho Aduaneiro */}
          <label className="labelCheckMaritime">
            <input
              type="checkbox"
              checked={customsClearance}
              onChange={(e) => setCustomsClearance(e.target.checked)}
            />
            Precisarei do despacho aduaneiro
          </label>
          {customsClearance && (
            <InputPrimeReact
              label="NCM da Carga"
              type="text"
              placeholder="Informe o NCM da carga"
              width="280px"
              value={cargoNCM}
              onChange={(e) => setCargoNCM(e.target.value)}
              className={"inputMaritime"}
            />
          )}
        </div>
      </div>

      {
        <>
          {/* Carga Não Empilhável */}
          <label className="labelCheckMaritime">
            <input
              type="checkbox"
              checked={nonStackableCargo}
              onChange={(e) => setNonStackableCargo(e.target.checked)}
            />
            Carga Não Empilhável
          </label>
          {nonStackableCargo && (
            <InputPrimeReact
              label="Dimensões"
              type="text"
              width="280px"
              placeholder="Informe as dimensões da carga"
              value={dimensions}
              onChange={(e) => setDimensions(e.target.value)} // Adicione o onChange handler apropriado
              className={"inputMaritime"}
            />
          )}

          {/* Quantidade de Volumes */}

          <InputPrimeReact
            label="Peso Bruto"
            type="number"
            placeholder="KG"
            onChange={(e) => setWeight(e.value)} // Adicione o onChange handler apropriado
            className={"inputNumberMaritime"}
            suffix=" KG"
            mode="decimal"
            // minFractionDigits={2}
          />

          <InputPrimeReact
            label="Quantidade de Volumes"
            type="number"
            placeholder="Informe a quantidade"
            onChange={(e) => setPalletQuantity(e.value)} // Adicione o onChange handler apropriado
          />

          {/* Tipo de Volumes */}

          <InputPrimeReact
            label="Tipo de Volumes"
            type="text"
            width="280px"
            placeholder="Informe as dimensões da carga"
            value={palletType}
            onChange={(e) => setPalletType(e.target.value)} // Adicione o onChange handler apropriado
            className={"inputMaritime"}
          />
        </>
      }

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Pagamento das Taxas Locais de Origem */}
        <InputPrimeReact
          label="Pagamento das Taxas Locais de Origem"
          type="dropdown"
          options={[
            { label: "Brasil", value: "Brasil" },
            { label: "Exterior", value: "Exterior" },
          ]}
          value={localOriginFeesPayment}
          onChange={(e) => setLocalOriginFeesPayment(e.value)}
          placeholder="Selecione o local"
          className={"selecatMaritime"}
        />

        {/* Pagamento do THC de Destino */}
        <InputPrimeReact
          label="Pagamento do THC de Destino"
          type="dropdown"
          options={[
            { label: "Brasil", value: "Brasil" },
            { label: "Exterior", value: "Exterior" },
          ]}
          placeholder="Selecione o local"
          className={"selecatMaritime"}
          value={destinationTHCPayment}
          onChange={(e) => setDestinationTHCPayment(e.value)}
        />

        {/* Pagamento do frete */}
        <InputPrimeReact
          label="Pagamento do frete"
          type="dropdown"
          options={[
            { label: "Brasil", value: "Brasil" },
            { label: "Exterior", value: "Exterior" },
          ]}
          placeholder="Selecione o local"
          className={"selecatMaritime"}
          value={seaFreight}
          onChange={(e) => setSeaFreight(e.value)}
        />
      </div>
    </div>
  );
};

export default CardInfoQuotationAir;
