import React, { useState, useEffect } from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import {
  locale,
  addLocale,
  updateLocaleOption,
  updateLocaleOptions,
  localeOption,
  localeOptions,
} from "primereact/api";

const InputPrimeReact = ({
  label,
  type,
  value,
  onChange,
  minWidth,
  width,
  options,
  placeholder,
  className,
  optionLabel,
  optionValue,
  idStyle,
  currencyType,
  disabled,
  prefix,
  suffix,
  mode,
  minFractionDigits,
}) => {
  // State to store the current value and currency format
  const [inputValue, setInputValue] = useState(value);
  const [currentCurrency, setCurrentCurrency] = useState({
    locale: "pt-BR",
    currency: "BRL",
  });

  const style = {
    outline: "none",
    border: "2px solid #d7d7d7",
    padding: "6px 10px",
    borderRadius: "4px",
    width: width ? `${width}` : "100px",
    minWidth: minWidth ? `${minWidth}px` : "200px",
  };

  useEffect(() => {
    const currencyFormats = {
      BRL: { locale: "pt-BR", currency: "BRL" },
      USD: { locale: "en-US", currency: "USD" },
      EUR: { locale: "de-DE", currency: "EUR" },
    };

    // Update the currency format based on the provided currencyType
    setCurrentCurrency(currencyFormats[currencyType] || currencyFormats["BRL"]);
  }, [currencyType]);

  useEffect(() => {
    // Update the local state value whenever the external value changes
    setInputValue(value);
  }, [value]);

  locale("pt");

  const today = new Date().toLocaleString("en-US", {
    timeZone: "America/Sao_Paulo",
  });
  const minDate = new Date(today); // Convertendo para objeto Date
  minDate.setDate(minDate.getDate() + 1);

  addLocale("pt", {
    closeText: "Fechar",
    prevText: "Anterior",
    nextText: "Próximo",
    currentText: "Hoje",
    monthNames: [
      "janeiro",
      "fevereiro",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro",
    ],
    monthNamesShort: [
      "jan",
      "fev",
      "mar",
      "abr",
      "mai",
      "jun",
      "jul",
      "ago",
      "set",
      "out",
      "nov",
      "dez",
    ],
    dayNames: [
      "domingo",
      "segunda-feira",
      "terça-feira",
      "quarta-feira",
      "quinta-feira",
      "sexta-feira",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    weekHeader: "Semana",
    firstDay: 1,
    showMonthAfterYear: false,
    yearSuffix: "",
    timeOnlyTitle: "Só Horas",
    timeText: "Tempo",
    hourText: "Hora",
    minuteText: "Minuto",
    secondText: "Segundo",
    ampm: false,
    month: "Mês",
    week: "Semana",
    day: "Dia",
    allDayText: "Todo Dia",
    chooseYear: "Escolha o Ano",
    chooseMonth: "Escolha o Mês",
    chooseDate: "Escolha a Data",
  });

  const typeInputs = {
    currency: (
      <InputNumber
        inputId="currency-input"
        mode="currency"
        currency={currentCurrency.currency}
        locale={currentCurrency.locale}
        value={inputValue}
        onValueChange={(e) => {
          setInputValue(e.value);
          onChange(e);
        }}
        minFractionDigits={2}
        placeholder={`${
          currentCurrency.currency === "BRL" ? "R$" : currentCurrency.currency
        } 0,00`}
        className={`padding-prime ${className}`}
      />
    ),
    number: (
      <InputNumber
        className={className ?? "padding-prime"}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.value);
          onChange(e);
        }}
        useGrouping={false}
        prefix={prefix}
        suffix={suffix}
        mode={mode}
        placeholder={placeholder}
        minFractionDigits={minFractionDigits}
        disabled={disabled}
      />
    ),
    text: (
      <InputText
        style={style}
        value={inputValue}
        onChange={(e) => onChange(e)}
        className={className}
      />
    ),
    calendar: (
      <Calendar
        value={inputValue}
        onChange={(e) => onChange(e)}
        placeholder={"DD/MM/YYYY"}
        showIcon
        dateFormat="dd/mm/yy"
        className={className}
        locale="pt"
        minDate={minDate}
      />
    ),
    dropdownInput: (
      <Dropdown
        style={style}
        value={inputValue}
        onChange={(e) => onChange(e)}
        options={options}
        optionLabel={optionLabel}
        filter
        optionValue={optionValue}
        placeholder={placeholder}
        className="textSelectFilterManager"
        id={idStyle}
        emptyFilterMessage="Não encontrado"
        disabled={disabled}
      />
    ),
    dropdown: (
      <Dropdown
        style={style}
        value={inputValue}
        onChange={(e) => onChange(e)}
        options={options}
        optionLabel={optionLabel}
        optionValue={optionValue}
        placeholder={placeholder}
        className={className}
        id={idStyle}
        editable
        disabled={disabled}
        emptyFilterMessage="Não encontrado"
      />
    ),
  };

  return (
    <div
      className="flex flex-col gap-[8px]"
      style={{ display: "flex", flexDirection: "column", gap: "13px" }}
    >
      <p className="textPrimaryLabelInput">{label}</p>
      {typeInputs[type]}
    </div>
  );
};

export default InputPrimeReact;
