const TableProposalHeader = () => {
  const styleColum1 = {
    maxWidth: "1000px",
    border: "1px solid #D7D7D7",
    height: "55px",
    borderRadius: "10px",
    background: "white",
    display: "flex",
    alignItems: "center",
  };

  return (
    <div className="containerBox">
      <div style={styleColum1}>
        <div
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <p className="textNameTableListUser transformY5">Cliente</p>

          <p className="textNameTableListUser transformY5">N° Proposta</p>

          <p className="textNameTableListUser transformY5">Tipo</p>

          <p className="textNameTableListUser transformY5">Valor</p>

          <p className="textNameTableListUser transformY5">Visualizado</p>

          <p className="textNameTableListUser transformY5">Status</p>
        </div>
      </div>
    </div>
  );
};

export default TableProposalHeader;
