import filterIcon from "../images/svg/filterIcon.svg";

const FilterPage = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        border: "1px solid #d7d7d7",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "9px 10px",
        borderRadius: "8px",
        cursor: "pointer",
        backgroundColor: "#fff",
      }}
    >
      <img src={filterIcon} />
    </div>
  );
};

export default FilterPage;
