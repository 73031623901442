import { useState } from "react";
import CardUserInput from "./CardUserInput";
import CardUserList from "./CardUserList";
import api from "../services/api";
import { useEffect } from "react";
import LetterLimit from "../utils/LimitCharacters";

const InputSelectUsers = ({
  name,
  width,
  listDiscount,
  setListDiscount,
  id,
}) => {
  const [optionSelect, setOptionSelect] = useState(false);
  const [inputOrDiv, setInputOrDiv] = useState(false);
  const [listUsers, setListUsers] = useState([]);

  const [search, setSearch] = useState([]);
  const [listLodding, setListLodding] = useState(false);

  const getUsers = async () => {
    const response = await api.get("/user");
    setListUsers(response.data);
    setSearch(response.data);
  };

  const getVisibility = async () => {
    if (id) {
      const response = await api.get(`/quotation/${id}`);

      const { name, visibility, type, rota } = response.data;

      if (visibility == "Todos") {
        const response = await api.get(`/discounts/${id}`);
        setListDiscount(response.data);
        setListLodding(true);
        return false;
      }

      setListDiscount(visibility);
      setListLodding(true);
      return false;
    }
  };

  const addDiscount = async (id) => {
    const response = await api.get(`/user/${id}`);

    const verifyExistUser = listDiscount.filter(
      (item) => item.name == response.data.name
    );

    if (verifyExistUser.length > 0) {
      return false;
    }

    setListDiscount([...listDiscount, response.data]);
    setOptionSelect(false);
    setInputOrDiv(false);
    setListLodding(true);
  };

  const removeDiscount = async (id) => {
    const removeUser = listDiscount.filter((item) => item.id != id);

    setListDiscount(removeUser);
  };

  const listUsersByName = async (name) => {
    if (String(name).length == 0) {
      return setListUsers(search);
    }

    search.map((item) => {
      if (item.name.includes(name)) {
        setListUsers([item]);
      } else {
        setListUsers([]);
      }
    });
  };

  useEffect(() => {
    getUsers();
    getVisibility();
  }, []);

  return (
    <div
      style={{
        width: width,
        display: "flex",
        flexDirection: "column",
        gap: "14px",
        padding: "7px 0",
        position: "relative",
      }}
    >
      <label>{name}</label>

      {inputOrDiv ? (
        <input
          onChange={(e) => listUsersByName(e.target.value)}
          onClick={() => {
            setOptionSelect(true);
          }}
          onBlur={() => {
            setOptionSelect(false);
            setInputOrDiv(false);
          }}
          style={{
            width: "100%",
            overflowX: "auto",
            borderRadius: "10px",
            border: "1px solid #D7D7D7",
            height: "50px",
            outline: "none",
            padding: "0 10px",
          }}
        />
      ) : (
        <div
          onBlur={() => {
            setOptionSelect(false);
            setInputOrDiv(false);
          }}
          style={{
            width: "100%",
            borderRadius: "10px",
            border: "1px solid #D7D7D7",
            height: "50px",
            outline: "none",
            padding: "10px 10px 5px",
          }}
        >
          <div
            style={{
              display: "flex",
              overflow: "hidden",
              alignItems: "center",
              gap: "10px",
              width: "100%",
              overflow: "hidden",
              overflowX: "auto",
            }}
          >
            {listDiscount?.length > 0 &&
              listLodding == true &&
              listDiscount?.map((item) => {
                return (
                  <>
                    <CardUserList
                      onClick={() => removeDiscount(item.id)}
                      name={item.corporate_name}
                      cnpj={item.cnpj}
                    />
                  </>
                );
              })}

            <div
              onClick={() => {
                setOptionSelect(true);
                setInputOrDiv(true);
              }}
              style={{
                height: "30px",
                width: "100%",
              }}
            ></div>
          </div>
        </div>
      )}

      {optionSelect && (
        <div
          style={{
            position: "absolute",
            width: "300px",
            height: "200px",
            backgroundColor: "#fff",
            zIndex: "5",
            border: "1px solid #d7d7d7",
            borderRadius: "0 0 10px 10px",
            top: "95px",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              overflow: "hidden",
              overflowY: "auto",
              height: "100%",
            }}
          >
            {listUsers?.map((item) => {
              return (
                <>
                  <CardUserInput
                    onClick={() => addDiscount(item.id)}
                    name={
                      <LetterLimit
                        limit={20}
                        id={"type"}
                        threePoints={true}
                        text={item.corporate_name}
                      />
                    }
                  />
                </>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default InputSelectUsers;
